const {EditorState} = require("prosemirror-state");
const {EditorView} = require("prosemirror-view");
const ComponentLoader = require('client/src/component-loader').default;
const TooltipPluginFactory = require('./tooltip-plugin').default;

const schema = require('./markdown-schema');
const buildMarkdownEditorMenu = require('./markdown-editor-menu.js').default;
const markdownParser = require('./markdown-parser');
const markdownSerializer = require('./markdown-serializer');
const {exampleSetup} = require("prosemirror-example-setup");
const buildFileTooltip = require('./file-tooltip').default;

const {isString, debounce} = require('lodash');
const EventInterface = require('core/src/event-interface');
const def = require('utils/src/validation').def;

// Styles
require('prosemirror-view/style/prosemirror.css');
require('prosemirror-menu/style/menu.css');
require('prosemirror-example-setup/style/style.css');
require('./markdown.scss');
require('./markdown-editor.scss');
require('./prosemirror.css');

class MarkdownEditor {
	constructor(dependencies, target, markdown) {
		this._setup(dependencies);

		if(!isString(markdown)) markdown = '';
		this.target = target;
		this._componentLoader = new ComponentLoader(dependencies);

		this._eventInterface = new EventInterface();
		this._eventInterface.extend(this);

		this.setContent(markdown);
	}

	_setup(dependencies) {
		let tooltipPluginFactory = new TooltipPluginFactory();
		const fileTooltip = buildFileTooltip(dependencies)
		tooltipPluginFactory.addTooltip(fileTooltip);

		const menu = buildMarkdownEditorMenu(dependencies);

		this.plugins = exampleSetup({schema: schema, menuContent: menu.fullMenu});
		this.plugins.push(tooltipPluginFactory.createTooltipPlugin());
	}

	setContent(markdown) {
		const doc = markdownParser.parse(markdown);

		// I have not found a way to replace just the document in ProseMirror, seems like it has be constructed with it.
		if(def(this.view)) {
			this.view.destroy();
		}

		this.view = new EditorView(this.target, {
			state: EditorState.create({
				doc: doc,
				plugins: this.plugins
			}),
			handleDOMEvents: {
				blur: (view, event) => {
					this.fire('blur');
				},

			},
			handleKeyDown: debounce((view, event) => {
				this.fire('change');
			}, 500)
		});
		$(this.target).addClass('markdown-output');
		this._componentLoader.loadComponents(this.target, {
			exclude: ['markdown-editor']
		});
	}

	getContent() {
		return markdownSerializer.serialize(this.view.state.doc);
	}

	focus() { this.view.focus(); }
	destroy() { this.view.destroy(); }
}

export default MarkdownEditor;
