const Function = require('core/src/function');
const IAPI = require('core/src/api-client-abstract').default;
const _ = require('core/src/utils/legacy');
const Validation = require('core/src/utils/validation');
const Language = require('core/src/language').default;
const {default: readOnly, isReadOnly, writable} = require('core/src/utils/read-only');

const SessionMessage = Function.extend(Function, 'SessionMessage');

SessionMessage.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);

	let params = {
		'$channel': undefined,
		'$data': undefined,
		'$stayAlive': 'dashboard' 
	};
	this.setParameters(params);

	var notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		channel	: ['isString', {default: '', warn: _.def}],
		data 	: ['isObject', notRequired],
	});
};

SessionMessage.prototype.onExecute = function(){
	this.broadcastChannel = new BroadcastChannel(this.readModel('channel'));

	this.broadcastChannel.onmessage = (event) => {
		this.executeTriggers({
			type: 'messageReceived',
			data: event.data
		});
	};

	this.sendMessage();
};

SessionMessage.prototype.onUpdate = function(){
	this.sendMessage();
}

SessionMessage.prototype.sendMessage = function(){
	let data = this.readModel('data');

	if (_.isNil(data)) {
		return;
	}

	if (isReadOnly(data)) {
		data = data.$writable();
	}

	this.broadcastChannel.postMessage(data);

	// reset $data state
	this.update({
		data: null
	});
}

SessionMessage.prototype.onClose = function() {
	this.broadcastChannel.close();
}

module.exports = SessionMessage;