const Function = require('core/src/function');

const Parser = require('papaparse');
const _ = require('lodash');
const {def} = require('utils/src/validation');

const CsvParser = Function.extend(Function, "CsvParser");

CsvParser.prototype.onInit = function () {
	this.setParameters({
		'$csv': "",
		'$options': {
			"delimiter": "",	// auto-detect
			"newline": "",	// auto-detect
			"quoteChar": '"',
			"escapeChar": '"',
			"header": false,
			"transformHeader": undefined,
			"dynamicTyping": false,
			"preview": 0,
			"encoding": "",
			"worker": false,
			"comments": false,
			"step": undefined,
			"complete": undefined,
			"error": undefined,
			"download": false,
			"downloadRequestHeaders": undefined,
			"skipEmptyLines": false,
			"chunk": undefined,
			"fastMode": undefined,
			"beforeFirstChunk": undefined,
			"withCredentials": undefined,
			"transform": undefined,
			"delimitersToGuess": [',', '\t', '|', ';', Parser.RECORD_SEP, Parser.UNIT_SEP]
		}
	});

	const notRequired = {default: undefined, warn: def};
	this.setModelValidation({
		'csv': ['isString'],
		'options': ['isObject', notRequired],
	});
};

CsvParser.prototype.onExecute = function (input) {
	try{
		let data = Parser.parse(input.csv, input.options);
		this.executeTriggers(_.assign(data,{type: 'success'}));
	}catch (e) {
		this.error(e);
	}
};

module.exports = CsvParser;