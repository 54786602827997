const fileTemplate = require('./filewidget.nghtml');
const EntityFormViewRenderer = require('client/src/renderers/entity-form-view-renderer');
const IAPI = require('core/src/api-client-abstract').default;

module.exports = addFileWidgetToJSONForms;

// Extend an Angular app that depends on JSONForms
// with a file widget for the JSONForms
//
// @params app: An Angular app that is already loaded 'jsonforms'
function addFileWidgetToJSONForms(ngapp, dependencies) {
	return ngapp
		.directive("ngFileread", function () {
			return {
				restrict: 'A',
				scope: {
					ngFileread: "="
				},

				link: function (scope, elem) {
					elem[0].addEventListener('change', scope.ngFileread.bind(scope));
				}
			};
		})
		.directive('fileControl', function() {
			return {
				restrict: 'E',
				controller: ['BaseController', '$scope', function(BaseController, $scope) {
					BaseController.call(this, $scope);
					this.triggerChangeEvent = BaseController.prototype.triggerChangeEvent;
					this.validate = BaseController.prototype.validate;

					this.id = $scope.uischema.scope.$ref;
					var vm = this;

					this.handleFileRead = async function (readEvent) {
						let api = dependencies.get(IAPI);
						api.upload(readEvent.target.files[0])
							.then(response => {
								$scope.$apply(function() {
									vm.resolvedData[vm.fragment] = response.download_link;
								});
								vm.triggerChangeEvent();
							});
					};
				}],
				controllerAs: 'vm',
				template: fileTemplate
			};
		})
		.run(['RendererService', 'JSONFormsTesters', function(RendererService, Testers) {
			RendererService.register('file-control', Testers.and(
				Testers.uiTypeIs('Control'),
				Testers.schemaTypeMatches(function (el) { return el.file; })
			), 10);
		}])
		.run(['$templateCache', function ($templateCache) {
			$templateCache.put('file.html', fileTemplate);
		}]);
}
