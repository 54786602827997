const _ = require('lodash');

const LEAVES_FIRST = 1; // call the iterator/function for collection's items first and then for the whole collection (object/array)
const BRANCH_FIRST = 2; // call the iterator/function for the collection first and then for each item

// Similar to _.defaultsDeep
// Imposes type of values from `defaults` for non matching objects or arrays
// Functions mutates obj

function defaultsDeepByKeys(obj, defaults) {
	_.forEach(defaults, (value, key) => {
		if( !(key in obj)
			|| _.isPlainObject(value) && ! _.isPlainObject(obj[key])
			|| _.isArray(value) && ! _.isArray(obj[key])
		) {
			// Missing key or non-matching types: overwrite value
			obj[key] = value;
			return;
		}

		if(_.isPlainObject(value) || _.isArray(value)) {
			defaultsDeepByKeys(obj[key], value);
		}
	});
}

// Returns path (array of keys) to first value that makes predicate truthy.
// Searching is done breadth-first

function findKeyDeep(deepStruct, predicate) {
	let __findKey = (deepStruct, predicate, path = []) => {
		let foundKey = _.findKey(deepStruct, predicate);

		if (foundKey !== undefined){
			return _.concat(path, [foundKey]);
		}

		_.forEach(
			deepStruct,
			(value, key) => {
				if (! _.isArray(value) && ! _.isObjectLike(value)) {
					return;
				}

				foundKey = __findKey(value, predicate, _.concat(path, key));

				if (foundKey !== undefined) return false;
			}
		);

		if (_.isArray(foundKey)) return foundKey;

		return;
	};

	return __findKey(deepStruct, predicate);
}



// maps a NONCIRCULAR nested structure and sets key = func(value, keyPath, structure) for each value (except root), returns a new structure
// For BRANCH_FIRST, func is called for the items in the result of the call for the collection (brach) (NOT the original key-value pairs)
function mapDeep(deepStruct, func, mode = BRANCH_FIRST) {
	let _mapDeep = (value, path = []) => {
		if (mode === BRANCH_FIRST) {
			value = (_.isEmpty(path) ? value : func(value, path, deepStruct));
		}

		if (_.isArray(value)) {
			value = _.map(
				value, 
				(value, key) => _mapDeep(value, _.concat(path, [key]))
			);
		}
		else if(_.isObjectLike(value)) {
			value =_.mapValues(
				value,	
				(value, key) => _mapDeep(value, _.concat(path, [key]))
			)
		}
		
		if (mode === LEAVES_FIRST) {
			value = (_.isEmpty(path) ? value : func(value, path, deepStruct));
		}		

		return value;
	}

	return _mapDeep(deepStruct);
}



function reduceDeep(deepStruct, func, initialValue, mode = BRANCH_FIRST) {
	let result = initialValue;
	mapDeep(
		deepStruct,
		(value, path, struct) =>  {
			result = func(result, value, path, struct);
			return value;
		}, 
		mode
	);
	return result;
}



module.exports = {
	defaultsDeepByKeys,
	findKeyDeep,
	mapDeep,
	reduceDeep,
	BRANCH_FIRST,
	LEAVES_FIRST
};
