const Function = require('core/src/function');
const View = require('core/src/functions/view');

const ChartView = Function.extend(View, "ChartView");

/**
 * @override
 * @param {object} input The data to pass with the event.
 * @returns {object} The data to be passed with the RenderEvent
 */
ChartView.prototype.alterRenderData = function(renderData) {
	return renderData;
};

/**
 * @override
 * @returns {undefined}
 */
ChartView.prototype.onInit = function() {
	this.setParameters({
		'$chartType': undefined,
		'#data': undefined,
		'$column': undefined,
		'$options': undefined
	});
};

module.exports = ChartView;