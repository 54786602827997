'use strict';

const { t } = require('core/src/language');
const _ = require('core/src/utils/legacy');
const { has } = require('lodash');

var GraphSelector = function(selector) {
	this.type = '';
	this.selectorBody = '';
	this.selector = '';
	this.ok = true;

	this.setSelector(selector);
};

GraphSelector.prototype.isOk = function() {
	return this.ok;
};

GraphSelector.prototype.setSelector = function(selector) {
	this.ok = true;
	if (!_.isString(selector)) {
		console.error('GraphSelector.setSelector: selector should be string', selector);
		this.ok = false;
		this.selector = false;
		return;
	}

	var expandedSelector = GraphSelector.expandSelector(selector);

	if (expandedSelector.isWrong) {
		this.ok = false;
		console.error('GraphSelector.setSelector: selector is invalid', selector);
		return false;
	}

	this.type = null;

	if (expandedSelector.node && !expandedSelector.rel) {
		this.type = 'node';
		this.selectorBody = selector.replace('node', '');
	}

	if (expandedSelector.rel && !expandedSelector.node){
		this.type = 'rel';
		this.selectorBody = selector.replace('rel', '');
	}


	if (!this.type) {
		console.error('GraphSelector.setSelector: selector must refer to node or rel');
		this.ok = false;
	}

	if (this.isOk) {
		this.selector = selector;
		this.expandedSelector = expandedSelector;
	}

	return this.isOk();
};

GraphSelector.prototype.getFullSelector = function() {
	return this.type + this.selectorBody;
};

GraphSelector.prototype.getSelector = function() {
	return this.selectorBody;
};

GraphSelector.prototype.isNodeSelector = function() {
	if ( !this.isOk()) {
		return false;
	}

	if (this.type === 'node') {
		return true;
	}

	return false;
};

GraphSelector.prototype.isRelationSelector = function() {
	if ( !this.isOk()) {
		return false;
	}

	if (this.type === 'rel') {
		return true;
	}

	return false;
};

GraphSelector.prototype.getExpandedSelector = function() {
	if (!this.expandedSelector) {
		console.error('Should not be here');
		this.expandedSelector = GraphSelector.expandSelector(this.getFullSelector());
		this.ok = true;
		if (this.expandedSelector.isWrong) {
			this.ok = false;
		}
	}

	return this.expandedSelector;
};
GraphSelector.prototype.matchesNode = function(node) {
	if (!this.isNodeSelector()) {
		return false;
	}

	if (GraphSelector.matchNodeSelector(node, this.getExpandedSelector())) {
		return true;
	}

	return false;
};

GraphSelector.prototype.matchesRelation = function(relation) {
	if (!this.isRelationSelector()) {
		return false;
	}

	if (GraphSelector.matchRelSelector(relation, this.getExpandedSelector())) {
		return true;
	}

	return false;
};

GraphSelector.prototype.createMatchingNode = function() {

	var expandedSelector = this.getExpandedSelector();

	if ( ! this.isOk()) {
		return _.withError(`GraphSelector.createMatchingNode: ${t('selector is not valid')}`, this);
	}

	if ( ! this.isNodeSelector()) {
		return _.withError(`GraphSelector.createMatchingNode: ${t('selector is not valid node selector')}`, this);
	}

	var node = {
		id: _.uniqueId(),
		labels: [],
		properties: {}
	};

	_.forEach(expandedSelector.label, function(label) {
		node.labels.push(label.label);
	}, this);


	_.forEach(expandedSelector.property, function(property) {
		if ((property.operation === '=') || _.isEmpty(property.operation)) {
			node.properties[property.property] = property.value;
		}

		if (property.operation === '>') {
			node.properties[property.property] = GraphSelector.getGreaterValue(property.value);
		}

		if (property.operation === '<') {
			node.properties[property.property] = GraphSelector.getSmallerValue(property.value);
		}

	}, this);

	return node;
};

GraphSelector.prototype.createMatchingRelation = function() {
	var expandedSelector = this.getExpandedSelector();

	if ( ! this.isOk()) {
		return _.withError(`GraphSelector.createMatchingRelation: ${t('selector is not valid')}`, this);
	}

	if ( ! this.isRelationSelector()) {
		return _.withError(`GraphSelector.createMatchingRelation: ${t('selector is not valid relation selector')}`, this);
	}

	var relation = {
		id: _.uniqueId(),
		type: '',
		properties: {},
	};

	if ( ! _.isEmpty(expandedSelector.label)) {
		relation.type = expandedSelector.label[0].label;
	}

	_.forEach(expandedSelector.property, function(property) {
		if ((property.operation == '=') || _.isEmpty(property.operation)) {
			relation.properties[property.property] = property.value;
		}

		if (property.operation == '>' || property.operation == '>=') {
			relation.properties[property.property] = GraphSelector.getGreaterValue(property.value);
		}

		if (property.operation == '<' || property.operation == '<=') {
			relation.properties[property.property] = GraphSelector.getSmallerValue(property.value);
		}

		if ((property.operation == '<>')) {
			relation.properties[property.property] = GraphSelector.getGreaterValue(property.value);
		}

	}, this);

	return relation;
};

GraphSelector.getGreaterValue = function (value) {
	if (_.isNumeric(value)) {
		return value + 1;
	}

	if (_.isString(value)) {
		return String.fromCharCode(value.charCodeAt(0) + 1);
	}

	return false;
};


GraphSelector.getSmallerValue = function(value) {
	if (_.isNumeric(value)) {
		return value - 1;
	}

	if (_.isString(value)) {
		return String.fromCharCode(value.charCodeAt(0) - 1);
	}

	return false;
};


GraphSelector.isGraphSelectorObject = function(item) {
	if (GraphSelector.prototype.isPrototypeOf(item)) {
		return true;
	}
	return false;
};

GraphSelector.selectorFromNode = function(node) {
	if (! _.isObject(node)) {
		return _.withError(`selectorFromNode: ${t('node object expected')}`, node);
	}

	if (! _.isArray(node['labels'])) {
		return _.withError(`selectorFromNode: ${t('node has no labels')}`, node);
	}

	var result = '';

	_.forEach(node['labels'], function(label) {
		result += ':' + label;
	});

	return result;
};

GraphSelector.selectorFromRelation = function(rel) {
	if (! _.isObject(rel)) {
		return _.withError(`selectorFromRelation: ${t('relation object expected')}`, rel);
	}

	if (! rel['type']) {
		return _.withError(`selectorFromRelation: ${t('relation has no type')}`, rel);
	}

	return ':' + rel['type'];
};


/*
 * Transform the string selector into a usable object
 * @param {String} The selector
 * @returns {Object} The object that contains the expanded selector into rules
 */
GraphSelector.expandSelector = function(Selector)
{
	if ( ! _.isString(Selector) || _.isEmpty(Selector)) {
		return _.withError([`GraphSelector.expandSelector(): ${t('selector must be a non empty string')}`, Selector]);
	}
	if (_.has(GraphSelector.expandSelector.expandedSelectors, Selector)) {
		return GraphSelector.expandSelector.expandedSelectors[Selector];
	}
	var originalSelector = Selector;

	// Object to be returned
	var expanded = {
		'selector': Selector,
		'node': true,
		'rel': true,
		'group': true,
		'property': [],
		'root': [],
		'label': [],
		'isWrong': false
	};

	// Selectors
	var whitespace = "[\\x20\\t\\r\\n\\f]";
	var identifier = "(?:\\\\.|[\\w-\\(\\)\x20]|[^\x00-\xa0]|([+-]?\\d+\\.\\d+?))+";

	// Property regex
	var attributes = "\\[" + whitespace + "*(" + identifier + ")(?:" + whitespace +
		// Operator (capture 2)
		"*([*^$|!~]?[=\>\<]{1,2})" + whitespace +
		// "Attribute values must be CSS identifiers [capture `] or strings [capture 3 or capture 4]"
		"*(?:'((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\"|(" + identifier + "))|)" + whitespace +
		"*\\]";
	var propertySelector = new RegExp(attributes);

	// Find properties
	var property;
	var property_aux = {};
	while(property = Selector.match(propertySelector)){
		// If we find a property save and remove it from the string and keep on searching for more
		// Save the property
		property_aux = {
			'string_matched': property[0],
			'property': property[1],
			'value': property[6],
			'operation': property[3]
		};
		expanded.property.push(property_aux);

		// Remove it from the string
		Selector = Selector.replace(propertySelector, '');
	}

	// Root regex
	var root = "\\{" + whitespace + "*(" + identifier + ")(?:" + whitespace +
		// Operator (capture 2)
		"*([*^$|!~]?[=\>\<]{1,2})" + whitespace +
		// "Attribute values must be CSS identifiers [capture `] or strings [capture 3 or capture 4]"
		"*(?:'((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\"|(" + identifier + "))|)" + whitespace +
		"*\\}";
	var rootSelector = new RegExp(root);

	// Find root properties
	property_aux = {};
	while(property = Selector.match(rootSelector)){
		// If we find a property save and remove it from the string and keep on searching for more
		// Save the property
		property_aux = {
			'string_matched': property[0],
			'property': property[1],
			'value': property[6],
			'operation': property[3]
		};
		expanded.root.push(property_aux);

		// Remove it from the string
		Selector = Selector.replace(rootSelector, '');
	}

	// Label regex
	var label = ":(" + identifier + ")(?:\\((" +
		// To reduce the number of selectors needing tokenize in the preFilter, prefer arguments:
		// 1. quoted (capture 3; capture 4 or capture 5)
		"('((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\")|" +
		// 2. simple (capture 6)
		"((?:\\\\.|[^\\\\()[\\]]|" + attributes + ")*)|" +
		// 3. anything else (capture 2)
		".*" +
		")\\)|)";
	var labelSelector = new RegExp(label);

	// Find labels
	var label_aux = {};
	while(label = Selector.match(labelSelector)){
		// If we find a label save and remove it from the string and keep on searching for more
		// Save the label
		label_aux = {
			'string_matched': label[0],
			'label': label[1]
		};
		expanded.label.push(label_aux);

		// Remove it from the string
		Selector = Selector.replace(labelSelector, '');
	}

	// It applies for nodes only
	if (Selector.substring(0, 4) === 'node'){
		expanded.rel = false;
		expanded.group = false;
		Selector = Selector.replace('node', '');
	}

	// It applies for rel only
	if (Selector.substring(0, 3) === 'rel'){
		expanded.node = false;
		expanded.group = false;
		Selector = Selector.replace('rel', '');
	}

	// It applies for group only
	if(Selector.substring(0,5) === 'group') {
		expanded.node = false;
		expanded.rel = false;
		Selector = Selector.replace('group', '');
	}

	if (Selector != '') {
		expanded.isWrong = true;
	}

	GraphSelector.expandSelector.expandedSelectors[originalSelector] = expanded;

	return expanded;
};

GraphSelector.expandSelector.expandedSelectors = {};

/*
 * Check if a node matches the expanded selector
 * @param {Object} The node object that needs to be checked against the selector
 * @param {Object}/{String} The (expanded) selector that needs to be matched against the node
 * @returns {boolean} True if the node matches all the conditions
 */
GraphSelector.matchNodeSelector = function(Node, Selector) {

	if (!Selector) {
		return false;
	}

	if (typeof Selector === 'string'){
		Selector = GraphSelector.expandSelector(Selector);
	}

	if (!('node' in Selector) || !Selector.node) {
		return false;
	}

	return GraphSelector.matchNodeLabels(Node, Selector.label)
		&& GraphSelector.matchNodeProperties(Node, Selector.property)
		&& GraphSelector.matchEntityRoot(Node, Selector.root);
};

/*
 * Check if a link matches the expanded selector
 * @param {Object} The link object that needs to be checked against the selector
 * @param {Object}/{String} The (expanded) selector that needs to be matched against the link
 * @returns {boolean} True if the link matches all the conditions
 */
GraphSelector.matchRelSelector = function(Link, Selector)
{
	if (typeof Selector === 'string'){
		Selector = GraphSelector.expandSelector(Selector);
	}

	if (!Selector.rel) {
		return false;
	}

	return GraphSelector.matchRelType(Link, Selector.label)
		&& GraphSelector.matchRelProperties(Link, Selector.property)
		&& GraphSelector.matchEntityRoot(Link, Selector.root);
};

GraphSelector.matchGroupSelector = function(group, selector) {
	if(typeof selector === 'string') {
		selector = GraphSelector.expandSelector(selector);
	}

	if(!selector.group) {
		return false;
	}

	return GraphSelector.matchProperties(group.properties, selector.property)
		&& GraphSelector.matchProperties(group, selector.root);
};


/*
 * Checks if the node has all the respective labels
 * @param {Object} Graph node
 * @param {Object} Label object from expanded selector
 * @returns {boolean} True if the node has all the labels, false otherwise
 */
GraphSelector.matchNodeLabels = function(Node, Labels)
{
	var all_match = true;
	var index_label;
	var selector_label;

	if (! _.isArray(Node.labels)) {
		return false;
	}

	for (index_label = 0 ; index_label < Labels.length ; index_label++){
		selector_label = Labels[index_label];

		// Backward compatibility for multi-label Functions: if type is equal to label consider it matching
		if (has(Node, 'properties.type') && Node.properties.type && `IA_${Node.properties.type}` === selector_label.label
				&& Node.labels.indexOf('IA_Function') >= 0){
			break;
		}

		// If one label does not match do not consider it matching
		if (Node.labels.indexOf(selector_label.label) === -1){
			all_match = false;
			break;
		}
	}

	return all_match;
};

GraphSelector.matchProperties = function(properties, selectorProperties) {
	var all_match = true;
	var index_property;
	var selector_property;

	var operators = {
		'>': function(a,b) { return a > b; },
		'<': function(a,b) { return a < b; },
		'>=': function(a,b) { return a >= b; },
		'<=': function(a,b) { return a <= b; },
		'=': function(a,b) { return a === b; },
		'!=': function(a,b) { return a !== b; },
		'<>': function(a,b) { return a !== b; }
	};

	function parseNumericIfPossible(value) {
		return _.isNumeric(value) ? parseFloat(value) : value;
	}
	function parseBooleanIfPossible(value) {
		return value === "true" || value === "false" ? value === "true" : value;
	}
	function parseIfPossible(value) {
		if(!_.isString(value)) return value;

		value = parseBooleanIfPossible(value);
		if(!_.isString(value)) return value;

		value = parseNumericIfPossible(value);

		return value;
	}

	for (index_property = 0 ; index_property < selectorProperties.length ; index_property++){
		selector_property = selectorProperties[index_property];

		// If one property does not match, do not consider it matching
		if (!_.isPlainObject(properties) || !properties.hasOwnProperty(selector_property.property)){
			all_match = false;
			break;
		}

		// If the property matches and value needs to be checked
		if (typeof selector_property.value !== 'undefined') {
			var selectorValue = parseIfPossible(selector_property.value);
			var nodeValue = parseIfPossible(properties[selector_property.property]);
			var compare = operators[selector_property.operation] || function() { return false; };
			if (!compare(nodeValue, selectorValue)){
				all_match = false;
				break;
			}
		}
	}

	return all_match;
};

/*
 * Checks if the node has all the respective properties
 * @param {Object} Graph node
 * @param {Array} Property object from expanded selector
 * @returns {boolean} True if the node has all the properties, false otherwise
 */
GraphSelector.matchNodeProperties = function(node, selectorProperties)  {
	// Backward compatibility for multi-label Functions: if labels contain specified type, also match
	const typePropertyIndex = _.findIndex(selectorProperties, {property: 'type'});
	const typeProperty = selectorProperties[typePropertyIndex];
	if(typeProperty && _.includes(node.labels, 'IA_Function') && _.includes(node.labels, 'IA_' + typeProperty.value)) {
		// 'type' property matched by label: remove 'type' from required matches
		selectorProperties = [...selectorProperties];
		selectorProperties.splice(typePropertyIndex, 1);
	}
	return GraphSelector.matchProperties(node.properties, selectorProperties);
};

GraphSelector.matchEntityRoot = function(Entity, Root) {
	return GraphSelector.matchProperties(Entity, Root);
};

/*
 * Checks if the link has all the respective properties
 * @param {Object} Graph link
 * @param {Object} Type (label) object from the expanded selector
 * @returns {boolean} True if the link type matches the first label, false otherwise
 */
GraphSelector.matchRelType = function(Link, Type)
{
	if (Type.length > 0){
		return Link.type === Type[0].label;
	}
	else{
		return true;
	}
};

/*
 * Checks if the link has all the respective properties
 * @param {Object} Graph link
 * @param {Object} Properties object from expanded selector
 * @returns {boolean} True if the link has all the properties, false otherwise
 */
GraphSelector.matchRelProperties = function(Link, Properties) {
	return GraphSelector.matchProperties(Link.properties, Properties);
};

module.exports = GraphSelector;
