const VueRenderer = require('../vue-renderer');
const PropertiesView = require('./properties-view.vue').default;

const log = require('core/src/log').instance("function/propertiesview");
/**
 *
 * @constructor
 */
const PropertiesViewRenderer = function (dependancies) {
	VueRenderer.call(this, dependancies);
};

PropertiesViewRenderer.viewType = 'PropertiesView';
PropertiesViewRenderer.prototype = Object.create(VueRenderer.prototype);

/**
 *
 * @returns {*}
 */
PropertiesViewRenderer.prototype.getVueComponent = function() {
	return PropertiesView;
};

module.exports = PropertiesViewRenderer;