const _ = require('core/src/utils/legacy');

const IAPI = require('core/src/api-client-abstract').default;
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const Language = require('core/src/language').default;

const NetworkStylesView = Function.extend(View, "NetworkStylesView");

NetworkStylesView.prototype.onInit = function(dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);

	this.setParameters({
		'$styles': undefined,
		'$node': undefined,
		'$relation': undefined,
		'$store': undefined
	});
};

/**
 * @override
 *
 * @param input
 * @returns {*}
 */
NetworkStylesView.prototype.setModelFromInput = function(input) {
	var changed = View.prototype.setModelFromInput.call(this, input);

	var self = this;

	var deferred = new $.Deferred();

	var node = _.get(input, 'node');
	var rel = _.get(input, 'relation');
	var store = _.get(input, 'store') || 'application';

	var requests = {};
	if(node !== undefined) {
		if(_.isValidNode(node)) {
			changed = this.updateModel('node', node) || changed;
		} else {
			requests.node = this.api.loadNode(node, store);
		}
	}
	if(rel !== undefined) {
		if(_.isValidRelation(rel)) {
			changed = this.updateModel('relation', rel) || changed;
		} else {
			requests.relation = this.api.loadRelation(rel, store);
		}
	}

	var wait = _.waitForAll(requests, 5000);
	wait.then(
		function(responses) {
			if('node' in responses) {
				changed = self.updateModel('node', responses.node) || changed;
			}
			if('relation' in responses) {
				changed = self.updateModel('relation', responses.relation) || changed;
			}
			deferred.resolve(changed);
		},
		function(err) {
			deferred.reject(new _.Error({
				message: self.language.translate('Could not load StylesView.'),
				originalError: err
			}));
		});

	return deferred.promise();
};

NetworkStylesView.prototype.alterRenderData = function(input) {
	return input;
};

module.exports = NetworkStylesView;