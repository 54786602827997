
	const _ = require('lodash');

	export default {
		props: {
			title: String,
			height: Number,
			width: Number,
			closable: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				config: {}
			}
		},
		computed: {
			contentStyle() {
				let style = {}

				if (! _.isNil(this.height)) {
					style.height = this.height >= 0 ? this.height + 'px' : this.height;
				}

				if (! _.isNil(this.width)) {
					style.width = this.width >= 0 ? this.width + 'px' : this.width
				}

				return style;
			}
		},
		methods: {
			setConfig(config) {
				_.forEach(config, (value, key) => {
					this.$set(this.config, key, value);
				});
			},
			setContent(content) {
				if(!('content' in this.$refs)) {
					console.warn("No 'content' ref defined in Container component. Cannot set content.");
					return;
				}
				this.$refs.content.innerHTML = '';
				this.$refs.content.appendChild(content);
			},
			close() {
				this.$emit('close', true);
			},
			onClose() {

			}
		}
	}
