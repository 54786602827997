const Function = require('core/src/function');
const IGraphileon = require('core/src/i-graphileon').default;
const IAPI = require('core/src/api-client-abstract').default;
const _ = require('core/src/utils/legacy');

const DeleteRelation = Function.extend(Function, "DeleteRelation");

/**
 * @override
 * @returns {undefined}
 */
DeleteRelation.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.graphileon = dependencies.get(IGraphileon);

	this.setParameters({
		'$id': undefined,
		'$store': undefined,
		'$container': undefined,
		'$container.height': 200,
		'$area': undefined,
		'$confirm': true,
		'$stayAlive': 'dashboard' // Should stay alive until rel is deleted or user cancels
	});

	const notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		id			: 'isStringOrNumber',
		store		: 'isString',
		confirm		: ['isBooleanParsable', notRequired],
		area		: ['isString', notRequired],
		container	: [function(val) { return _.isString(val) || _.isObject(val); }, this.graphileon.translate('Must be string or object.'), notRequired]
	});
};

/**
 * Delete the specified relation from the specified store
 *
 * @param  {number|string}	relationID	  	the relation ID
 * @param  {string}	 store		   the name of the store
 *
 * @return {boolean}					it always returns true
 */
DeleteRelation.prototype.executeDeleteRelation = async function(relationID, store) {
	try {
		let relation = await this.api.loadRelation(relationID, store);
		let success = await this.api.delete(relation);
		this.executeTriggers({
			type: 'deleted',
			relation: relation,
			deleted: success
		});
	} catch(e) {
		this.graphileon.viewmanager.alert(
			this.language.translate(
				'function-error',
				{description: this.toString()}),
			{
				alertType: 'danger',
				headerText: this.graphileon.translate('Delete Relation')
			}
		);
		this.error(e, true);
	}

	return true;
};

DeleteRelation.prototype.onExecute = async function (input) {
	var check = _.validate({
		relationID  : [input.id, _.isStringOrNumber(input.id)],
		store   : [input.store, 'isString']
	}, this.graphileon.translate('Could not delete relation.'));

	if(!check.isValid()) {
		return check.createError();
	}

	const valid = check.getValue();

	// If we need a confirmation before deleting the node (defaults to yes).
	if (_.hasBooleanValue(input.confirm, true)) {
		let deleteConfirmed = (! this.graphileon.viewmanager) ? true : await this.graphileon.viewmanager.confirm(
			this.graphileon.translate(`confirm-delete-relation`, {relationId: valid.relationID, store: valid.store}),
			{
				headerText: 'Delete Relation',
				okButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				alertType: 'danger',
				okButtonClass: 'btn-danger'
			}
		);

		if (! deleteConfirmed) {
			this.closeFunctionInstance();
			return false;
		}
	}

	await this.executeDeleteRelation(valid.relationID, valid.store);
	this.closeFunctionInstance();
	
	return true;
};

module.exports = DeleteRelation;
