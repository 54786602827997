require('codemirror/mode/markdown/markdown.js');

const AlternativeEditorAbstract = require('../alternative-editor-abstract').default;
const MarkdownEditor = require('client/src/markdown/markdown-editor').default;
const ComponentLoader = require('client/src/component-loader').default;

const markdownSerializer = require('client/src/markdown/markdown-serializer');

require('./markdown.scss');

class MarkdownWYSIWYG extends AlternativeEditorAbstract {
	constructor(dependencies, element) {
		super();

		this.markdownEditor = new MarkdownEditor(dependencies, element);
		this.markdownEditor.on('blur', () => {
			this.fire('blur')
			this.fireChange(this.getValue());
		});
		this.markdownEditor.on('change', () => {
			this.fireChange(this.getValue());
		});

		this._componentLoader = new ComponentLoader(dependencies);
		this._componentLoader.loadComponents(element);
	}

	setValue(value) {
		this.markdownEditor.setContent(value, 'string');
	}

	getValue() {
		// for MarkdownEditView we changed codemirror lineSperator to '\r\n' because of that we need hack for output of porseMirror
		let str = markdownSerializer.serialize(this.markdownEditor.view.state.doc);
		let parts = str.split('\n');
		str = parts.join('\r\n');
		return str;
	}

	destroy() {
		this.markdownEditor.destroy();
	}
}


module.exports = {
	options: {
		mode: 'text/x-markdown'
	},
	alternativeEditors: {
		markdown: 'Markdown', // will use default editor
		wysiwyg: {
			label: 'WYSIWYG',
			Editor: MarkdownWYSIWYG
		}
	}
};
