import {hasStructure} from 'core/src/data-structures';

export const GraphRegistry = 'GraphRegistry';
export const GraphListenerRegistry = 'GraphListenerRegistry';

export function isGraph(object) {
	return hasStructure(object, {
		getNodes(){},
		getRelations(){},
		updateStyles(){}
	});
}

export function isGraphListener(object) {
	return hasStructure(object, {
		onGraphUpdated(){}
	});
}