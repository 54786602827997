import _ from "lodash";
import Edition from "./edition";
import { deobfuscate } from 'core/src/utils/string';

export default class PersonalEdition extends Edition {
	static get code() {
		return 'gpe';
	}
	static get name() {
		return super.name + " Personal Edition";
	}
	static get YFILES_LICENSE_AUTO() {
		return 'yfiles_pe';
	}

	static isValidForPE(license) {
		// "projectname": "Graphileon yFilesView Personal Edition"
		if (_.includes(license, deobfuscate('µÓúÏÚÔùÖ¤ÊüíÆÚ×÷½¶ ¶À³ª±Â´Öö¢×é®Ô´ÕØÂ¬Î²'))) {
			return true;
		}
		return false;
	}

	isValidYFilesLicense(license) {
		return PersonalEdition.isValidForPE(license);
	}

	defineFeatures() {
		// Do not forget to update counts in license/license-check messages
		return {
			...super.defineFeatures(),
			[Edition.Feature.USER_COUNT]:1,
			[Edition.Feature.DASHBOARD_COUNT]:3,
			[Edition.Feature.STORES_COUNT]:3,
			[Edition.Feature.GRAPHILEONS_COUNT]:64,
			[Edition.Feature.EXPIRES]: false,
			[Edition.Feature.FUNCTION_TIMER]: true,
			[Edition.Feature.FUNCTION_ITERATE]: true,
			[Edition.Feature.FUNCTION_REQUEST]: true,
			[Edition.Feature.FUNCTION_API]: true,
			[Edition.Feature.YFILES_READY]: true,
			[Edition.Feature.YFILES_LICENSE_AUTO]: PersonalEdition.YFILES_LICENSE_AUTO
		}
	}
}
