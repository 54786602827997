const Function = require('core/src/function');
const _ = require('lodash');
const Language = require('core/src/language').default;
const Edition = require('core/src/edition/index').default;
const ApiClientAbstract = require('core/src/api-client-abstract').default;

const Request = Function.extend(Function, 'Request');
Request.aliases = ['Ajax'];

Edition.setBuildFeature(Edition.Feature.FUNCTION_REQUEST, false);

Request.jQueryParameters = [
	'accepts',
	'cache',
	'contents',
	'contentType',
	'converters',
	'crossDomain',
	'dataType',
	'ifModified',
	'mimeType',
	'password',
	'processData',
	'requestDataType',
	'traditional',
	'username',
	'xhrFields',
];

Request.prototype.onInit = function(dependencies) {
	this.language = dependencies.get(Language);
	this.api = dependencies.get(ApiClientAbstract);

	this.setParameters({
		'$allowFailure': undefined,
		'$auth': undefined,
		'$data': undefined,
		'$download': false,
		'$downloadName': 'download',
		'$headers': undefined,
		'$method': undefined,
		'$params': undefined,
		'$serverSide': undefined,
		'$timeout': undefined,
		'$url': undefined,
		'$urlData': undefined
	});

	// Backward compatibility: add jquery parameters
	for(let param in Request.jQueryParameters) {
		this.setParameter('$'+param, undefined);
	}

	const notRequired = defaultValue => ({default: defaultValue, warn: value => !_.isNil(value)});
	this.setModelValidation({
		allowFailure	: ['isBoolean', notRequired(false)],
		auth			: [{
			username: 'isString',
			password: 'isString'
		}, notRequired()],
		data			: ['isPlainObject', notRequired()],
		download		: ['isBoolean', notRequired(false)],
		downloadName	: ['isString', notRequired('download')],
		headers			: ['isPlainObject', notRequired()],
		method			: ['isString', notRequired('get')],
		params			: ['isPlainObject', notRequired()],
		serverSide		: ['isBoolean', notRequired(false)],
		timeout			: ['isNumber', notRequired(30000)],
		url				: 'isString',
		urlData			: ['isPlainObject', notRequired({})]
	});
};

Request.errorKeys = ['responseJSON', 'error', 'message', 'errors', 0];
Request.prototype.findErrorMessage = function(response) {
	if(_.isString(response)) {
		return response;
	}

	if(_.isObject(response)) {
		for(let i in Request.errorKeys) {
			const err = this.findErrorMessage(response[Request.errorKeys[i]]);
			if(_.isString(err)) {
				return err;
			}
		}
	}

	return null;
};

Request.prototype.standardizeResponse = function(response) {
	let clean = {};

	if(Request.isXHRResponse(response)) {
		// Error
		clean.status = response.status;
		clean.statusText = response.statusText;
		clean.data = _.def(response.responseJSON) ? response.responseJSON : response.responseText;
		clean.error = this.findErrorMessage(response);
	} else if ('status' in response && 'statusText' in response && 'data' in response) {
		// Typical Axios response
		clean = _.pick(response, 'status', 'statusText', 'data');
	} else if (response instanceof Error) {
		// Typical Axios error
		clean = _.pick(response.response, 'status', 'statusText', 'data');
		clean.error = this.findErrorMessage(response);
	} else {
		// jQuery and other responses (for backward compatibility)
		clean.status = 200;
		clean.statusText = 'OK';
		clean.data = response;
	}

	return clean;
};

Request.isXHRResponse = function(response) {
	return _.isObject(response) && _.isFunction(response.statusCode);
};

Request.prototype.execute = function(...args) {
	// feature
	const error = Edition.functionNotAvailableError('Request');
	this.error(error);
	throw error;
};

Request.prototype.onExecute = async function() {
	// feature
};

module.exports = Request;
