const Function = require('core/src/function');
const log = require('core/src/log').instance("function/console");
const Language = require('core/src/language').default;
const Console = Function.extend(Function, 'Console');

Console.prototype.onInit = function (dependencies) {
	this.language = dependencies.get(Language);
	this.setParameters({
		'$error': undefined,
		'$log': undefined,
		'$logMessage': '',
		'$errorMessage': `${this.language.translate('Error')}: `
	});
};

Console.prototype.onExecute = function() {
	var value;
	if (value = this.getInput('error')) {
		log.error(this.getInput('errorMessage'), value);
	}
	if (value = this.getInput('log')) {
		log.log(this.getInput('logMessage'), value);
	}
};

module.exports = Console;