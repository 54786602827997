const Function = require('core/src/function');
const IAPI = require('core/src/api-client-abstract').default;
const _ = require('core/src/utils/legacy');
const Validation = require('core/src/utils/validation');
const deferredPromise = require('core/src/utils/deferred-promise');

const CypherQuery = Function.extend(Function, 'CypherQuery');

CypherQuery.aliases = ['Neo4jQuery'];

CypherQuery.prototype.onInit = function(dependencies) {
	this.api = dependencies.get(IAPI);

	var params = {
		'$cypher': '<required>',
		'$params': undefined,
		'$autoComplete': false,
		'$process': false,
		'$store': undefined
	};
	this.setParameters(params);

	var notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		cypher			: ['isString', notRequired],
		params			: ['isObject', notRequired],
		autoComplete	: ['isBooleanParsable', {default: false, warn: _.def}],
		process			: ['isBooleanParsable', {default: false, warn: _.def}],
		store			: ['isString', notRequired]
	});
};

CypherQuery.prototype.onExecute = function() {
	const promise = new Promise(async (resolve, reject) => {
		const cypherQuery = {
			id: this.getId(),
			cypher: this.readModel('cypher'),
			params: this.readModel('params'),
			autoComplete: this.readModel('autoComplete'),
			process: this.readModel('process'),
			store: this.readModel('store')
		};

		try {
			const response = await this.api.executeCypher(cypherQuery);
			const event = {
				type: 'success',
				data: _.get(response, 'result.table'),
				processed: _.get(response, 'result.processed'),
				meta: {
					columns		: _.get(response, 'resultInfo.columns'),
					nodecount	: _.get(response, 'resultInfo.nodecount'),
					relcount	: _.get(response, 'resultInfo.relcount'),
					params		: _.get(response, 'request.params'),
					store		: _.get(response, 'request.store'),
					processData	: _.get(response, 'request.process'),
					autoComplete: _.get(response, 'request.autoComplete'),
					memory		: _.get(response, 'performance.memory'),
					timing		: _.get(response, 'performance.timing'),
					status		: _.get(response, 'status'),
					stats		: _.get(response, 'meta.stats')
				}
			};

			if ( Validation.isTrue(_.get(this.session.getUser(),'properties.devMode')) ) {
				event.meta.cypher = _.get(response, 'request.cypher');
			}

			this.executeTriggers(event);
			resolve(true);
		} catch(error) {
			reject(error);
		}
	});

	return deferredPromise(promise);
};

module.exports = CypherQuery;
