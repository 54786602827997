const _ = require('core/src/utils/legacy');
const { isNil, has, uniqueId, cloneDeep } = require('lodash');
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const { isReadOnly, writable } = require('core/src/utils/read-only');

const MapView = Function.extend(View, "MapView");

/**
 * @override
 * @returns {undefined}
 */
MapView.prototype.onInit = function() {
	this.setParameters({
		'$autoFit': true,
		'$center' : undefined,
		'$geoJson': undefined, // for backwards compatibility
		'$geoJSON': undefined,
		'$bounds': undefined,
		'#markers': undefined,
		'$spiderfier': {
			active: true
		},
		'$zoom': null,
		'$addressSearch.enabled': false,
		'$addressSearch.text': '',
		'$addressSearch.placeholder': 'Search Address'
	});
};

MapView.prototype.onExecute = function() {
	// Google Maps does not have default/minimum height, so if container height was not set, we set it here.
	const containerHeight = this.readModel('container.height');
	if(isNil(containerHeight)) {
		this.updateModel('container.height', 400);
	}
	this.alterMarkers();
	this.alterGeoJSON();
	return View.prototype.onExecute.call(this);
};

MapView.prototype.onUpdate = function() {
	// Add id property to new items in model
	this.alterMarkers();
	this.alterGeoJSON();
}

MapView.prototype.alterRenderData = function(data) {
	data.spiderfier = _.parseValue(data.spiderfier);
	// Remove deprecated properties
	delete data.geoJson;
	return data;
};

MapView.prototype.alterMarkers = function() {
	let markers = this.readModel('markers');
	if (isNil(markers)) return;
	
	let update = false;
	if (isReadOnly(markers)) markers = cloneDeep(writable(markers));
	if(!_.isArray(markers)) { 
		update = true;
		markers = _.transpose(markers);
	}
	for (let marker of markers) {
		if (has(marker, 'id')) continue;
		marker.id = uniqueId('marker-');
		update = true;
	}
	if (update) this.updateModel('markers', markers);
};

MapView.prototype.alterGeoJSON = function() {
	let geoJSON = this.readModel('geoJSON') || this.readModel('geoJson');
	if(isNil(geoJSON)) return;

	if (isReadOnly(geoJSON)) geoJSON = cloneDeep(writable(geoJSON));
	let type = geoJSON.type;

	if(type === 'Feature') {
		geoJSON = {
			"type":"FeatureCollection",
			"features":[geoJSON]
		}
	}

	if(!_.isArray(geoJSON.features)) {
		if(type === 'FeatureCollection' && geoJSON.features !== undefined) {
			log.error("FeatureCollection should be an array.");
		}
		geoJSON.features = [];
	}

	for (let feature of geoJSON.features) {
		if (has(feature, 'id')) continue;
		feature.id = uniqueId('feature-');
	}

	this.updateModel('geoJSON', geoJSON);
};

module.exports = MapView;
