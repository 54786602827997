const _ = require('lodash');
const Err = require("utils/src/error");

const Function = require('core/src/function');
const Language = require('core/src/language').default;

const Navigate = Function.extend(Function, 'Navigate');

Navigate.prototype.onInit = function(dependencies) {
	this.language = dependencies.get(Language);

	this.setParameters({
		'$url': undefined,
		'$target': undefined,
		'$refresh': undefined
	});
	this.setModelValidation({
		url: 'isString',
		target: ['isString', { default: '_self' }],
		refresh: ['isBoolean', { default: false }]
	});
};

Navigate.prototype.onExecute = function() {
	const url = this.readModel('url');
	const target = this.readModel('target');
	const refresh = this.readModel('refresh');

	if(typeof window === 'undefined') {
		this.error(new Err(this.language.translate('Can only navigate client browsers.')));
		return;
	}

	window.open(url, target);

	if (refresh) {
		window.location.reload();
	}
};

module.exports = Navigate;