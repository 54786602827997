const { pathStartsWith } = require('core/src/utils');
const _ = require('lodash');

/**
 * From a nested object (where any changed property has contains a 'change' object {old:any, new:any} as value,
 * this function will find for any path below a change object, the old and new values for that path.
 *
 * For example:
 *
 * changes = {
 * 		state: {
 *			visible: {
 *				old: { nodes: [] },
 *				new: { nodes: [1,2,3] }
 *			},
 *			selected: {
 *				nodes: {
 *					old: [],
 *					new: [1,2,3]
 *				}
 *			}
 * 		}
 * }
 *
 * findChangeFromRoot(changes, 'state.visible.nodes')
 * findChangeFromRoot(changes, 'state.selected.nodes')
 *
 * both return
 *
 * {
 *     old: [],
 *     new: [1,2,3]
 * }
 *
 * findChangeFromRoot(changes, 'state.visible')
 *
 * would return
 *
 * {
 *		old: { nodes: [] },
 *		new: { nodes: [1,2,3] }
 * }
 *
 * however,
 *
 * findChangeFromRoot(changes, 'state.selected')
 *
 * would return `undefined`, as `state.selected` was not necessarily updated
 *
 * This function is tested together with Function updateModel (which generates the changes objects) in functionTests.js
 *
 * @param {object} changes	Nested object with {old: any, new: any} at the level of the change.
 * @param {string} path		The path to find the change at.
 *
 * @return {{old:any, new:any}}		A change object for the given path.
 */
const findChangeFromRoot = function(changes, path) {
	if(!_.isPlainObject(changes)) return undefined; // won't find anything

	// Try straight to goal
	if(_.has(changes, path)) {
		return _.get(changes, path);
	}

	let found = undefined;
	_.forEach(changes, (value, key) => {
		// Find partial path to step into
		if(path.substr(0, key.length) === key) { // e.g. key 'state' for path 'state.selected'
			let remainingPath = path.substr(key.length+1); // continue from key + dot
			found = findChangeFromRoot(changes[key], remainingPath);

			if(found) return false; // exit loop
		}
		// Is the current step a change-object with `new` and `old` properties?
		if('new' in changes && 'old' in changes) {
			found = findChangeFromRoot(changes.new, path);
			if(found) {
				// Also get old value
				let old = findChangeFromRoot(changes.old, path);
				found = {new: found, old: old};

				return false; // exit loop
			}
		}
	});
	return found;
};

const hasChangesInChildren = function(changes, path) {
	if(!_.isPlainObject(changes)) {
		return false;
	}

	let paths = Object.keys(changes);
	return !!_.find(paths, changePath => {
		return pathStartsWith(changePath, path);
	});
};

module.exports = {
	findChangeFromRoot,
	hasChangesInChildren
};
