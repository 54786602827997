const Function = require('core/src/function');
const _ = require('core/src/utils/legacy');
const Validation = require('core/src/utils/validation');
const createDefaultCodeEvaluator = require('core/src/default-code-evaluator');
const Log = require('_common/utils/src/log');
// ES5 import to match the right Dependency
const CodeEvaluator = require('_common/core/src/code-evaluator');

const log = Log.instance("frontend/functions/debug-evaluate");

const TriggerEvaluate = Function.extend(Function, 'TriggerEvaluate');

const JSONParseDeeply = function (obj) {
	// Check if the input is a string that can be parsed as JSON
	if (_.isString(obj)) {
		try {
			let parsed = JSON.parse(obj);
			// Recursively parse the string if it is a JSON string
			return JSONParseDeeply(parsed);
		} catch (e) {
			// Return the string if it cannot be parsed as JSON
			return obj;
		}
	} else if (_.isArray(obj)) {
		// Recursively call for each element in the array
		return obj.map(element => JSONParseDeeply(element));
	} else if (_.isPlainObject(obj) && obj !== null) {
		// Create a new object to hold the transformed properties
		const transformed = {};
		for (const key in obj) {
			transformed[key] = JSONParseDeeply(obj[key]); // Recursively transform each property
		}
		return transformed;
	}
	// Return the value if it is not an object or array
	return obj;
}
const replaceCodePlaceholders = function(code, map) {
	if(!_.isObject(map)) {
		return code;
	}

	for(var placeholder in map) {
		var replacement = map[placeholder];
		code = code.split(placeholder).join(replacement); // this replaces all occurrences
	}

	return code;
};

TriggerEvaluate.prototype.onInit = function (dependencies) {
	this.codeEvaluator = dependencies.get(CodeEvaluator, createDefaultCodeEvaluator);

	let params = {
		'$input': undefined,
		'$matching': undefined,
		'$mapping': undefined 
	};
	this.setParameters(params);

	const notRequired = {default: undefined, warn: _.negate(_.isNil)};
	this.setModelValidation({
		input       : 'isObject',
		matching    : ['isObject', notRequired],
		mapping     : ['isObject', notRequired],
	});
};

TriggerEvaluate.prototype.onExecute = async function () {
	let inputData = this.readModel('input');
	let matchingData = this.readModel('matching');
	let mappingData = this.readModel('mapping');
	matchingData =_.filter(matchingData, (x) => !_.isEmpty(x.key) && !_.isEmpty(x.value));
	mappingData =_.filter(mappingData, (x) => !_.isEmpty(x.key) && !_.isEmpty(x.value));

	inputData = JSONParseDeeply(inputData);
	const regStr = '\\((%|@)\\)(\\.\\w+)?(\\[\\d+\\]|\\[\\#\\])?((\\.\\w+)(\\[\\d+\\]|\\[\\#\\])?)*';
	// prepare input data
	try {
		const mappingDataValues = _.filter(_.values(mappingData), _.isPlainObject);
		
		const matchingValues = _.map(_.values(matchingData), 'value');
		const matchingKeys = _.map(_.values(matchingData), 'key');

		const mappingValues = _.map(mappingDataValues, 'value');

		// collect required input properties from matching and mapping
		const allKeyValuePairs = _.uniq(
			_.filter(
				_.flattenDeep(
					_.concat(
						matchingKeys,
						matchingValues,
						_.map(
							matchingValues || [],
							(value) => value ? value.match(new RegExp(regStr, 'g')) : ''
						),
						_.map(
							matchingKeys || [],
							(key) => key ? key.match(new RegExp(regStr, 'g')) : ''
						),
						_.map(
							mappingValues || [],
							(value) => value ? value.match(new RegExp(regStr, 'g')) : ''
						)
					)
				),
				_.isString
			)
		);

		function arrayExpressionParse(obj, path) {
			const segments = path.match(/[^.\[\]#]+|\[\#\]/g); // Extract path segments, including array notations
			
			function transform(current, segmentPath) {
				// cehck if it is parseable
				try {
					current = JSON.parse(current);
				} catch(err) {
				}
				if (!segmentPath.length) return current;
				const segment = segmentPath.shift();

				if (segment === '[#]') { // Handle array notation
					if (_.isArray(current)) {
						// Process each item in the array according to the remaining path
						return _.map(current, item => transform(item, [...segmentPath]));
					} else {
						throw new Error("Expected array, found: " + typeof current);
					}
				} else {
					if (segmentPath.length && segmentPath[0] === '[#]') {
						// If the next segment is '[#]', prepare for mapping
						const next = current[segment];
						return { [segment]: transform(next, segmentPath) };
					} else {
						if (_.isArray(current)) {
							return _.map(current, item => {
								const value = JSON.parse(item[segment]);
								return { [segment]: value }
							});
						} else {
							return { [segment]: transform(current[segment], segmentPath) };
						}
					}
				}
			}

			return transform(obj, segments);
		}

		// pick required and used part of input based on mapping and matching from the input object 
		let filteredInputData = _.reduce(
			_.map(
				allKeyValuePairs,
				(x) => _.replace(
					_.replace(
						x,
						'\(%\)',
						'input'
					),
					'\(@\)',
					'input._global'
				)   
			),
			(acc, curr) => {
				let property = curr;
				if (_.includes(property, '[#].')) {
					let filteredData = arrayExpressionParse(inputData, property);
					return _.merge(acc, filteredData.input || filteredData);
				} else {
					let filteredData = _.get(
						inputData,
						property,
						_.get(
							inputData,
							`input.${property}`
						)
					);
					if (!filteredData)
						return acc;
					// if value (%)
					if (property === 'input') {
						return _.merge(acc, filteredData);
					}
					return _.set(
						acc,
						_.replace(property, 'input.', ''),
						filteredData
					);
				}
			},
			{}
		);

		const cleanDeeply = (data) => {
			if (!_.isObject(data) && ! _.isArray(data))
				return data;
			if (_.isArray(data))
				return _.filter(data);
			_.forEach(data, (value, key) => {
				data[key] = cleanDeeply(value);
			});
			return data;
		}

		let scopes = {_event: filteredInputData};
		let expressionSet = 'full';
		const evaluateWithInput = this.codeEvaluator._advancedFunctions.evaluateWithInput;
		const isMatchingFalsy = _.some(
			_.map(
				_.values(matchingData),
				(row) => {
					let evaluatedKey = row.key;
					let evaluatedKeyValue = row.value;
					try {
						let code = replaceCodePlaceholders(row.key, {
							'(%)': '_event',
							'(@)': '_event._global'
						});
						// evaluate input with matching keys (key can be (%).data.anything)
						evaluatedKey = this.codeEvaluator.evaluate('let evaluated = ' + code, scopes, {arrayExpressions: true, expressionSet})['evaluated'];
						// evaluate input with matching value (value can be (%).data.anything)
						code = replaceCodePlaceholders(row.value, {
							'(%)': '_event',
							'(@)': '_event._global'
						});
						evaluatedKeyValue = this.codeEvaluator.evaluate('let evaluated = ' + code, scopes, {arrayExpressions: true, expressionSet})['evaluated'];
					} catch (e) {
						// keep the defaults
						console.error('Evaluate value error: ', e)
					}
					const dataFromFilteredInputByKey = _.get(filteredInputData, row.key);
					const dataFromFilteredInputByValue = _.get(filteredInputData, row.value);
					return evaluatedKey === evaluatedKeyValue ||
							dataFromFilteredInputByKey === row.value ||
							dataFromFilteredInputByKey === evaluatedKeyValue ||
							dataFromFilteredInputByValue === evaluatedKey ||
							dataFromFilteredInputByValue === row.key;
				}
			), 
			// check if all matching createria correct
			(x) => !x
		);
		const outputData = isMatchingFalsy && mappingDataValues
		? _.reduce(
			_.values(matchingData),
			(res, row) => {
				expressionSet = 'path';
				let evaluatedKey = row.key;
				let evaluatedKeyValue = row.value;
				try {
					let code = replaceCodePlaceholders(row.key, {
						'(%)': '_event',
						'(@)': '_event._global'
					});
					// evaluate input with matching keys (key can be (%).data.anything)
					evaluatedKey = this.codeEvaluator.evaluate('let evaluated = ' + code, scopes, {arrayExpressions: true, expressionSet})['evaluated'];
					// evaluate input with matching value (value can be (%).data.anything)
					code = replaceCodePlaceholders(row.value, {
						'(%)': '_event',
						'(@)': '_event._global'
					});
					evaluatedKeyValue = this.codeEvaluator.evaluate('let evaluated = ' + code, scopes, {arrayExpressions: true, expressionSet})['evaluated'];
				} catch (e) {
					// keep the defaults
				}
				const dataFromFilteredInputByKey = _.get(filteredInputData, row.key);
				const dataFromFilteredInputByValue = _.get(filteredInputData, row.value);
				
				return evaluatedKey === evaluatedKeyValue ||
						dataFromFilteredInputByKey === row.value ||
						dataFromFilteredInputByKey === evaluatedKeyValue ||
						dataFromFilteredInputByValue === evaluatedKey ||
						dataFromFilteredInputByValue === row.key ?
						_.set(res, '+ ' + row.key, row.value) :
						_.set(res, '- ' + row.key, row.value);
			},
			{}
		)
		: _.reduce(
			_.reduce(
				mappingDataValues,
				(res, row) => {
					let evaluatedKeyValue = row.value;
					try {
						if (_.isString(row.value)) {
							let code = row.value.trim();
							code = replaceCodePlaceholders(code, {
								'(%)': '_event',
								'(@)': '_event._global'
							});
							//evaluatedKeyValue = this.codeEvaluator.evaluate('let evaluated = ' + code, scopes, {arrayExpressions: true, expressionSet})['evaluated'];
							evaluatedKeyValue = evaluateWithInput(this.codeEvaluator, scopes, row.key, row.value, filteredInputData);
						}
					} catch (e) {
						if (expressionSet === 'full' || /^e(?:valuate)?\(.*\)$/.exec(code)) {
							throw new _.Error({
								message: this.language.translate("Could not evaluate parameter value '{{value}}'.", {value: row.value}),
								originalError: e
							});
						}
					}
					return _.set(
						res,
						row.key,
						evaluatedKeyValue
					)
				},
				{}
			),
			(output, value, key) => _.set(
				output,
				_.replace(key, /[#|\$]/, ''),
				_.startsWith(key, '#') && !_.isArray(value) ?
					[value] :
					_.startsWith(key, '$') && _.isArray(value) ?
						_.first(value) :
						value
			),
			{}
		);

		this.executeTriggers({
			type: 'evaluated',
			data: {
				inputData: cleanDeeply(filteredInputData),
				outputData
			}
		});
	} catch (err) {
		console.error('ERRORRRRR: ', err);
	}
};

module.exports = TriggerEvaluate;
