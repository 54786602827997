var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "button",
        { class: _vm.buttonClass, on: { click: _vm.invokeTrigger } },
        [
          _c("span", { class: _vm.iconClass }),
          _vm._v("\n    " + _vm._s(_vm.label) + "\n"),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }