import { render, staticRenderFns } from "./empty.vue?vue&type=template&id=c09d791e&scoped=true&"
import script from "./empty.vue?vue&type=script&lang=js&"
export * from "./empty.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c09d791e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/graphileon/graphileon/styles/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c09d791e')) {
      api.createRecord('c09d791e', component.options)
    } else {
      api.reload('c09d791e', component.options)
    }
    module.hot.accept("./empty.vue?vue&type=template&id=c09d791e&scoped=true&", function () {
      api.rerender('c09d791e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/renderers/vue-components/empty/empty.vue"
export default component.exports