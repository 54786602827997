/**
 * Created by remi on 28-12-15.
 */

(function() {
	const ViewRenderer = require('../view-renderer');
	const $ = require('jquery');

	const _ = require('core/src/utils/legacy');

	/* Inheritance and constructor */
	const PromptRenderer = function() {
		ViewRenderer.call(this);
	};
	PromptRenderer.viewType = 'Prompt';
	PromptRenderer.prototype = Object.create(ViewRenderer.prototype);

	/* Properties */
	PromptRenderer.prototype._id = undefined;
	PromptRenderer.prototype._prompt = undefined;
	PromptRenderer.prototype._options = undefined;

	PromptRenderer.prototype.choose = function(value) {
		this.event('choice', {
			value: value,
			promptID: this._id
		});
		this.close();
	};

	PromptRenderer.prototype.doRender = function(renderData) {
		var self = this;
		var $div = $('<div class="Prompt">');

		var check = _.validate({
			id	  : [renderData.id, 'isString', {default: undefined, warn: _.def(renderData.id)}],
			prompt  : [renderData.prompt, 'isString'],
			options : [renderData.options, 'isObject']
		}, "Invalid render data. Could not render Prompt.");
		if(!check.isValid()) return $div;
		var valid = check.getValue();

		this._id = valid.id;
		this._prompt = valid.prompt;
		this._options = valid.options;

		var $prompt = $('<div class="message">');
		$prompt.html(valid.prompt);

		var $actions = $('<div class="actions">');
		for(var i in valid.options) {
			var $button = $('<button class="option '+i+'" data-value="'+i+'">').html(valid.options[i]);
			$button.on('click', function() {
				var value = $(this).data('value');
				self.choose(value);
			});
			$actions.append($button);
		}

		$div.append($prompt);
		$div.append($actions);

		return $div;
	};

	module.exports = PromptRenderer;

})();
