const Function = require('core/src/function');
const IAPI = require('core/src/api-client-abstract').default;
const Language = require('core/src/language').default;
const Error = require('utils/src/error');
const _ = require('lodash');

const Management = Function.extend(Function, 'Management');

Management.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);

	this.setParameters({
		'$action': undefined,
		'$params': undefined
	});

	var notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		params			: ['isObject', notRequired],
		action			: [val => val in this.actions, this.language.translate('Must be one of the predefined actions.')]
	});

	this.actions = {
		'create': params => this.api.executeRequest(`${this.getModule()}/create`, 'post', params),
		'read': params => this.api.executeRequest(`${this.getModule()}/read`, 'get', params),
		'read-all': params => this.api.executeRequest(`${this.getModule()}/read-all`, 'post', params),
		'update': params => this.api.executeRequest(`${this.getModule()}/update`, 'post', params),
		'patch': params => this.api.executeRequest(`${this.getModule()}/patch`, 'post', params),
		'delete': params => this.api.executeRequest(`${this.getModule()}/delete`, 'delete', params),
		'find': params => this.api.executeRequest(`${this.getModule()}/`, 'get', params),
	};
};

Management.prototype.createSimpleAction = function(action, method) {
	return params => this.api.executeRequest(`${this.getModule()}/${action}`, method, params);
};

Management.prototype.getModule = function() {
	throw new Error("Not implemented.");
};

Management.prototype.getActionParams = function() {
	return this.readModel('params');
};

/**
 * The 'action' parameter can only be set on the Function itself.
 * @returns {*}
 */
Management.prototype.getAction = function() {
	let action = this.getProperty('action')
	if(_.isNil(action)) {
		action = this.getParameter('action');
	}
	return action;
};

Management.prototype.onExecute = async function() {
	const action = this.getAction();
	const params = this.getActionParams();

	const handler = this.actions[action];
	if(!_.isFunction(handler)) {
		throw new Error("Invalid action.");
	}

	const response = await handler.call(this, params);
	this.executeTriggers({
		type: 'success',
		response: response
	});
};

module.exports = Management;
