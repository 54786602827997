const Function = require('core/src/function');
const View = require('core/src/functions/view');
const Trigger = require('core/src/trigger');
const IFactory = require('core/src/i-factory').default;
const ISession = require('core/src/i-session').default;

const _ = require('core/src/utils/legacy');

const UserView = Function.extend(View, "UserView");

UserView.Event = {
	In : {
		SHORTCUT: 'Shortcut'
	}
};

UserView.prototype.onInit = function(dependencies) {
	this.factory = dependencies.get(IFactory);
	this.session = dependencies.get(ISession);

	this.setParameters({
		'$user': undefined,
		'#dashboards' : undefined,
		'$shortcuts' : undefined
	});

	this._eventHandlers[UserView.Event.In.SHORTCUT] = function(event) {
		event = _.isObject(event) ? event : {};
		let check = _.validate({
			id	: [event.id, 'isStringOrNumber'],
			section: [event.section, 'isString']
		});
		if(!check.isValid()) return check.createError();
		let valid = check.getValue();

		let shortcuts = this.readModel('shortcuts.' + valid.section);
		let shortcut = _.find(shortcuts, {id: valid.id});

		let trigger = this.factory.createTriggerFromRelation(shortcut.trigger);
		if(!(trigger instanceof Trigger)) {
			return new _.Error("No valid trigger found for id '" + valid.id + "'");
		}

		this.alterTriggerData(event);
		event.user = this.session.getUser();
		this.executeTrigger(trigger, event);
	};
};

module.exports = UserView;