
	/*
	 * For some reason, the creator of vue-chartjs decided to generate all these chart types as separate classes,
	 * instead of one class with a `type` property. This Chart class will use a `type` property to select the
	 * corresponding chart class.
	 */

	import {Bar, Bubble, Doughnut, HorizontalBar, Line, Pie, PolarArea, Radar, Scatter, mixins, generateChart } from 'vue-chartjs';
	import chartjsPluginAnnotation from "chartjs-plugin-annotation";
	import chartjsPluginGauge from "chartjs-gauge";
	import ChartDataLabels from 'chartjs-plugin-datalabels';

	const { reactiveProp } = mixins;

	import _, {clone, cloneDeep, values, get, forEach, isArray } from 'lodash';
	import {objectsToArrays, deepDefaults} from 'core/src/utils/legacy';

	import VueRendererComponent from 'client/src/renderers/vue-components/vue-renderer-component.vue';

	/**
	 * Generate our own classes including reactive props
	 * @param ChartClass
	 * @return {{extends: *, mixins: *[], props: string[], mounted(): void}}
	 */
	function generateChartClass(ChartClass) {
		return {
			extends: ChartClass,
			mixins: [reactiveProp],
			props: ['options'],
			mounted () {
				// this.chartData is created in the mixin.
				// If you want to pass options please create a local options object
				this.addPlugin(chartjsPluginAnnotation);
				this.renderChart(this.chartData, this.options)
			},
		}
	}

	const components = {
		'bar-chart': generateChartClass(Bar),
		'bubble-chart': generateChartClass(Bubble),
		'doughnut-chart': generateChartClass(Doughnut),
		'gauge-chart': generateChartClass(generateChart('gauge-chart', 'gauge')),
		'horizontalbar-chart': generateChartClass(HorizontalBar),
		'line-chart': generateChartClass(Line),
		'pie-chart': generateChartClass(Pie),
		'polararea-chart': generateChartClass(PolarArea),
		'radar-chart': generateChartClass(Radar),
		'scatter-chart': generateChartClass(Scatter),
	};

	// Chart Class
	export default {
		mixins: [VueRendererComponent],
		components: components,
		data() {
			return {
				chartType: 'line',
				data: {},
				options: {},
				chartData: {}
			};
		},
		computed: {
			chartComponent: function() {
				return _.toLower(this.chartType) + '-chart';
			},
			chartOptions: function() {
				let options = cloneDeep(this.options);
				objectsToArrays(options, [
					'scales.xAxes',
					'scales.yAxes'
				]);
				deepDefaults(options, {
					maintainAspectRatio: false,
					scales: {
						xAxes: [{
							scaleLabel: {
								display: true
							},
							ticks: {
								beginAtZero: true
							}
						}],
						yAxes: [{
							scaleLabel: {
								display: true
							},
							ticks: {
								beginAtZero: true
							}
						}]
					},
					plugins: {
						datalabels: {
							// datalabels are hidden by default
							display: false
						}
					}							
				});
				options.onClick = this.onClick;
				return options;
			}
		},
		methods: {
			onClick(event, activeElements) {
				let data = {};
				forEach(activeElements, (element) => {
					let index = element._index;
					let datasetIndex = element._datasetIndex;

					let datasetLabel = Object.keys(this.data.datasets)[datasetIndex];
					let value = this.chartData.datasets[datasetIndex].data[index];
					let label = isArray(this.chartData.labels) ? this.chartData.labels[index] : index;

					data[datasetLabel] = {x: index, y: value, label: label};
				});

				this.trigger({
					type: 'click',
					data: data
				});
			}
		},
		watch: {
			data: {
				handler() {
					let data = cloneDeep(this.data); // deep or we'll change `data` and keep triggering this watcher
					objectsToArrays(data, [
						'datasets'
					]);
					this.chartData = data;
				},
				deep: true
			}
		}
	}
