const {io} = require('socket.io-client');
const Edition = require('core/src/edition').default
const Socket = require('core/src/utils/socket').default;

Edition.setBuildFeature(Edition.Feature.WEBSOCKET, true);

Socket.prototype.io = function (...args) {
	if (!Edition.hasFeature(Edition.Feature.WEBSOCKET)) {
		throw Edition.notAvailableError('Websocket');
	}

	return io(...args);
};
