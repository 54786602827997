'use strict';

const VueRenderer = require('client/src/renderers/vue-renderer');
const AgGridComponent = require('./ag-grid-view-renderer/ag-grid.vue').default;

const AgGridViewRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
};
AgGridViewRenderer.viewType = 'AgGridView';
AgGridViewRenderer.prototype = Object.create(VueRenderer.prototype);

AgGridViewRenderer.prototype.getVueComponent = function() {
	return AgGridComponent;
};
AgGridViewRenderer.prototype.initVueComponent = function(component) {
	this.setComponentProps({
		contextMenus: this.getContextMenuData()
	});
	component.$on('context', (menu, item, target) => {
		this.triggerContextMenuItem(item, target);
	});
};

module.exports = AgGridViewRenderer;

