const Function = require('core/src/function');
const _ = require('core/src/utils/legacy');

const ServerSentEvents = Function.extend(Function, 'ServerSentEvents');

ServerSentEvents.prototype.onInit = function (dependencies) {
	this.eventSource = undefined;

	let params = {
		'$url': '',
		'$withCredentials': ''
	};
	this.setParameters(params);

	this.setModelValidation({
		url   : ['isString', {default: '', warn: _.def}],
		withCredentials: ['isBoolean', {default: false, warn: _.def}]
	});
};

ServerSentEvents.prototype.onExecute = function(){
	const self = this;
	const url = this.readModel('url');
	const withCredentials = this.readModel('withCredentials');
	const isWebsocket = _.startsWith(url, 'ws://') || _.startsWith(url, 'wss://');

	if (!isWebsocket) {
		this.eventSource = new EventSource(url, {withCredentials});
		this.eventSource.onopen = function() {
			self.executeTriggers({
				type: 'connected'
			});
		};
	} else {
		this.eventSource = new WebSocket(url);
	}
	this.eventSource.onmessage = (event) => {
		const data = event.data;
		self.executeTriggers({
			type: 'messageReceived',
			data
		});
	};

	this.eventSource.onerror = (error) => {
		self.executeTriggers({
			type: 'error',
			error
		});
		self.eventSource.close();
	};
};

module.exports = ServerSentEvents;
