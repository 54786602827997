const Function = require('core/src/function');
const _ = require('lodash');
const Management = require('core/src/functions/management');

const UserManagement = Function.extend(Management, 'UserManagement');

UserManagement.prototype.onInit = function () {
	_.extend(this.actions, {
		'activate': this.createSimpleAction('activate', 'post'),
		'accept-request': this.createSimpleAction('accept-request', 'post'),
		'change-password': this.changePassword.bind(this),
		'create-api-token': this.createSimpleAction('create-api-token', 'post'),
		'decline-reqest': this.createSimpleAction('decline-request', 'post'),
		'register': this.createSimpleAction('register', 'post'),
		'reset-password': this.resetPassword.bind(this),
		'set-password': this.changePassword.bind(this),
	});
};

UserManagement.prototype.getModule = function() {
	return 'user';
};

UserManagement.prototype.changePassword = UserManagement.prototype.setPassword =  async function(params) {
	let { id, token, newPassword, oldPassword } = params;
	if (_.isNil(oldPassword)) oldPassword = '';
	if (_.isNil(newPassword)) newPassword = '';
	return this.api.userSetPassword(id, token, oldPassword.toString(), newPassword.toString());
};

UserManagement.prototype.resetPassword = async function(params) {
	const { email } = params;
	return this.api.resetPassword(email);
};

module.exports = UserManagement;
