import APIjQueryComponent from 'client/src/components/api/api.jquery';
import MarkdownEditorjQueryComponent from 'client/src/components/markdown-editor/markdown-editor.jquery';
import RichTextEditorjQueryComponent from 'client/src/components/rich-text-editor/rich-text-editor.jquery';
import jQueryComponent from './components/component.jquery.js';
import get from 'lodash/get';
import {ensure} from 'utils/src/validation';
import isArray from 'lodash/isArray';

const ComponentLoader = function(dependencies, mode = 'jquery') {
	this.dependencies = dependencies;
	this._model = mode;
	this._jQueryComponents = {
		"api": APIjQueryComponent,
		"markdown-editor": MarkdownEditorjQueryComponent,
		"text-editor": RichTextEditorjQueryComponent
	};
};

ComponentLoader.prototype.getAvailableComponents = function() {
	return Object.keys(this._jQueryComponents);
};

ComponentLoader.prototype.loadComponents = function(element, config = null) {
	if(this.mode === 'vue') {
		this._loadVueComponents(element, config);
	} else {
		this._loadjQueryComponents(element, config);
	}
};

ComponentLoader.prototype.unloadComponents = function(element) {
	if(this.mode === 'vue') {
		this._unloadVueComponents(element);
	} else {
		this._unloadjQueryComponents(element);
	}
};

ComponentLoader.prototype._loadVueComponents = function(element) {
	const $element = $(element);

	this.vm = new Vue({
		el: $element[0],
		components: {
			'ia-api': ApiComponent
		}
	});

	return this.$el;
};

ComponentLoader.prototype._loadjQueryComponent = function(componentName, element) {
	let Component = this._jQueryComponents[componentName];
	let component = new Component(this.dependencies);
	component.render(element);
};

ComponentLoader.prototype._loadjQueryComponents = function(element, config) {
	const self = this;
	const exclude = ensure(get(config, 'exclude'), isArray, []);
	const include = ensure(get(config, 'include'), isArray, null);

	for(let component in this._jQueryComponents) {
		if(exclude.indexOf(component) >= 0) continue;
		if(include !== null && include.indexOf(component) < 0) continue;
		$(element).find(jQueryComponent.prefix + component).each(function() {

			self._loadjQueryComponent(component, $(this));
		});
	}
};

ComponentLoader.prototype._unloadjQueryComponents = function(element) {
	element.find('.ia-component').each((i, component) => {
		let $component = $(component);
		let replacement = jQueryComponent.createPlaceholder($component);
		$component.replaceWith(replacement);
	});
};

ComponentLoader.prototype._unloadVueComponents = function(element) {
	// Not implemented yet
};

export default ComponentLoader;
