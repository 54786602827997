const Function = require('core/src/function');
const View = require('core/src/functions/view');
const IAPI = require('core/src/api-client-abstract').default;

const _ = require('core/src/utils/legacy');
const { Validity, validateArray, validateObject } = require('utils/src/validation');
const { findChangeFromRoot } = require('core/src/utils/changes');
const { GraphFilterStatus, validateGraphFilters } = require('core/src/graph/graph-filters');
const Language = require('core/src/language').default;

const log = require('core/src/log').instance("function/yfilesview");

const Defaults = {
	filters: {
		node: {filter: 'node', status: GraphFilterStatus.INACTIVE},
		rel: {filter: 'rel', status: GraphFilterStatus.INACTIVE}
	}
};

class YFilesView extends View {
	constructor(dependencies, initData) {
		super(dependencies, initData);

		this.api = dependencies.get(IAPI);
		this.language = dependencies.get(Language);

		this._eventHandlers = _.cloneDeep(View.prototype._eventHandlers);
		this._modelUpdateHandlers = _.cloneDeep(View.prototype._modelUpdateHandlers);

		this.setHashCollectionItemFunction('nodes', this.hashEntity.bind(this));
		this.setHashCollectionItemFunction('relations', this.hashEntity.bind(this));
		this.setHashCollectionItemFunction('groups', this.hashEntity.bind(this));
		this.setHashCollectionItemFunction('state.selected.nodes', this.hashEntity.bind(this));
		this.setHashCollectionItemFunction('state.selected.relations', this.hashEntity.bind(this));
		this.setHashCollectionItemFunction('state.selected.groups', this.hashEntity.bind(this));
		this.setHashCollectionItemFunction('state.visible.nodes', this.hashEntity.bind(this));
		this.setHashCollectionItemFunction('state.visible.relations', this.hashEntity.bind(this));
		this.setHashCollectionItemFunction('state.visible.groups', this.hashEntity.bind(this));

		this.setModelUpdateHandler('nodes', this.handleNodesUpdate.bind(this));
		this.setModelUpdateHandler('relations', this.handleRelationsUpdate.bind(this));
		this.setModelUpdateHandler('groups', this.handleGroupsUpdate.bind(this));
		this.setModelUpdateHandler('autoCompleteStatus', this.handleAutoCompleteStatusUpdate.bind(this));
		this.setModelUpdateHandler('filterProperty', this.handleFilterPropertyUpdate.bind(this));

		this.setDeepModelUpdateHandler('state', this.handleStateUpdate.bind(this));
		this.setDeepModelUpdateHandler('generateGroups', this.handleGenerateGroups.bind(this));

		this.setEventHandler('findNeighbours', this.findNeighbours.bind(this));
	}

	onInit() {
		var notRequired = {default: undefined, warn: _.def};
		var booleanCheck = ['isBooleanParsable', notRequired];

		this.setParameters({
			'store': undefined,
			'nodes': [],
			'relations': [],
			'groups': [],
			'generateGroups': undefined,
			'state': this.createEmptyState(),
			'showOverview': true,
			'layouts': undefined,
			'layout': 'organic',
			'layoutOptions': {},
			'autoCompleteStatus': true,
			'styles': {},
			'stylesEvaluation': 'all',
			'explorable': true,
			'filters': undefined,
			'filterProperty': undefined,
			'selectionMode': undefined,
			'canSetStyles': 1,
			'canSwitchAutoCompleteStatus': 1,
			'canSwitchZoomToFitStatus': 1,
			'canSetLayout': 1,
			'canSwitchSelectionStatus': 1,
			'canSetFilters': 1,
			'canExportPDF': 1,
			'canSwitchOverviewStatus': 1,
			'toolbarVisible': 1,
			'addAutoFilters': 1,
			'canChangeRelationEnds': 0,
			'exportedPdfFileName': undefined,
			'exportedPNGFileName': undefined,
			'zoomToFitStatus': true,
			'nodeTemplates': {}
		});

		function optional(defaultValue) { return { default: defaultValue, warn: _.def}; }
		this.setModelValidation({
			store: ['isString', optional('application')],
			nodes: this.optionalArrayOf(_.isValidNode, 'node'),
			relations: this.optionalArrayOf(_.isValidRelation, 'relation'),
			groups: [this.validateGroups.bind(this), this.language.translate('Invalid groups')],
			generateGroups: ['isObject', optional()],
			showOverview: booleanCheck,
			autoCompleteStatus: booleanCheck,
			styles: ['isObject', optional({})],
			stylesEvaluation: ['isString', optional('all')],
			explorable: booleanCheck,
			filters: [validateGraphFilters, this.language.translate('Invalid filters list')],
			filterProperty: ['isString', optional('visible')],
			selectionMode: [mode => _.includes(['marquee', 'lasso', 'individual'], mode), optional('marquee')],
			layoutOptions: [{
				orientation: ['isString', optional()],
				edgeLabelOrientation: ['isString', optional()],
				edgeToEdgeDistance: ['isNumber', optional(10)],
				nodeToNodeDistance: ['isNumber', optional(30)],
				nodeToEdgeDistance: ['isNumber', optional(15)],
				minimumLayerDistance: ['isNumber', optional(20)],
				nodeHalos: ['isNumber', optional(40)]
			}, optional({})],
			zoomToFitStatus: booleanCheck,
			nodeTemplates: ['isObject', optional({})]
		});

		// Use uniqueness when adding nodes and relations

		this._updateCollection.add.state = {selected:{}, visible:{}};

		this._updateCollection.add.nodes =
			this._updateCollection.add.state.selected.nodes =
				this._updateCollection.add.state.visible.nodes =
					this._updateCollection.set.default;

		this._updateCollection.add.relations =
			this._updateCollection.add.state.selected.relations =
				this._updateCollection.add.state.visible.relations =
					this._updateCollection.set.default;

		this._updateCollection.add.groups =
			this._updateCollection.add.state.selected.groups =
				this._updateCollection.add.state.visible.groups =
					this._updateCollection.set.default;

		// Set update priorities
		this.setUpdatePriorities({
			'state': -2,
			'nodes': -1
		});
	}

	validateGroups(groups) {
		if(!_.def(groups)) return true;

		let validity = new Validity('groups', groups, true);
		if(!_.isArray(groups)) {
			return validity.invalidate(this.language.translate('Must be array.'));
		}

		let assignedNodes = {};
		let valid = validateArray(
			'groups',
			groups,
			group => this.validateGroup(group, assignedNodes),
			'Invalid groups',
			{itemType: 'group'}
		);

		return valid;
	}

	validateGroup(group, assignedNodes = {}) {
		const validateChildNode = node => {
			let valid = new Validity('GroupNode', node, true);
			if(!_.isValidNode(node)) {
				return valid.invalidate(this.language.translate('Invalid node structure.'));
			}
			if(node.id in assignedNodes) {
				return valid.invalidate(this.language.translate('Node can only belong to one group.'));
			}
			assignedNodes[node.id] = group;
			return valid;
		};
		return validateObject('group', group, {
			id: ['isStringOrNumber', {default: _.uniqueId, warn: _.def}],
			children: [[validateChildNode, {itemType: 'node'}]]
		});
	}

	hashEntity(entity) {
		const store = this.readModel('store') || 'application';
		return _.get(entity, 'meta.store', store) + "_" + _.get(entity, 'id');
	}

	getOrphanRelations(nodes, relations) {
		let indexedNodes = _.keyBy(nodes, (node) => this.hashEntity(node));

		return _.filter(
			relations,
			(relation) => {
				const relationStore = _.get(relation, 'meta.store');
				const sourceStore = _.get(relation, 'meta.sourceStore', relationStore);
				const targetStore = _.get(relation, 'meta.targetStore', relationStore);
				return !indexedNodes[this.hashEntity({id: relation.source, meta: {store: sourceStore}})]
				|| !indexedNodes[this.hashEntity({id: relation.target, meta: {store: targetStore}})];
			}
		);
	}

	reviseNodeProperties(nodes, oldNodes) {
		const updates = [];
		for(let node of nodes) {
			let update = {id: node.id, meta: { store: _.get(node, 'meta.store') }};
			let shouldUpdate = false;

			// 1. Backward compatibility of `px` and `py`
			if(_.isNil(node.x) && _.isFinite(node.px)) {
				update = {...update, id: node.id, x: node.px};
				shouldUpdate = true;
			}
			if(_.isNil(node.y) && _.isFinite(node.py)) {
				update = {...update, id: node.id, y: node.py};
				shouldUpdate = true;
			}

			// 2. Some properties should not be changed/deleted unless explicitly updated
			// Those properties we get from the old node (we only find the old node once using this cached function).
			let oldNode;
			function cachedOldNode() {
				if(!oldNode) oldNode = _.find(oldNodes, old => old.id === node.id);
				return oldNode;
			}
			for(let property of ['x', 'y', 'fixed', 'finalStyle']) {
				if(!(property in node)) {
					let old = cachedOldNode();
					if(!old) break;

					update[property] = old[property];
					shouldUpdate = true;
				}
			}

			if(shouldUpdate) {
				updates.push(update);
			}
		}

		if(updates.length) {
			return this.updateCollection('change', 'nodes', updates);
		}
	}

	updateState(collection, items) {
		const filterProperty = this.readModel('filterProperty') || 'visible';

		const selected = _.filter(items, item => _.get(item, 'selected', false));
		const visible = _.filter(items, item => _.get(item, filterProperty, true));

		this.updateModel('state.visible.' + collection, visible);
		this.updateModel('state.selected.' + collection, selected);
	}

	removeOrphanRelations(nodes, relations) {
		// Find relations connected to removed nodes
		let relsToRemove = this.getOrphanRelations(nodes, relations);

		if (relsToRemove.length) {
			return this.updateCollection('remove', 'relations', relsToRemove);
		}
	}

	handleNodesUpdate(nodes, oldNodes) {
		this.reviseNodeProperties(nodes, oldNodes);
		this.removeOrphanRelations(nodes, this.readModel('relations'));
		if (this.readModel('autoCompleteStatus')) {
			const oldIds = _.map(oldNodes, 'id');
			const newIds = _.map(nodes, 'id');
			if(_.difference(newIds, oldIds).length) {
				this.findAutoRelations();
			}
		}
		return this.updateState('nodes', nodes);
	}

	handleRelationsUpdate(relations) {
		this.removeOrphanRelations(this.readModel('nodes'), relations);
		return this.updateState('relations', relations);
	}

	handleGroupsUpdate(groups, oldValue) {
		// Generate a new id for each group that does not have an id yet,
		// Offsetting the generated ids with the max id will guarantee uniqueness in a group list where some groups already have ids
		const maxId = _.maxBy(_.map(groups, 'id'), _.toNumber) || -1;
		_.forEach(groups, (group, i) => {
			if(!('id' in group)) {
				group.id = i + maxId + 1;
			}
		});

		this.updateState('groups', groups);
	}

	handleStateUpdate(changes) {
		if(findChangeFromRoot(changes, 'state.selected.nodes')) {
			this.setItemsStateProperty('nodes', 'selected', 'state.selected.nodes');
		}
		if(findChangeFromRoot(changes, 'state.selected.relations')) {
			this.setItemsStateProperty('relations', 'selected', 'state.selected.relations');
		}
		if(findChangeFromRoot(changes, 'state.selected.groups')) {
			this.setItemsStateProperty('groups', 'selected', 'state.selected.groups');
		}

		const filterProperty = this.readModel('filterProperty') || 'visible';

		if(findChangeFromRoot(changes, 'state.visible.nodes')) {
			this.setItemsStateProperty('nodes', filterProperty, 'state.visible.nodes');
		}
		if(findChangeFromRoot(changes, 'state.visible.relations')) {
			this.setItemsStateProperty('relations', filterProperty, 'state.visible.relations');
		}
		if(findChangeFromRoot(changes, 'state.visible.groups')) {
			this.setItemsStateProperty('groups', filterProperty, 'state.visible.groups');
		}
	}

	handleFilterPropertyUpdate(newValue, oldValue) {
		function updateFilterProperty(entity) {
			entity[newValue] = entity[oldValue];
			delete entity[oldValue];
			return entity;
		}
		this.updateCollectionBy('nodes', updateFilterProperty);
		this.updateCollectionBy('relations', updateFilterProperty);
	}

	handleAutoCompleteStatusUpdate(newValue) {
		if (_.hasBooleanValue(newValue, false)) {
			return this.removeAutoRelations();
		}

		this.findAutoRelations();
	}

	handleGenerateGroups() {
		let generateGroups = this.readModel('generateGroups');

		let groups = _.map(generateGroups, (children, id) => {
			let group = { id: String(id), children };
			if(_.isString(id)) {
				group.name = id;
			}
			return group;
		});
		this.updateModel('groups', groups);
	}

	findNeighbours(nodeIds) {
		var nodes = _.filter(
			this.readModel('nodes'),
			(node) => {
				return _.includes(nodeIds, node.id)
			}
		);

		var stores = _.uniq(_.map(nodes, 'meta.store'));

		Promise.all(
			_.map(
				stores,
				(store) => {
					return this.api.getNeighbours({
						store: store,
						ids: _.map(_.filter(nodes, isNodeInStore(store)), 'id')
					});
				}
			)
		)
			.then(
				(results) => {
					this.update({
						"_update.complete" : {
							nodes: _.union(... _.map(results, 'processed.nodes')),
							relations: _.union(... _.map(results, 'processed.relations'))
						}
					});
				},
				(response) => {
					this.error(new _.Error({
						message: this.language.translate('Could not retrieve neighbours.'),
						originalError: response.message
					}));
				}
			);
	}

	removeAutoRelations () {
		var relations = this.readModel('relations');
		var remove = [];
		// Remove all relations that were loaded by autocomplete
		_.forEach(relations, function(relation) {
			if(relation.autoCompleted) {
				remove.push(relation);
			}
		});

		this.update({"_update.remove": {relations: remove}});
	}


	findAutoRelations(newNodes) {
		// No nodes, nothing to find
		const nodes = this.readModel('nodes');
		if(!_.isArray(nodes) || nodes.length === 0) {
			return;
		}

		if(Function.logging) {
			log.log("Finding autoComplete in " + this);
		}

		const stores =
			_.uniq(
				_.union(
					_.map(nodes, 'meta.store'),
					_.map(newNodes, 'meta.store')
				)
			);

		Promise.all(
			_.map(stores, (store) => {
				return this.api.autoComplete(
					_.map(_.filter(nodes, isNodeInStore(store)), 'id'),
					_.map(_.filter(newNodes, isNodeInStore(store)), 'id'),
					store
				);
			})
		).then(
			(results) => {
				const existingRelations = this.readModel('relations');
				const allRelations = _.flatMap(results, 'relations');
				const newRelations = _.differenceBy(allRelations, existingRelations, 'id');

				_.forEach(newRelations, function(relation) {
					relation.autoCompleted = true;
				});

				// Unfix connected nodes so they will be included in layouting process
				// const connectedNodeIds = _.uniq(_.flatMap(newRelations, relation => [relation.source, relation.target]));
				// const nodeMutations = _.map(connectedNodeIds, id => ({id, fixed: false}));

				const collectionMutations = {"_update.complete": {relations: newRelations}};

				_.promise(this._executing.promise).then(()=> {
					this.update(collectionMutations, false);
				});
			},
			(response) => {
				this.error(new _.Error({
					message: this.language.translate('Could not retrieve relations.'),
					originalError: response.message
				}));
			}
		);
	}

	createEmptyModel() {
		let model = super.createEmptyModel();
		model.state = this.createEmptyState();
		model.nodes = [];
		model.relations = [];

		return model;
	}

	createEmptyState() {
		return {
			selected: {
				nodes: [],
				relations: [],
				groups: []
			},
			visible: {
				nodes: [],
				relations: [],
				groups: []
			}
		};
	};

	async onExecute() {
		const filters = this.readModel('filters') || {};

		// Add default filters
		let defaultFilters = _.cloneDeep(Defaults.filters);

		// Delete default filters that are already in model.filters
		_.forEach(filters, function(filter) {
			let filterIndex = _.findKey(defaultFilters, {filter: filter['filter']});
			delete defaultFilters[filterIndex];
		});

		// Add model.filters to filters so that default filters are first in the list
		_.mergeObjectInto(filters, defaultFilters);
		this.updateModel('filters', defaultFilters);

		// Get used styles
		const styles = await this.fetchUsedStyles();
		this.updateModel('usedStyles', styles);

		super.onExecute();
	}

	async fetchUsedStyles() {
		const resources = await this.fetchUsedResources('IA_UserStyles');
		return _.map(resources, 'node');
	}

	optionalArrayOf(itemValidation, itemType) {
		let self = this;
		return [function(arr) {
			// Array can be undefined, or be valid.
			// Otherwise it's an error, not a warning.
			return !_.def(arr) || _.validateArray(
				_.capitaliseFirst(itemType),
				arr,
				itemValidation,
				self.language.translate('Invalid {{itemType}} structure.', {itemType}),
				{ itemType: itemType },
				false
			);
		}];
	}
}
YFilesView.functionName = 'YFilesView';

var isNodeInStore = _.curry(
	(store, node) => {
		return _.get(node, 'meta.store') === store;
	}
);

module.exports = YFilesView;
