
	import ViewToolbar from './view-toolbar.vue';
	import ViewMessages from './view-messages.vue';
	import ContextMenu from './context-menu.vue';
	import Log from "utils/src/log";
	import {get} from 'lodash';

	const log = Log.instance("client/vue-view/common");

	export default {
		name: "view-common",
		components: {ViewToolbar, ViewMessages, VueContextMenu, ContextMenu},
		props: {
			config: {
				batchTriggers: Array,
				contextMenus: Object,
				contextMenuRefGetters: Object,
				disableContextMenu: Object,
				messages: Object,
				onBatchTriggerClick: Function,
				onContextMenuClick: Function,
				onContextMenuClose: Function,
				onCloseMessage: Function
			}
		},
		methods: {
			closeContextMenu(menu) {
				if(!menu) return;

				const ref = `menu-${menu}`;
				if(!(ref in this.$refs)) {
					log.error(`Context menu '${menu}' not found.`);
					return;
				}
				this.$refs[ref][0].close();
			},
			openContextMenu(menu, event, target) {
				if(get(this.config.disableContextMenu, menu)) return;

				const ref = `menu-${menu}`;
				if(!(ref in this.$refs)) {
					log.error(`Context menu '${menu}' not found.`);
					return;
				}
				return this.$refs[ref][0].open(event, target);
			}
		}
	}
