'use strict';

const SetPasswordView = require('core/src/functions/views/set-password-view');
const VueRenderer = require('../vue-renderer');
const SetPassword = require('./set-password.vue').default;
const IGraphileon = require('core/src/i-graphileon').default;

const SetPasswordRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
	this.graphileon = dependencies.get(IGraphileon);
};

SetPasswordRenderer.viewType = 'SetPasswordView';
SetPasswordRenderer.prototype = Object.create(VueRenderer.prototype);

SetPasswordRenderer.prototype.getVueComponent = () => {
	return SetPassword;
};

SetPasswordRenderer.prototype.initVueComponent = function (component) {
	const self = this;
	component.$on('submit', event => {
		self.event(SetPasswordView.Event.In.SUBMIT, event);
	});
};

module.exports = SetPasswordRenderer;