const Function = require('core/src/function');
const View = require('core/src/functions/view');
const _ = require('core/src/utils/legacy');
const log = require('core/src/log').instance("function/IFC3DView");

class IFC3DView extends View {
	constructor(dependencies, initData) {
		super(dependencies, initData);
	}

	onInit() {
		var notRequired = {default: undefined, warn: _.def};
		var booleanCheck = ['isBooleanParsable', notRequired];
		let optional = (defaultValue) => ({ default: defaultValue, warn: _.def});

		this.setParameters({
			"$url": null,
			"#IFCItems": [],
			'$IFCItemsById': {},
			'$state': {
				'selected': [],
				'visible': []
			}
		});

		this.setModelValidation({
		});
	}


	async onExecute() {
		super.onExecute();
	}
}

IFC3DView.functionName = 'IFC3DView';

module.exports = IFC3DView;
