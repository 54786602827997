const _ = require('core/src/utils/legacy');
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const objectdiff = require('objectdiff/objectDiff');

const Diff = Function.extend(View, "DiffView");

/**
 * @override
 * @returns {undefined}
 */
Diff.prototype.onInit = function() {
	this.setParameters({
		'$from': undefined,
		'$to': undefined,
		'$visual': true
	});

	this.setModelValidation({
		visual: 'isBoolean'
	});

	// Turn off built-in templating
	this.setTemplating('template', false);
	this.setTemplating('data', false);
	this.setTemplating('html', false);

	// Set update priorities
	this.setUpdatePriorities({
		'template': -1
	});
};

Diff.prototype.diff = function() {
	let from = this.readModel('from');
	let to = this.readModel('to');
	let diff = objectdiff.diff(from, to);
	let xml = objectdiff.convertToXMLString(diff);

	this.updateModel('diff', diff);
	this.updateModel('xml', xml);

	this.executeTriggers({
		type: 'diff',
		diff: diff,
		xml: xml
	});
};

Diff.prototype.onExecute = function() {
	this.diff();
	if(this.readModel('visual')) {
		View.prototype.onExecute.call(this);
	} else {
		this.closeFunctionInstance();
	}
};

module.exports = Diff;