var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("view-common", {
        ref: "common",
        attrs: { config: _vm.getViewCommonConfig() },
        scopedSlots: _vm._u([
          {
            key: "toolbar",
            fn: function () {
              return [_c("view-toolbar-button", [_vm._v("Built-in Test")])]
            },
            proxy: true,
          },
          {
            key: "context-foo",
            fn: function () {
              return [
                _c(
                  "context-menu-item",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onItemClick("test")
                      },
                    },
                  },
                  [_vm._v("Built-in Test")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("div", { staticClass: "errors" }, [
        _vm._v("Errors: " + _vm._s(_vm.messages)),
      ]),
      _vm._v(" "),
      _c("div", [_vm._v("Foo.Bar: " + _vm._s(_vm.validFoo))]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "ShowConditionalBatchTrigger: " +
            _vm._s(_vm.model.showConditionalBatchTrigger)
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          on: {
            contextmenu: function ($event) {
              return _vm.openContextMenu("foo", $event, { value: true })
            },
          },
        },
        [_vm._v("Foo Menu with true")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          on: {
            contextmenu: function ($event) {
              return _vm.openContextMenu("foo", $event, { value: false })
            },
          },
        },
        [_vm._v("Foo Menu with false")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          on: {
            contextmenu: function ($event) {
              return _vm.openContextMenu("bar", $event)
            },
          },
        },
        [_vm._v("Bar Menu")]
      ),
      _vm._v(" "),
      _c("div", [_vm._v("Context: " + _vm._s(_vm.currentContextMenu))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }