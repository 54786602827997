const Function = require('core/src/function');
const _ = require('lodash');
const Management = require('core/src/functions/management');

const NodeManagement = Function.extend(Management, 'NodeManagement');

NodeManagement.prototype.onInit = function () {
	_.extend(this.actions, {
		'import': params => this.api.executeRequest('/node/import', 'post', params)
	});
};

NodeManagement.prototype.getModule = function() {
	return 'node';
};

module.exports = NodeManagement;
