
	import { abstractField } from "vue-form-generator";
	import Treeselect from '@riophae/vue-treeselect';
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import _ from "lodash";

	export default {
		// register the component
		components: { Treeselect },
		mixins: [ abstractField ],
		data() {
			return {
				renderIndex: 1, //used to force rerendering/recreating of the component
				lastDefaultOptions: this.schema.defaultOptions || []
			}
		},

		watch: {
			'schema.options': {
				deep: true,
				handler: function (value) {
					// To update the options async you need to also set `field.forSearch` with the searched string (to make sure the options are for current search)
					if (	this.schema.forSearch === this.optionsLoadedSearchQuery
						&& _.isFunction(this.optionsLoadedCallback)
					) {
						this.optionsLoadedCallback(null, value);
					}
				},
			},

			// Fixes: On async Treeselect, if you update the defaultOptions the component does not update correctly (selected items are missing labels)
			'schema.defaultOptions': function(value) {
				if (! _.isEqual(value, this.lastDefaultOptions)) {
					this.renderIndex += 1;
					this.lastDefaultOptions = value;
				}
			}
		},

		methods: {
			// This method is called by the vue-treeselect library when options need to be loaded async. (https://vue-treeselect.js.org/#props -> loadOptions)
			onLoadOptions(params) {
				// If (field's) `field.async: true`, we expect the search result to be set in the `field.options` through a trigger so we just save the callback that needs to be called after options are loaded
				// In this case the initial options SHOULD BE in `field.defaultOptions`
				if (params.action === 'ASYNC_SEARCH') {
					// Use the previous callback to make sure the library does not wait for it (it will not trigger another search if the previous one is not handled)
					// First parameter is error (message?), if it's not set the library understands that the options (undefined) are loaded and an error is generated on the next search of the same string
					if (_.isFunction(this.optionsLoadedCallback)) this.optionsLoadedCallback('canceled');

					// Save the callback to be used when `field.options` is updated
					this.optionsLoadedCallback = params.callback;
					this.optionsLoadedSearchQuery = params.searchQuery;

					// This will notify the parent (VueFormView) that user filtered the options ('ASYNC_SEARCH')
					this.schema.onSearchChange(params.searchQuery);
				}
			},

			onSelect(...params) {
				// Fixes an issue with Treeselect library: it does not clear the search if schema.async == true
				if (this.schema.async && this.schema.clearOnSelect) {
					this.$refs.self.trigger.searchQuery = "";
				}
			}
		}
	}
