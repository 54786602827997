
	import _ from "lodash";
	import { abstractField } from "vue-form-generator";
	import icons from "../../icons";

	// A List is an Array
	let List = {
		// nil => [], Array => Array, nonArray => [nonArray]
		from: _.cond([
			[_.isNil, _.stubArray],
			[_.isArray, _.identity],
			[_.stubTrue, _.castArray]
		]),
		// push value and return THE ARRAY (not length)
		push: _.curry((value, array) => (array.push(value), array))
	};

	export default {
		mixins: [ abstractField ],

		watch: {
			schema: {
				immediate: true,
				deep: true,
				handler: function(schema) {
					this.schema = _.defaultsDeep(
						schema,
						{
							editable: false,
							icon: 'folder-open',
							minSize: 0,
							maxSize: Infinity,
							allowedMimeTypes: [],
							deniedMimeTypes: []
						}
					);
		
					// Add read validation to list of validators (making sure it is a list)
					this.schema.validator = _.flow([
						List.from, 
						(list) => _.includes(list, this.readValidation) ? list : List.push(this.readValidation, list)
					])(this.schema.validator);
				}
			}
		},
		beforeMount(){
			this.schema.icon = icons.toIconClass(this.schema.icon);
		},

		data() {
			return {reading: false}
		},

		mounted() {
			// Custom errors set when user selects a file
			this.readErrors = [];
		},

		methods: {
			readValidation() {
				return this.readErrors;
			},

			addReadError(error) {
				this.readErrors.push(error);
				this.validate();
			},

			clearReadErrors() {
				this.readErrors = [];
				this.validate();
			},

			onFilesChange(event) {
				this.clearReadErrors();
				_.forEach(event.target.files, (file) => this.readFile(file));
				// Clear the value of the file input to prepare it for the next selection, so you can select the same file again if you want
				event.target.value = null;
			},

			setValue(value) {
				this.value = value;
			},

			validateSelectedFile(file) {
				if (file.size < this.schema.minSize) {
					let error = `Selected file is too small. Minimum size should be ${this.schema.minSize}`;
					this.addReadError(error);
					throw new Error(error);
				}

				if (file.size > this.schema.maxSize) {
					let error = `Selected file is too big. Maximum size should be ${this.schema.maxSize}`;
					this.addReadError(error);
					throw new Error(error);
				}

				if (_.includes(this.schema.deniedMimeTypes, file.type)) {
					let error = `The selected file type is not allowed`;
					this.addReadError(error);
					throw new Error(error);
				}

				if (! _.isEmpty(this.schema.allowedMimeTypes) && ! _.includes(this.schema.allowedMimeTypes, file.type)) {
					let error = `The selected file type is not allowed`;
					this.addReadError(error);
					throw new Error(error);
				}
			},

			async readFile(file) {
				this.reading = true;
				this.validateSelectedFile(file);

				this.fileName = file.name;

				const value = {
					content: await file.text(),
					fileName: file.name,
					fileSize: file.size,
					mimeType: file.type
				}
				this.setValue(value)

				this.reading = false;
			},

			stopRead() {
				this.reading = false;
				this.fileName = '';
				this.clearReadErrors();
			},

			onVisibleChange(event) {
				this.setValue(event.target.value);
			}
		}
	}
