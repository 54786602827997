import {toggleMark} from "prosemirror-commands"
import {wrapInList} from "prosemirror-schema-list"
const linkTemplate = require('./link-popup.html');
const popup = require('./popup');

function linkItem(state, dispatch, view, markType) {
  let $dialog = $(linkTemplate);
  let title = 'Insert Link';
  let buttonText = 'Insert';

  popup(title, $dialog, buttonText, () => {
    toggleMark(markType, {
      href: $dialog.find('.input-url').val(),
      title: $dialog.find('.input-title').val(),
      target: $dialog.find('.select-target').is(':checked') ? '_blank' : undefined
    })(view.state, view.dispatch)
    view.dispatchEvent(new Event('blur'))
    view.focus();
  });
}


export default linkItem;