
	export default {
		name: "view-messages",
		props: {
			messages: Array
		},
		methods: {
			onClick(name) {
				this.$emit('close', name);
			}
		}
	}
