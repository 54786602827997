import _ from 'lodash';
import Vue from 'vue';
import ViewContainer from '../view-container';
import ContainerVueComponentBase from "./ContainerVueComponentBase.vue";

/**
 * This is an example custom Container implementation to create a new custom Container from.
 * To use the default functionality for any of the methods, simply remove the overrides.
 */
export default function ContainerVueBase(...args) {
	ViewContainer.call(this, ...args);
}
ContainerVueBase.prototype = Object.create(ViewContainer.prototype);

ContainerVueBase.prototype.createVueComponent = function() {
	return new Vue(ContainerVueComponentBase); // default
};

ContainerVueBase.prototype.ready = function() {
	Vue.nextTick(()=>{
		ViewContainer.prototype.ready.call(this);
	});
};

/**
 * Should build the container. Anything returned from here will be the first argument to all other functions.
 * @param {string} containerID
 * @param {object} containerData	Contains `id` and any property set on $container in the View.
 * @param {string[]} classes		Array of classes to be added to the container
 *
 * @return {HTMLElement|jQuery}
 */
ContainerVueBase.prototype._build = function(containerID, containerData, classes) {
	const div = document.createElement('div');
	div.className = classes.join(' ');
	div.id = containerID;

	this.vue = this.createVueComponent();
	this.vue.id = containerID;
	this.vue.closable = !! _.get(containerData, 'closable', true);

	this.addListeners();

	return div;
};

ContainerVueBase.prototype.setConfig = function(config) {
	this.vue.setConfig(config);
};

ContainerVueBase.prototype.addListeners = function() {
	this.vue.$on('close', () => {
		this.close('user');
	});
};
ContainerVueBase.prototype.close = function(origin) {
	ViewContainer.prototype.close.call(this, origin);
	this.vue.onClose(origin);
};
/**
 * Should attach the given container to the given area, possibly before another element.
 * @param {HTMLElement} container
 * @param {jQuery} area
 * @param {jQuery} before
 */
ContainerVueBase.prototype._attachTo = function(container, area, before) {
	ViewContainer.prototype._attachTo.call(this, container, area, before);

	// Content
	const content = document.createElement('div');
	container.appendChild(content);

	this.vue.$mount(content);
};

/**
 * Should add the given content to the body of the container.
 * @param {HTMLElement|jQuery} container
 * @param {jQuery} content
 */
ContainerVueBase.prototype._setContent = function(container, content) {
	this.vue.setContent(content);
};

/**
 * Should set the title of the container
 * @param {HTMLElement|jQuery} container
 * @param {string} title
 */
ContainerVueBase.prototype._setTitle = function(container, title) {
	this.vue.title = title;
};

/**
 * Should resize the container
 * @param {HTMLElement|jQuery} container
 * @param {number} width
 * @param {number} height
 */
ContainerVueBase.prototype._setSize = function(container, width, height) {
	this.vue.height = height;
	this.vue.width = width;

	Vue.nextTick(() => {
		this.resize(); // notify listeners
	});

	return false; // tell ViewContainer not to notify listeners - we'll do that
};

/**
 * Should return the size of the body of the container.
 * @param {HTMLElement|jQuery} container
 */
ContainerVueBase.prototype._getInnerSize = function(container) {
	if(!('content' in this.vue.$refs)) {
		return {height: 0, width: 0};
	}
	return {height: this.vue.$refs.content.clientHeight, width: this.vue.$refs.content.clientWidth};
};
