const Mustache = require('mustache');
const _ = require('./utils/legacy');
const log = require('core/src/log').instance('function');
const CodeEvaluator = require('core/src/code-evaluator');
const ApplicationInfo = require('core/src/application-info').default;
const EventInterface = require('core/src/event-interface');
const { findPathsToArrays } = require('core/src/utils/');
const ISession = require("core/src/i-session").default;
const IFactory = require('core/src/i-factory').default;
const deferredPromise = require('core/src/utils/deferred-promise');
const {default: readOnly, isReadOnly, writable} = require('core/src/utils/read-only');
const {findChangeFromRoot} = require('core/src/utils/changes');
const Language = require('core/src/language').default;
const Profiler = require('utils/src/profiler');
const APIClientAbstract = require("core/src/api-client-abstract").default;
const { checkType} = require('utils/src/validation');
const Err = require('utils/src/error');

const FUNCTION_UPDATE_TIMEOUT = 10000;

// Generic Function object
const Function = function (dependencies, initData) {
	this.id = undefined;
	this.name = this.constructor.functionName;
	this.parameters = {};
	this.properties = {};
	this.input = {};
	this.triggers = [];

	this.parameterMeta = {};

	this.dependencies = dependencies;
	this.codeEvaluator = dependencies.get(CodeEvaluator);
	this.factory = dependencies.get(IFactory);
	this.fti = dependencies.get(FTI);
	this.session = dependencies.get(ISession);
	this.appInfo = dependencies.get(ApplicationInfo);
	this.language = dependencies.get(Language);
	this.api = dependencies.get(APIClientAbstract);

	this._prompts = {};
	this._parameterPrefixes = {};
	this._modelValidation = {};
	this._updatePriorities = {};
	this._templating = {};
	this._closed = false;
	this._modelValidationEnabled = true;

	this._deepModelUpdateHandlers = {};
	this._modelUpdateHandlers = {};
	// TODO: also _eventHandlers should be here instead of prototype
	// It causes problems if you use setEventHandler or setModelUpdateHandler on an instance: it overwrites the prototype values.

	this.setParameters({
		'$_path': {},
		'$kill': undefined,
		'$waitForUpdates': undefined, // deprecated: alias for stayAlive
		'$stayAlive': undefined,
		'$showErrors': undefined,
		'$_suppressTriggers': undefined
	});

	// Path should not evaluate or template
	this.setTemplating('_path', false);
	this.setParameterMetaProperty('_path', 'evaluate', 'none');
	let notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		waitForUpdates	  : ['isBooleanParsable', notRequired],
		stayAlive		  : [
			Function.isValidStayAlive,
			this.language.translate('Must be one of {{values}}.', {values: _.values(Function.StayAlive).join('/')}),
			{ default: Function.StayAlive.NONE, warn: _.def }
		],
		kill			  : ['isBooleanParsable', notRequired],
		showErrors		  : ['isBoolean', {default: true, warn: _.def}],
		_suppressTriggers : ['isBoolean', {default: false, warn: _.def}]
	});

	this.history = [];

	this.setModel(this.createEmptyModel());
	this._modelChanges = {};
	this._executing = null;
	this._executed = false;
	this._updating = null;
	this._updateQueue = [];

	this._eventInterface = new EventInterface();
	this._eventInterface.extend(this);

	this.init(dependencies, initData);
};

// ***************
// STATICS
// ***************

Function.functionName = 'Function';

Function.VALUE_OBJECT = '_valueObject';

Function.StayAlive = {
	DASHBOARD: 'dashboard',
	SESSION: 'session',
	EXECUTION: 'execution',
	NONE: 'none'
};

Function.ParameterType = {
	OBJECT: 1,
	ARRAY: 2
};

Function.isValidStayAlive = function(value) {
	for(let key in Function.StayAlive) {
		if(Function.StayAlive[key] === value) {
			return true;
		}
	}
	return false;
};

Function.setName = function (name) {
	if (Function.isValidFunctionName(name)) {
		this.functionName = name;
	}
	return this;
};

Function.Error = {
	INPUT_CANCELLED: 'input-cancelled'
};

Function.Event = {
	// These 3 for legacy
	UPDATE: 'UpdateEvent',
	FUNCTIONPROMPT: 'FunctionPrompt',
	ERROR: 'FunctionError',

	Out: {
		UPDATE: 'UpdateEvent',
		FUNCTION_PROMPT: 'FunctionPrompt',
		ERROR: 'FunctionError',
		CLOSE: 'FunctionClose',
		STAY_ALIVE: 'StayAlive',
		TRIGGER: 'FunctionTrigger'
	},
	In: {
		CLOSE			: 'Close',
		TRIGGER			: 'Trigger',
		TRIGGER_BY_ID	: 'TriggerById',
		PROMPT_ANSWER	: 'ParameterPromptAnswer',
		PROMPT_CANCEL 	: 'PromptCancel',
		UPDATE_MODEL	: 'UpdateModel'
	}
};

// Create new function/object based on existing function
Function.extend = function(Parent, name) {
	class Function extends Parent {}
	this.initFunctionClass(Function, Parent, name);

	return Function;
};

Function.initFunctionClass = function(Class, Parent, name) {
	Class.setName(name);

	_.forEach([
		'_hashCollectionItem',
		'_eventHandlers'
	], function(property) {
		Class.prototype[property] = _.cloneDeep(Parent.prototype[property]);
	});
};

/**
 * Similar to lodash's _.set, but overwrites strings with object if the path continues.
 * E.g. Function.set({a: 'foo'}, 'a.b', 'bar') would result in {a:{b:'bar'}}.
 * @param {object} obj
 * @param {string|Array} path
 * @param value
 */
Function.set = function(obj, path, value) {
	if(!_.isObject(obj)) {
		return false;
	}
	if(_.isString(path)) {
		path = path.split('.');
	}
	if(!_.isArray(path) || path.length === 0) {
		return false;
	}

	if(isReadOnly(value)) {
		log.warn(`Function.set read-only value provided for '${path}'. Exporting to writable value.`, value);
		value = value.$writable();
	}

	let step = path.shift();
	if(path.length === 0) {
		let changed = !_.isEqual(obj[step], value);
		obj[step] = value;
		return changed;
	} else {
		if(!_.isObject(obj[step])) {
			obj[step] = {};
		}
		return Function.set(obj[step], path, value);
	}
};

Function.getEvaluationLevel = function(value) {
	if(value === undefined) return 'path';
	return value;
};

Function.isValidEvent = function (event) {
	if (!_.isPlainObject(event)) {
		return false;
	}
	return true;
};

Function.isValidParameter = function (parameterName, parameterValue) {
	if (!_.isString(parameterName) || parameterName.length < 2) {
		return false;
	}

	if (!_.isObjectPath(parameterName.substring(1))) {
		return false;
	}

	if (!(_.includes(['$', '#'], parameterName[0]))) {
		return false;
	}

	if ((_.includes(['$'], parameterName[0])) && (_.isArray(parameterValue)) && (parameterValue !== undefined)) {
		return false;
	}

	return true;
};

Function.isValidFunctionName = function (functionName) {
	if (!_.isString(functionName)) {
		return false;
	}
	if (!functionName.match(/^[a-z\$_]+[a-z0-9_\$]*$/i)) {
		return false;
	}
	return true;
};

Function.isValidPropertyName = function (propertyName) {
	if (!_.isString(propertyName)) {
		return false;
	}

	if (!propertyName.match(/^[a-z\$_]+[\.a-z0-9_\$]*$/i)) {
		return false;
	}

	return true;
};

/**
 * Checks:
 * - starts with $ or #
 * - max 1 colon ':'
 * - captures part before and after colon
 *
 * @param parameterName
 * @returns {object} {name: ..., meta: ...} or undefined if invalid
 */
Function.getParameterDefinition = function (parameterName) {
	let match = /^([$#]?)([\w\d.]+?)(?::([^:]*))?$/.exec(parameterName);
	if (!match) {
		return null;
	}
	return {
		prefix: match[1],
		root: match[2].split('.')[0],
		name: match[2],
		meta: match[3]
	};
};

/**
 * Strips the first character ($ or #) from a valid parameter name.
 * @param {string} parameter
 * @returns {string}
 */
Function.parameterToKey = function (parameter) {
	let def = Function.getParameterDefinition(parameter);
	if (def !== null && !_.isEmpty(def.prefix)) {
		return parameter.substring(1);
	}

	return parameter;
};

Function.getParameterType = function (parameter) {
	if (!Function.isValidParameter(parameter)) {
		return false;
	}

	if (parameter.substring(0, 1) === '$') {
		return Function.ParameterType.OBJECT;
	}

	if (parameter.substring(0, 1) === '#') {
		return Function.ParameterType.ARRAY;
	}

	return false;
};

Function.splitParametersAndProperties = function (object) {
	let result = {
		properties: {},
		parameters: {}
	};

	if (!_.isPlainObject(object)) {
		return false;
	}

	_.forEach(object, function (value, key) {
		let trimmedKey = key.trim();
		let parameterDefinition = Function.getParameterDefinition(key);
		if (!_.isEmpty(_.get(parameterDefinition, 'prefix'))) {
			result.parameters[trimmedKey] = value;
		}
		else {
			result.properties[trimmedKey] = value;
		}
	});

	return result;
};

Function.runOnProtoChain = function (object, functionName, self, args) {
	if (object.__proto__) {
		Function.runOnProtoChain(object.__proto__, functionName, self, args);
	}
	if (object.hasOwnProperty(functionName)) {
		object[functionName].apply(self, args);
	}
};

/**
 * Splits data object into data and metadata (properties with ':' are considered metadata).
 * Only root-level properties can be meta properties. Key can be a path.
 * @param {object} data
 * @returns {{main: {}, meta: {}}}
 */
Function.splitMetaData = function(data) {
	let main = {};
	let meta = {};

	for(let key in data) {
		let match = /(.*):(.*)/.exec(key);

		// Decide between data and meta data
		if(match !== null) {
			// Meta
			let path = match[1];
			let metaKey = match[2];

			if(!(path in meta)) {
				meta[path] = {};
			}
			meta[path][metaKey] = data[key];
		} else {
			// Data
			main[key] = data[key];
		}
	}

	return {main: main, meta: meta};
};

// ***************
// METHODS
// ***************

Function.prototype.setModelUpdateHandler = function(path, callback) {
	_.set(this._modelUpdateHandlers, path, callback);
};

Function.prototype.setDeepModelUpdateHandler = function(property, callback) {
	this._deepModelUpdateHandlers[property] = callback;
};

Function.prototype.getStayAlive = function() {
	return this.readModel('stayAlive') || Function.StayAlive.NONE;
};

/**
 * Merge object into other object. Undefined values from source will not overwrite defined values in target.
 * @param {object} source
 * @param {object} target
 */
Function.prototype.mergeInput = function(source, target) {
	this._mergeInput(target, [], source);
};

/**
 * Merges the given value into the given object at the given path. Defined values take precedence over undefined values.
 * @param {object} object 	Target object
 * @param {array} path		Path array
 * @param {object} value	Source object
 */
Function.prototype._mergeInput = function(object, path, value) {
	const currentValue = path.length ? _.get(object, path) : object;
	if(_.isPlainObject(value) && !(Function.VALUE_OBJECT in value) && _.isPlainObject(currentValue)) {
		for(let key in value) {
			let newPath = _.clone(path);
			newPath.push(key);
			this._mergeInput(object, newPath, value[key]);
		}
	} else if (!_.isEmpty(path)) {
		let pathStr = path.join('.');

		// Set value (if not undefined).
		if (value !== undefined || !_.has(object, pathStr)) {
			Function.set(object, pathStr, value);
		}
	}
};

Function.prototype._isExecuted = false;

/* EVENT HANDLERS */

Function.prototype._eventHandlers = {};
Function.prototype._eventHandlers[Function.Event.In.TRIGGER] = function(data) {
	this.executeTriggers(data);
};
Function.prototype._eventHandlers[Function.Event.In.TRIGGER_BY_ID] = function(event) {
	this.executeTriggerById(event.id, event.data);
};
Function.prototype._eventHandlers[Function.Event.In.PROMPT_ANSWER] = function(event) {
	let id = event.id;
	let data = event.data;

	if(!(id in this._prompts)) {
		log.warn("Prompt " + id + " does not exist.");
		return false;
	}

	let deferred = this._prompts[id];
	deferred.resolve(event.data);

	return false; // don't fire triggers
};
Function.prototype._eventHandlers[Function.Event.In.PROMPT_CANCEL] = function(event) {
	let id = event.id;

	if(!(id in this._prompts)) {
		log.warn("Prompt " + id + " does not exist.");
		return false;
	}

	let deferred = this._prompts[id];
	deferred.reject(new _.Error({
		message: this.language.translate('Prompt cancelled'),
		code: Function.Error.INPUT_CANCELLED
	}));

	return false; // don't fire triggers
};
Function.prototype._eventHandlers[Function.Event.In.UPDATE_MODEL] = function(data) {
	this.update(data.mutations, data.executeTriggers, data.updateID);
	return false;
};

/* PUBLIC METHODS */

Function.prototype.init = function (dependencies, initData) {
	Function.runOnProtoChain(this, 'onInit', this, [dependencies]);

	let initialized = false;

	if (initData !== undefined) {

		if(initData.id !== undefined) {
			this.setId(initData.id);
		}

		if (initData.parameters !== undefined) {
			this.setParameters(initData.parameters);
			initialized = true;
		}

		if (initData.properties !== undefined) {
			this.setProperties(initData.properties);
			initialized = true;
		}

		if (!initialized) {
			this.loadFromJson(initData);
		}

		if(initData.labels !== undefined) {
			this.labels = initData.labels;
		}
	}

	this._instanceID = _.uniqueId('instance_');

	return this;
};

Function.prototype.setModelValidation = function(validation) {
	let check = _.validate('Function.setModelValidation', {
		validation: [validation, 'isObject']
	});
	if(!check.isValid()) return check.createError();

	let valid = check.getValue();
	for(let i in valid.validation) {
		this._modelValidation[i] = valid.validation[i];
	}
};

Function.prototype.loadFromJson = function (initData) {
	let propsAndParams = Function.splitParametersAndProperties(initData);
	this.setParameters(propsAndParams.parameters)
		.setProperties(propsAndParams.properties);
	return this;
};

Function.prototype.onInit = function () {
	return true;
};

Function.prototype.getInstanceID = function () {
	return this._instanceID;
};

/**
 *
 * @param {string|number} instanceID	The ID the Function instance should have.
 * @param {boolean} namedInstance		Is this a manually named instance? Defaults to false.
 */
Function.prototype.setInstanceID = function(instanceID, namedInstance) {
	this._instanceID = instanceID;
	this._isNamedInstance = namedInstance === true;
};

Function.prototype.isNamedInstance = function() {
	return this._isNamedInstance;
};

Function.prototype.clearInstanceID = function() {
	return this._instanceID = null;
};

Function.prototype.registerFunctionInstance = function () {
	let registered = this.fti.registerFunctionInstance(this);
	if(registered instanceof _.Error) {
		this.error(registered);
		return this._instanceID;
	}

	this._instanceID = registered;

	// Should have at least stayAlive DASHBOARD
	const stayAlive = this.getStayAlive();
	if(stayAlive === Function.StayAlive.NONE || stayAlive === Function.StayAlive.EXECUTION) {
		this.updateModel('stayAlive', Function.StayAlive.DASHBOARD);
	}

	return this._instanceID;
};

/**
 * @deprecated
 */
Function.prototype.closeFunctionInstance = function(notifyFTI, origin) {
	return this.close(notifyFTI, origin);
};

/**
 * @param [notifyFTI]
 * @param {string} [origin]	The origin of the close event.
 * @return {*}
 */
Function.prototype.close = function(notifyFTI, origin = 'function') {
	if(this.isClosed()) return;

	if(this.onClose() !== false) {
		this.executeTriggers({
			type: 'functionClosed',
			origin: origin
		});
	}

	// This must be done after the functionClosed trigger, or we'll have the _function property set to null
	this.setModel({});
	this.input = {};

	this._closed = true;

	if (notifyFTI !== false) {
		this.fti.closeFunctionInstance(this._instanceID, origin);
	}

	this.eventOut(Function.Event.Out.CLOSE, {}, true);

	return this._instanceID;
};

Function.prototype.isClosed = function() {
	return this._closed;
};

Function.prototype.getId = function() {
	return this.id;
};

Function.prototype.setId = function(id) {
	this.id = id;
};

Function.prototype.getName = function () {
	return this.name;
};

Function.prototype.toString = function() {
	let funcType = this.getName();
	if(_.isEmpty(funcType)) funcType = 'Function';
	let name = this.readModel('name') || this.getPredefined('name');
	return `${funcType} '${name}' (id ${this.getId()})`;
};

Function.prototype.setProperty = function (propertyName, value) {
	let def = Function.getParameterDefinition(propertyName);
	if (def != null) {
		if(def.meta) {
			Function.set(this.parameterMeta, def.name + '.' + def.meta, value);
		} else {
			this.properties[propertyName] = value;
		}
		// TODO: properties are now set as a flat array. Should be nested.
	}
	else {
		log.error('Function.setProperties: property name is not valid: ', propertyName);
	}
	return this;
};

/**
 * Get the value of the property by key. If possible, any template placeholders are filled in using the input object.
 * @param {string} propertyName		The name of the property to retrieve.
 * @param {boolean} [raw]			Set to TRUE if template placeholders should not be filled in.
 * @returns {*}
 */
Function.prototype.getProperty = function (propertyName, raw) {
	if (Function.isValidPropertyName(propertyName)) {
		let value = _.get(this.properties, propertyName);
		if(raw !== true) {
			value = this._fillTemplate(value);
		}
		return value;
	}

	return undefined;
};

Function.prototype.setProperties = function (properties) {
	let self = this;
	if (_.isPlainObject(properties)) {
		_.forOwn(properties, function (value, key) {
			self.setProperty(key, value);
		});
	}

	return this;
};

Function.prototype.getProperties = function () {
	return this.properties;
};

Function.prototype.setParameter = function (parameterName, value) {
	let parameterDefinition = Function.getParameterDefinition(parameterName);
	if(parameterDefinition !== null) {
		// Metadata
		if(parameterDefinition.meta !== undefined) {
			// Parameter does not exist yet, create it now
			if (_.get(this._parameterPrefixes, parameterDefinition.name) === undefined) {
				this.setParameter(parameterDefinition.prefix + parameterDefinition.name, undefined);
			}
			this.setParameterMetaProperty(parameterDefinition.name, parameterDefinition.meta, value);
			// Actual data
		} else {
			// Nested parameter
			if(_.isObject(value) && !_.isArray(value) && Object.keys(value).length > 0) {
				for(let prop in value) {
					this.setParameter(parameterName + '.' + prop, value[prop]);
				}
				// Plain value
			} else {
				Function.set(this._parameterPrefixes, parameterDefinition.name, parameterDefinition.prefix);
				Function.set(this.parameters, parameterName, value);
			}
		}
	} else {
		log.error('Function.setParameter: parameter name is not valid: ', parameterName);
	}

	return this;
};

Function.prototype.setParameters = function (parameters) {
	let self = this;
	if (_.isPlainObject(parameters)) {
		_.forOwn(parameters, function (value, key) {
			self.setParameter(key, value);
		});
	}

	return self;
};

Function.prototype.getParameter = function (parameterName) {
	let variants = [parameterName, '$' + parameterName, '#' + parameterName];
	for(let i in variants) {
		let variant = variants[i];
		// Find the first variant that is defined and return its value
		if(_.has(this.parameters, variant)) {
			return _.get(this.parameters, variant);
		}
	}
};

Function.prototype.getParameters = function (includePrefix) {
	if(includePrefix === false) {
		let parameters = {};
		for(let param in this.parameters) {
			let key = Function.parameterToKey(param);
			if(_.isPlainObject(this.parameters[param]) && _.isPlainObject(parameters[key])) {
				_.mergeObjectInto(this.parameters[param], parameters[key], Function.VALUE_OBJECT);
			} else {
				Function.set(parameters, key, this.parameters[param]);
			}
		}
		return parameters;
	}
	return this.parameters;
};

Function.prototype.setTemplating = function(path, templatingOn) {
	this._templating[path] = templatingOn !== false;
};

/**
 * Sets a single meta property for the given parameter path.
 * @param {string} inputKey			The parameter name (exluding prefix # or $).
 * @param {string} metaProperty		The name of the meta property.
 * @param value						The value for the meta property.
 * @returns {Function}
 */
Function.prototype.setParameterMetaProperty = function(inputKey, metaProperty, value) {
	if (!_.isObject(this.parameterMeta[inputKey])) {
		this.parameterMeta[inputKey] = {};
	}

	let currentValue = this.parameterMeta[inputKey][metaProperty];
	if(_.isPlainObject(value) && _.isPlainObject(currentValue)) {
		this.mergeInput(value, currentValue);
	} else {
		Function.set(this.parameterMeta[inputKey], metaProperty, value);
	}
};

/**
 * Sets parameter meta data
 * @param {object} data
 */
Function.prototype.setParameterMetaData = function(data) {
	for(let key in data) {
		if(!_.isObject(data[key])) continue;
		for(let metaKey in data[key]) {
			this.setParameterMetaProperty(key, metaKey, data[key][metaKey]);
		}
	}
};

/**
 * Fetches parameter metadata for the given path.
 * @param {string|Array} path		The path of the data
 * @param {boolean} [raw]	If set to false, will return raw values instead of filling in template placeholders.
 */
Function.prototype.getParameterMetaData = function(path, raw) {
	let value = undefined;
	if(path === undefined) {
		value = this.parameterMeta;
	} else {
		if(_.isArray(path)) path = path.join('.');
		value = this.parameterMeta[path];
	}

	if(raw !== true) {
		value = this._fillTemplate(value);
	}

	return value;
};

/**
 * Gets the value for the given input. If this is a string containing template placeholders, these will be filled in
 * from the $params input (if available).
 * @param {string} [key]		The key of the value to retrieve.
 * @param {boolean} [raw]	Set to TRUE if template placeholders should not be filled in.
 * @returns {*}
 */
Function.prototype.getInput = function (key, raw) {
	let value = undefined;
	if(key === undefined) {
		value = this.input;
	}
	if (Function.isValidPropertyName(key)) {
		value = _.get(this.input, key);
	}
	if(raw !== true) {
		value = this._fillTemplate(value, key);
	}

	return value;
};

/**
 * Gets a property or parameter. Can be called before execute, to get predefined values.
 * @param {string} key
 */
Function.prototype.getPredefined = function(key) {
	return this.getProperty(key, true) || this.getParameter(key);
};

/**
 * @deprecated Properties are now included in the input (and take precedence over parameters or other input). It now
 * simply calls the method getInput.
 *
 * @param propertyName
 * @param raw
 * @returns {*}
 */
Function.prototype.getPropertyOrInput = function (propertyName, raw) {
	return this.getInput(propertyName, raw);
};

/**
 * Gets the Function's model in its current state.
 * @returns {Object}
 */
Function.prototype.getModel = function() {
	return this._model;
};

/**
 * For override. This method sets up the structure of the model in such a way that all paths that are assumed to be
 * always available are created.
 * @returns {{}}
 */
Function.prototype.createEmptyModel = function() {
	return {};
};

/**
 * @param {object} input
 * @returns {boolean|_.Error}	Whether anything changed, or an Error object if something went wrong.
 */
Function.prototype.setModelFromInput = function(input) {
	let self = this;

	// Function needs its own copy of the input
	input = _.cloneDeep(input);

	let check = _.validate({
		input: [input, 'isObject']
	}, this.language.translate('Could not create model.'));
	if(!check.isValid()) {
		return check.createError();
	}
	let valid = check.getValue();

	// Keep copy of old model as backup
	let oldModel;
	if(this._modelValidationEnabled) {
		oldModel = _.cloneDeep(this._model);
	}

	// Apply changes
	let changes = {};
	let keys = Object.keys(valid.input).sort(this._compareParametersByUpdatePriority.bind(this));
	_.forEach(keys, function(key) {
		let updates = self.updateModel(key, valid.input[key], true);
		_.extend(changes, updates);
	});

	// If model changed (and function was not killed), validate new model
	if(this._modelValidationEnabled && !this.isClosed() && Object.keys(changes).length > 0) {
		let modelCheck = this.validateModel();

		// New model invalid: revert changes
		if(!modelCheck.isValid()) {
			this.setModel(oldModel); // revert changes
			let err = new _.Error(this.language.translate('Function model invalid. Could not set model.'));
			err.errorMap = self._createValidityErrorMap(modelCheck);
			return err;
		}
		if(modelCheck.getMessage() !== undefined) {
			let warnings = self._createValidityWarnings(modelCheck);
			for(let i in warnings) {
				log.warn("Input to " + this +": Invalid value for '" + warnings[i].path + "'",
					warnings[i].warning.getMessage()
				);
			}
		}

		// Set new validated model (may overwrite values with default values).
		this.setModel(modelCheck.getValue());
	}

	return changes;
};

Function.prototype.setModel = function(model) {
	this._model = model;
};

/**
 * Set the history to this Function.
 * @param {array} history Array of objects containing 'instance' and 'type' properties.
 * @returns {undefined}
 */
Function.prototype.setHistory = function(history) {
	if(!_.isArray(history)) {
		log.warn("Invalid history.");
		history = [];
	} else {
		for(let i = history.length - 1; i >= 0; i--) { // reverse to not mess up indexes
			if(!_.isStringOrNumber(history[i].instance) || !_.isString(history[i].type)) {
				log.warn("Invalid history entry", history[i]);
				history.splice(i, 1);
			}
		}
	}
	this.history = history;
};

/**
 * Get the history to this Function.
 * @returns {array}
 */
Function.prototype.getHistory = function() {
	return this.history;
};

Function.prototype.findInheritedMetaProperty = function(path, metaProperty) {
	let self = this;
	if(_.isString(path)) {
		path = path.split('.');
	}

	// Meta property not found or inherited.
	if(path.length === 0) {
		return undefined;
	}

	// Check current path
	let meta = self.getParameterMetaData(path);
	if(_.isPlainObject(meta) && metaProperty in meta) {
		return meta[metaProperty];
	}

	// Check inheritance
	return self.findInheritedMetaProperty(path.slice(0, path.length-1), metaProperty);

};

/**
 * Gets paths to properties that need a prompt.
 * @param {object} data	Parameter data.
 * @param {object} meta			Parameter meta data.
 * @returns {Array} Array of {path:{}, meta:{}} objects.
 */
Function.prototype.getPromptParameters = function(data, meta) {
	let paths = [];
	for(let path in meta) {
		if('prompt' in meta[path]) {
			paths.push({
				path: path,
				meta: meta[path]
			});
		}
	}

	return paths;
};

/**
 * For override. Called before sending data with UpdateEvent to FTI.
 * @param data
 * @returns {*}
 */
Function.prototype.alterUpdateData = function(data) {
	return data;
};

/**
 * For override. Called after alterUpdateData, before sending the UpdateEvent to FTI.
 * UpdateEvent can be prevented by returning false.
 */
Function.prototype.onUpdate = function(changes) {
	return true;
};

/**
 * Get a deep copy of the model data.
 * @param {string} [path]		Undefined for entire model.
 */
Function.prototype.readModel = function(path) {
	if(path === undefined) {
		return readOnly(this._model);
	}
	return readOnly(_.get(this._model, path));
};

Function.prototype.newModelTransaction = function() {
	this._modelChanges = {};
};

/**
 * Get the changes to the model since the last `newModelTransaction()`.
 */
Function.prototype.getModelChanges = function() {
	return this._modelChanges;
};

/**
 * Finds the changes to a specific path in a change tree where the given path or one of its parents was updated.
 * @param {object} changes	A tree of data, in which the changed item contains has the structure: {old: ..., new: ...}.
 * @param {string} path		The path of which to find the old and new value.
 * @return {{old,new}}			Returns a change object, with structure {old:..., new:...}
 */
Function.prototype.findChange = function(changes, path) {
	return findChangeFromRoot(changes, path);
};

Function.prototype.setModelValue = function(path, value) {
	return Function.set(this._model, path, value);
};

/**
 * Merges a given object into the current value at the given path. If the current value is not an object, or the
 * given object has a replacement property set, it will simply overwrite the current value.
 * @param pathString
 * @param value
 */
Function.prototype.mergeModelValue = function(pathString, value) {
	checkType(pathString, 'string', 'pathString');

	const path = pathString.split('.');
	const current = this.readModel(pathString);

	// Merge objects
	if(_.isPlainObject(current) && _.isPlainObject(value) && value[Function.VALUE_OBJECT] !== true) {
		const changes = {};
		_.forEach(value, (__, key) => {
			const newPath = [...path, key].join('.');
			const subChanges = this.mergeModelValue(newPath, value[key]);
			_.forEach(subChanges, (change, subPath) => {
				changes[subPath] = change;
			});
		});
		return changes;
	}

	// Just write
	const copy = _.cloneDeep(value);
	_.removePropertyWithName(copy, Function.VALUE_OBJECT);
	const changed = this.setModelValue(pathString, copy);
	if(changed) {
		return {[pathString]: {new: copy, old: _.cloneDeep(current)}};
	}

	// No changes
	return {};
};

/**
 * Update model data by its path. Will not updated if value is unchanged. Will call modelUpdateHandlers if changed.
 * @param {string} path
 * @param value
 * @param {boolean} [merge]		If true, the new value will be merged with the value at the given path. Defaults to false.
 * @return {array} Array of changed paths.
 */
Function.prototype.updateModel = function(path, value, merge) {
	let modelValue = _.get(this._model, path);

	let oldValue = this.readModel(path);
	if(isReadOnly(oldValue)) oldValue = oldValue.$clone();

	// If both are objects (not arrays) and need to be merged
	let changes = {};
	if(merge === true && _.isPlainObject(value)) {
		// Merge objects at path
		changes = this.mergeModelValue(path, value);
	} else {
		// Otherwise, just replace
		_.removePropertyWithName(value, Function.VALUE_OBJECT);
		let changed = this.setModelValue(path, value);
		if(changed) {
			changes[path] = {new: value, old: oldValue};
		}
	}

	// No changes, nothing left to do here
	if(!Object.keys(changes).length) {
		return changes;
	}

	_.forEach(changes, (change, path) => {
		if(path in this._modelChanges) { // already changed in this update, only record the new value - keep the old
			this._modelChanges[path].new = changes[path].new;
		} else {
			this._modelChanges[path] = changes[path];
		}
	});

	// Call handlers with change for path
	let basePaths = {};
	_.forEach(changes, (change, path) => {
		this._onModelUpdate(path, change.new, change.old);

		// Keep track of changes and which base path they belong to
		let basePath = path.split('.')[0];
		if(!(basePath in basePaths)) {
			basePaths[basePath] = {};
		}
		basePaths[basePath][path] = change;
	});

	// Call deep model handlers for base paths
	_.forEach(basePaths, (changes, basePath) => {
		this._onDeepModelUpdate(basePath, changes);
	});

	return changes;
};

/**
 * Removes any keys that do not match any parameters.
 * @param {object} input
 */
Function.prototype.filterByParameters = function(input) {
	for (let key in input) {
		// Find corresponding parameter
		let inputDef = Function.getParameterDefinition(key);
		if (!(inputDef.root in this._parameterPrefixes)) {
			delete input[key]; // no such parameter
		}
	}
};

Function.prototype.removePropertyPaths = function(input, properties) {
	if(!_.isObject(properties) || !_.isObject(input)) return;

	for(let path in properties) {
		_.removeObjectPath(input, path);
	}

	// TODO: Properties are now stored as a flat array. Should be nested. Then code below applies.

	// for(let property in properties) {
	// 	if(!_.isPlainObject(properties[property])) {
	// 		// Property already has value, cannot be merged with new input
	// 		delete input[property];
	// 	} else {
	// 		// Property is object, can be merged with input?
	// 		if(!_.isPlainObject(input[property])) {
	// 			// Input is not object, cannot be merged with property.
	// 			delete input[property];
	// 		} else {
	// 			// Dig deeper
	// 			this.removePropertyPaths(input[property], properties[property]);
	// 		}
	// 	}
	// }
};

Function.prototype.setInput = function(input) {
	this.processInput(input);
	return this;
};

/**
 * Validates and merges new input into existing input and sets the model accordingly.
 * @param {object} [input]			The raw input provided by the user. If not provided, will process input from parameters and properties.
 * @param {boolean} [isProperties]	If set to true, input is considered as properties:
 * 										- they overwrite existing properties
 * 										- they don't need parameters with the same name to exist
 * @param {boolean} [evaluate]		If set to false, will not evaluate input.
 * @returns {object}		The filtered and evaluated input that was merged into the Function's input.
 */
Function.prototype.processInput = function(input, isProperties, evaluate = true) {
	if(!input) {
		const input = this.processInput(this.getProperties(), true);
		_.merge(input, this.processInput(this.getParameters(false)));
		return input;
	}
	let self = this;

	// Validate input type
	input = _.ensure(input, _.isObject, {});

	// Remove property paths and non-parameter paths
	if(isProperties !== true) {
		// We're gonna remove some keys from the input so we need a shallow clone
		input = _.clone(input);
		this.filterByParameters(input);
		this.removePropertyPaths(input, this.getProperties());
	}

	let _evaluate = function(value, path) {
		if(!_.isArray(path)) path = [];

		if (_.isPlainObject(value)) {
			let ev = {};
			for(let key in value) {
				let newPath = _.clone(path);
				newPath.push(key);
				ev[key] = _evaluate(value[key], newPath);
			}
			return ev;
		}

		// Get evaluation level
		let evaluate = _.get(self.getParameterMetaData(path), 'evaluate');
		let expressionSet = Function.getEvaluationLevel(evaluate);

		try {
			return self.evaluateWith(value, expressionSet);
		} catch(e) {
			self.error(e);
			return undefined;
		}
	};

	// Evaluate input
	if(evaluate) {
		input = _evaluate(input);
	}

	// Store input values
	let data = Function.splitMetaData(input);
	this.mergeInput(data.main, this.input);
	this.setParameterMetaData(data.meta);

	return data.main;
};

Function.prototype.promptMissingInput = async function() {
	let empty = this.getPromptParameters(this.getInput(), this.getParameterMetaData());
	if (empty.length === 0) {
		return;
	}

	try {
		// Return answer
		return this.prompt(this.getProperty('name') || this.getName(), empty);
	} catch(e) {
		throw new _.Error({
			message: this.language.translate('Input cancelled.'),
			code: Function.Error.INPUT_CANCELLED
		});
	}
};

Function.prototype.onClose = function() {
	return true;
};

Function.prototype.applyInputMetaData = function () {
	let self = this;
	let input = this.getInput();

	let __applyInputMetaData = function(input, path) {
		if(_.isObject(input)) {
			for(let key in input) {
				let newPath = _.cloneDeep(path);
				newPath.push(key);
				__applyInputMetaData(input[key], newPath);
			}
		} else {
			if(!_.def(input)) {
				let meta = self.getParameterMetaData(path);
				if(_.isObject(meta)) {
					if('default' in meta) {
						let newInput = {};
						Function.set(newInput, path.join('.'), meta.default);
						self.setInput(newInput);
					}
					if(_.hasBooleanValue(meta.required, true)) {
						return new _.Error(self.language.translate("Parameter '{{path}}' is required.", {path}));
					}
				}
			}
		}
	};

	return __applyInputMetaData(input, []);
};

Function.prototype.validateModel = function(model){
	if(model === undefined) {
		// Read directly from model property (no readModel) for performance. Validation should not change values anyway.
		model = this._model;
	}

	return _.validateObject('model', model, this._modelValidation, this.language.translate('Invalid model for {{name}}', {name: this.toString()}));
};

Function.prototype.prompt = function(name, parameters) {
	const id = _.uniqueId();
	const deferred = {};
	const promise = new Promise((resolve, reject) => {
		this.registerFunctionInstance();

		this.eventOut(Function.Event.Out.FUNCTION_PROMPT, {
			id: id,
			name: name,
			parameters: parameters
		});
		deferred.resolve = resolve;
		deferred.reject = reject;
	});
	this._prompts[id] = deferred;
	return deferredPromise(promise);
};

Function.prototype.registerStayAlive = function() {
	let waitForUpdates = this.readModel('waitForUpdates');
	if(waitForUpdates) {
		log.warn("Parameter 'waitForUpdates' is deprecated. Please use 'stayAlive'.");
	}

	const stayAlive = this.getStayAlive();
	if([Function.StayAlive.DASHBOARD, Function.StayAlive.SESSION].includes(stayAlive)) {
		this.registerFunctionInstance();
		return;
	}

	// Backward compatibility
	if (_.hasBooleanValue(waitForUpdates, true)) {
		this.registerFunctionInstance();
		return;
	}
};

Function.prototype.setDashboard = function(dashboard, bookmark) {
	// Using _path._dashboard because _path.dashboard may be too common in client setups
	return this.updateModel('_path._dashboard', {...dashboard, bookmark});
};

Function.prototype.getDashboard = function() {
	return this.readModel('_path._dashboard');
};

Function.prototype.isOnCurrentDashboard = function() {
	const currentDashboardID = _.get(this.appInfo, 'dashboard.id');
	const currentBookmark = this.appInfo.currentPage.bookmark;

	const dashboard = this.getDashboard();
	const functionDashboardID = _.get(dashboard, 'id');
	const functionBookmark = _.get(dashboard, 'bookmark');

	return currentDashboardID === functionDashboardID && currentBookmark === functionBookmark;
};

/**
 * Executes the Function with initial input.
 * @param {object} input
 * @returns {*}
 */
Function.prototype.execute = function(input) {
	const profilerExecute = Profiler.start('function.execute');

	let self = this;
	if(!_.isPlainObject(input)) input = {};

	if(!this._executing) {
		// Create a Promise that we can resolve later, from outside the Promise
		this._executing = {};
		this._executing.promise = deferredPromise(new Promise((resolve, reject) => {
			this._executing.resolve = resolve;
			this._executing.reject = reject;
		}));
	}

	let handleError = function(err, executeTriggers) {
		let error = new _.Error(self.language.translate('Failed to execute Function.'), err);
		self.error(error, executeTriggers);
		self._executing.reject(error);
	};

	// Set input from properties and parameters
	this.processInput();

	// Process input
	let processedMutations = this.getCollectionMutations(input);
	this.processInput(input, false, false);

	this.promptMissingInput()
		.then(function(answer) {
			if (answer !== undefined) {
				let processedAnswer = self.processInput(answer);
				if(processedAnswer instanceof _.Error) {
					handleError(processedAnswer);
					return;
				}
			}

			self.applyInputMetaData(); // such as default values

			log.info("Executing " + self, self, "Input: ", self.getInput());

			// Set model
			let setModel = _.promise(self.setModelFromInput(self.getInput()),
				function(value) {return value === false || value instanceof _.Error;});
			setModel.then(function() {
				self.updateCollections(processedMutations);

				if(_.isNil(self.getDashboard())) {
					self.setDashboard(self.appInfo.dashboard, self.appInfo.currentPage.bookmark);
				}
				const stayAlive = self.getStayAlive();
				if((stayAlive === Function.StayAlive.DASHBOARD || stayAlive === Function.StayAlive.NONE)
					&& !_.isNil(self.getDashboard()) && !self.isOnCurrentDashboard()) {

					log.log(`Dashboard switched. Cancelling execution of ${self}`);
					self.closeFunctionInstance();
					return;
				}
				self.registerStayAlive();

				// If onExecute results in either FALSE or a Err, don't handle event
				let onExecuting = _.promise(
					self.onExecute(self.getInput()),
					function (result) {
						return result === false || result instanceof _.Error;
					}
				);
				onExecuting.then(function (result) {
					self._executed = true;
					self.executeTriggers({
						type: 'functionExecuted',
						data: self.getInput()
					});
					profilerExecute.stop();
					self._executing.resolve(self.getInput());

					const finalStayAlive = self.getStayAlive(); // may have been changed by onExecute
					if([Function.StayAlive.NONE, Function.StayAlive.EXECUTION].includes(finalStayAlive)) {
						self.close();
					}
				}).catch(function (err) {
					handleError(err, true); // handle error, with execution of triggers
				});
			}).catch(function(err) {
				handleError(err);
			});
		}).catch(function(error) {
			if(error.code !== Function.Error.INPUT_CANCELLED) {
				handleError(error);
			}
			self.closeFunctionInstance();
		});

	return this._executing.promise;
};

/**
 * Updates the Function instance with the given mutations.
 * @param {object} mutations				Object with properties of the Function model to set.
 * @param {boolean} [executeTriggers]		Defaults to true. Will execute triggers with type 'functionUpdated'.
 * @param {number|string} [updateID]		An ID to keep track of the update request
 */
Function.prototype.update = async function(mutations, executeTriggers = true, updateID = undefined) {
	const profilerUpdate = Profiler.start('function.update');

	// An update is already in progress. Enqueue this update.
	if(this._updating) {
		this._updateQueue.push(arguments);
		return;
	}

	let timeout = null;
	this._updating = new Promise((resolve, reject) => {
		let self = this;
		timeout = setTimeout(()=>{
			reject(new _.Error({
				message: this.language.translate('Function update timed out.'),
				data: {mutations}
			}));
		}, FUNCTION_UPDATE_TIMEOUT);

		if (!this._executed) {
			return reject(false);
		}

		let collectionMutations = this.getCollectionMutations(mutations);

		log.info("Updating " + this, this, "Mutations: ", mutations, "CollectionMutations: ", collectionMutations);

		if (_.hasBooleanValue(_.get(mutations, 'kill'), true)) {
			this.closeFunctionInstance();
			return resolve({});
		}

		let handleError = (err) => {
			let error = new _.Error(this.language.translate('Failed to update Function.'), err);
			self.error(error);
			reject(error);
		};

		let oldModel = _.cloneDeep(this._model);

		if(!mutations) mutations = {};
		let processedInput = this.processInput(mutations, false, false);
		if (processedInput instanceof _.Error) {
			handleError(processedInput);
		}

		this.promptMissingInput()
			.then(function (answer) {
				if (answer !== undefined) {
					let processedAnswer = self.processInput(answer);
					_.mergeObjectInto(processedAnswer, processedInput);
				}

				// Set model
				self.newModelTransaction(); // reset change monitoring
				let setModel = _.promise(self.setModelFromInput(processedInput),
					function (value) {
						return value === false || value instanceof _.Error;
					});
				setModel.then(function (changes) {
					// Update collections
					let collectionsUpdated = self.updateCollections(collectionMutations);
					if (Object.keys(collectionsUpdated).length > 0) {
						// Check new model
						let check = self.validateModel();
						if (!check.isValid()) {
							// Reset model
							self.setModel(oldModel);
							handleError(check.createError());
							return;
						}
						if (check.getMessage() !== undefined) {
							let warnings = self._createValidityWarnings(check);
							for (let i in warnings) {
								log.warn("Input to " + this + ": Invalid value for '" + warnings[i].path + "'",
									warnings[i].warning.getMessage()
								);
							}
						}
					}

					// Execute hook if there were any changes
					changes = self.getModelChanges();
					let model = oldModel;
					if (Object.keys(changes).length > 0) {
						self.onUpdate(changes);

						// Read model and changes again, after onUpdate
						model = self.readModel();
						changes = self.getModelChanges();

						// Get list of paths to arrays in model that have a different length
						let arrayLengthChanges = _.reduce(
							changes, 
							(res, change, path) => {
								if (_.isArray(change.old) && (_.size(change.old) !== _.size(change.new))) {
									res.push(path + '.length');
								}
								return res;
							},
							[]
						);

						log.info("Changes to model of " + self + ". Changes: ", changes, "New model:", model);

						// Execute triggers
						if (executeTriggers !== false) {
							let event = {
								type: 'functionUpdated',
								data: mutations,
								changed: _.concat(Object.keys(changes), arrayLengthChanges)
							};
							self.executeTriggers(event);
						}
					} else {
						log.info("Update did not result in changes to model of " + self, mutations, collectionMutations);
					}

					profilerUpdate.stop();
					resolve(changes);

				}).catch(function (err) {
					handleError(err);
				});
			}).catch(function (error) {
			if (error.code !== Function.Error.INPUT_CANCELLED) {
				handleError(error);
			}
		});
	}).catch((e)=>{ log.error(e) });

	// Get new updates from the queue
	this._updating.finally(()=>{
		this._updating = null;
		clearTimeout(timeout);
		this.nextUpdate();
	});

	return this._updating;
};

Function.prototype.nextUpdate = function() {
	if(this._updateQueue.length === 0) {
		return;
	}

	// Execute next update
	const args = this._updateQueue.shift();
	this.update.apply(this, args);
};

/**
 * Called at the end of Function::execute.
 * @param {object} inputData	The input with which the Function was executed.
 * @returns {boolean}
 */
Function.prototype.onExecute = function (inputData) {
	return true;
};

/**
 * Sends an event to the FTI.
 * @param type
 * @param event
 * @param allowAfterClose	Send the event even if the Function was already closed.
 */
Function.prototype.eventOut = function(type, event, allowAfterClose = false) {
	if(!allowAfterClose && this.isClosed()) {
		log.log(`Function closed. Not sending ${type} event.`);
		return;
	}

	// Don't catch errors from listeners
	setTimeout(()=>this.fti.eventOut(type, event, this));
};

/**
 * Register a trigger that can be executed from this Function.
 * @param {Trigger|object} trigger
 * @returns {Function}
 */
Function.prototype.registerTrigger = function (trigger) {
	if (!trigger) {
		log.error('Function.prototype.registerTrigger: trigger is not valid:', trigger);
		return this;
	}

	if(!(trigger instanceof Trigger)) {
		trigger = this.factory.createTrigger(trigger);
	}
	trigger.sourceFunction = this;
	this.triggers.push(trigger);

	return this;
};

Function.prototype.getTriggers = function (event) {
	return this.triggers;
};

Function.prototype.findTriggersWith = function (properties, conditionalParameter) {
	return _.filter(this.getTriggers(), trigger => {
		let triggerProperties = trigger.getProperties();
		// Evaluate condition
		// Look for 'condition' property for backward compatibility
		let fallbackParameter = 'condition';
		if(!_.isNil(conditionalParameter) && (conditionalParameter in triggerProperties || fallbackParameter in triggerProperties)) {
			let condition = triggerProperties[conditionalParameter] || triggerProperties[fallbackParameter];
			let pass = trigger.evaluate(condition, this.alterTriggerData({}), false, 'full');
			if(!pass) return false;
		}
		// Try to match all required properties
		return _.find([triggerProperties], properties);
	});
};

Function.prototype.getMatchingTriggers = function (event) {
	let result = _.filter(this.getTriggers(), function (trigger) {
		if(trigger.matchesEvent(event)) {
			return true;
		}
	});

	return result;
};

Function.prototype.executeTrigger = function(trigger, input) {
	_.assertOne('trigger', trigger, _.instanceOf(Trigger), this.language.translate('Invalid trigger.'));

	trigger = trigger.clone();
	let history = _.clone(this.getHistory());

	// Add self to history if staying alive
	const stayAlive = this.getStayAlive();
	if(stayAlive !== Function.StayAlive.NONE && stayAlive !== Function.StayAlive.EXECUTION) {
		history.unshift({
			instance: this.getInstanceID(),
			type: this.getName(),
			id: this.getId()
		});
	}
	trigger.setHistory(history);

	let data = _.clone(input) || {};
	data._triggerID = trigger.id;
	data._sourceID = this.id;
	// Sometimes `targetFunction` is an object, sometimes an ID
	data._targetID = _.isObject(trigger.targetFunction) ? trigger.targetFunction.id : trigger.targetFunction;

	return trigger.execute(data);
};

/**
 * @deprecated Use the pure-function `getEvaluationContext` and alter that instead.
 *
 * Adds function model, path and global information to trigger event.
 * @param {object} triggerEvent
 * @return {*}
 */
Function.prototype.alterTriggerData = function(triggerEvent) {
	const context = this.getEvaluationContext();
	_.defaults(triggerEvent, context); // fill in triggerEvent from context, where keys not already defined
	return triggerEvent;
};

/**
 * Gets the standard context (%) for evaluation.
 * @param {object} merge	Object to merge into the context.
 * @returns
 */
Function.prototype.getEvaluationContext = function(merge) {
	const context = {
		_function: this.readModel(),
		_path: this._getPathData(),
		_global: this.fti.getGlobalData(),
		_instanceID: this.getInstanceID()
	};
	_.merge(context, merge);

	return context;
};

/**
 * Alter global information of trigger event.
 * @param {object} triggerEvent
 * @return {*}
 */
Function.prototype.getTriggerLog = function(triggerEvent) {
	const log = _.cloneDeep(triggerEvent.$writable());

	if (_.get(log, '_global.apiKeys')) {
		const apiKeys = log._global.apiKeys;
		_.forEach(apiKeys, (value, key) => apiKeys[key] = 'Private');
	}

	return log;
};

Function.prototype.executeTriggers = function(data) {
	const suppressTriggers = this.readModel('_suppressTriggers');
	if (suppressTriggers) {
		log.warn("Trigger execution prevented");
		return;
	}
	let triggerEvent = readOnly(data).$cloneShallow().$writableTop();
	this.alterTriggerData(triggerEvent);
	this.fire('trigger', triggerEvent);
	this.eventOut(Function.Event.Out.TRIGGER, {
		function: this,
		data: triggerEvent
	});

	// Execute triggers
	const triggerLog = this.getTriggerLog(triggerEvent);
	log.info("Finding triggers from " + this + ". Event: ", triggerLog);
	// log.info("Finding triggers from " + this + ". Event: ", triggerEvent.$writable());
	let matchingTriggers = this.getMatchingTriggers(triggerEvent);

	return Promise.all(
		_.map(matchingTriggers, trigger => {
			return this.executeTrigger(trigger, triggerEvent);
		})
	);
};

Function.prototype.executeTriggerById = function(id, data) {
	let trigger = _.find(this.getTriggers(), trigger => {
		return trigger.id === id;
	});
	if(!trigger) {
		log.warn("Trigger not found by id ", id);
		return;
	}

	if(!data) data = {
		type: trigger.getProperties().type // default to Trigger type
	};

	let triggerEvent = readOnly(data).$cloneShallow().$writableTop();
	this.alterTriggerData(triggerEvent);

	return this.executeTrigger(trigger, triggerEvent);
};

Function.prototype.isExecuted = function() {
	return this._executed;
};

Function.prototype.setEventHandler = function(event, handler) {
	let check = _.validate("setEventHandler", {
		event: [event, 'isString'],
		handler: [handler, 'isFunction']
	}, this.language.translate('Could not set event handler.'));
	if(!check.isValid()) return false;

	const valid = check.getValue();
	this._eventHandlers[valid.event] = valid.handler;
};

/**
 * Handle an event. Will check if any special handlers are defined for the event and execute them.
 *
 * @param {string} event
 * @param {object} data
 */
Function.prototype.handleEvent = function (event, data) {
	let self = this;
	let type = event;
	let handler = _.get(this._eventHandlers, type);
	if(_.isFunction(handler)) {
		// Execute custom handler
		let promise = _.promise(handler.call(this, data), function(result) { return result instanceof _.Error; });
		promise.catch(function(err) {
			self.error(new _.Error(err));
		});
	}
};

/**
 * Communicate that an error has happened.
 * @param {object|string} error	   The error object or message.
 * @param {boolean} [executeTriggers]   If set to true, triggers will be executed on the error event. Defaults to false.
 */
Function.prototype.error = function(error, executeTriggers = false) {
	if(_.isString(error)) {
		error = new _.Error(error);
	}

	if(executeTriggers === true) {
		this.triggerError(error);
	}

	if(this.readModel('showErrors') !== false) {
		this.errorOut(error);
	}

	Err.printStackTrace(error, log);
};

Function.prototype.triggerError = function(error) {
	this.executeTriggers({
		type: 'error',
		error: error
	});
};

Function.prototype.errorOut = function(error) {
	if(_.isString(error)) {
		error = new _.Error(error);
	}
	
	let functionError = new _.Error({
		message: this.language.translate('encountered-error', {name: this.toString()}),
		public: true,
		originalError: error,
		data: {
			functionID: this.getId(),
			instanceID: this.getInstanceID(),
			input: this.getInput()
		}
	});
	this.eventOut(Function.Event.ERROR, {
		error: functionError
	});
};

Function.prototype.updateCollectionBy = function(path, operation) {
	// Get current value
	let array = this.readModel(path);

	// If value is undefined, consider it an empty array
	if(!_.def(array)) {
		array = [];
	}
	// Check array
	if(!_.isArray(array)) {
		log.warn("Model value at path '" + path + "' is not an array. Cannot perform " + operation + " operation.", array);
		return {};
	}

	// Get writable clone to modify
	array = array.$clone().$writable();

	// Modify value
	let changed = false;
	_.forEach(array, item => {
		if(operation.call(this, item)) {
			changed = true;
		}
	});
	if(changed) {
		return this.updateModel(path, array);
	}

	return {};
};

/**
 * Update a collection in the model. To override the default operational method, implement
 *
 * this._updateCollection.path.to.array = function(array, value, affected, path) {}
 * 		 	array (Array)		- The array to modify
 * 			value				- The value to add/remove/set
 * 			affected (object)	- An object to keep track of any modifications during your operation.
 * 								  	Contains arrays `add`, `remove` and `set`, to which to add the modifications. These
 * 								  	will be used to call any update listeners accordingly if anything changed.
 * 			path (string)		- The path to the array. You probably already have this, but for generic functions used
 * 									for more than one path this may be useful.
 *
 * @param {string} operation				A string describing the operation. Can be 'add', 'remove' or 'set'.
 * @param {string} path						The path to the collection. Must point to an array.
 * @param value								The value to add, remove or set in the collection.
 * @returns {{add: Array, remove: Array, set: Array}}
 */
Function.prototype.updateCollection = function(operation, path, value) {
	let affected = {
		updated: false,
		add: [],
		remove: [],
		set: [],
		update: []
	};
	// Get operation function
	let operationFunc = _.get(this._updateCollection[operation], path);
	if(!_.isFunction(operationFunc)) {
		operationFunc = this._updateCollection[operation].default;
	}

	// Get current value
	let array = this.readModel(path);

	// If value is undefined, consider it an empty array
	if(!_.def(array)) {
		array = [];
	}
	// Check array
	if(!_.isArray(array)) {
		log.warn("Model value at path '" + path + "' is not an array. Cannot perform " + operation + " operation.", value);
		affected.updated = false;
		return affected;
	}

	// Modify value
	let newArray = operationFunc.call(this, _.cloneDeep(array), value, affected, path);
	let updates = this.updateModel(path, newArray);
	if(Object.keys(updates).length >= 0) {
		affected.updated = true;
	}

	return affected;
};

/**
 * Get a function that can hash an item in a collection
 * @param path
 * @returns {*}
 */
Function.prototype.getHashCollectionItemFunction = function(path) {
	return _.get(this._hashCollectionItem, path, this._hashCollectionItem.default);
};

Function.prototype.setHashCollectionItemFunction = function(path, func) {
	_.set(this._hashCollectionItem, path, func);
};

/**
 * Get a function that can evaluate equality in a collection.
 * @param path
 * @returns {*}
 */
Function.prototype.getEqualsInCollectionFunction = function(path) {
	let hash = this.getHashCollectionItemFunction(path);

	return (value1, value2) => {
		try {
			return (value1 === value2) ? true : hash(value1) === hash(value2);
		}
		catch (ex) {
			return _.isEqual(value1, value2);
		}
	}
};

Function.prototype.valueNeedsPrompt = function(data, meta, path) {
	let value = _.get(data, path);
	let metaValue = _.get(meta, path);

	if(value === '<required>' || _.def(_.get(metaValue, 'prompt'))) {
		return true;
	}

	return false;
};

/**
 * Set the update priority of a model property. Lower values get updated first.
 * @param priorities
 */
Function.prototype.setUpdatePriorities = function(priorities) {
	this._updatePriorities = priorities;
};

Function.prototype.fetchUsedResources = function(label) {
	checkType(label, 'string', 'label');
	return this.api.loadFunctionUsedResources(this.id, label);
};

/* PROTECTED METHODS */

/**
 * Sets a boolean property on all items in the given list, based on whether or not they are in the given state list.
 * @param {string} itemList		The path to the list of items to change.
 * @param {string} property		The state property to set to true/false.
 * @param {string} stateList	The path to the state list, containing the items of which the property value should be `true`.
 */
Function.prototype.setItemsStateProperty = function(itemList, property, stateList) {
	let hash = this.getHashCollectionItemFunction(itemList);
	let stateItemsByHash = _.keyBy(this.readModel(stateList), hash);

	return this.updateCollectionBy(itemList, item => {
		// Find item in state list
		let currentState = item[property];
		let newState = !! stateItemsByHash[hash(item)];
		if(newState !== currentState) {
			item[property] = newState;
			return true;
		}
		return false;
	});
};

/**
 * Checks the given state list on whether all its items exist in the given item list, and removes those that are not.
 * @param {string} stateList 	The state list to check and clean.
 * @param {string} itemList		The item list to use as reference.
 */
Function.prototype.reviseStateListConsistency = function(stateList, itemList) {
	let stateItems = this.readModel(stateList).$cloneShallow().$writable();
	if(_.isEmpty(stateItems)) {
		// Nothing to do here
		return {};
	}

	let hash = this.getHashCollectionItemFunction(itemList);
	let itemsByHash = _.keyBy(this.readModel(itemList), hash);
	let existingStateItems = _.filter(stateItems, (item) => !! itemsByHash[hash(item)]);

	if(stateItems.length === existingStateItems.length) {
		return {};
	}

	return this.updateModel(stateList, existingStateItems);
};

Function.CollectionUpdateBuilder = function(idFunction) {
	idFunction = _.ensure(idFunction, _.isFunction, function(item) {
		return _.isObject(item) ? item.id : item+"";
	});

	this._ids = {add:{}, remove: {}, set: {}, change: {}};
	this.updates = {add:{}, remove: {}, set: {}, change: {}};

	this.updateItemProperty = function(collection, items, property, value) {
		let self = this;

		_.ensurePath(self.updates.change, collection, _.isArray, []);
		let list = _.get(self.updates.change, collection);

		_.forEach(items, function(item) {
			let id = idFunction(item);
			let idx = _.get(self._ids.change, collection+'.'+id);
			if(!_.def(idx)) {
				idx = list.length;
				Function.set(self._ids.change, collection+'.'+id, idx);
				list.push({id: id});
			}
			Function.set(list[idx], property, value);
		});
		return this;
	};

	this.getUpdates = function() {
		return this.updates;
	};
};

/**
 * Updates all collections following a collectionMutation object. This argument can contain the keys `add`, `remove`, `set`, 'change' and 'complete'.
 *
 * Uses updateCollection(...) for each path.
 *
 * Example:
 * Function.prototype.updateCollections({
 * 		add: {
 * 			numbers: {
 *				even: [2,4,6]
 *			}
 *		}
 * });
 * Equivalent:
 * Function.prototype.updateCollections({
 *     'add.numbers.even': [2,4,6]
 * });
 *
 * This would append the numbers 2, 4 and 6 to the collection at path `numbers.even`.
 *
 *
 * @param {object} mutations
 * @returns {object}
 */
Function.prototype.updateCollections = function(mutations) {
	let self = this;
	let operations = ['remove', 'add', 'set', 'change', 'merge', 'complete', 'update'];

	let changes = {};
	if(_.isObject(mutations)) {
		_.forEach(operations, operation => {
			// Get mutations regarding this operation.
			let operationMutations = this.getOperationMutations(mutations, operation);

			// Find all operation paths, including from nested objects
			let operationPaths = this.getOperationPaths(operationMutations, operation);

			// Sort paths by update priority
			operationPaths.sort(this._compareParametersByUpdatePriority.bind(self));

			// Perform updates
			_.forEach(operationPaths, path => {
				if(!path) return; // Cannot handle empty paths

				let items = _.get(operationMutations, path);
				let affected = this.updateCollection(operation, path, items);
				if(affected.updated === true) {
					changes[path[0]] = true; // report change to base path
				}
			})
		});
	}

	return changes;
};

/**
 * Get the mutations for the given operation from a mutations object.
 * @param {object} mutations 	Object with paths as keys, starting with an operation as the first path step (e.g. 'add')
 * @param {string} operation	The operation to filter by.
 *
 * @return {object}		An object with paths as keys, without the operation.
 */
Function.prototype.getOperationMutations = function(mutations, operation) {
	let operationMutations = {};
	// Get the update paths that start with this operation
	_.forEach(
		mutations,
		(items, key) => {
			let pathArray = key.split('.');
			let operationStep = pathArray[0];
			// Check if first step of path is equal to the operation
			if(operationStep === operation) {
				// Only the operation was provided in the path
				if(pathArray.length === 1) {
					if(_.isPlainObject(items)) {
						// For mutations like {add: {foo: [1,2,3]}}
						_.extend(operationMutations, items);
						return;
					} else {
						log.warn("Cannot perform collection operation without path.", key);
						return; // We cannot handle e.g. {add: [1,2,3]}
					}
				}
				// E.g. {add.foo: ...}, {add.foo.bar: ...}, etc
				let path = pathArray.slice(1).join('.');
				operationMutations[path] = items; // add path without operation
			}
		}
	);
	return operationMutations;
};

/**
 * Find all operation paths, including from nested objects (although deprecated)
 * @param operationMutations
 * @param operation
 */
Function.prototype.getOperationPaths = function(operationMutations, operation) {
	return _.reduce(operationMutations, (paths, items, path) => {
		if(_.isPlainObject(items)) {
			log.warn(`Deprecation Warning: _update.${operation} with nested objects is deprecated and will be removed in future releases. Use full path to collection instead.`, `${operation}.${path}`, items);
			// Perhaps update data was provided in nested object (e.g. {add: {items: [...]}} instead of paths
			let newPaths = this.findCollectionPaths(items, path);
			if(newPaths.length > 0) {
				// Object indeed contained nested paths pointing to collections
				return paths.concat(newPaths);
			}
			// No paths found, let's assume the object is a collection item.
		}
		paths.push(path);
		return paths;
	}, []);
};

/**
 * Finds collection paths from (partial) update objects. Traverses the hierarchy of the update object until it finds
 * a collection corresponding to the hierarchy path in the model.
 * @param {object} object
 * @param {string} path		The path at which to start searching in the model.
 */
Function.prototype.findCollectionPaths = function(object, path = '') {
	return _.reduce(
		object,
		(paths, item, key) => {
			let subPath = path ? `${path}.${key}` : key;
			return paths.concat(
				findPathsToArrays(
					this.readModel(subPath),
					subPath
				)
			);
		},
		[]);
};

/**
 * Calls any listeners for the given model path
 * @param {string} path
 * @param newValue
 * @param oldValue
 * @private
 */
Function.prototype._onModelUpdate = function(path, newValue, oldValue) {
	let handler = _.get(this._modelUpdateHandlers, path);
	if(_.isFunction(handler)) {
		handler.call(this, newValue, oldValue);
	}
};

Function.prototype._onDeepModelUpdate = function(property, changes) {
	let handler = _.get(this._deepModelUpdateHandlers, property);
	if(_.isFunction(handler)) {
		handler.call(this, changes);
	}
};

/**
 * Contains handlers for model updates
 * Example (place in constructor):
 * this._modelUpdateHandlers.numbers.even = function(newValue, oldValue) {
 *
 * };
 * This would create a handler for when the `numbers.even` path of the model is changed.
 *
 * @private
 */
Function.prototype._modelUpdateHandlers = {};

Function.prototype._updateCollection = {
	remove: {
		default: function(array, value, affected, path) {
			let self = this;
			affected = _.ensurePath(affected, 'remove', _.isArray, [], this.language.translate("Invalid 'affected' argument."));

			if(!_.isArray(array)) {
				log.warn("First argument is not an array. Cannot remove element(s) ", value);
				return array;
			}

			// For arrays, repeat this procedure
			if(_.isArray(value)) {
				_.forEach(value, function(element) {
					let rm = Function.prototype._updateCollection.remove.default.call(self, array, element, affected, path);
					if(_.isArray(rm)) {
						_.forEach(rm, function(o) { affected.remove.push(o); });
					}
				});
				return array;
			}

			// Remove all elements equal to the value
			let equals = this.getEqualsInCollectionFunction(path);
			for(let i = array.length - 1; i >= 0; i--) {
				if(equals(array[i], value)) {
					affected.remove.push(array.splice(i, 1)[0]);
				}
			}
			return array;
		}
	},
	add: {
		default: function(array, value, affected, path) {
			let self = this;
			affected = _.ensurePath(affected, 'add', _.isArray, [], this.language.translate("Invalid 'affected' argument."));

			if(!_.isArray(array)) {
				log.warn("First argument is not an array. Cannot add element " + value);
				return array;
			}

			// For arrays, repeat this procedure
			if(_.isArray(value)) {
				_.forEach(value, function(element) {
					Function.prototype._updateCollection.add.default.call(self, array, element, affected, path);
				});
				return array;
			}

			if(!_.def(value)) {
				return array;
			}
			affected.add.push(value);
			array.push(value);
			return array;
		}
	},
	change: {
		default: function(array, value, affected, path) {
			let self = this;
			affected = _.ensurePath(affected, ['add', 'set'], _.isArray, [], this.language.translate("Invalid 'affected' argument."));

			if(!_.isArray(array)) {
				log.warn("First argument is not an array. Cannot set element(s) ", value);
				return array;
			}

			// For arrays, repeat this procedure
			if(_.isArray(value)) {
				_.forEach(value, function(element) {
					Function.prototype._updateCollection.change.default.call(self, array, element, affected, path);
				});
				return array;
			}

			let equals = this.getEqualsInCollectionFunction(path);

			// Replace all elements equal to the value
			for(let i = array.length - 1; i >= 0; i--) {
				if(equals(array[i], value)) { // this equals function might check only for id, meaning all other properties can be different
					affected.set.push(array[i]);
					_.mergeObjectInto(value, array[i]);
				}
			}

			return array;
		}
	},
	// change if exists, add if not
	merge: {
		default: function(array, value, affected, path) {
			let self = this;
			affected = _.ensurePath(affected, ['add', 'set'], _.isArray, [], this.language.translate("Invalid 'affected' argument."));

			if(!_.isArray(array)) {
				log.warn("First argument is not an array. Cannot set element(s) ", value);
				return array;
			}

			// For arrays, repeat this procedure
			if(_.isArray(value)) {
				_.forEach(value, function(element) {
					Function.prototype._updateCollection.merge.default.call(self, array, element, affected, path);
				});
				return array;
			}

			let equals = this.getEqualsInCollectionFunction(path);
			let changed = false;

			// Replace all elements equal to the value
			for(let i = array.length - 1; i >= 0; i--) {
				if(equals(array[i], value)) { // this equals function might check only for id, meaning all other properties can be different
					affected.set.push(array[i]);
					_.mergeObjectInto(value, array[i]);
					changed = true;
				}
			}

			// Element was not found, add it
			if(! changed) {
				if(!_.def(value)) {
					return array;
				}
				affected.add.push(value);
				array.push(value);
			}
			return array;
		}
	},
	set: {
		default: function(array, value, affected, path) {
			let self = this;
			let replaced = false;

			affected = _.ensurePath(affected, ['add', 'set'], _.isArray, [], this.language.translate("Invalid 'affected' argument."));

			// For arrays, repeat this procedure
			if(_.isArray(value)) {
				_.forEach(value, function(element) {
					Function.prototype._updateCollection.set.default.call(self, array, element, affected, path);
				});
				return array;
			}

			const updateResult = this._updateOperation(array, value, affected, path);
			replaced = updateResult.replaced;
			array = updateResult.array;

			// Element was not found, add it
			if(!replaced) {
				if(!_.def(value)) {
					return array;
				}
				affected.add.push(value);
				array.push(value);
			}
			return array;
		}
	},
	/**
	 * Adds the item if it is not present, but does not overwrite properties if it is already present
	 */
	complete : {
		default: function(array, value, affected, path) {
			let self = this;
			if(_.isArray(value)) {
				_.forEach(value, function(v) {
					Function.prototype._updateCollection.complete.default.call(self, array, v, affected, path);
				});
				return array;
			}

			let equals = this.getEqualsInCollectionFunction(path);
			let found = _.find(array, function(element) { return equals(element, value); });
			if(found === undefined) {
				affected.add.push(value);
				array.push(value);
			}
			return array;
		}
	},
	update: {
		default: function (array, value, affected, path) {
			let self = this;
			affected = _.ensurePath(affected, ['update'], _.isArray, [], this.language.translate("Invalid 'affected' argument."));

			// For arrays, repeat this procedure
			if(_.isArray(value)) {
				_.forEach(value, function(element) {
					Function.prototype._updateCollection.update.default.call(self, array, element, affected, path);
				});
				return array;
			}

			const updateResult = this._updateOperation(array, value, affected, path);
			array = updateResult.array;

			return array;
		}
	}
};

Function.prototype._updateOperation = function(array, value, affected, path){
	let replaced = false;

	if(!_.isArray(array)) {
		log.warn("First argument is not an array. Cannot set element(s) ", value);
		return array;
	}

	let equals = this.getEqualsInCollectionFunction(path);

	// Replace all elements equal to the value
	for(let i = array.length - 1; i >= 0; i--) {
		if(equals(array[i], value)) { // this equals function might check only for id, meaning all other properties can be different
			affected.set.push(array[i]);
			array[i] = value;
			replaced = true;
		}
	}

	return { replaced, array };
};

Function.prototype._hashCollectionItem = {
	/**
	 * Default hashing function for collection items.
	 * Simple values are returned as is, objects are indexed by `id`
	 * @param item
	 * @returns {(number|string|boolean|undefined|null)}
	 */	
	default: (item) => {
		if (_.isNil(item) || _.isString(item) || _.isNumber(item) || _.isBoolean(item)) {
			return item;
		}

		let id = _.get(item, 'id');

		if (! _.isNil(id)) {
			return id;
		}

		throw new _.Error('Cannot hash item');
	}
};

/**
 * Fills the given data with template data, if possible.
 * @param template
 * @param path
 * @param data
 * @private
 */
Function.prototype._fillTemplate = function(template, path) {
	if(_.isString(path)) path = [path];
	if(!_.isArray(path)) path = [];

	if(_.get(this._templating, path) === false
		|| _.get(this.getParameterMetaData(path, true), 'templating') === 'none') {
		return template;
	}

	let output = template;
	if(_.isString(template)) {
		let data = this.input || {};
		output = Mustache.render(template + "", data); // Mustache cannot handle String objects
	} else if (_.isArray(template)) {
		output = [];
		template.forEach(function(value) {
			output.push(value);
		});
	} else if (_.isPlainObject(template)) {
		output = {};
		for(let key in template) {
			let newPath = _.clone(path);
			newPath.push(key);
			output[key] = this._fillTemplate(template[key], newPath);
		}
	}

	return output;
};

/**
 * Compares two parameters by their update priority.
 * @param a
 * @param b
 * @returns {number}
 */
Function.prototype._compareParametersByUpdatePriority = function(a,b) {
	return this._getUpdatePriority(a)-this._getUpdatePriority(b);
};

/**
 * Get the update priority of a parameter. Determines the order in which parameters are updated.
 * @param parameter
 * @returns {*}
 * @private
 */
Function.prototype._getUpdatePriority = function(parameter) {
	let key = Function.parameterToKey(parameter);
	let priority = parseInt(this._updatePriorities[key]);
	if(isNaN(priority)) {
		return 0;
	}
	return priority;
};

Function.prototype._createValidityWarnings = function(validity, path) {
	if(!(validity instanceof _.Validity)) {
		return new _.Error(this.language.translate("An error occurred."));
	}

	if(!_.isArray(path)) {
		path = [];
	}

	let warnings = [];
	if(validity.getMessage() === undefined) {
		return [];
	} else {
		let map = validity.getValidityMap();
		let subWarnings = [];
		if(_.isObject(map) && validity.getType() !== 'array') {
			for( let i in map) {
				let newPath = path.slice(0);
				newPath.push(i);

				subWarnings = subWarnings.concat(this._createValidityWarnings(map[i], newPath));
			}
		}
		if(subWarnings.length > 0) {
			warnings = warnings.concat(subWarnings);
		} else {
			// No subwarnings? This validity is the root cause.
			let warning = validity.createError(false);
			if(warning !== null) {
				warnings.push({
					path: path,
					warning: warning
				});
			}
		}
	}

	return warnings;
};

Function.prototype._createValidityErrorMap = function(validity, path) {
	if(!(validity instanceof _.Validity)) {
		return new _.Error(this.language.translate('An error occurred.'));
	}

	if(!_.isArray(path)) {
		path = [];
	}
	let errors = {};
	let map = validity.getValidityMap();
	if(_.isObject(map) && validity.getType() !== 'array') {
		for(let i in map) {
			let newPath = path.slice(0);
			newPath.push(i);
			let subMap = this._createValidityErrorMap(map[i], newPath);
			if(!_.isEmpty(subMap)) {
				_.extend(errors, subMap);
			}
		}
	} else {
		if(!validity.isValid()) {
			let error;
			error = validity.createError();
			if(error) {
				errors[path.join('.')] = error;
			}
		}
	}
	return errors;
};

Function.prototype.evaluate = function(value, context = {}) {
	try {
		return this.evaluateWith(value, 'full', this.getEvaluationContext(context));
	} catch(e) {
		return value;
	}
};

/**
 * Attempts to evaluate the given value as code. If this fails, the value itself is returned. If it fails and it was
 * written explicitly as code (i.e. surround with ` characters), it will throw an error.
 * @param value
 * @param {string} expressionSet
 * @param {object} context			Context accessible as (%) in evaluation
 * @returns {*}
 */
Function.prototype.evaluateWith = function(value, expressionSet, context = {}) {
	if(_.isString(value)) {
		let code = value.trim();

		const globalData = this.fti.getGlobalData();
		code = CodeEvaluator.replaceCodePlaceholders(code, {
			'(@)': '_global',
			'(%)': '_context'
		});
		let fullContext = {
			_global: globalData,
			_context: context
		};

		try {
			if(code === "") code = 'undefined';
			let evaluated = this.codeEvaluator.evaluate('let x = ' + code, fullContext, {
				expressionSet: expressionSet,
				arrayExpressions: true
			});
			value = evaluated.x;
		}
		catch (err) {
			// Full evaluation should throw an error if it cannot evaluate
			if(expressionSet === 'full' || /^e(?:valuate)?\(.*\)$/.exec(code)) {
				throw err;
			}
		}
	}
	return value;
};

/**
 * Feature: path parameter
 * @returns {object}
 * @protected
 */
Function.prototype._getPathData = function() {
	return this.readModel('_path');
};

Function.prototype.getCollectionMutations = function(data){
	let collectionMutations = {};
	if(!data) return collectionMutations;

	for (let i = 0, keys = Object.keys(data); i < keys.length; i++){
		let key = keys[i];
		if (key.split(".")[0] !== "_update") {
			continue;
		}
		collectionMutations[key.split('.').slice(1).join('.')] = data[key];
	}

	return collectionMutations;
}


module.exports = Function;

// Importing from here allows circular dependencies
const Trigger = require('core/src/trigger');
const FTI = require('core/src/fti');
