const schema = require('./markdown-schema');
const markdownit = require('markdown-it');
const markdownitAttrs = require('markdown-it-attrs');
const MarkdownParser = require('prosemirror-markdown').MarkdownParser;
const MarkdownItComponent = require('markdown-it-component');

// Setup markdown-it
const md = markdownit('commonmark', {html: false})
	.use(MarkdownItComponent())
	.use(markdownitAttrs);

const markdownParser = new MarkdownParser(schema, md, {
	blockquote: {block: "blockquote"},
	paragraph: {block: "paragraph"},
	list_item: {
		block: "list_item", getAttrs: function(tok) {
			return ({isTight: true});
		}
	},
	bullet_list: {block: "bullet_list"},
	ordered_list: {
		block: "ordered_list", getAttrs: function (tok) {
			return ({order: +tok.attrGet("order") || 1});
		}
	},
	heading: {
		block: "heading", getAttrs: function (tok) {
			return ({level: +tok.tag.slice(1)});
		}
	},
	code_block: {block: "code_block"},
	fence: {
		block: "code_block", getAttrs: function (tok) {
			return ({params: tok.info || ""});
		}
	},
	hr: {node: "horizontal_rule"},
	image: {
		node: "image", getAttrs: function (tok) {
			return ({
				src: tok.attrGet("src"),
				title: tok.attrGet("title") || null,
				alt: tok.children[0] && tok.children[0].content || null
			});
		}
	},
	hardbreak: {node: "hard_break"},

	em: {mark: "em"},
	strong: {mark: "strong"},
	link: {
		mark: "link", getAttrs: function (tok) {
			return ({
				href: tok.attrGet("href"),
				title: tok.attrGet("title") || null,
				target: tok.attrGet("target") || null
			});
		}
	},
	code_inline: {mark: "code"},
	component: {
		node: "component", getAttrs: function(tok) {
			return ({
				tag: tok.tag,
				props: tok.props
			});
		}
	}
});

module.exports = markdownParser;
