const _ = require('lodash')

/** Creates a ResizeObserver which calls the callback when (one of the) domElements resize
  * @param {HTMLElement[]} - array of DOM elements to observe for resizing
  * @param {callback} - function to be called when one of the elements has been resized
  * @param {object} options - observe options (same as here: https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/observe)
  * @returns {ResizeObserver} - instance of ResizeObserver that has been created
  */

let mockResizeObserver = function(){ 
	return {
		observe: _.stubTrue, 
		unobserve: _.stubTrue, 
		disconnect: _.stubTrue 
	} 
}

let onDOMElementsResized = (domElements, callback, options) => {
	// Give alternative for nodejs tests
	let ResizeObserver = window.ResizeObserver || mockResizeObserver;
	let observer = new ResizeObserver((events) => {
		_.forEach(events, callback);
	});

	_.forEach(domElements, (el) => observer.observe(el, options));

	return observer;
}

module.exports = onDOMElementsResized;