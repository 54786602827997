
	import Icon from 'client/src/utils/icon';

	export default {
		name: "context-menu-item",
		props: {
			id: [String, Number],
			icon: String
		},
		computed: {
			iconClass() {
				return Icon.getClass(this.icon);
			}
		},
		methods: {
			onClick() {
				this.$emit('click', this.id);
			}
		}
	}
