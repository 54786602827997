
	import {debounce, get, pick, sortBy, isArray, reduce, values, union, orderBy} from "lodash";
	import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";
	import NeighbourItem from './neighbour-item.vue';

	function buildTree(obj) {
		let data = obj.rawData,
				currentId = obj.id,
				layers = obj.groupBy.split(',');

		let nameMap = {
			in: 'Incoming relations',
			out: 'Outgoing relations',
			self: 'Self relations'
		};

		const createNeighbourObjects = (relations) => {
			return sortBy(relations.map(relationRow => {
				let {rel, neighbourCaption, nodeCaption} = relationRow,
						neighbourId = get(rel, "target") === currentId ? rel["source"] : rel["target"];
				return {
					id: neighbourId,
					__relation: pick(rel, ["id", "type", "source", "target"]),
					tooltip: `(${nodeCaption})-[:${rel.type}]-(${neighbourCaption ? neighbourCaption : neighbourId})`,
					name: neighbourCaption || "-no-name-",
				};
			}), "name");
		};

		try {
			return reduce(data["table"], (result, object) => {
				let parent = result;
				for (let i = 0; i < layers.length; i++) {
					let layerType = layers[i];
					let layerName = object[layerType];
					parent = (parent.children[layerName] || (parent.children[layerName] = {
						childCount: 0,
						children: {},
						totalChildCount: 0,
						name: nameMap[layerName] ? nameMap[layerName] : layerName,
						type: layerName,
					}));
					parent.totalChildCount += object.totalRelCount;
					parent.childCount += object.relCount;
					if (layers.length === i + 1) {
						parent.children = createNeighbourObjects(object.relations);
					}
				}
				return result;
			}, {children: {}});
		} catch (err) {
			console.error(err);
			obj.error = err;
			return false;
		}
	}

	function itemAddAction(item, id, loadsBasedOn = "relation") {
		const getRequestedEntities = (item) => {
			if (item.hasOwnProperty("children")) {
				return getRequestedEntities(values(item.children));
			}
			if (isArray(item)) {
				let nodes = [], relations = [];
				for (let child of item) {
					let childEntities = getRequestedEntities(child);
					nodes = union(nodes, childEntities.nodes);
					relations = union(relations, childEntities.relations);
				}
				return {nodes, relations};
			}
			return {
				nodes: [item.id],
				relations: [get(item, "__relation.id")]
			};
		};
		let {nodes, relations} = getRequestedEntities(item);
		return {nodes, relations: loadsBasedOn === 'relation' ? relations : []};
	}

	/**
	 * Vue component definition
	 */
	export default {
		name: "neighbours-view",
		data: function () {
			return {
				"error": null,
				"id": null,
				"store": "application",
				"rawData": {},
				"groupBy": [],
				"loadsBasedOn": "relation",
				"searchString": "",
				"searchEnabled": true,
				"treeTypes": [{
					"type": "#"
				}, {
					"type": "in",
					"icon": "fa fa-arrow-left"
				}, {
					"type": "out",
					"icon": "fa fa-arrow-right"
				}, {
					"type": "self",
					"icon": "fa fa-undo"
				}],
				"openAll": true,
			};
		},
		computed: {
			treeData() {
				return orderBy(values(buildTree(this)["children"]), 'name');
			},
		},
		watch: {
			searchEnabled(newValue, oldValue) {
				if (!oldValue && newValue) {
					// The search box will have lost focus because it was disabled during the filter request
					this.$nextTick(() => this.$refs.search.focus());
				}
			}
		},
		methods: {
			selected(node) {
				this.$emit('selected', node);
			},
			openTree(node) {
				this.$emit('openTree', node);
			},
			/**
			 * Action functions. currently only two implemented.
			 * itemAddClicked runs on clicked item have neighbours & creates action & run it
			 *
			 * @param item
			 */
			itemAddClicked(item) {
				this.event('addEntities', {
					"entityIds": itemAddAction(item, this.id, this.loadsBasedOn),
					"store": this.store
				});
			},
			/**
			 * Action for sending a clicked to PropertiesView
			 *
			 * @param item
			 */
			itemClicked(item) {
				if (!("children" in item)) {
					this.trigger({
						"type": "itemClick",
						"item": {id: item.id, meta: {store: this.store}}
					});
				}
			},
			/**
			 * Debounce function for keyboard input in searchText model
			 */
			search: debounce(function (e) {
				this.searchEnabled = false;
				this.searchString = e.target.value;
				this.event('filterResults', {"searchString": e.target.value});
			}, 500)
		},
		mixins: [VueRendererComponent],
		components: {NeighbourItem}
	};
