const Function = require('core/src/function');
const View = require('core/src/functions/view');
const _ = require('lodash');
const {def,validate} = require("utils/src/validation");
const IAPI = require('core/src/api-client-abstract').default;
const log = require('core/src/log').instance("function/neighboursview");
const Language = require('core/src/language').default;

let NeighboursView = Function.extend(View, "NeighboursView");

NeighboursView.Event = {
	"In": {
		"FILTER": 'filterResults', // fired by neighbours-view-renderer submits API a search action
		"ADD_ENTITIES": 'addEntities'
	}
};
/**
 * API call wrapper
 *
 * @param obj
 */
NeighboursView.prototype.getNeighboursData = function () {
	let params = {
		id: this.readModel("id"),
		store: this.readModel("store"),
		groupBy: this.readModel("groupBy").split(",").map(t => String(t).trim()),
		ignoreLabels: this.readModel("ignoreLabels").split(",").map(t => String(t).trim()),
		relationKey: this.readModel("relationKey"),
		itemLabels: this.readModel("itemLabels").split(",").map(t => String(t).trim()),
		loadsBasedOn: this.readModel("loadsBasedOn"),
		maxNodesPerLeaf: this.readModel("maxNodesPerLeaf"),
		searchString: this.readModel("searchString"),
		excludeNeighboursWithLabel: this.readModel("excludeNeighboursWithLabel").split(",").map(t => String(t).trim()),
		excludeRelationTypes: this.readModel("excludeRelationTypes").split(",").map(t => String(t).trim())
	};

	log.debug("API Params", "\r:params " + JSON.stringify(params) + "\r");
	try {
		return this.api.getNeighbourDetails(params);
	} catch (err) {
		throw Error(err);
	}
};

NeighboursView.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);

	this.setParameters({
		"id": undefined,
		"store": undefined,
		"groupBy": undefined,
		"ignoreLabels": undefined,
		"relationKey": undefined,
		"itemLabels": undefined,
		"loadsBasedOn": undefined,
		"maxNodesPerLeaf": undefined,
		"excludeNeighboursWithLabel": undefined,
		"excludeRelationTypes": undefined,

		// Local parameters (undocumented)
		"rawData": undefined,
		"searchEnabled": true,
		"searchString": ""
	});

	let notRequired = {"default": undefined, "warn": def};

	this.setModelValidation({
		"id": ["isStringOrNumber", {default: undefined, warn: def}],
		"store": ["isString", {default: "application", warn: def}],
		"groupBy": ["isString", {default: "direction,neighbourLabels,relationType", warn: def}],
		"ignoreLabels": ["isString", {default: "Graphileon,IA_Function,IA_DUMP,IA_Install", warn: def}],
		"itemLabels": ["isString", {default: "name, naam", warn: def}],
		"relationKey": ["isString", {default: "type", warn: def}],
		"loadsBasedOn": ["isString", {default: "relation", warn: def}],
		"searchString": ["isString", {default: "", warn: def}],
		"maxNodesPerLeaf": ["isNumber", {default: 100, warn: def}],
		"excludeNeighboursWithLabel": ["isString", {default: "", warn: def}],
		"excludeRelationTypes": ["isString", {default: "", warn: def}],
		"rawData": ["isObject", notRequired]
	});

	/**
	 *
	 * @param event
	 */
	this.setEventHandler(NeighboursView.Event.In.FILTER, function (event) {
		this.updateModel("searchEnabled", false);
		this.updateModel("searchString", event.searchString);
		this.getNeighboursData().then(data => {
			this.update({"rawData": data, searchEnabled: true});
		}).catch((err) => {
			this.updateModel("error", err);
		});
	});

	/**
	 *
	 * @param event
	 */
	this.setEventHandler(NeighboursView.Event.In.ADD_ENTITIES, async function (event) {
		let nodes = [],
			relations = [];

		nodes = _.map(await this.api.executeRequest('/data/read-all-entities', 'post', {
			entities: _.map(event.entityIds.nodes, (entityId) => { return {id: entityId, meta: {store: event.store}}})
		}), (node) => node.data);
		relations = _.map(await this.api.executeRequest('/data/read-all-entities', 'post', {
			entities: _.map(event.entityIds.relations, (entityId) => { return {id: entityId, meta: {store: event.store}}}),
			type: 'relation'
		}), (relation) => relation.data);

		if(relations.length === 0){
			relations = await this.api.autoComplete(event.entityIds.nodes);
		}
		this.executeTriggers({
			"type": "itemAddClick",
			"store": event.store,
			"nodes": nodes,
			"relations": relations
		});
	});
};

/**
 *
 * @param input
 */
NeighboursView.prototype.onExecute = async function (input) {
	let check = validate({
		nodeID  : [input.id, 'isStringOrNumber']
	}, this.language.translate('No node is selected'));

	if(!check.isValid()) {
		this.updateModel("error",  check.createError());
	}

	try {
		let data = await this.getNeighboursData();
		this.updateModel("rawData", data);
	} catch (err) {
		this.updateModel("error", err);
	}
	return View.prototype.onExecute.call(this, input);
};

module.exports = NeighboursView;
