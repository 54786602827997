const APIClientAbstract = require("core/src/api-client-abstract").default;
const ApplicationInfo = require('core/src/application-info').default;
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const log = require('core/src/log').instance("function/teameditview");
const Language = require('core/src/language').default;

const _ = require('core/src/utils/legacy');

const TeamEditView = Function.extend(View, "TeamEditView");

/**
* @override
* @returns {undefined}
*/
TeamEditView.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(APIClientAbstract);
	this.appInfo = dependencies.get(ApplicationInfo);
	this.language = dependencies.get(Language);

	this.setParameters({
		'$id': undefined
	});
};

TeamEditView.prototype.alterRenderData = async function (data) {
	data = data || {};
	try {
		data.allUsers = await this._getAllUsers();
		data.allTeams = await this._getAllTeams();

		let team = {
			id: undefined,
			properties: {
				name: undefined
			}
		};
		if(_.isStringOrNumber(data.id)) {
			data.teamUsers = await this._getTeamUsers(data.id);
			data.teamTeams = await this._getTeamTeams(data.id);
			team = await this.api.loadTeam(data.id);
		}

		data.team = team;

		return data;
	}catch(err) {
		throw new _.Error({
			message: this.language.translate('Could not prepare render data.'),
			originalError: err
		});
	}
};

TeamEditView.prototype._getTeamUsers = async function(id) {
	let users = {};
	try {
		var response = await this.api.getTeamUsers(id);
		_.forIn(response, user => { users[user.id] = {id: user.id, name: user.properties.name, email: user.properties.email}; });
	} catch(err) {
		log.error('Could not load team users.', err);
	}
	return users;
};

TeamEditView.prototype._getTeamTeams = async function(id) {
	let teams = {};
	try {
		var response = await this.api.getTeamTeams(id);
		_.forIn(response, team => { teams[team.id] = {id: team.id, name: team.properties.name}; });
	} catch(err) {
		log.error('Could not load team teams.', err);
	}
	return teams;
};

TeamEditView.prototype._getAllUsers = async function() {
	let users = {};
	try {
		let response = await this.api.getAllUsers();
		_.forIn(response, user => { users[user.id] = {id: user.id, name: user.properties.name, email: user.properties.email}; });
	} catch(err) {
		log.error('Could not load users.', err);
	}
	return users;
};

TeamEditView.prototype._getAllTeams = async function() {
	let teams = {};
	try {
		let response = await this.api.getAllTeams();
		_.forIn(response, team => { teams[team.id] = {id: team.id, name: team.properties.name}; });
	} catch(err) {
		log.error('Could not load teams.', err);
	}
	return teams;
};

module.exports = TeamEditView;