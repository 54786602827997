const Function = require('core/src/function');
const _ = require('lodash');

const CustomStyle = Function.extend(Function, 'CustomStyle');

CustomStyle.prototype.onInit = function() {
	this.setParameters({
		'css': undefined,
		'$stayAlive': 'dashboard',
	});
};

CustomStyle.prototype.onExecute = function(input) {
	if (! _.isString(input.css)) {
		return;
	}

	this.styleElement = $('<style type="text/css">' + input.css + '</style>');

	$('head').first().append(this.styleElement);
};

CustomStyle.prototype.onClose = function() {
	this.styleElement.remove();

	return true;
};

module.exports = CustomStyle;