import G128Edition from "./g128-edition";
import Edition from "./edition";

export default class G256Edition extends G128Edition {
	static get code() {
		return 'g256';
	}
	static get name() {
		return Edition.name + ' 256 Edition';
	}
	defineFeatures() {
		return {
			...super.defineFeatures(),
			[Edition.Feature.GRAPHILEONS_COUNT]: 256,
			[Edition.Feature.PASSPORT_INTEGRATION]: true,
			[Edition.Feature.PERMISSION]: true,			
		};
	}
}
