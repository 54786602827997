const _ = require('core/src/utils/legacy');
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const ApplicationInfo = require('core/src/application-info').default;

const SearchView = Function.extend(View, "SearchView");

/**
	 * @override
	 * @returns {undefined}
	 */
SearchView.prototype.onInit = function(dependencies) {
	this.appInfo = dependencies.get(ApplicationInfo);

	this.setParameters({
		'$query': undefined,
		'$store': undefined,
		'$output': undefined,
		'$outputs': undefined, // computed with getOutputOptions
		'$storeType': undefined, // for backward compatibility
		'$storeTypes': undefined,
		'$storeOptions': undefined,
		'$syntax': undefined,
		'$queryTitle': '',
		'$queryFieldRows': undefined, // legacy support
		'$queryMinHeight': undefined,
		'$queryMaxHeight': undefined,
		'$hideOutputs': false,
		'$hideStores': false,
		'$hideExecuteButton': false,
		'$executeOnEnter': true
	});
};

SearchView.prototype.getOutputOptions = function() {
	var triggers = this.findTriggersWith({
		   type: 'execute'
	});

	var outputFromTrigger = function(trigger) {
		var output = {
			label: _.get(trigger.getProperties(), 'output')
		};

		const compatibilityIndex = _.get(trigger.getParameters(), '$trigger.index');
		output.index = _.get(trigger.getProperties(), 'index', compatibilityIndex);
		return output;
	};

	var hasLabel = function(output) {
		return _.isString(output.label) && output.label.length;
	};

	var outputs = _.map(
		triggers,
		outputFromTrigger
	);

	outputs = _.filter(
		outputs,
		hasLabel
	);

	outputs = _.sortBy(outputs, 'index');

	return outputs;
};

SearchView.prototype.getOutputStores = function(input) {
	var definedOptions = _.isString(input.storeOptions) ? _.map(input.storeOptions.split(','), _.trim) : input.storeOptions;

	if (_.isArray(definedOptions)) {
		return _.filter(definedOptions, _.isString);
	}
	let storeType = input.storeType ? [input.storeType] : undefined;
	let storeTypes = input.storeTypes || storeType;
	return this.getStores(storeTypes);
};

SearchView.prototype.alterRenderData = function(input) {
	input.outputs = this.getOutputOptions();

	input.stores = this.getOutputStores(input);

	return input;
};

/**
 * @param storeTypes
 * @returns {Array}
 */
SearchView.prototype.getStores = function(storeTypes) {
	var types = _.isString(storeTypes) ? _.map(storeTypes.split(','), _.trim) : storeTypes;

	var stores = _.get(this.appInfo, 'stores', []);

	if (! _.isArray(types)) {
		return _.keys(stores);
	}

	var storeTypeIn = _.curry(function(typesList, store) {
		return _.includes(typesList, store.driver);
	});

	return _.keys(
		_.pickBy(
			stores,
			storeTypeIn(types)
		)
	);
};

module.exports = SearchView;
