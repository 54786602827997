const Function = require('core/src/function');
const IGraphileon = require('core/src/i-graphileon').default;

const LoadDashboard = Function.extend(Function, "LoadDashboard");

/**
 * @override
 * @returns {undefined}
 */
LoadDashboard.prototype.onInit = function(dependencies) {
	this.graphileon = dependencies.get(IGraphileon);

	this.setParameters({
		$dashboard: undefined,
		$bookmark: undefined,
		$params: undefined
	});

	const notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		dashboard: ['isString'],
		bookmark: ['isString', notRequired],
		params: ['isPlainObject', notRequired]
	});
};

LoadDashboard.prototype.onExecute = function() {
	const dashboardIAName = this.readModel('dashboard');
	const bookmarkName = this.readModel('bookmark');
	const params = this.readModel('params');

	return this.graphileon.router.goToDashboard(dashboardIAName, bookmarkName, params);
};

module.exports = LoadDashboard;
