const jQueryComponent = require('../component.jquery');

const Editor = require('client/src/editor');

const MarkdownEditorComponent = function(dependencies) {
	jQueryComponent.call(this, dependencies, 'markdown-editor');
	this.dependencies = dependencies;
};
MarkdownEditorComponent.prototype = Object.create(jQueryComponent.prototype);

MarkdownEditorComponent.prototype._element = undefined;

MarkdownEditorComponent.prototype.onRender = function(properties, innerHtml) {
	const $container = $('<div>').addClass('text-editor');
	const $textarea = $('<textarea>').attr('name', properties.name);
	$container.append($textarea);

	let editor = new Editor(this.dependencies, $textarea, ['markdown']);
	editor.setMode('markdown');
	editor.setAlternativeEditor('wysiwyg');
	editor.setValue(innerHtml);

	return $container;
};

module.exports = MarkdownEditorComponent;
