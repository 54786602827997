import Err from "utils/src/error";

function notImplemented() {
	throw new Err('IGraphileon is an abstract class and should not be instantiated directly.');
}
export default class IGraphileon {
	on() { notImplemented(); }
	fire() { notImplemented(); }
	login() { notImplemented(); }
	loadDashboard(dashboard, bookmark, params) { notImplemented() }
	loadDefaultDashboard() { notImplemented() }
	closeDashboard() { notImplemented() }
	login(user, password) { notImplemented() }
	logout() { notImplemented() }
	isLoggedIn() { notImplemented() }
	isEnvironmentLoaded() { notImplemented() }
	start() { notImplemented() }
	executeFunction(functionData, input) { notImplemented() }

}
IGraphileon.Event = {
	ERROR: 'error',
	ERROR_CONFIGURATION: 'errorConfiguration',
	ERROR_SERVER: 'errorServer',
	ERROR_CONNECTION: 'errorConnection',
	ERROR_LICENSE_EXPIRED: 'licenseExpired',
	ERROR_LICENSE_BROKEN: 'licenseBroken',
	ERROR_FUNCTION: 'errorFunction',
	ERROR_DASHBOARD_LOAD_FAILED: 'dashboardLoadFailed',
	SESSION_EXPIRED: 'sessionExpired',

	READY: 'ready',
	TFA_TOKEN_REQUIRED: 'tfaTokenRequired',
	LOGIN: 'login',
	LOGOUT: 'logout',
	CONTENT_LOADED: 'contentLoaded',
	ENVIRONMENT_LOADED: 'environmentLoaded',
	DASHBOARD_LOADED: 'dashboardLoaded',
	DASHBOARD_CLOSED: 'dashboardClosed',
	GET_ORIGIN_FUNCTION: 'getOriginFunction',
	AUTOLOGIN_FAILED: 'autoLoginFailed',
	LOGIN_REQUIRED: 'loginRequired',
	USER_ACTIVATED: 'userActivated',
	USER_ACTIVATION_FAILED: 'userActivationFailed',
	LANGUAGE_CHANGED: 'languageChanged',
	LANGUAGES_LOADED: 'languagesLoaded',
	PASSWORD_RESET: 'passwordReset',
	STYLE_ERROR: 'styleError',
	FUNCTION_TRIGGER: 'functionTrigger',
	LOADING_STARTED: 'loadingStarted',
	LOADING_FINISHED: 'loadingFinished',
	ACCOUNT_RECOVER: 'accountRecover',
	USER_VIEW_ACCOUNT_BUTTON: 'userViewAccountButton',
	USER_VIEW_INFO_BUTTON: 'userViewInfoButton',
};
IGraphileon.ErrorCode = {
	NOT_AVAILABLE: 'NotAvailable',
	NOT_FOUND: 'NotFound',
	NOT_LOGGED_IN: 'NotLoggedIn'
};
