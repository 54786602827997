const Iterate = require('./iterate');
const Edition = require('core/src/edition').default;
const Function = require('core/src/function');
const _ = require('lodash');

Edition.setBuildFeature(Edition.Feature.FUNCTION_TIMER, true);

Iterate.prototype.execute = function(...args) {
	if(!Edition.hasFeature(Edition.Feature.FUNCTION_TIMER)) {
		const error = Edition.functionNotAvailableError('Iterate');
		this.error(error);
		throw error;
	}
	return Function.prototype.execute.call(this, ...args);
};

Iterate.prototype.onExecute = function(input) {
	var self = this;

	var runIteration = function(data, key) {
		let eventData = {
			type: 'iteration',
			data: data,
			index: key
		};

		return self.executeTriggers(eventData);
	};

	var removeItem = (array, key) => {
		if (key == null) {
			return array.shift();
		}

		var index = _.findIndex(
			array,
			(item) => { return item[0] == key;}
		);

		if (index < 0) {
			return;
		}

		return _.first(array.splice(index, 1));
	};

	var endIteration = () => {
		self.executeTriggers({
			type: 'iterationEnd'
		});

		// error appears if we close it now
		setTimeout(
			function() {
				self.closeFunctionInstance();
			}
		);
	};

	var iterateArray = function(array, key) {
		if (_.isEmpty(array)) {
			endIteration();
			return;
		}

		var runNextIteration = function(newModel, oldModel) {
			// Reset nextIteration to be able to update it with `true` to trigger next iteration
			self._model.nextIteration = null;

			iterateArray(array, newModel.nextIndex);
		};

		if (input.iterationType == 'serial') {
			self.onUpdate = runNextIteration;
			runNextIteration = function() {}; //onUpdate will run next iteration
		}

		var item = removeItem(array, key);

		if (! item) {
			endIteration();
			return;
		}

		runIteration(item[1], item[0])
			.then(
				runNextIteration,
				runNextIteration// run next iteration even if the previous failed
			);
	};

	var toIterate = input.data;

	if (! _.isArray(toIterate) && ! _.isObjectLike(toIterate)) {
		toIterate = [toIterate];
	}

	iterateArray(_.toPairs(toIterate), input.nextIndex);
};
