const Function = require('core/src/function');
const _ = require('lodash');
const Management = require('core/src/functions/management');

const DataManagement = Function.extend(Management, 'DataManagement');

DataManagement.prototype.getActionParams = function() {
	const params = Management.prototype.getActionParams.call(this);
	return {...params, managerId: this.id};
};

DataManagement.prototype.onInit = function () {
	_.extend(this.actions, {
		'import': params => this.api.executeRequest('/data/import', 'post', params),
		'read-entity': params => this.api.executeRequest('/data/read-entity', 'get', params),

		// Backward compatibility
		'read-node': params => this.api.executeRequest('/data/read-entity', 'get', params),
		'read-relation': params => this.api.executeRequest('/data/read-entity', 'get', {...params, type: 'relation'})
	});
};

DataManagement.prototype.getModule = function() {
	return 'data';
};

module.exports = DataManagement;
