const APIClientAbstract = require("core/src/api-client-abstract").default;
const ApplicationInfo = require('core/src/application-info').default;
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const log = require('core/src/log').instance("function/permissioneditview");
const Language = require('core/src/language').default;

const _ = require('core/src/utils/legacy');

const PermissionEditView = Function.extend(View, "PermissionEditView");

/**
* @override
* @returns {undefined}
*/
PermissionEditView.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(APIClientAbstract);
	this.appInfo = dependencies.get(ApplicationInfo);
	this.language = dependencies.get(Language);

	this.setParameters({
		'$id': undefined
	});
};

PermissionEditView.prototype.alterRenderData = async function (data) {
	data = data || {};
	try {
		data.allUsers = await this._getAllUsers();
		data.allTeams = await this._getAllTeams();

		let permission = {
			id: undefined,
			properties: {
				name: undefined
			}
		};
		if(_.isStringOrNumber(data.id)) {
			data.permissionUsers = await this._getPermissionUsers(data.id);
			data.permissionTeams = await this._getPermissionTeams(data.id);
			permission = await this.api.loadPermission(data.id);
		}

		data.permission = permission;

		return data;
	}catch(err) {
		throw new _.Error({
			message: this.language.translate('Could not prepare render data.'),
			originalError: err
		});
	}
};

PermissionEditView.prototype._getPermissionUsers = async function(id) {
	let users = {};
	try {
		var response = await this.api.getPermissionUsers(id);
		_.forIn(response, user => { users[user.id] = {id: user.id, name: user.properties.name, email: user.properties.email}; });
	} catch(err) {
		log.error('Could not load permission users.', err);
	}
	return users;
};

PermissionEditView.prototype._getPermissionTeams = async function(id) {
	let teams = {};
	try {
		var response = await this.api.getPermissionTeams(id);
		_.forIn(response, team => { teams[team.id] = {id: team.id, name: team.properties.name}; });
	} catch(err) {
		log.error('Could not load permission teams.', err);
	}
	return teams;
};

PermissionEditView.prototype._getAllUsers = async function() {
	let users = {};
	try {
		let response = await this.api.getAllUsers();
		_.forIn(response, user => { users[user.id] = {id: user.id, name: user.properties.name, email: user.properties.email}; });
	} catch(err) {
		log.error('Could not load users.', err);
	}
	return users;
};

PermissionEditView.prototype._getAllTeams = async function() {
	let teams = {};
	try {
		let response = await this.api.getAllTeams();
		_.forIn(response, team => { teams[team.id] = {id: team.id, name: team.properties.name}; });
	} catch(err) {
		log.error('Could not load teams.', err);
	}
	return teams;
};

module.exports = PermissionEditView;