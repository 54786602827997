const uploadTemplate = require('./upload-popup.html');
const popup = require('./popup');
const def = require('utils/src/validation').def;
const get = require('lodash/get');

const schema = require('./markdown-schema');

function insertImage(url, state, dispatch, view) {
	let nodeType = schema.nodes.image;

	let {$from} = state.selection, index = $from.index();
	if (!$from.parent.canReplaceWith(index, index, nodeType)) {
		return false;
	}
	if (dispatch) {
		let node = nodeType.create({
			src: url
		});
		dispatch(state.tr.replaceSelectionWith(node));
		// The rich text editor is not firing any event when toolbar buttons are clicked this hack fires a `blur` event when any insertimage buttons event is finished
		view.dispatchEvent(new Event('blur'));
		view.focus();
	}
	return true;
}

function uploadPopup(api, currentUrl, state, dispatch, view) {
	let $dialog = $(uploadTemplate);
	let title = 'Insert image';
	let buttonText = 'Insert';
	if(def(currentUrl)) {
		title = 'Change image';
		buttonText = 'Change';
		$dialog.find('.input-url').val(currentUrl);
	}

	popup(title, $dialog, buttonText, async () => {
		let $file = $dialog.find('.input-file');
		if($file.val()) {
			let file = get($file[0], 'files[0]');
			file = await api.upload(file);
			await insertImage(file.download_link, state, dispatch, view);
		} else {
			await insertImage($dialog.find('.input-url').val(), state, dispatch, view);
		}
	});
}

export default uploadPopup;
