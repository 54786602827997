/**
* TODO: RelationFormViewRenderer currently access API directly. Should rather be done by NodeFormView Function.
*/

const EntityFormViewRenderer = require('./entity-form-view-renderer');
const IAPI = require('core/src/api-client-abstract').default;

const log = require('core/src/log').instance("function/relationformview/renderer");

const $ = require('jquery');
const _ = require('core/src/utils/legacy');

const formToObject = require('client/src/utils/formToObject');

/* Inheritance and constructor */
const RelationFormViewRenderer = function(dependencies) {
	EntityFormViewRenderer.call(this, dependencies, 'relation');
	this.api = dependencies.get(IAPI);
};
RelationFormViewRenderer.viewType = 'RelationFormView';
RelationFormViewRenderer.prototype = Object.create(EntityFormViewRenderer.prototype);

/* Statics */

/* Properties */

/* Methods */

RelationFormViewRenderer.prototype.setEntity = function(entity) {
	EntityFormViewRenderer.prototype.setEntity.call(this, entity);
	if(_.def(entity.type) && entity.id) {
		this._$form.find('fieldset.type').hide();
	}
};

RelationFormViewRenderer.prototype.isEditingTypedEntity = function() {
	// Only if it is a new relation or already has type property
	let data = formToObject(this._$form);
	let properties = _.get(data, 'properties.name');
	return (!this._$form.find('input[name=id]').val() || (properties && properties.indexOf('type') >= 0)) &&
		this._$form.find(':input[name=type]').val().trim() === 'TRIGGER';
};

RelationFormViewRenderer.prototype.initForm = function($form) {
	EntityFormViewRenderer.prototype.initForm.call(this, $form);
	var labelsInput = $form.find(':input[name=labels]');
	labelsInput.prop('disabled', 'disabled');
	this._$form.find('fieldset.labels').hide();

	// Form might not be on screen at this point so we defer the focusing
	_.defer(() => $form.find(':input[name=type]').focus());
};

RelationFormViewRenderer.prototype.createEntityDescription = function(entity) {
	const description = EntityFormViewRenderer.prototype.createEntityDescription.call(this, entity);

	if(_.def(entity.type) && entity.id) {
		description.unshift({label: 'Type', value: entity.type});
	}

	description.push({label: 'Start node', value: entity.from});
	description.push({label: 'End node', value: entity.to});

	return description;
};

RelationFormViewRenderer.prototype.validateEntity = function(relation) {
	if(!_.isObject(relation)) {
		log.error("Invalid relation.", relation);
	}
	return _.validate({
		id:				 [relation.id, _.isStringOrNumber, "Invalid id.",
			{default: undefined, warn: _.def(relation.id)}],
		type:			   [relation.type, 'isString', {default: "", warn: _.def(relation.type)}],
		properties:			[relation.properties, 'isObject', {default: {}, warn: _.def(relation.properties)}],
		meta:			   [relation.meta, 'isObject', {default: {}, warn: _.def(relation.meta)}]
	}, "Relation is invalid.");
};

RelationFormViewRenderer.prototype.requestSaveEntity = function(relation) {
	var deferred = $.Deferred();
	var self = this;
	var request = this.api.save(relation);

	request.done(function(relation) {
		let entity = _.extend({}, self._initData['preset'], relation);
		deferred.resolve(entity);
	});

	request.fail(function(response) {
		deferred.reject(response);
	});

	return deferred.promise();
};

module.exports = RelationFormViewRenderer;
