
	import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";

	import Log from "utils/src/log";

	import _ from "lodash";
	const log = Log.instance("client/renderers/markdown-edit-view");

	import Editor from 'client/src/editor';

	export default {
		name: "markdown-edit",
		mixins: [VueRendererComponent],
		components: { },
		data() {
			return {
				markdown: '',
				editor: undefined
			}
		},
		computed: {
			textareaId() {
				return `markdown${_.random(0, 100000)}`;
			}
		},
		watch: {
			markdown: _.debounce(function(newVal) {
				this.requestUpdate({markdown: newVal});
			}, 100)
		},
		mounted() {
			this.editor = new Editor(this.dependencies, this.$refs.textarea, ['markdown'], '\r\n');
			this.editor.setMode('markdown');
			this.editor.setAlternativeEditor('markdown');
			this.editor.setValue(this.markdown || '');
			const self = this;
			this.editor.on('change', function(event) {
				self.markdown = event;
			})
		}
	}
