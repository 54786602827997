import Err from 'utils/src/error';
import Log from 'utils/src/log';
import { t } from 'core/src/language';
import { isFunction, truncate } from 'lodash';

const log = Log.instance("core/dependencies");

export default class Dependencies {
	constructor(parent) {
		this._dependencyMap = new Map();

		this.parent = null;
		if(parent instanceof Dependencies) {
			this.parent = parent;
		}
	}

	copy(dependencies, reference) {
		this.set(reference, dependencies.get(reference));
	}

	/**
	 * Get a dependency based on the given reference.
	 * @param reference
	 * @param {Function} createDefault	A Function to create a default value, in case the dependency could not be resolved.
	 * @return {*}
	 */
	get(reference, createDefault = null) {
		// Find locally
		if(this._dependencyMap.has(reference)) {
			return this._dependencyMap.get(reference);
		}
		// Not found locally, try parent
		if(!this._dependencyMap.has(reference) && this.parent) {
			return this.parent.get(reference, createDefault);
		}
		// Still not found, try default
		if(isFunction(createDefault)) {
			return createDefault();
		}
		// Not found and no default, error
		const err = new Err(t('No dependency found for {{reference}}.', {reference: truncate(reference.toString(), {length:20, omission:'...'})}) );
		log.error(err.message, err);
		throw err;
	}

	has(reference) {
		if(this._dependencyMap.has(reference)) {
			return true;
		}
		if(this.parent) {
			return this.parent.has(reference);
		}
	}

	set(reference, instance) {
		if (isFunction(reference) && !(instance instanceof reference)) {
			throw new Err({
				message: t('Dependency is not an instance of {{referenceName}}', {referenceName: reference.name}),
				data: instance
			});
		}
		return this._dependencyMap.set(reference, instance);
	}

	/**
	 * Set dependency only if it was not already set.
	 * @param reference
	 * @param create
	 * @return 	The final reference.
	 */
	setDefault(reference, create) {
		if(this.has(reference)) {
			return this.get(reference);
		}
		const created = create();
		this.set(reference, created);
		return created;
	}
}