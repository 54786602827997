const Function = require('core/src/function');
const View = require('core/src/functions/view');
const log = require('core/src/log').instance("function/treeview");
const _ = require('core/src/utils/legacy');

const TreeView = Function.extend(View, "TreeView");

let warnOnDeprecatedTriggerType = (trigger) => {
	if (_.get(trigger, 'properties.type') === 'select') {
		log.warn('TreeView select event is deprecated and will be removed in next versions of Graphileon. Please use itemSelect event type');
	}

	if (_.get(trigger, 'properties.type') === 'checked') {
		log.warn('TreeView checked event is deprecated and will be removed in next versions of Graphileon. Please use itemCheck event type');
	}

	if (_.get(trigger, 'properties.type') === 'unchecked') {
		log.warn('TreeView unchecked event is deprecated and will be removed in next versions of Graphileon. Please use itemUncheck event type');
	}
}

TreeView.prototype.registerTrigger = function(trigger) {
	View.prototype.registerTrigger.call(this, trigger);
	warnOnDeprecatedTriggerType(trigger);
}

/**
 * @override
 * @returns {undefined}
 */
TreeView.prototype.onInit = function() {
	this.setParameters({
		'$config': {}, // config (jqxTree init properties)

		'$keyFor': { // where jqxTree item properties are taken from source data
			id: 'id',
			label: 'label',
			html: 'html',
			parentId: 'parentId',
			disabled: 'disabled',
			checked: 'checked',
			expanded: 'expanded',
			selected: 'selected',
			items: 'items',
			icon: 'icon',
			iconsize: 'iconsize',
			class: 'class',
		},
		'#tree': [], // tree structure
		'#items': [], // tree elements (with parentId)
		'$search': '',
		'$state': {
			selected: [],
			checked: [],
			expanded: []
		}
	});

	let enforceArray = ['isArray', {default: [], warn: _.def}];
	let enforceString = ['isString', {default: '', warn: _.def}];
	let enforceObject = ['isObject',{default: {}}];

	this.setModelValidation({
		config: enforceObject,
		keyFor: 'isObject',
		tree: enforceArray,
		items: enforceArray,
		search: enforceString,
		state: [{
			checked: enforceArray,
			expanded: enforceArray,
			selected: enforceArray,					
		}, "Invalid state structure"]
	});

	this.setUpdatePriorities({
		keyFor: 1,
		state: 2,
		items: 3,
		tree: 4,
	});

	this._hashCollectionItem = _.extend({}, this._hashCollectionItem, {
		items:  _.bind(this.itemId, this),
		'state.checked': _.bind(this.itemId, this),
		'state.expanded': _.bind(this.itemId, this)
	});

	this.setDeepModelUpdateHandler('state', handleStateUpdate);
	this.setModelUpdateHandler('tree', handleTreeUpdate);
	this.setModelUpdateHandler('items', handleItemsUpdate);
};

TreeView.prototype.createEmptyModel = function() {
	return {
		items: [],
		state: {
			selected: [],
			checked: [],
			expanded: []
		}
	}
}

TreeView.prototype.itemId = function(item) {
	let id = _.get(item, this.keyFor('id'));

	if (_.isNil(id)) {
		log.error('Cannot get id for item', item);
		throw new Error('Cannot get ID for item: ' + JSON.stringify(item));
	}

	return id;
}

TreeView.prototype.syncState = function() {
	this.updateModel('state', this.stateFromItems());
}

TreeView.prototype.stateFromItems = function() {
	let model = this.readModel();

	let state = {
		selected: _.filter(model.items, _.set({}, this.keyFor('selected'), true)),
		checked: _.filter(model.items, _.set({}, this.keyFor('checked'), true)),
		expanded: _.filter(model.items, _.set({}, this.keyFor('expanded'), true))
	}

	return state;
};

TreeView.prototype.keyFor = function(key) {
	return this.readModel(['keyFor', key]) || key;
};


// Given a `list` of objects, set `path` (property path) to true for the items that are in `trueItems` list, otherwise set to false
// Identity is checked with the hashItem function (hashItem(a) == hashItem(b))
// We use this function instead of Function.setItemsStateProperty to prevent multiple calls to Function.updateModel after syncing each model.state list
// list is being modified (mutated)
const syncFlagProperty = function(list, trueItems, path, hashItem) {
	path = _.toPath(path);
	let indexedList = _.flow([
		// set all items' path to false
		(list) => _.forEach(list, (item) => _.set(item, path, false)), 
		// index by hash for speed
		(list) => _.keyBy(list, hashItem)
	])(list);

	_.forEach(
		trueItems,
		(trueItem) => {
			let itemIndex = hashItem(trueItem);
			return _.set(indexedList, _.concat([itemIndex], path), true);
		}
	)

	return list;
}

const handleStateUpdate = function() {
	let hash = this.getHashCollectionItemFunction('items');
	let state = this.readModel('state');

	let items = _.flow([
		(list) => syncFlagProperty(list, state.selected,  this.keyFor('selected'), hash),
		(list) => syncFlagProperty(list, state.checked,  this.keyFor('checked'), hash),
		(list) => syncFlagProperty(list, state.expanded,  this.keyFor('expanded'), hash),
	])(this.readModel('items').$clone().$writable());

	this.updateModel('items', items);
};

const handleItemsUpdate = function() {
	this.syncState();
};

let handleTreeUpdate = function(newValue, oldValue) {
	let treeToArray = (tree) => {
		return _.reduce(
			tree, 
			(result, item, key) => {
				return _.concat(result, item, treeToArray(_.get(item, this.keyFor('items'))));
			},
			[]
		)
	}		

	let items = treeToArray(this.readModel('tree'));

	if (_.isEmpty(items)) return;

	this.updateModel('items', items);
}

module.exports = TreeView;
