const Function = require('core/src/function');
const IAPI = require('core/src/api-client-abstract').default;
const _ = require('lodash');
const Err = require('utils/src/error');
const Language = require('core/src/language').default;
const log = require('core/src/log').instance("function/elasticsearch-query");
const Edition = require('core/src/edition').default;

Edition.setBuildFeature(Edition.Feature.FUNCTION_ELASTICSEARCH, false);

const ElasticsearchQuery = Function.extend(Function, 'ElasticsearchQuery');

ElasticsearchQuery.prototype.onInit = function(dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);
	var params = {
		'$query': undefined,
		'$params': undefined,
		'$size': undefined,
		'$connectionString': undefined,
		'$index': undefined,
		'$queryType': undefined
	};
	this.setParameters(params);
};

/**
 * Creates a request for the backend, based on the input
 * @returns {object}	Request body object.
 */
ElasticsearchQuery.prototype.createRequest = function() {

	let id = this.getId();
	let query = this.getPropertyOrInput('query', true);
	let params = this.getPropertyOrInput('params', true);
	let size = this.getPropertyOrInput('size');
	let connectionString = this.getPropertyOrInput('connectionString');
	let index = this.getPropertyOrInput('index');
	let queryType = this.getPropertyOrInput('queryType');

	let request = {
		id, query, params, size, index, queryType
	};
	if(_.isString(connectionString)) {
		request.connectionString = connectionString;
	}

	return request;
};

ElasticsearchQuery.prototype.getESErrorMessage = function(response) {
	let json = {};
	let jsonMessage = _.get(response, 'responseJSON.message');
	try {
		json = JSON.parse(jsonMessage);
	}
	catch (exception) {
		// Not an Elasticsearch response (e.g. connection refused); just return the message.
		return jsonMessage;
	}

	let error = _.get(json, 'error');

	if(_.isString(error)) { // ES 1.x
		return error;
	}

	// ES 2.x and 5.x
	let message = error.reason;

	if(json.status == 404) {
		return message + " (" + error.index + ")";
	}

	return message;
};

ElasticsearchQuery.prototype.execute = function(...args) {
	// feature
	const error = Edition.functionNotAvailableError('ElasticsearchQuery');
	this.error(error);
	throw error;
}

ElasticsearchQuery.prototype.onExecute = async function() {
	// feature
};

module.exports = ElasticsearchQuery;