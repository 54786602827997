const _ = require('core/src/utils/legacy');
const Log = require('core/src/log');
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const IGraphileon = require('core/src/i-graphileon').default;
const IAPI = require('core/src/api-client-abstract').default;
const Language = require('core/src/language').default;
const Edition = require('core/src/edition/edition').default;

const log = Log.instance("Function/LoginView");

const LoginView = Function.extend(View, "LoginView");

LoginView.Event = {
	In : {
		SUBMIT: 'Submit',
		ACCOUNT_RECOVER: 'AccountRecover'
	}
};

LoginView.prototype._eventHandlers[LoginView.Event.In.SUBMIT] = async function(event) {
	const check = _.validateObject(event, {
		username: ['isString', {default: '', warn: _.def}],
		password: ['isString', {default: '', warn: _.def}],
		tfaToken: ['isString', {default: '', warn: _.def}]
	});
	if(!check.isValid()) {
		this.setError(this.language.translate('No valid user data provided.'));
		log.error(check.createError());
		return;
	}
	const valid = check.getValue();

	try {
		await this.graphileon.login(valid.username, valid.password, valid.tfaToken);
	} catch(error) {
		log.error(error);
		this.setError(error.getMessage());
	}
};

LoginView.prototype._eventHandlers[LoginView.Event.In.ACCOUNT_RECOVER] = function(event) {
	this.graphileon.fire(IGraphileon.Event.ACCOUNT_RECOVER, event); 
};

LoginView.prototype.setError = function(message) {
	this.update({'error': message});
};

/**
 * @override
 * @returns {undefined}
 */
LoginView.prototype.onInit = function(dependencies) {
	this.graphileon = dependencies.get(IGraphileon);
	this.api =  dependencies.get(IAPI);

	this.language = dependencies.get(Language);
	this.setParameters({
		'error': undefined,
		'loginProviders' : [],
		'accountRecovery': this.graphileon.appInfo.accountRecovery 
	});


};

LoginView.prototype.onExecute = function() {
	if(Edition.hasFeature(Edition.Feature.PASSPORT_INTEGRATION)) {
		this.api.getAuthenticationProviders()
			.done((data) => {
				this.update({loginProviders: data});
			});
	}
	return View.prototype.onExecute.call(this);
};

module.exports = LoginView;


