'use strict';
const VueRenderer = require('../vue-renderer');
const Layout = require('./layout.vue').default;
const IGraphileon = require('core/src/i-graphileon').default;
const ComponentLoader = require('client/src/component-loader').default;

const LayoutViewRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
	this.componentLoader = new ComponentLoader(dependencies);
	this.graphileon = dependencies.get(IGraphileon);
};
LayoutViewRenderer.viewType = 'LayoutView';
LayoutViewRenderer.prototype = Object.create(VueRenderer.prototype);
LayoutViewRenderer.prototype.componentLoader = null;

LayoutViewRenderer.prototype.getVueComponent = function() {
	return Layout;
};
LayoutViewRenderer.prototype.initVueComponent = function(component) {
	const smart = document.getElementsByTagName("smart-docking-layout")[0];
	this.componentLoader.loadComponents(smart);
};

module.exports = LayoutViewRenderer;