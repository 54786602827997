var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "neighbours-view" }, [
    _vm.error
      ? _c("div", { attrs: { title: "Error" } }, [_vm._v(_vm._s(_vm.error))])
      : _vm._e(),
    _vm._v(" "),
    !_vm.error
      ? _c("div", { staticClass: "neighbours-search" }, [
          _c("i", { staticClass: "fa fa-search" }),
          _c("input", {
            ref: "search",
            staticClass: "neighbours-search",
            attrs: { disabled: !_vm.searchEnabled },
            domProps: { value: _vm.searchString },
            on: { input: _vm.search },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.error
      ? _c(
          "ul",
          _vm._l(_vm.treeData, function (item) {
            return _c("neighbour-item", {
              key: item.id,
              staticClass: "v-treeview-item",
              attrs: {
                level: 0,
                item: item,
                treeTypes: _vm.treeTypes,
                openAll: _vm.openAll,
              },
              on: {
                itemClicked: _vm.itemClicked,
                itemAddClicked: _vm.itemAddClicked,
                selected: _vm.selected,
                openTree: _vm.openTree,
              },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }