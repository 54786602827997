var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.config
    ? _c(
        "div",
        { staticClass: "view-common" },
        [
          _c(
            "view-toolbar",
            {
              attrs: { "batch-triggers": _vm.config.batchTriggers },
              on: { batchTriggerClick: _vm.config.onBatchTriggerClick },
            },
            [_vm._t("toolbar")],
            2
          ),
          _vm._v(" "),
          _c("view-messages", {
            attrs: { messages: _vm.config.messages },
            on: { close: _vm.config.onCloseMessage },
          }),
          _vm._v(" "),
          _vm._l(_vm.config.contextMenus, function (menu, name) {
            return _c(
              "context-menu",
              {
                ref: `menu-${name}`,
                refInFor: true,
                attrs: { items: menu.items, filter: menu.filter },
                on: {
                  itemClick: _vm.config.onContextMenuClick,
                  close: _vm.config.onContextMenuClose,
                },
              },
              [_vm._t(`context-${name}`)],
              2
            )
          }),
        ],
        2
      )
    : _c("div", { staticClass: "view-common error" }, [
        _vm._v("Common view components not configured."),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }