require('codemirror/mode/cypher/cypher.js');
const { CypherEditor } = require('./cypher-editor');

module.exports = {
	options: {
		mode: 'application/x-cypher-query',
		lineNumbers: true,
		smartIndent: false,
		theme: 'neo'
	},
	alternativeEditors: {
		cypher: 'Cypher',
		'cypher-editor': {
			label: 'Cypher Editor (Beta)',
			Editor: CypherEditor
		}
	}
};
