const Function = require('core/src/function');
const _ = require('lodash');
const Management = require('core/src/functions/management');

const AppManagement = Function.extend(Management, 'AppManagement');

AppManagement.prototype.onInit = function () {
	_.extend(this.actions, {
		'fetch-list': params => this.api.executeRequest('/apps/fetch-list', 'get', params),
		'fetch-local-list': params => this.api.executeRequest('/apps/fetch-local-list', 'get', params),
		'fetch-app': params => this.api.executeRequest('/apps/fetch-app', 'get', params),
		'read-full': params => this.api.executeRequest('/apps/read-full', 'get', params)
	});
};

AppManagement.prototype.getModule = function() {
	return 'apps';
};

module.exports = AppManagement;
