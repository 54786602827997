var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.agGridLicenseLoaded
    ? _c(
        "ag-grid-vue",
        _vm._b(
          {
            ref: "grid",
            staticClass: "ag-grid-vue ag-theme-balham",
            attrs: {
              immutableData: true,
              suppressAsyncEvents: true,
              getRowNodeId: _vm.getRowNodeId,
              rowData: _vm.data,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              frameworkComponents: _vm.frameworkComponents,
              getContextMenuItems: _vm.getContextMenuItems,
            },
            on: {
              gridReady: _vm.onGridReady,
              gridSizeChanged: _vm.onResize,
              cellClicked: _vm.onCellClickEvent,
              cellDoubleClicked: _vm.onCellClickEvent,
              cellContextMenu: _vm.onCellContextMenu,
              rowClicked: _vm.onRowClickEvent,
              rowDoubleClicked: _vm.onRowClickEvent,
              selectionChanged: _vm.onSelectionChangedDebounced,
              filterChanged: _vm.onFilterChanged,
              rangeSelectionChanged: _vm.onSelectionChangedDebounced,
              rowDragEnter: _vm.onRowDragEnter,
              rowDragLeave: _vm.onRowDragLeave,
              rowDragEnd: _vm.onRowDragEnd,
              cellValueChanged: _vm.onCellValueChanged,
              gridColumnsChanged: _vm.onGridColumnsChanged,
            },
          },
          "ag-grid-vue",
          _vm.options,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }