const _ = require('core/src/utils/legacy');
const Function = require('core/src/function');
const View = require('core/src/functions/view');

const LayoutView = Function.extend(View, "LayoutView");

LayoutView.prototype.handleLayoutUpdate = function(layout) {
	this.updateModel('layout', layout);
};

LayoutView.prototype._update = function() {
	const model = this.readModel();

	this.updateModel();
};

LayoutView.prototype.onUpdate = function(input) {
	this._update();
	return View.prototype.onUpdate.call(this);
};

LayoutView.prototype.onExecute = function(input) {
	this._update();
	View.prototype.onExecute.call(this, input);
};

/**
 * @override
 * @returns {undefined}
 */
LayoutView.prototype.onInit = function() {
	this.setParameters({
		'layout': undefined,
		'actions': undefined,
		'options': undefined
	});

	this.setModelValidation({
		layout: ['isObject', {default: undefined}],
		actions: ['isArray', {default: undefined}],
		options: ['isObject', {default: undefined}]
	});

	// Set update priorities
	this.setUpdatePriorities({
		'layout': -1
	});

	this.setModelUpdateHandler('layout', this.handleLayoutUpdate);
};

module.exports = LayoutView;