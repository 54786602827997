const Function = require('core/src/function');
const IGraphileon = require('core/src/i-graphileon').default;
const IAPI = require('core/src/api-client-abstract').default;
const _ = require('core/src/utils/legacy');

const DeleteNode = Function.extend(Function, "DeleteNode");

/**
 * @override
 * @returns {undefined}
 */
DeleteNode.prototype.onInit = function(dependencies) {
	this.api = dependencies.get(IAPI);
	this.graphileon = dependencies.get(IGraphileon);

	this.setParameters({
		'$id': undefined,
		'$store': undefined,
		'$container': undefined,
		'$container.height': 200,
		'$area': undefined,
		'$confirm': true,
		'$stayAlive': 'dashboard' //This should stay alive until user confirms delete or cancels
	});

	var notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		id			: 'isStringOrNumber',
		store		: 'isString',
		confirm		: ['isBooleanParsable', notRequired],
		area		: ['isString', notRequired],
		container	: [function(val) { return _.isString(val) || _.isObject(val); }, this.graphileon.translate('Must be string or object.'), notRequired]
	});
};

/**
 * Delete the specified node from the specified store
 *
 * @param  {number|string}	nodeID  the node ID
 * @param  {string}	 store   the name of the store
 *
 * @return {boolean}			it always returns true
 */
DeleteNode.prototype.executeDeleteNode = async function(nodeID, store) {
	try {
		let node = await this.api.loadNode(nodeID, store);
		let success = await this.api.delete(node);
		this.executeTriggers({
			type: 'deleted',
			node: node,
			deleted: success
		});
	} catch(e) {
		this.graphileon.viewmanager.alert(
			this.language.translate(
				'function-error', 
				{description: this.toString()}
			), 
			{
				alertType: 'danger', 
				headerText: this.graphileon.translate('Delete Node')
			}
		);
		this.error(e, true);
	}

	return true;
};

DeleteNode.prototype.onExecute = async function(input) {
	var check = _.validate({
		nodeID  : [input.id, _.isStringOrNumber(input.id)],
		store   : [input.store, 'isString']
	}, this.graphileon.translate('Could not delete node.'));

	if(! check.isValid()) {
		return check.createError();
	}

	const valid = check.getValue();

	// If we need a confirmation before deleting the node (defaults to yes).
	if (_.hasBooleanValue(input.confirm, true)) {
		let deleteConfirmed = (! this.graphileon.viewmanager) ? true : await this.graphileon.viewmanager.confirm(
			this.graphileon.translate(`confirm-delete-node`, {nodeId: valid.nodeID, store: valid.store}),
			{
				headerText: 'Delete Node',
				okButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				alertType: 'danger',
				okButtonClass: 'btn-danger'
			}
		);

		if (! deleteConfirmed) {
			this.closeFunctionInstance();
			return false;
		}
	}

	await this.executeDeleteNode(valid.nodeID, valid.store);
	this.closeFunctionInstance();

	return true;
};

module.exports = DeleteNode;
