const _ = require('lodash');

/**
 * Tests whether the variable has a value that can be considered equal to the given boolean value.
 * @param variable			  The variable to test.
 * @param {boolean} value	   The boolean value to test with.
 */
function hasBooleanValue(variable, value) {
	return value
		? variable === true || variable === 'true' || variable === 1 || variable === "1"
		: variable === false || variable === 'false' || variable === 0 || variable === "0";
}

function isTrue(value) {
	return value === true || value === 1 || value === "true" || value === '1';
}

function isFalse(value) {
	return value === false || value === 0 || value === "false" || value === '0';
}

/**
 * Checks if both given arrays have the same contents
 * @param array1
 * @param array2
 * @returns {boolean}
 */
function arraysHaveSameContents(array1, array2) {
	if(!_.isArray(array1) || !_.isArray(array2)) {
		Log.warn('array1,array2', [array1,array2], "Must be arrays");
		return false;
	}
	if(array1.length !== array2.length) {
		return false;
	}

	for(let i in array1) {
		if(array2.indexOf(array1[i]) < 0) {
			return false;
		}
	}
	return true;
}

module.exports = {
	hasBooleanValue,
	isTrue,
	isFalse,
	arraysHaveSameContents,
	def: _.negate(_.isNil)
};
