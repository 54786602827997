const jQueryComponent = require('../component.jquery');

const Editor = require('client/src/editor');

const RichTextEditorComponent = function(dependencies) {
	jQueryComponent.call(this, dependencies, 'text-editor');
};
RichTextEditorComponent.prototype = Object.create(jQueryComponent.prototype);

RichTextEditorComponent.prototype._element = undefined;

RichTextEditorComponent.prototype.onRender = function(properties, innerHtml) {
	const $container = $('<div>').addClass('text-editor');
	const $textarea = $('<textarea>').attr('name', properties.name);
	$container.append($textarea);

	let editor = new Editor(this.dependencies, $textarea, ['rich-text']);
	editor.setMode('rich-text');

	// Decode inner html special characters and set value of editor
	innerHtml = $('<div>').html(innerHtml).text();
	editor.setValue(innerHtml);

	return $container;
};

module.exports = RichTextEditorComponent;
