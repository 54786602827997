const Function = require('core/src/function');
const IFactory = require('core/src/i-factory').default;
const Language = require('core/src/language').default;
const Trigger = require('core/src/trigger');
const APIClientAbstract = require('core/src/api-client-abstract').default;

const _ = require('core/src/utils/legacy');

const PreloadFunctions = Function.extend(Function, "PreloadFunctions");

/**
 * @override
 * @returns {undefined}
 */
PreloadFunctions.prototype.onInit = function(dependencies) {
	this.factory = dependencies.get(IFactory);
	this.language = dependencies.get(Language);
	this.APIClient = dependencies.get(APIClientAbstract);
	this.dependencies = dependencies;

	this.setParameters({
		'#ids': [],
		'#uuids': [],
		'#iaNames': [],
	});

	const notRequired = {default: [], warn: _.def};
	this.setModelValidation({
		ids		: ['isArray', notRequired],
		uuids		: ['isArray', notRequired],
		iaNames		: ['isArray', notRequired],
	});
};

/**
 * @override
 * @param {object} data The data passed with the event.
 */
PreloadFunctions.prototype.onExecute = async function(input) {
	await this.APIClient.loadFunctionsByIds(input.ids);
	await this.APIClient.loadFunctionsByUuids(input.uuids);
	await this.APIClient.loadFunctionsByIaNames(input.iaNames);
	this.executeTriggers({type: 'success'});
};

module.exports = PreloadFunctions;