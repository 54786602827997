const _ = require('core/src/utils/legacy');
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const IAPI = require('core/src/api-client-abstract').default;
const Language = require('core/src/language').default;
const log = require('core/src/log').instance("function/networkview");
const {writable} = require('core/src/utils/read-only');
const {findChangeFromRoot} = require('core/src/utils/changes');
const {validateGraphFilters, GraphFilterStatus} = require('core/src/graph/graph-filters');

const isNodeInStore = _.curry(
	(store, node) => {
		return _.get(node, 'meta.store') === store;
	}
);

const NetworkView = Function.extend(View, "NetworkView");

NetworkView.Event = {
	In: {
		EXPLORE: 'Explore',
		UPDATE: 'networkUpdated'
	}
};

NetworkView.Defaults = {
	filters: {
		node: {filter: 'node', status: GraphFilterStatus.INACTIVE},
		rel: {filter: 'rel', status: GraphFilterStatus.INACTIVE}
	},
	forceParameters: {
		linkDistance: 250,
		linkStrength: 0.7,
		friction: 0.9,
		charge: -1000,
		chargeDistance: 1000,
		theta: 0.8,
		gravity: 0.05
	}
};

NetworkView.prototype._batchTriggers = undefined;
/**
 * @override
 * @returns {undefined}
 */
NetworkView.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);
	this.setParameters({
		'$store': undefined,
		'#nodes': [],
		'#relations': [],
		'#filters': undefined,
		'$explorable': 1,
		'$viewFilter': 1,
		'$canSave': 1,
		'$canSetForceParameters': 1,
		'$forceParameters': undefined,
		'$stylesID': undefined,
		'$state': this.createEmptyState(),
		'$container.height': 500,

		'$canDownloadSVG': 1,
		'$canSwitchZoomToFitStatus': 1,
		'$canSwitchAutoLayoutStatus': 1,
		'$canSwitchAutoCompleteStatus': 1,
		'$autoLayoutStatus': 1,
		'$zoomToFitStatus': 1,
		'$autoCompleteStatus': 1,
		'$canSetStyles': 1,
		'$linkDiagramToUser': false,
		'$diagramName': undefined,
		'$diagramStore': undefined, // get from NetworkView store
		'$viewZoomButtons': false
	});

	var notRequired = {default: undefined, warn: _.def};
	var booleanCheck = ['isBooleanParsable', notRequired];
	let self = this;
	var optionalArrayOf = function (itemValidation, itemType) {
		return [function (arr) {
			// Array can be undefined, or be valid. Otherwise it's an error, not a warning.
			return !_.def(arr) || _.validateArray(_.capitaliseFirst(itemType), arr, itemValidation, self.language.translate('Invalid {{itemType}} structure.', {itemType}), {itemType: itemType}, false);
		}];
	};
	this.setModelValidation({
		store: ['isString', {default: 'application', warn: _.def}],
		nodes: optionalArrayOf(_.isValidNode, 'node'),
		relations: optionalArrayOf(_.isValidRelation, 'relation'),
		filters: [validateGraphFilters, this.language.translate('Invalid filters list.')],
		explorable: booleanCheck,
		viewFilter: booleanCheck,
		canSave: booleanCheck,
		canSetForceParameters: booleanCheck,
		forceParameters: ['isObject', {default: NetworkView.Defaults.forceParameters, warn: _.def}],
		styles: [_.isStringOrNumber, this.language.translate('Invalid UserStyles ID.'), notRequired],
		state: [{
			selected: [{
				nodes: optionalArrayOf(_.isValidNode, 'node'),
				relations: optionalArrayOf(_.isValidRelation, 'relation')
			}],
			visible: [{
				nodes: optionalArrayOf(_.isValidNode, 'node'),
				relations: optionalArrayOf(_.isValidRelation, 'relation')
			}]
		}, this.language.translate('Invalid state structure')],
		canDownloadSVG: booleanCheck,
		canSwitchZoomToFitStatus: booleanCheck,
		canSwitchAutoLayoutStatus: booleanCheck,
		canSwitchAutoCompleteStatus: booleanCheck,
		canSetStyles: booleanCheck,
		autoLayoutStatus: booleanCheck,
		zoomToFitStatus: booleanCheck,
		autoCompleteStatus: booleanCheck,
		linkDiagramToUser: booleanCheck,
		diagramName: ['isString', {default: '', warn: _.def}],
		viewZoomButtons: booleanCheck
	});

	// Use uniqueness when adding nodes and relations

	this._updateCollection.add.state = {selected:{}, visible:{}};

	this._updateCollection.add.nodes =
		this._updateCollection.add.state.selected.nodes =
			this._updateCollection.add.state.visible.nodes =
				this._updateCollection.set.default;

	this._updateCollection.add.relations =
		this._updateCollection.add.state.selected.relations =
			this._updateCollection.add.state.visible.relations =
				this._updateCollection.set.default;

	this.setModelUpdateHandler('nodes', handleNodesUpdate);
	this.setModelUpdateHandler('relations', handleRelationsUpdate);
	this.setDeepModelUpdateHandler('state', handleStateUpdate);

	// Set update priorities
	this.setUpdatePriorities({
		'state': -2,
		'nodes': -1
	});

	this.setHashCollectionItemFunction('nodes', this.hashEntity.bind(this));
	this.setHashCollectionItemFunction('relations', this.hashEntity.bind(this));
	this.setHashCollectionItemFunction('state.selected.nodes', this.hashEntity.bind(this));
	this.setHashCollectionItemFunction('state.selected.relations', this.hashEntity.bind(this));
	this.setHashCollectionItemFunction('state.visible.nodes', this.hashEntity.bind(this));
	this.setHashCollectionItemFunction('state.visible.relations', this.hashEntity.bind(this));
};

/* EVENT HANDLERS */

/**
 * Handles exploration requests from the renderer.
 * @param event
 * @returns {boolean}
 */
NetworkView.prototype._eventHandlers[NetworkView.Event.In.EXPLORE] = function (event) {
	const nodes = event;
	const stores = _.uniq(_.map(nodes, 'meta.store'));

	Promise.all(
		_.map(
			stores,
			(store) => {
				return this.api.getNeighbours({
					store: store,
					ids: _.map(_.filter(nodes, isNodeInStore(store)), 'id')
				});
			}
		)
	)
		.then(
			(results) => {
				this.update({
					"_update.add": {
						nodes: _.union(..._.map(results, 'processed.nodes')),
						relations: _.union(..._.map(results, 'processed.relations'))
					}
				});
			},
			(response) => {
				this.error(new _.Error({
					message: this.language.translate("Could not retrieve neighbours."),
					originalError: response.message
				}));
			}
		);
};

let handleNodesUpdate = function (newValue, oldValue) {
	var equals = this.getEqualsInCollectionFunction('nodes');
	var diff = _.collectionDiff(oldValue, newValue, equals);

	if (diff.changed) {
		// Find relations connected to removed nodes
		let relsToRemove = this.getOrphanRelations(newValue, this.readModel('relations'));

		if (_.size(relsToRemove))
			this.updateCollection('remove', 'relations', relsToRemove);
	}
	
	// diff has only added/removed nodes, we want to update even if nodes (properties) were changed
	this.updateStateForNodes();
};

let handleRelationsUpdate = function (newValue, oldValue) {
	var equals = this.getEqualsInCollectionFunction('relations');
	var diff = _.collectionDiff(oldValue, newValue, equals);

	if (diff.changed) {
		let relsToRemove = this.getOrphanRelations(this.readModel('nodes'), newValue);

		// return because we changed relations which will call this function again and then state will be updated
		if (_.size(relsToRemove))
			return this.updateCollection('remove', 'relations', relsToRemove);
	}

	// diff has only added/removed rels, we want to update even if rels (properties) were changed	
	this.updateStateForRelations();
};

let handleStateUpdate = function (changes) {
	if (findChangeFromRoot(changes, 'state.selected.nodes')) {
		this.setItemsStateProperty('nodes', 'selected', 'state.selected.nodes');
	}
	if (findChangeFromRoot(changes, 'state.selected.relations')) {
		this.setItemsStateProperty('relations', 'selected', 'state.selected.relations');
	}
	if (findChangeFromRoot(changes, 'state.visible.nodes')) {
		this.setItemsStateProperty('nodes', 'visible', 'state.visible.nodes');
	}
	if (findChangeFromRoot(changes, 'state.visible.relations')) {
		this.setItemsStateProperty('relations', 'visible', 'state.visible.relations');
	}
};

/* PUBLIC METHODS */

NetworkView.prototype.getOrphanRelations = function (nodes, relations) {
	let indexedNodes = _.keyBy(nodes, (node) => this.hashEntity(node));

	return _.filter(
		relations,
		(relation) => (
			!indexedNodes[this.hashEntity({id: relation.source, meta: relation.meta})]
			|| !indexedNodes[this.hashEntity({id: relation.target, meta: relation.meta})]
		)
	);
}

NetworkView.prototype.hashEntity = function (entity) {
	const store = this.readModel('store') || 'application';
	return _.get(entity, 'meta.store', store) + "_" + _.get(entity, 'id');
}

NetworkView.prototype.createEmptyState = function () {
	return {
		selected: {
			nodes: [],
			relations: []
		},
		visible: {
			nodes: [],
			relations: []
		}
	};
};

NetworkView.prototype.createEmptyModel = function () {
	var model = View.prototype.createEmptyModel();
	model.state = this.createEmptyState();
	model.nodes = [];
	model.relations = [];

	return model;
};

/**
 * @override
 */
NetworkView.prototype.alterTriggerData = function (data) {
	Function.prototype.alterTriggerData.call(this, data);

	/*
		 This data comes from the NetworkViewRenderer at the moment of the trigger event. It is non-centralized data
		 (such as x,y coordinates of nodes) that should nevertheless be accessible from an outgoing trigger.
		 */
	if ('_localData' in data) {
		const localData = _.cloneDeep(data._localData);

		// Merge _localData from NetworkViewRenderer with state
		var lookupNodes = {};
		_.forEach(localData.nodes, function (node) {
			if (!_.has(node, 'id')) return;
			lookupNodes[node.id] = writable(node);
		});
		var lookupRels = {};
		_.forEach(localData.relations, function (rel) {
			if (!_.has(rel, 'id')) return;
			lookupRels[rel.id] = rel;
		});
		var __merge = function (items, extras) {
			_.forEach(items, function (item, i) {
				if (item.id in extras) {
					_.merge(item, _.pick(extras[item.id], ['x', 'y', 'px', 'py', 'style', 'linkCount']));
				}
			});
		};

		// _function is as far as we are allowed to overwrite, so we clone that to be able to modify it
		const functionData = _.cloneDeep(data._function);

		__merge(functionData.nodes, lookupNodes);
		__merge(functionData.relations, lookupRels);
		__merge(functionData.state.visible.nodes, lookupNodes);
		__merge(functionData.state.visible.relations, lookupRels);
		__merge(functionData.state.selected.nodes, lookupNodes);
		__merge(functionData.state.selected.relations, lookupRels);

		delete data._localData;
		data._function = functionData;
	}

	return data;
};

/**
 * Finds all relations between nodes in the model and adds these relations to the model.
 * @param {array} [newNodes]	An optional set of nodes of which at least one side of the relation must belong to.
 */
NetworkView.prototype.autoComplete = function (newNodes) {
	// No nodes, nothing to find
	let nodes = this.readModel('nodes');
	if (!_.isArray(nodes) || nodes.length === 0) {
		return;
	}

	log.log("Finding autoComplete in " + this);

	let stores = _.uniq(_.union(
		_.map(nodes, 'meta.store'),
		_.map(newNodes, 'meta.store')
	));

	Promise.all(_.map(stores, (store) => {
		return this.api.autoComplete(
			_.map(_.filter(nodes, isNodeInStore(store)), 'id'),
			_.map(_.filter(newNodes, isNodeInStore(store)), 'id'),
			store
		);
	})).then((results) => {
		let allRelations = _.union(..._.map(results, 'relations'));

		_.forEach(allRelations, function (relation) {
			relation.autoCompleted = true;
		});

		let collectionMutations = {"_update.complete": {relations: allRelations}};
		this.update(collectionMutations, false);
	}, (response) => {
		this.error(new _.Error({
			message: this.language.translate("Could not retrieve relations."),
			originalError: response.message
		}));
	});
};

NetworkView.prototype.removeAutoRelations = function () {
	let relations = this.readModel('relations');
	let remove = [];
	// Remove all relations that were loaded by autocomplete
	_.forEach(relations, function (relation) {
		if (relation.autoCompleted) {
			remove.push(relation);
		}
	});
	this.update({"_update.remove": {relations: remove}}, false);
};

NetworkView.prototype.onExecute = function (input) {
	if (_.hasBooleanValue(input.autoCompleteStatus, true)) {
		this.autoComplete();
	}

	// Add default force parameters
	let forceParameters = _.extend({}, NetworkView.Defaults.forceParameters, input.forceParameters);

	// Add default filters
	let filters = _.cloneDeep(NetworkView.Defaults.filters);

	// Delete default filters that are already in input.filters
	_.forEach(input.filters, function (filter) {
		let filterIndex = _.findKey(filters, {filter: filter['filter']});
		delete filters[filterIndex];
	});

	// Add input.filters to filters so that default filters are first in the list
	if (input.filters) {
		_.mergeObjectInto(input.filters, filters);
	}

	this.updateModel('forceParameters', forceParameters);
	this.updateModel('filters', filters);

	View.prototype.onExecute.call(this, input);
};

NetworkView.prototype.onUpdate = function (changes) {
	let autoCompleteStatus = _.hasBooleanValue(this.readModel('autoCompleteStatus'), true);
	let autoCompleteChanged = 'autoCompleteStatus' in changes;

	// Autocomplete if 1) just switched on or 2) new nodes were added
	if (autoCompleteStatus) {
		if (autoCompleteChanged) {
			this.autoComplete();
		} else if ('nodes' in changes) {
			let nodesDiff = _.collectionDiff(changes.nodes.old, changes.nodes.new, this.getEqualsInCollectionFunction('nodes'));
			if (!_.isEmpty(nodesDiff.add)) {
				this.autoComplete(nodesDiff.add);
			}
		}
		// Remove auto-relations if autoComplete was just switched off
	} else if (autoCompleteChanged) {
		this.removeAutoRelations();
	}
};

NetworkView.prototype.updateStateForNodes = function () {
	var model = {
		nodes: this.readModel('nodes'),
	};

	let newState = {
		selected: {
			nodes: _.filter(model.nodes, (node) => _.get(node, 'selected', false)),
		},
		visible: {
			nodes: _.filter(model.nodes, (node) => _.get(node, 'visible', true)),
		}
	}

	this.updateModel('state.visible.nodes', newState.visible.nodes);
	this.updateModel('state.selected.nodes', newState.selected.nodes);
};

NetworkView.prototype.updateStateForRelations = function () {
	var model = {
		relations: this.readModel('relations')
	};

	let newState = {
		selected: {
			relations: _.filter(model.relations, (rel) => _.get(rel, 'selected', false)),
		},
		visible: {
			relations: _.filter(model.relations, (rel) => _.get(rel, 'visible', true)),
		}
	}

	this.updateModel('state.visible.relations', newState.visible.relations);
	this.updateModel('state.selected.relations', newState.selected.relations);
};


module.exports = NetworkView;
