const Function = require('core/src/function');
const IAPI = require('core/src/api-client-abstract').default;
const _ = require('core/src/utils/legacy');
const Validation = require('core/src/utils/validation');
const Language = require('core/src/language').default;

const Query = Function.extend(Function, 'Query');

Query.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);

	let params = {
		'$store': undefined,
		'$ids': undefined,
		'$params': undefined,
		'$cypher': undefined,
		'$sparql': undefined,
		'$gremlin': undefined,
		'$templateParams': undefined,
	};
	this.setParameters(params);

	var notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		store			: ['isString', notRequired],
		ids				: ['isArray', {default: val => _.isString(val) ? [val] : [], warn: val => _.def(val) && !_.isString(val)}],
		params			: ['isObject', notRequired],
		process			: ['isBooleanParsable', {default: false, warn: _.def}],
		cypher			: [val => ( _.isString(val) || _.isObject(val)), this.language.translate('Must be string or object.'), notRequired],
		sparql			: [val => ( _.isString(val) || _.isObject(val)), this.language.translate('Must be string or object.'), notRequired],
		gremlin			: [val => ( _.isString(val) || _.isObject(val)), this.language.translate('Must be string or object.'), notRequired],
		templateParams : ['isObject', notRequired]
	});
};

Query.prototype.onExecute = function(){
	const promise = new Promise(async (resolve, reject) => {
		const parameters = {
			id: this.getId(),
			store: this.readModel('store'),
			params: this.readModel('params'),
			process: this.readModel('process'),
			options: this.readModel('options'),
			templateParams: this.readModel('templateParams')
		};
		if(!_.has(parameters.options, 'ids')) {
			const ids =  this.readModel('ids'); // backward compatibility
			if(ids) {
				_.set(parameters, 'options.ids', ids);
			}
		}

		['cypher', 'gremlin', 'sparql'].map((language) => {
			const modelQuery = this.readModel(language);
			if(!_.isEmpty(modelQuery)) {
				parameters[language] = modelQuery;
			}
		});

		try {
			const response = await this.api.executeQuery(parameters);
			const event = {
				type: 'success',
				data: _.get(response, 'result.table'),
				processed: _.get(response, 'result.processed'),
				meta: {
					columns		: _.get(response, 'resultInfo.columns'),
					nodecount	: _.get(response, 'resultInfo.nodecount'),
					relcount	: _.get(response, 'resultInfo.relcount'),
					memory		: _.get(response, 'performance.memory'),
					timing		: _.get(response, 'performance.timing'),
					status		: _.get(response, 'status'),
					stats		: _.get(response, 'meta.stats'),
					params		: _.get(response, 'request.params'),
					store		: _.get(response, 'request.store'),
					processData	: _.get(response, 'request.process')
				}
			};

			if ( Validation.isTrue(_.get(this.session.getUser(),'properties.devMode')) ) {
				event.meta.cypher = _.get(response, 'request.cypher');
				event.meta.sparql =  _.get(response, 'request.sparql');
				event.meta.gremlin =  _.get(response, 'request.gremlin');
			}

			this.executeTriggers(event);
		    resolve(true);

		} catch (error) {
			reject(error);
		}

	});

	return promise;
};

module.exports = Query;
