
/*
HtmlInject renders a Mustash template with given data and injects the resulted HTML into container elements

Targeted (container) elements will lose their initial content.

Input:
	template -> Mustache template to be rendered
	data -> data for the template
	container -> CSS selector for the targeted element(s)
*/

const Function = require('core/src/function');
const Mustache = require('mustache');

const HtmlInject = Function.extend(Function, "HtmlInject");

HtmlInject.prototype.onExecute = function(input) {
	var $container = $(input.container);

	if (! $container.length) {
		return;
	}

	var html = Mustache.render(input.template, input);
	$container.html(html);
};

/**
 * @override
 * @returns {undefined}
 */
HtmlInject.prototype.onInit = function() {
	this.setParameters({
		'$data': undefined,
		'$template': undefined,
		'$container': undefined,
	});

	this.setModelValidation({
		template: ['isString'],
		container: ['isString']
	});

	// Turn off built-in templating
	this.setTemplating('template', false);
	this.setTemplating('data', false);
	this.setTemplating('container', false);
};

module.exports = HtmlInject;