const Function = require('core/src/function');
const View = require('core/src/functions/view');
const IGraphileon = require('core/src/i-graphileon').default;
const IAPI = require('core/src/api-client-abstract').default;
const log = require('core/src/log').instance("core/src/functions/save-diagram");

const _ = require('lodash');
const { def, validateArray } = require('utils/src/validation');

const {isNonEmptyString, isValidId} = require('core/src/utils');

const SaveDiagram = Function.extend(Function, "SaveDiagram");

// store all prompts for eventout

SaveDiagram.prototype.onInit = function(dependencies) {
	this.api = dependencies.get(IAPI);
	this.graphileon = dependencies.get(IGraphileon);
	this.setParameters({
		'$diagram': {
			'id'		: undefined,
			'uuid'		: undefined,
			'name'		: undefined,
			'nodes' 	: undefined,
			'relations'	: undefined,
			'filters'	: undefined,
			'userId'	: undefined,
		},
		'$overwrite': false,
		'$container.height': 200,
		'$container.width': 300,
		'$container.title': this.graphileon.translate('Confirm Save Diagram')
	});

	const notRequired = {default: undefined, warn: def};
	let self = this;
	this.setModelValidation({
		'diagram' : [{
			'id'		: ['isNumber', notRequired],
			'uuid'		: ['isString', notRequired],
			'name'		: ['isString', notRequired],
			'nodes' 	: this.isOptionalArrayOf(isDiagramNode, 'nodes'),
			'relations'	: this.isOptionalArrayOf(isDiagramRelation, 'relations'),
			'filters'	: 'isArray',
			'userId'	: ['isNumber', notRequired],
		}, this.graphileon.translate('Invalid diagram structure')],
		'overwrite'	: ['isBoolean'],
		'container'	: [function(val) { return _.isString(val) || _.isObject(val); }, self.graphileon.translate('Must be string or object.'), notRequired]
	});
};


SaveDiagram.prototype.overWritePrompt = async function(input) {
	let confirmOptions = {
		headerText: this.graphileon.translate('Overwrite Diagram'),
		activeConfirmButton: 'no'
	}
	let confirm = this.graphileon.viewmanager.confirm(this.graphileon.translate(`Diagram '{{name}}' already exists.<br/>Do you want to replace it?`, {name: input.diagram.name}), confirmOptions);

	confirm.done(choice => {
		if (choice === true) {
			input.overwrite = true;
			this.doSave(input);	
		} else if (choice === false){
			this.savePrompt(input);
		}
	})
};


SaveDiagram.prototype.doSave = async function(input) {
	const self = this;
	let params = _.extend({}, input.diagram, {overwrite: input.overwrite});
	try {
		let response = await this.api.saveDiagram(params);
		this.executeTriggers(_.extend({}, response, {type: 'success'}));
	} catch( e ){
		// 409 is Conflict code  'DiagramExists' code is seems Internal Server Error 
		if ( e.code === 409 && ! input.overwrite && this.graphileon.viewmanager ) {
			self.overWritePrompt(input);
		} else {
			this.error(e);
			// Call to `error` will not result in visual error, so we use viewmanager to show an alert
			this.graphileon.viewmanager.alert(e.message, {
				alertType: 'danger',
			});
		}
	}
};

SaveDiagram.prototype.onExecute = async function(input) {
	if ( this.graphileon.viewmanager ) {
		await this.savePrompt(input);
		return;
	}
	
	if ( input.diagram.name ) {
		await self.doSave(input);
		return;
	}

	throw new Error(this.graphileon.translate('Please provide a diagram name'));
};

SaveDiagram.prototype.savePrompt = async function(input) {
	let self = this;

	try {
		let diagramName = await this.graphileon.viewmanager.prompt(this.graphileon.translate('Please enter Diagram name'), input.diagram.name, {headerText: this.graphileon.translate('Save Diagram')});

		if (! _.isString(diagramName)) {
			return false;
		}
		if (diagramName.trim() === '') {
			let emptyDiagramNameAlert = self.graphileon.viewmanager.alert(this.graphileon.translate('Please provide a diagram name'), {alertType: 'danger', headerText: this.graphileon.translate('Save Diagram')});
			emptyDiagramNameAlert.done(() => {
				self.savePrompt(input);
			});
			return false;
		}
		input.diagram.name = diagramName;

		if (input.diagram.name !== null) {
			self.doSave(input);
		}
	} catch(e) {
		log.info('Diagram save canceled');
	}
}

let isDiagramNode = _.conforms({
	id: isValidId,
	x: _.isFinite,
	y: _.isFinite,
	store: isNonEmptyString
});

let isDiagramRelation = _.conforms({
	id: isValidId,
	store: isNonEmptyString
});

SaveDiagram.prototype.isOptionalArrayOf = function(itemValidation, itemName) {
	let self = this;
	return [function(arr) {
		return ! arr || _.isEmpty(arr) || validateArray(itemName, arr, itemValidation, self.graphileon.translate('Invalid {{itemName}} structure.', {itemName}), {itemName}, false);
	}];
};

module.exports = SaveDiagram;
