'use strict';

const MarkdownView = require('core/src/functions/views/markdown-view');
const Vue = require('vue');
const VueRenderer = require('../vue-renderer');
const Markdown = require('./markdown.vue').default;
const _ = require('core/src/utils/legacy');

const MarkdownViewRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
};
MarkdownViewRenderer.viewType = 'MarkdownView';
MarkdownViewRenderer.prototype = Object.create(VueRenderer.prototype);

MarkdownViewRenderer.prototype.getVueComponent = function() {
	return Markdown;
};
MarkdownViewRenderer.prototype.initVueComponent = async function(component) {
	
};

module.exports = MarkdownViewRenderer;
