const View = require('core/src/functions/view');
const Log = require('core/src/log');
const Function = require('core/src/function');
const _ = require('core/src/utils/legacy');
const Language = require('core/src/language').default;

const TableView = Function.extend(View, "TableView");

TableView.prototype._batchTriggers = undefined;

/**
 * @override
 * @returns {undefined}
 */
TableView.prototype.onInit = function(dependencies) {
	this.language = dependencies.get(Language);
	this.setModelUpdateHandler('data', handleDataUpdate);

	this.setParameters({
		'#data': undefined,
		'$column' : undefined, // backward compatibility
		'$columns': undefined,
		'$row': undefined,
		'$options' : undefined,
		'$state': undefined,
		'$autoColumns': false,
		'$container.height': '200'
	});

	this.setModelValidation({
		'data': [this._validateData.bind(this), {default: [], warn: _.def}],
		'column': ['isObject', {default: undefined, warn: _.def}],
		'columns': ['isObject', {default: undefined, warn: _.def}],
		'autoColumns': ['isBoolean', {default: true, warn: _.def}],
		'row': ['isObject', {default: undefined, warn: _.def}],
		'options': ['isObject', {default: undefined, warn: _.def}],
		'state': [{
			selected: [['isObject'], {array: {itemType: 'row'}}],
			visible: [['isObject'], {array: {itemType: 'row'}}]
		}, this.language.translate("Invalid state."), {default: undefined, warn: _.def}]
	});

	this.setTemplating('column', false);
	this.setTemplating('columns', false);
};

TableView.prototype.createEmptyModel = function() {
	var model = View.prototype.createEmptyModel.call(this);
	model.state = {
		selected: [],
		visible: []
	};

	return model;
};

/**
 *
 * @param input
 */
TableView.prototype.setModelFromInput = function(input) {
	// Column settings
	var legacyColumns = _.get(input, 'column');
	if(_.def(legacyColumns)) {
		Log.warn("TableView parameter 'column' is deprecated. Please use 'columns' instead.");
		if(!_.def(_.get(input, 'columns'))) {
			input.columns = legacyColumns;
		}
	}

	// If data consists of columns, transpose data
	var data = _.get(input, 'data');
	if(_.isPlainObject(data)) {
		var newData = [];
		for(var col in data) {
			for(var row in data[col]) {
				if(!(row in newData)) {
					newData[row] = {};
				}
				newData[row][col] = data[col][row];
			}
		}
		input.data = newData;
	}
	// Create default state if not defined
	if(!_.def(input.state)) {
		input.state = {
			selected: [],
			visible: _.clone(input.data)
		};
	}

	return View.prototype.setModelFromInput.call(this, input);
};

let handleDataUpdate = function(newValue, oldValue) {
	var equals = this.getEqualsInCollectionFunction('data');
	var diff = _.collectionDiff(oldValue, newValue, equals);
	this.updateCollections({
		set: {
			state: {
				visible: diff.add
			}
		},
		remove: {
			state: {
				visible: diff.remove,
				selected: diff.remove
			}
		}
	});
};

/**
 * @override
 * @param {object} input
 * @returns {object}
 */
TableView.prototype.alterRenderData = function(input) {
	input.batchTriggers = this.findBatchTriggers();
	return input;
};

TableView.prototype._validateData = function(data) {
	var validity = new _.Validity("data", data, true, this.language.translate('Table data must be either an array or an object of arrays.'));
	if(!_.isObject(data)) {
		validity.setValid(false);
		return validity;
	}

	if(_.isPlainObject(data)) {
		for(var key in data) {
			if(!_.isArray(data[key])) {
				validity.setValid(false);
				return validity;
			}
		}
		return validity;
	} else {
		return validity;
	}
};

module.exports = TableView;

