import _ from 'lodash';
import {checkType} from "utils/src/validation";
import Err from "utils/src/error";

class CachedValue {
	constructor(value, timeout, onTimeout) {
		timeout = checkType(timeout, 'number', 'timeout', Infinity);

		// defaultValue takes function, so to get a function as default value, we need a function that returns a function
		onTimeout = checkType(onTimeout, 'function', 'onTimeout', ()=>()=>{});

		if(_.isNil(value)) {
			throw new Err("No cache value provided.");
		}

		this.value = value;
		this.timeout = timeout;
		this.onTimeout = onTimeout;

		this.refresh();
	}

	isExpired() {
		return _.now() > this.expires;
	}

	refresh() {
		this.expires = _.now() + this.timeout;
	}
}

export default class Cache {
	constructor(config) {
		const defaultConfig = {
			timeout: Infinity, // in ms, cached values do not expire
		}
		this.config = _.extend({}, defaultConfig, config);

		this.values = {};

		if(this.config.cleanUpInterval > 0 && this.config.cleanUpInterval < Infinity) {
			this.cleanInterval = setInterval(() => this.clean(), this.config.cleanUpInterval);
		}
	}

	has(key) {
		return key in this.values;
	}

	set(key, value, timeout, onTimeout) {
		checkType(key, 'string', 'key');
		timeout = checkType(timeout, 'number', 'timeout', this.config.timeout);

		this.values[key] = new CachedValue(value, timeout, onTimeout);
		return value;
	}

	get(key, init, timeout, onTimeout) {
		checkType(key, 'string', 'key');
		init = checkType(init, 'function', 'init', null);

		let cachedValue = this.values[key];
		if(cachedValue && cachedValue.isExpired()) {
			this.delete(key);
			cachedValue = undefined;
		}

		let value;
		if(cachedValue) {
			value = cachedValue.value;
			cachedValue.refresh();
		} else if (_.isFunction(init)) {
			value = init();
			this.set(key, value, timeout, onTimeout);
		}

		return value;
	}

	getAll() {
		this.clean();
		return this.values;
	}

	delete(key) {
		const cachedValue = this.values[key];
		if(cachedValue) {
			cachedValue.onTimeout(cachedValue.value);
		}
		delete this.values[key];
	}

	clean() {
		_.forEach(this.values, (value, key) => {
			if(value.isExpired()) {
				this.delete(key);
			}
		});
	}

	clearAll() {
		_.forEach(this.values, (value, key) => {
			this.delete(key);
		})
	}

	stop() {
		this.clearAll();
		if(this.cleanInterval) {
			clearInterval(this.cleanInterval);
		}
	}
};
