import G64Edition from "./g64-edition";
import Edition from "./edition";

export default class G128Edition extends G64Edition {
	static get code() {
		return 'g128';
	}
	static get name() {
		return Edition.name + ' 128 Edition';
	}
	defineFeatures() {
		return {
			...super.defineFeatures(),
			[Edition.Feature.GRAPHILEONS_COUNT]: 128
		};
	}
}
