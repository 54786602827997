(function() {

	const _ = require('./utils/legacy');
	const { t } = require('core/src/language');

	function isValidHookName(hookName) {
		if (!_.isString(hookName)) {
			return false;
		}

		if (!/^[a-z]+[a-z0-9_]*$/i.test(hookName)) {
			return false;
		}

		return true;
	}

	function addHooksTrait(classConstructor, className) {

		if ( ! _.isFunction(classConstructor)) {
			return _.withError([`addHooksTrait(): ${t('classConstructor must be a function')}`, classConstructor]);
		}

		classConstructor.prototype.ensureHasHooks = function() {
			if ( ! _.has(this, 'hooks')) {
				this.hooks = {};
			}
		};

		classConstructor.prototype.addHook = function(hookName, hookFunction) {
			if (!isValidHookName(hookName)) {
				return _.withError([className + `.addHood: ${t('hook name is invalid')}`, hookName]);
			}

			if (!_.isFunction(hookFunction)) {
				return _.withError([className + `.addHood: ${t('hook function is invalid')}`, hookFunction]);
			}

			this.ensureHasHooks();

			if (!_.has(this.hooks, hookName)) {
				this.hooks[hookName] = [];
			}

			this.hooks[hookName].push(hookFunction);
		};

		classConstructor.prototype.removeHook = function(hookName) {
			if (!isValidHookName(hookName)) {
				return _.withError([className + `.removeHook: ${t('hook name is invalid')}`, hookName]);
			}

			delete this.hooks[hookName];

			return true;
		};

		classConstructor.prototype.removeHookCallback = function(hookFunction) {
			var self = this;
			if (!_.isFunction(hookFunction)) {
				return _.withError([className + `.removeHookCallback: ${t('hook function is invalid')}`, hookFunction]);
			}
			_.forEach(this.hooks, function(hooksArray, index) {
				self.hooks[index] = _.without(hooksArray, hookFunction);
			}, this);

			return true;
		};

		classConstructor.prototype.getHooks = function(hookName) {
			if (!isValidHookName(hookName)) {
				return _.withError([className + `.getHooks: ${t('hook name is invalid')}`, hookName]);
			}

			this.ensureHasHooks();

			if (!_.has(this.hooks, hookName)) {
				return [];
			}

			return this.hooks[hookName];
		};

		classConstructor.prototype.executeHook = function(hookName, parameters, thisObject) {
			var hooks = this.getHooks(hookName);
			var self = this;
			var result;
			_.forEach(hooks, function(hookFunction){
				if (hookFunction.apply(_.resolve(thisObject, self), parameters) === false) {
					result = false;
					return false;
				};
			});

			return result;
		};

		classConstructor.prototype.setUpHooks = function(hooks) {
			var self = this;
			if (!_.isPlainObject(hooks)) {
				return _.withError([className + `.setUpHooks: ${t('hooks is not valid')}`, hooks]);
			}

			_.forEach(hooks, function(functions, hookName) {
				functions =_.ensureIsArray(functions);
				_.forEach(functions, function(func) {
					self.addHook(hookName, func);
				});
			});

		};

		return classConstructor;
	}

	module.exports = addHooksTrait;
})();
