'use strict';

const TeamEditView = require('core/src/functions/views/team-edit-view');
const VueRenderer = require('../vue-renderer');
const TeamEdit = require('./team-edit.vue').default;
const _ = require('core/src/utils/legacy');

const TeamEditViewRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
};
TeamEditViewRenderer.viewType = 'TeamEditView';
TeamEditViewRenderer.prototype = Object.create(VueRenderer.prototype);

TeamEditViewRenderer.prototype.getVueComponent = function() {
	return TeamEdit;
};
TeamEditViewRenderer.prototype.initVueComponent = async function(component) {
	component.$on('delete', (team) => {
		this.trigger({type: 'deleted', team});
	});
	component.$on('submit', (team) => {
		this.trigger({type: team.id ? 'updated' : 'created', team});
	});
};

module.exports = TeamEditViewRenderer;
