import Navigo from 'navigo';
import EventInterface from 'core/src/event-interface';
import {def} from 'utils/src/validation';
import {defaults, isString, isPlainObject, reduce, isEmpty} from 'lodash';
const queryString = require('query-string');
import Log from "utils/src/log";

const log = Log.instance("client/router");

const UNRESOLVED = '__unresolved__';

export default class Router {
	constructor(config = {}) {
		this.config = defaults({}, config, {
			basePath: '',
			baseRoute: '',
			hash: '#!',
			rootToDefaultDashboard: false
		});

		this._currentState = {
			event: undefined,
			data: undefined
		};
		this.events = new EventInterface();

		this.started = false;
		this.paused = false;
	}

	_dashboardPage(params, query) {
		if(this.paused) return;

		this.events.fire('loadDashboard', {
			dashboardName: params.dashboardName,
			bookmarkName: params.bookmarkName,
			params: queryString.parse(query)
		});
	}
	_defaultDashboard() {
		if(this.paused) return;

		this.events.fire('defaultDashboard');
	}
	_unresolve() {
		if(this.paused) return;
		this.events.fire('unresolved');
	}
	
	_activationPage(params, query){
		if(this.paused) return;
		const { token } = params;

		this.events.fire('userActivation', {
			token,
			params: queryString.parse(query)
		});
	}

	_resetPasswordPage(params, query){
		if(this.paused) return;
		const { token } = params;

		this.events.fire('resetPassword', {
			token,
			params: queryString.parse(query)
		});
	}

	start() {
		this.started = true;

		this.events.onEvent((data, event) => {
			this._currentState.data = data;
			this._currentState.event = event;
		});

		let root = null;
		if(this.config.basePath) {
			root = this.config.basePath;
		}
		const baseRoute = this.config.baseRoute;

		this.navigo = new Navigo(root, isString(this.config.hash), this.config.hash);
		this.navigo.on({
			[UNRESOLVED]: this._unresolve.bind(this),
			[baseRoute + '/:dashboardName']: this._dashboardPage.bind(this),
			[baseRoute + '/:dashboardName/:bookmarkName']: this._dashboardPage.bind(this),
			['/activation/:token']: this._activationPage.bind(this),
			['/reset-password/:token']: this._resetPasswordPage.bind(this),
		});

		let matchAllSet = false;

		if(this.config.rootToDefaultDashboard) {
			if(baseRoute) {
				this.navigo.on(baseRoute, this._defaultDashboard.bind(this));
				this.navigo.on(baseRoute + '/*', this._defaultDashboard.bind(this));
			} else {
				matchAllSet = true;
				this.navigo.on('*', this._defaultDashboard.bind(this));
			}
		}
		if(!matchAllSet) {
			this.navigo.on('*', ()=> {
				log.log("URL does not match any Graphileon paths.");
				this._unresolve();
				/*
				Navigo will not resolve the same path twice in a row, and will not change its state if it didn't resolve any
				path. We need to make sure it always resolve a path so it can go back to a state it was also if, in between,
				it navigated away from any matching paths.
				 */
			});
		}

		this.navigo.resolve();
	}
	stop() {
		this.navigo.destroy();
	}

	/**
	 * Pauses the router's callbacks. Will still change URLs if explicitly called.
	 */
	pause() {
		this.paused = true;
		this.navigo.pause();
	}
	/**
	 * Resumes the router's callbacks.
	 */
	resume() {
		this.paused = false;
		this.navigo.resume();
	}

	/**
	 * Execute any callbacks matching the given URL, without changing the browser's url.
	 * @param url
	 * @return {*}
	 */
	resolve(url) {
		if(!this.navigo) return;
		return this.navigo.resolve(url);
	}
	unresolve() {
		return this.navigo.resolve(UNRESOLVED);
	}
	/**
	 * Reload last route
	 */
	reload() {
		this.events.fire(this._currentState.event, this._currentState.data);
	}
	onDashboardPage(callback) {
		this.events.on('loadDashboard', callback);
	}
	onDefaultDashboard(callback) {
		this.events.on('defaultDashboard', callback);
	}
	onUnresolved(callback) {
		this.events.on('unresolved', callback);
	}
	onActivateUserPage(callback){
		this.events.on('userActivation', callback);
	}
	onResetPasswordPage(callback){
		this.events.on('resetPassword', callback);
	}
	goHome() {
		this.navigate('/');
	}
	goToDashboard(dashboardName, bookmarkName, params) {
		let path = '/' + encodeURI(dashboardName);
		if(def(bookmarkName)) {
			path += '/' + encodeURI(bookmarkName);
		}
		// Create query string
		if(isPlainObject(params) && !isEmpty(params)) {
			path += '?';
			path += reduce(params, (result, value, key) => {
				result.push(`${encodeURI(key)}=${encodeURI(value)}`);
				return result;
			}, []).join('&');
		}
		this.navigate(path);
	}
	clearUrl() {
		this.pause();
		this.navigate('/');
		// Wait for Navigo to try and resolve the url (and notice it is paused)
		setTimeout(()=>this.resume());
	}
	navigate(path) {
		this.navigo.navigate(this.config.baseRoute + path);
	}
};
