const ViewRenderer = require('../view-renderer');

const _ = require('core/src/utils/legacy');

require('./diff-view-renderer.scss');

const DiffRenderer = function() {
	ViewRenderer.call(this);
};
DiffRenderer.viewType = 'DiffView';
DiffRenderer.prototype = Object.create(ViewRenderer.prototype);


/* Methods */

DiffRenderer.prototype.doRender = function(data) {
	const check = _.validateObject("Render Data", data, {
		from: true,
		to: true,
		diff: 'isObject',
		xml: 'isString'
	}, "Could not render error.");
	if(!check.isValid()) return false;
	const valid = check.getValue();

	const $main = $('<div class="DiffViewRenderer">');
	const $xml = $(valid.xml);
	$main.append($xml);

	return $main;
};

module.exports = DiffRenderer;