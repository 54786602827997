const Log = require('utils/src/log');
const modalTemplate = require('./modal.html');

const log = Log.instance("client/components/markdown-editor/upload");

module.exports = function popup(title, content, buttonText, callback) {
	let $modal = $(modalTemplate);
	let $submit = $modal.find('.modal-submit');
	$submit.html(buttonText).click(async () => {
		$modal.find('.spinner').show();
		$submit.attr('disabled', true);
		try {
			await callback();
			$modal.find('.spinner').hide();
			$modal.modal('hide');
		} catch(error) {
			log.error(error);
			$submit.removeAttr('disabled');
			$modal.find('.modal-error').html(error.message).show();
		}
	});
	$modal.find('.modal-title').html(title);
	$modal.find('.modal-body').html(content);
	$modal.find('.modal-body').html(content);

	$('body').append($modal);
	$modal.modal('show');
};
