import {Plugin} from "prosemirror-state";

import {validateObject} from 'utils/src/validation';
import Log from 'core/src/log';

const log = Log.instance("function/markdownview/tooltip");

require('./tooltip-plugin.scss');

class TooltipPlugin {
	constructor(view, tooltips) {
		this.tooltip = document.createElement("div");
		this.tooltip.className = "editor-tooltip";
		view.dom.parentNode.appendChild(this.tooltip);

		this._tooltips = tooltips;

		this.update(view, null);
	}

	update(view, lastState) {
		let state = view.state;
		// Don't do anything if the document/selection didn't change
		if (lastState && lastState.doc.eq(state.doc) &&
			lastState.selection.eq(state.selection)) return;

		// Hide the tooltip if the selection is empty
		if (!('node' in state.selection)) {
			this.tooltip.style.display = "none";
			return;
		}

		let nodeType = state.selection.node.type;
		if(!(nodeType.name in this._tooltips)) {
			this.tooltip.style.display = "none";
			return;
		}

		let tooltip = this._tooltips[nodeType.name];
		let content = tooltip.content(state.selection.node, state, view.dispatch, view);

		// Otherwise, reposition it and update its content
		this.tooltip.style.display = "";
		let {from, to} = state.selection;
		// These are in screen coordinates
		let start = view.coordsAtPos(from), end = view.coordsAtPos(to);
		// The box in which the tooltip is positioned, to use as base
		let box = this.tooltip.offsetParent.getBoundingClientRect();
		// Find a center-ish x position from the selection endpoints (when
		// crossing lines, end may be more to the left)
		let left = Math.max((start.left + end.left) / 2, start.left + 3);
		this.tooltip.style.left = (left - box.left) + "px";
		this.tooltip.style.top = (start.bottom - box.top) + "px";

		$(this.tooltip).html(content);
	}

	destroy() { this.tooltip.remove(); }
}

class TooltipPluginFactory {
	constructor() {
		this._tooltips = {};
	}

	addTooltip(tooltipDefinition) {
		let check = validateObject(tooltipDefinition, {
			nodeType: [{name: 'isString'}, "Invalid NodeType."],
			content: 'isFunction'
		});
		if(!check.isValid()) {
			log.error(check.createError());
			return;
		}
		const valid = check.getValue();
		this._tooltips[valid.nodeType.name] = {
			content: valid.content
		};
	}

	createTooltipPlugin() {
		const self = this;
		return new Plugin({
			view(editorView) { return new TooltipPlugin(editorView, self._tooltips); }
		});
	}
}

export default TooltipPluginFactory;
