const Function = require('core/src/function');
const _ = require('lodash');

const EventListener = Function.extend(Function, 'EventListener');

EventListener.prototype.onInit = function() {
	this.setParameters({
		'$event': {}
	});
};

EventListener.prototype.onExecute = function(input) {
	this.executeTriggers(input.event);
};

module.exports = EventListener;