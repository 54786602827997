import G512Edition from "./g512-edition";
import Edition from "./edition";

export default class G1024Edition extends G512Edition {
	static get code() {
		return 'g1024';
	}
	static get name() {
		return Edition.name + ' 1024 Edition';
	}
	defineFeatures() {
		return {
			...super.defineFeatures(),
			[Edition.Feature.GRAPHILEONS_COUNT]: 1024
		};
	}
}
