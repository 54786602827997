import Edition from "core/src/edition/index";

export default class ApplicationInfo {
	constructor() {
		this.version = undefined;
		this.revision = undefined;
		this.backend = undefined;
		this.dashboard = undefined;
		this.dashboards = [];
		this.shortcuts = {user: [], team: []};
		this.stores = [];
		this.currentPage = {
			dashboard: undefined,
			bookmark: undefined,
			params: {}
		};
		this.language = 'en';
		this.languages = ['en'];

		this.sessionTimeout = 0;
		this.sessionTimeoutWarnBefore = 0;
		this.accountRecovery = false;

		this.edition = Edition.current.code;
		this.build = Edition.build;
		this.license = {
			limits: {}
		};

		this.userView = {
			infoButton: true,
			userButton: true
		};

		this.debugScreen = {
			excludedDashboards: undefined
		}
	}
}
