import {buildMenuItems} from "prosemirror-example-setup";

const popup = require('./popup');
const _ = require('lodash');
import uploadPopup from './upload';
import linkItem from './linkItem';

import APIClientAbstract from "core/src/api-client-abstract";

const schema = require('./markdown-schema');
require('bootstrap');
require('bootstrap/dist/css/bootstrap.css');


export default function buildMarkdownEditorMenu(dependencies) {
	const api = dependencies.get(APIClientAbstract);

	// Ask example-setup to build its basic menu
	let menu = buildMenuItems(schema);

	const menuItems = [
		'insertHorizontalRule', 'makeCodeBlock', 'makeHead1',
		'makeHead2', 'makeHead3', 'makeHead4', 'makeHead5',
		'makeHead6', 'makeHead7', 'makeHead8', 'makeHead9',
		'makeHead10', 'makeParagraph', 'toggleCode', 'toggleEm',
		'toggleStrong', 'wrapBlockQuote', 'wrapBulletList', 'wrapOrderedList'
	];
	_.forEach(menuItems, menuItem => {
		const runTmp = menu[menuItem].spec.run;
		menu[menuItem].spec.run = function(state, dispatch, view) {
			runTmp(state, dispatch);
			// The rich text editor is not firing any event when toolbar buttons are clicked this hack fires a `blur` event when any toolbar buttons event is finished
			// the `togglelink` and `insertimage` has their own change event so we do not need to trigger here
			view.dispatchEvent(new Event('blur'));
			view.focus();
		}	
	})

	// Override
	menu.insertImage.spec.run = function(state, dispatch, view) {
		uploadPopup(api, null, state, dispatch, view);
	};
	// Override
	menu.toggleLink.spec.run = function(state, dispatch, view) {
		linkItem(state, dispatch, view, schema.marks.link);
	};

	return menu;
};
