'use strict';

const MarkdownEditView = require('core/src/functions/views/markdown-edit-view');
const VueRenderer = require('../vue-renderer');
const MarkdownEdit = require('./markdown-edit.vue').default;
const _ = require('core/src/utils/legacy');

const MarkdownEditViewRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
};
MarkdownEditViewRenderer.viewType = 'MarkdownEditView';
MarkdownEditViewRenderer.prototype = Object.create(VueRenderer.prototype);

MarkdownEditViewRenderer.prototype.getVueComponent = function() {
	return MarkdownEdit;
};
MarkdownEditViewRenderer.prototype.initVueComponent = async function(component) {
};

module.exports = MarkdownEditViewRenderer;
