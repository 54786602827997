
	import _ from "lodash";
	import { abstractField } from "vue-form-generator";

	// A List is an Array
	let List = {
		// nil => [], Array => Array, nonArray => [nonArray]
		from: _.cond([
			[_.isNil, _.stubArray],
			[_.isArray, _.identity],
			[_.stubTrue, _.castArray]
		]),
		// push value and return THE ARRAY (not length)
		push: _.curry((value, array) => (array.push(value), array))
	};

	export default {
		mixins: [ abstractField ],

		watch: {
			schema: {
				immediate: true,
				deep: true,
				handler: function(schema) {
					this.schema = _.defaultsDeep(
						schema,
						{
							editable: false,
							formData: {},
							fileKey: 'file',
							params: {
								type: 'POST',
								url: '/api/file/create',
								processData: false,
								contentType: false,
								crossDomain: true,
								dataType: 'json'
							},
							// can be String or Function(response)
							urlFromResponse: 'data.download_link',
							minSize: 0,
							maxSize: Infinity,
							allowedMimeTypes: [],
							deniedMimeTypes: []
						}
					);
		
					// Add upload validation to list of validators (making sure it is a list)
					this.schema.validator = _.flow([
						List.from, 
						(list) => _.includes(list, this.uploadValidation) ? list : List.push(this.uploadValidation, list)
					])(this.schema.validator);
				}
			}
		},

		data() {
			return {uploading: false}
		},

		mounted() {
			// Custom errors set when user uploads a file
			this.uploadErrors = [];
		},

		methods: {
			uploadValidation() {
				return this.uploadErrors;
			},

			addUploadError(error) {
				this.uploadErrors.push(error);
				this.validate();
			},

			clearUploadErrors() {
				this.uploadErrors = [];
				this.validate();
			},

			onFilesChange(event) {
				this.clearUploadErrors();
				_.forEach(event.target.files, (file) => this.uploadFile(file));
				// Clear the value of the file input to prepare it for the next selection, so you can select the same file again if you want
				event.target.value = null;
			},

			setValue(url) {
				this.value = url;
			},

			getUploadedPathFromResponse(uploadResponse) {
				if (_.isString(this.schema.urlFromResponse)) {
					return _.get(uploadResponse, this.schema.urlFromResponse);
				}

				if (_.isFunction(this.schema.urlFromResponse)) {
					return this.schema.urlFromResponse(uploadResponse);
				}

				throw new Error(`Upload field: urlFromResponse should be String or Function. ${typeof this.schema.urlFromResponse} given`);
			},

			validateSelectedFile(file) {
				if (file.size < this.schema.minSize) {
					let error = `Selected file is too small. Minimum size should be ${this.schema.minSize}`;
					this.addUploadError(error);
					throw new Error(error);
				}

				if (file.size > this.schema.maxSize) {
					let error = `Selected file is too big. Maximum size should be ${this.schema.maxSize}`;
					this.addUploadError(error);
					throw new Error(error);
				}

				if (_.includes(this.schema.deniedMimeTypes, file.type)) {
					let error = `The selected file type is not allowed`;
					this.addUploadError(error);
					throw new Error(error);
				}

				if (! _.isEmpty(this.schema.allowedMimeTypes) && ! _.includes(this.schema.allowedMimeTypes, file.type)) {
					let error = `The selected file type is not allowed`;
					this.addUploadError(error);
					throw new Error(error);
				}

			},

			uploadFile(file) {
				this.validateSelectedFile(file);

				let formData = new FormData();
				formData.append(this.schema.fileKey, file);
				
				_.forEach(
					this.schema.formData, 
					(value, key) => formData.append(key, value)
				);

				this.uploading = true;

				let ajaxParams = _.extend({}, this.schema.params, {data: formData})
				this.currentUpload = $.ajax(ajaxParams)
					.done((response, status, xhr) => {
						this.setValue(this.getUploadedPathFromResponse(response));
					})
					.fail((xhr, textStatus, errorThrown) => {
						if (_.isFunction(this.schema.errorFromResponse)) {
							this.addUploadError(this.schema.errorFromResponse(xhr));
							return;
						}

						this.addUploadError(_.get(xhr, 'responseJSON.error.message') || textStatus || errorThrown);
					})				
					.always(() => {
						this.uploading = false;
					})	
			},

			stopUpload() {
				this.currentUpload.abort('Upload canceled');
				this.uploading = false;
			},

			onVisibleChange(event) {
				this.setValue(event.target.value);
			}
		}
	}
