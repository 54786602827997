import { render, staticRenderFns } from "./fieldFileRead.vue?vue&type=template&id=22a3b8df&"
import script from "./fieldFileRead.vue?vue&type=script&lang=js&"
export * from "./fieldFileRead.vue?vue&type=script&lang=js&"
import style0 from "./fieldFileRead.vue?vue&type=style&index=0&id=22a3b8df&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/graphileon/graphileon/styles/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22a3b8df')) {
      api.createRecord('22a3b8df', component.options)
    } else {
      api.reload('22a3b8df', component.options)
    }
    module.hot.accept("./fieldFileRead.vue?vue&type=template&id=22a3b8df&", function () {
      api.rerender('22a3b8df', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/input-view/fieldFileRead.vue"
export default component.exports