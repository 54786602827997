const _ = require('lodash');
const IAPI = require('core/src/api-client-abstract').default;
const ApplicationInfo = require('./application-info').default;

class ActivityLog {
	constructor(dependencies) {
		this.api = dependencies.get(IAPI);
		this.appInfo = dependencies.get(ApplicationInfo);

		this.bucket = [];
		this.messageCounter = 0;
		this.enabled = _.get(this.appInfo, 'activityLog.enabled', false);
		this.sendIntervalMS = _.get(this.appInfo, 'activityLog.sendIntervalMS', 5000); // 5 seconds

		if (this.enabled) {
			this.init();
		}
	}

	async init() {
		setInterval(()=> this.sendBucket(), this.sendIntervalMS);
	}

	sendBucket() {
		if (_.isEmpty(this.bucket)) {
			return;
		}

		let filledBucket = this.bucket;
		this.bucket = [];

		this.api.executeRequest('/activity-log/save', 'post', filledBucket);
	}

	add(message) {
		if (! this.enabled ) {
			return;
		}
		this.bucket.push(
			_.extend(
				// fields that can be overwritten by caller
				{
					index: ++this.messageCounter,
					timestamp: new Date().getTime() / 1000,
				},
				message 
			)
		);
	}
}

export default ActivityLog;