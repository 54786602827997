const _ = require('core/src/utils/legacy');
const Function = require('../function');
const deferredPromise = require('core/src/utils/deferred-promise');
const { findChangeFromRoot } = require('core/src/utils/changes');
const Language = require('core/src/language').default;

const View = Function.extend(Function, 'View');

/* STATICS */

View.Event = {
	VIEWRENDER: 'ViewRender', // deprecated
	In: {
		BATCH: 'Batch',
		CONTEXT: 'Context'
	},
	Out: {
		VIEW_RENDER: 'ViewRender',
		CLOSE: 'Close'
	}
};

/**
 * Creates a table out of several arrays.
 * @param {object} arrays The keys of this object will be used as the keys
 * for each column. The arrays will be used for the values.
 * @returns {object}
 */
View.arraysToTable = function(arrays) {
	return _.transpose(arrays);
};

/* PROPERTIES */

View.prototype._contextMenus = undefined;
View.prototype._batchTriggers = undefined;

/* METHODS */

/**
 * @override
 * @returns {undefined}
 */
View.prototype.onInit = function (dependencies) {
	this.language = dependencies.get(Language);
	this.setParameters({
		'$area': undefined,
		'$container': undefined,
		'$css': undefined,
		'$disableDefaultContextMenu': undefined
	});
	this._contextMenus = {};
	this._batchTriggers = [];
	this.setModelValidation({
		area: ['isString', {default: undefined, warn: _.def}],
		container: [function(val) { return _.isString(val) || _.isObject(val); }, this.language.translate('Must be string or object.'), {default: undefined, warn: _.def}],
		stayAlive: [
			Function.isValidStayAlive,
			this.language.translate('Must be one of {{names}}.', {names: _.values(Function.StayAlive).join('/')}),
			{default: Function.StayAlive.DASHBOARD, warn: _.def}
		],
		disableDefaultContextMenu: ['isObject', {default: ()=>{}, warn: _.def}]
	});

	this.setDeepModelUpdateHandler('container', this.handleContainerUpdate);
};

View.prototype.handleContainerUpdate = function(changes) {
	let onTop = _.get(findChangeFromRoot(changes, 'container.onTop'), 'new');
	if (onTop) {
		_.defer(() => {
			this.updateModel('container.onTop', false)
		});
	}

	let onBottom = _.get(findChangeFromRoot(changes, 'container.onBottom'), 'new');
	if (onBottom) {
		_.defer(() => {
			this.updateModel('container.onBottom', false)
		});
	}


	// Backward compatibility
	const collapsedChange = findChangeFromRoot(changes, 'container.collapsed');
	if(!collapsedChange) return;

	if(collapsedChange.new) {
		this.updateModel('container.state', 'collapsed');
	} else {
		this.updateModel('container.state', 'normalized');
	}
};

/**
 * For override. Initializes the View upon execution, based on the model.
 * @param model
 * @returns {*}
 */
View.prototype.initView = function(model) {
	return model;
};

/**
 * For override. Allows extending Functions to alter input data before it is
 * sent to the ViewManager.
 * @param {object} input The data to pass with the event.
 * @returns {object} The data to be passed with the RenderEvent
 */
View.prototype.alterRenderData = function(renderData) {
	return renderData;
};

/**
 * Creates render data from the View's model.
 * @param {object} model
 * @returns {Promise}
 */
View.prototype.createRenderData = function(model) {
	const promise = new Promise((resolve, reject) => {
		var contextMenus = this.findContextTriggers();
		var batchTriggers = this.findBatchTriggers();

		var renderData = {
			viewtype: this.getName(),
			input: undefined, // will be populated by alterRenderData
			contextMenus: contextMenus,
			batchTriggers: batchTriggers,
			properties: this.getProperties(),
			history: this.getHistory()
		};

		// TODO: alteredRenderData is only responsible for renderData.input. Make naming consistent/more clear. E.g. alterRenderModel
		const alteredRenderData = this.alterRenderData(model.$cloneShallow().$writableTop());

		// Force name
		var name = this.getPropertyOrInput('name');
		if(name !== undefined) {
			alteredRenderData.name = this.getPropertyOrInput('name');
		}

		// Deal with both direct data and promises
		var promise = _.promise(alteredRenderData, function(val) { return val instanceof _.Error; });
		promise.then(function(finalInput) {
			renderData.input = finalInput;
			// TODO: We can remove this cloneDeep, but then we also need to modify many renderers to handle read-only data
			resolve(_.cloneDeep(renderData));
		}).catch(function(err) {
			reject(err);
		});
	});

	return deferredPromise(promise);
};

View.prototype.findBatchTriggers = function() {
	let batchTriggers = this.findTriggersWith({
		type: 'batch'
	}, 'show');
	this._batchTriggers = [];

	const evaluate = str => this.evaluate(str, {type: 'batch'});

	for(let i in batchTriggers) {
		let trigger = batchTriggers[i];
		let properties = trigger.getProperties();

		_.assertOne("BatchTrigger", properties, {
			action: 'isString'
		});
		this._batchTriggers.push({
			id: trigger.id,
			index: evaluate(properties.index) || 0,
			action: evaluate(properties.action),
			name: evaluate(properties.name) || '',
			icon: evaluate(properties.icon),
			style: evaluate(properties.style) || '',
			hoverStyle: evaluate(properties.hoverStyle) || '',
			tooltip: evaluate(properties.tooltip),
			enable: evaluate(properties.enable)
		});
	}
	return this._batchTriggers;
};

/**
 * Fetches context menu data from outgoing triggers.
 * @returns {object}
 */
View.prototype.findContextTriggers = function() {
	// Get context menu items from Triggers
	let contextTriggers = this.findTriggersWith({
		type: 'context'
	}, 'show');

	const evaluate = str => this.evaluate(str, {type: 'context'});

	let contextMenuData = {};
	for(let i in contextTriggers) {
		let trigger = contextTriggers[i];
		let properties = trigger.getProperties();

		_.assertOne('ContextTrigger', properties, {
			menu: "isString",
			action: "isString"
		});
		let menu = properties.menu;
		let action = properties.action;

		if(!_.isObject(contextMenuData[menu])) {
			contextMenuData[menu] = {};
		}
		if(!_.isObject(contextMenuData[menu][action])) {
			contextMenuData[menu][action] = {
				id: trigger.id,
				menu: evaluate(menu),
				action: evaluate(action),
				index: evaluate(properties.index),
				enable: evaluate(properties.enable)
			};
		}
	}

	return contextMenuData;
};

/**
 * @override
 * @returns {Promise}
 */
View.prototype.onExecute = function() {
	const promise = new Promise((resolve, reject) => {
		// Initialize View based on model
		var model = this.readModel();
		var init = _.promise(this.initView(model), _.isError);
		init.then(() => {
			var createRenderData = this.createRenderData(model);
			createRenderData.done(renderData => {
				this.eventOut(View.Event.Out.VIEW_RENDER, renderData);
				resolve();
			});
			createRenderData.fail(function(error) {
				reject(error);
			});
		});
		init.catch(function(error) {
			reject(error);
		});
	});

	return deferredPromise(promise);
};

View.prototype.update = async function(mutations, executeTriggers = true, updateID = undefined) {
	const changes = await Function.prototype.update.call(this, mutations, executeTriggers, updateID);

	let model = this.readModel();
	let contextMenus = this.findContextTriggers();
	let batchTriggers = this.findBatchTriggers();

	let updatedRenderData = { contextMenus, batchTriggers };

	// Send update event to renderers (whether there are changes or not, they may need to know the process finished).
	this._sendUpdateEvent(model, changes, updateID, updatedRenderData);
};

/**
 * Send an update event through the FTI to all listeners.
 * @param {object} model				Current (new) state of the model.
 * @param {object} changes				Paths that have changed, their old and their new values.
 * @param {number|string} updateID		An ID to identify the origin of the update by.
 * @param {object} updatedRenderData	Context and batch triggers.
 */
View.prototype._sendUpdateEvent = function(model, changes, updateID, updatedRenderData) {
	// Create event with clone of model before timeout: we don't want future changes affect the current event.
	const event = {model: model.$clone().$writable(), changes, updateID, updatedRenderData};
	setTimeout(()=>{ // do not catch errors from listeners
		this.eventOut(Function.Event.Out.UPDATE, event);
	});
};

module.exports = View;
