const _ = require('lodash');

(function() {

	/**
	 * Checks whether the given object has all the 'public' properties and methods
	 * (not starting with an underscore) of the given structure.
	 * @param {object} object
	 * @param {object} structure
	 * @returns {boolean}
	 */
	const hasStructure = function(object, structure, checkFunctions) {
		if(checkFunctions === undefined) {
			checkFunctions = true;
		}

		if(!_.isObject(object)) {
			return false;
		}
		if(_.isFunction(structure)) {
			structure = structure.prototype;
		}

		// Check all properties and methods of the structure
		for(var structureProperty in structure) {
			if(structureProperty.charAt(0) === '_') {
				// This is a protected or private property
				continue;
			}
			var value = structure[structureProperty];
			if(_.isFunction(value) && !_.isFunction(object[structureProperty])) {
				if(checkFunctions) {
					return false;
				} else {
					continue;
				}
			} else if (!(structureProperty in object)) {
				return false;
			}
		}

		return true;
	};

	/**
	 * Check if given object has the same 'public' properties (not starting with underscore)
	 * as the given structure object.
	 * @param {object} object
	 * @param {object} structure
	 * @returns {Boolean|undefined}
	 */
	const hasProperties = function(object, structure) {
		return hasStructure(object, structure, false);
	};

	/**
	 * Node structure
	 * @returns {undefined}
	 */
	const Node = function() {
		this.id = undefined;
		this.labels = [];
		this.properties = {};
	};

	Node.prototype.id = undefined;
	Node.prototype.labels = [];
	Node.prototype.properties = {};

	/**
	 * Relation structure
	 * @returns {undefined}
	 */
	const Relation = function() {
		this.id = undefined;
		this.type = undefined;
		this.properties = {};
		this.source = undefined;
		this.target = undefined;
	};

	Relation.prototype.id = undefined;
	Relation.prototype.type = undefined;
	Relation.prototype.properties = {};
	Relation.prototype.source = undefined;
	Relation.prototype.target = undefined;

	module.exports = {
		Node,
		Relation,
		hasStructure,
		hasProperties
	};

})();
