const Function = require('core/src/function');

const IO = Function.extend(Function, 'IO');

IO.prototype.onInit = function() {
	this.setParameters({
		'$data': undefined
	});
};

module.exports = IO;