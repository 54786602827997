/**
* TODO: EntityFormViewRenderer currently access API directly. Should rather be done by NodeFormView Function.
*/
const IAPI = require('core/src/api-client-abstract').default;
const CodeMirror = require('client/libraries/code-mirror-interactor');
const ViewRenderer = require('client/src/view-renderer');
const ApplicationInfo = require('core/src/application-info').default;
const InterActorLegacy = require('client/src/prologram-legacy');
const log = require('core/src/log').instance("function/misc/entityformview");
const Editor = require('client/src/editor');

const template = require('client/src/templates/form-entity.html');

const $ = require('jquery');
const _ = require('core/src/utils/legacy');
const formToObject = require('client/src/utils/formToObject');
const { isjQuery } = require('client/src/utils/jquery');
const { truncateString } = require('utils/src/string');

require('jquery-ui/ui/widgets/tabs');
require('../css/entity-form-view.css');

/* Inheritance and constructor */
const EntityFormViewRenderer = function(dependencies, entityName) {
	ViewRenderer.call(this, dependencies);
	this.dependencies = dependencies;
	this.appInfo = dependencies.get(ApplicationInfo);
	this.api = dependencies.get(IAPI);
	this._entityName = entityName;

	this._modes = {
		'css': ['css'],
		'html': ['html', 'template', 'content'],
		'cypher': ['cypher'],
		'markdown': ['markdown'],
		'sparql': ['sparql'],
		'graphql': ['graphql']
	};
};
EntityFormViewRenderer.prototype = Object.create(ViewRenderer.prototype);

/* Statics */

EntityFormViewRenderer.Status = {
	UPDATED	 : 'updated',
	CREATED	 : 'created',
	SUBMITTED   : 'submitted'
};

/* Properties */

EntityFormViewRenderer.prototype._entityName = null;
EntityFormViewRenderer.prototype._$element = null;
EntityFormViewRenderer.prototype._$form = null;
EntityFormViewRenderer.prototype._modes = null;
EntityFormViewRenderer.prototype._initialData = null;

/* Methods */

EntityFormViewRenderer.prototype.initForm = function($form) {
	var self = this;
	$form.on("keypress", "input", function(event) {
		return event.keyCode != 13;
	});

	$form.on('keyup', function(event) {
		self.onEditing();
		if ((event.keyCode === 13) && (event.ctrlKey)) {
			self.addProperty();
		}
	});
};

EntityFormViewRenderer.prototype.error = function(error) {
	let deepestError = _.invoke(
		_.invoke(error, 'getPublicInfo'),
		'getDeepestError'
	) || { message: '' };

	let message = error.message;
	if(deepestError.message !== message) {
		message += `<br/>${deepestError.message}`
	}

	if(error.code === 'EndpointClosed') {
		message = `Protected ${self._entityName} type; Cannot save from here.`;
	}
	this._$element.find('.error').html(message).slideDown();
};

EntityFormViewRenderer.prototype.inform = function(message){
	this._$element.find('.error').hide();
	const infoMessageElement = this._$element.find('.info-message');
	infoMessageElement.html(message).slideDown(400, () => {
		setTimeout(() => {
			infoMessageElement.hide();
		}, 3000);
	});
};

EntityFormViewRenderer.prototype.save = async function() {
	let self = this;
	let saved = await this.apply();
	if (saved) self.close();
};

EntityFormViewRenderer.prototype.apply = function(){
	let self = this;

	var $form = this._$form;
	var id = $form.find('input[name=id]').val();
	var store = $form.find('input[name="meta[store]"]').val();

	var entity = this.formToEntity();

	if(entity instanceof _.Error) {
		this.error(entity);
		return;
	} else if (!_.def(entity)) {
		this.error(new Error("Invalid " + this._entityName));
		return;
	}

	let propertieNames = _.keys(entity.properties);
	if (propertieNames.filter(name => name.trim() === "").length > 0){
		this.error(new Error("Not all properties have a name"));
		return;
	}

	// Only submit if both id and store are set
	if(_.isString(store)) {
		var request = this.requestSaveEntity(entity);
		request.done(function(entity) {
			var status = !_.isEmpty(id)
				? EntityFormViewRenderer.Status.UPDATED
				: EntityFormViewRenderer.Status.CREATED;

			var event = {
				type: status,
				status: status
			};
			event[self._entityName] = entity;
			self.userEvent(event);
			self.inform("Changes applied successfully!");

			self.setEntityID(entity.id); // next time we will update this Entity instead of create a new one
			self.setEntityStore(entity.meta.store);
			self.renderEntityDescription(self.createEntityDescription(entity));
		});
		request.fail(function(error) {
			self.error(error);
			self._$form.find('.btn[type=submit]').prop('disabled', false);
		});
	}

	var event = {
		type: EntityFormViewRenderer.Status.SUBMITTED,
		status: EntityFormViewRenderer.Status.SUBMITTED
	};
	event[self._entityName] = entity;
	self.userEvent(event);

	return request;
};

EntityFormViewRenderer.prototype.requestSaveEntity = function() {
	log.error("requestSaveEntity not implemented.");
};

EntityFormViewRenderer.prototype.formToEntity = function() {
	// Update textareas
	this._$form.find('textarea.property-value').trigger('update-value');

	var data = formToObject(this._$form);
	var nameArray = _.get(data, 'properties.name');
	var valueArray = _.get(data, 'properties.value');

	var entity = data;
	if(_.isArray(data.labels)) {
		entity.labels = data.labels.split(',').map(function(item) { return item.trim(); });
	}
	if(_.def(data.type)) {
		entity.type = data.type;
	}
	if(_.def(data.fromID)) {
		entity.from = data.fromID;
		entity.source = data.fromID;
	}
	if(_.def(data.toID)) {
		entity.to = data.toID;
		entity.target = data.toID;
	}
	if(_.isArray(nameArray) && _.isArray(valueArray)) {
		// Trim property names
		for(let i in nameArray) {
			nameArray[i] = nameArray[i].trim();
		}

		function strip(name) {
			// Strip $ or # from name
			return ['$', '#'].indexOf(name[0]) >= 0 ? name.substr(1) : name;
		}

		var nameIndex = {};
		var duplicate = _.find(nameArray, function(name) {
			let stripped = strip(name);
			var exists = stripped in nameIndex;
			nameIndex[stripped] = true;
			return exists;
		});
		if(duplicate !== undefined) {
			return new _.Error("Duplicate property '" + strip(duplicate) + "'.");
		}
		entity.properties = _.zipObject(nameArray, valueArray);
	} else {
		entity.properties = {};
	}

	if(!this.validateEntity(entity)) {
		return null;
	}

	return entity;
};

EntityFormViewRenderer.prototype.validateEntity = function() {
	return true;
};

EntityFormViewRenderer.prototype.entityFileLink = function(url) {
	// Returns a string with a html link to the given url
	return '<a class="entity-file-link" href="' + url + '" target="_window">&nbsp;</a>';
};

EntityFormViewRenderer.prototype.resetFileInput = function($elem) {
	var $newElem = $elem.clone();
	$elem.replaceWith($newElem);
};

EntityFormViewRenderer.prototype.createEntityDescription = function(entity) {
	if(!_.isObject(entity)) {
		entity = {};
	}

	const shortenedID = truncateString(entity.id, 30);
	let entityDescription = [
		{label: 'ID', value: shortenedID, tooltip: entity.id},
		{label: 'Store', value: _.get(entity, 'meta.store')}
	];

	return entityDescription;
};

EntityFormViewRenderer.prototype.renderEntityDescription = function(description) {
	if(!_.isArray(description)) {
		log.error("Invalid entity description:", description);
		return;
	}
	var $description = this._$form.find('.entity-description');
	$description.empty();
	for(var item of description) {
		var $property = $('<div class="property '+item.label.toLowerCase()+' d-inline me-4">').attr('property-name', item.label);
		var $label = $('<div class="label d-inline pe-2">').html(item.label);
		var $value = $('<div class="value d-inline fw-bold"></div>').html(item.value);
		if(item.tooltip) {
			$value.attr('title', item.tooltip);
		}
		$property.append($label);
		$property.append($value);
		$description.append($property);
	}
};

EntityFormViewRenderer.prototype.addProperty = function(key, value, isFile) {
	var newField;
	if (_.isUrl(value) || _.isUrlPath(value) || isFile === true) {
		newField = InterActorLegacy.cloneField(this._$form.find('.entity-file.dummy'));
	} else {
		newField = InterActorLegacy.cloneField(this._$form.find('.entity-property.dummy'));
	}

	// Set values
	var fieldKey	= newField.find(':input[name=properties\\[name\\]\\[\\]]');
	var fieldValue  = newField.find(':input[name=properties\\[value\\]\\[\\]]');
	this._setupEditor(fieldKey, fieldValue, key, value);

	newField.find('.dynamic-set-remove').on('click', event => {
		this.onEditing();
	})

	return newField;
};

EntityFormViewRenderer.prototype.clearEntityProperties = function() {
	this._$form.find('.key-value-set').not('.dummy').remove();
};

EntityFormViewRenderer.prototype.moveTypePropertyToTop = function(propertyName) {
	const propertyInput = this._$form.find('.entity-property.type-property');
	propertyInput.parent().prepend(propertyInput);
};

EntityFormViewRenderer.prototype.setEntityProperties = function(properties) {
	properties = _.flattenObject(properties);

	if (! this._initialData) this._initialData = properties;

	const keys = Object.keys(properties).sort(function(a,b) { return a.toLowerCase() <= b.toLowerCase() ? -1 : 1;});

	// Load entity properties
	_.forEach(keys, key => {
		this.addProperty(key, properties[key]);
	});

	return true;
};

EntityFormViewRenderer.prototype.setIsTypedEntity = function(isTypedEntity = true) {
	if(isTypedEntity) {
		this.autoAddTypeProperty();
		this.moveTypePropertyToTop();
	}
	this._$form.toggleClass('typed-entity', isTypedEntity);
};

EntityFormViewRenderer.prototype.setEntityID = function(id) {
	this._$form.find(':input[name=id]').val(id);
};

EntityFormViewRenderer.prototype.setEntityStore = function(store) {
	this._$form.find(':input[name="meta[store]"]').val(store);
};

EntityFormViewRenderer.prototype.setEntity = function(entity) {
	var self = this;
	var entityForm = this._$form;
	if(!isjQuery(entityForm)) {
		log.warn("Could not set value, form not initialized.");
		return false;
	}
	if(!_.isObject(entity)) {
		log.warn("Data should be object.", entity);
		return false;
	}

	// Set ID
	if(_.isStringOrNumber(entity.id)) {
		this.setEntityID(entity.id);
	}
	// Set store
	var store = _.get(entity, 'meta.store');
	if(_.isString(store)) {
		this.setEntityStore(store);
	}
	this.store = store;
	
	// Set labels
	if(_.isArray(entity.labels)) {
		var labelsInput = entityForm.find(':input[name=labels]');
		labelsInput.val(entity.labels.join(', '));
	}
	// Set type
	if(_.isStringOrNumber(entity.type)) {
		var typeInput = entityForm.find(':input[name=type]');
		typeInput.val(entity.type);
		entityForm.find('input.type').html(entity.type);
	}

	// Set properties
	if(_.isObject(entity['properties'])) {
		// Don't show UUID property
		if(_.isString(store)) {
			const uuidProperty = _.get(this.appInfo.stores[store], 'uuidProperty');
			this.uuid = _.get(entity, `properties.${uuidProperty}`)
			delete entity['properties'][uuidProperty];
		}
		this.setEntityProperties(entity.properties);
	}

	entityForm.find('.add-property').on('click', function() {
		self.addProperty();
	});
	entityForm.find('.add-file').on('click', function() {
		self.addProperty(undefined, undefined, true);
	});

	// From and To
	if(_.isStringOrNumber(entity.from)) {
		entityForm.find(':input[name=fromID]').val(entity.from);
		entityForm.find('.rel-description .from .description').html(entity.from);
	}
	if(_.isStringOrNumber(entity.to)) {
		entityForm.find(':input[name=toID]').val(entity.to);
		entityForm.find('.rel-description .to .description').html(entity.to);
	}

	var description = this.createEntityDescription(entity);
	this.renderEntityDescription(description);

	this.setIsTypedEntity(this.isEditingTypedEntity());
};

EntityFormViewRenderer.prototype.onFormRendered = function() {
	return true;
};

/**
 * For override
 * @returns {boolean}
 */
EntityFormViewRenderer.prototype.isEditingTypedEntity = function() {
	return false;
};

EntityFormViewRenderer.prototype.autoAddTypeProperty = function() {
	if(!this.isEditingTypedEntity()) return;

	let data = formToObject(this._$form);
	let properties = _.get(data, 'properties.name');
	if (properties && properties.indexOf('type') >= 0) return;
	this.addProperty('type', null, false);
};

EntityFormViewRenderer.prototype.doRender = function(renderData) {
	var self = this;
	var $div = $('<div>');
	$div.addClass('EntityFormViewRenderer h-100');

	self._initData = renderData;

	if(!_.isObject(renderData.style)) {
		renderData.style = {};
	}
	var check = _.validate({
		keyFieldWidth: [renderData.style.keyFieldWidth, 'isString', {default: undefined, warn: _.def(renderData.style.keyFieldWidth)}]
	}, "Could not render form.");
	if(!check.isValid()) return $div;
	var validStyle = check.getValue();

	var $template = $(template);
	$div.append($template);

	self._$element = $div;
	self._$form = self._$element.find('.form-entity');
	self._$fileForm = self._$element.find('.form-file-upload');

	if(validStyle.keyFieldWidth !== undefined) {
		self._$form.find('.property-name-area').css('width', validStyle.keyFieldWidth);
		self._$form.find('input.file-name-area').css('width', validStyle.keyFieldWidth);
	}

	self.initForm(self._$form);

	// Setup submission
	self._$form.on("submit", function(e) {
		e.preventDefault();
		self._$form.find('.btn[type=submit]').prop('disabled', true);
		self.save();
	});

	// Setup close and apply buttons
	this._$form.find('.button-cancel').on('click', () => {
		self.close();
	});

	this._$form.find('.button-apply').on('click', async function(event) {
		$(this).prop('disabled', true); // avoid double-click
		let saved = await self.apply();
		if (saved){
			$(event.target).attr('disabled', true);
		}
	});

	// Setup uploading
	var fileInputID = _.uniqueId();
	self._$fileForm.find('input[type=file][name=entity-file]').attr('id', fileInputID);
	self._$form.find('.set-current-file').attr('for', fileInputID);
	self._$form.on('click', '.set-current-file', function(){
		self._$form.find('.set-current-file').removeClass('current');
		$(this).addClass('current');
	});
	self._$fileForm.on('change', 'input[type=file][name=entity-file]', function(event){
		var $this = $(this);
		if (('files' in this) && (this.files.length > 0)) {
			$this.find('.entity-file-link').html('<img src="img/ajax-loader.gif" style="height: 20px"/>');
			self.api.upload(
				this.files[0]
			).done(function(file) {
				var currentFileHolder = self._$form.find('.set-current-file.current').closest('.entity-file');
				var valueInput = currentFileHolder.find(':input[name=properties\\[value\\]\\[\\]]');
				var entityFileLinkHolder = currentFileHolder.find('.entity-file-link-holder');

				var link = file.download_link;
				valueInput.val(link).change();
				entityFileLinkHolder.html(self.entityFileLink(link));
			}).fail(function(error){
				self.error(error);
				log.error("Uploading failed.", error);
			});
			self.resetFileInput($this);
		}
	});

	self._$form.find('input.labels, input.type').on('keyup', event => {
		this.setIsTypedEntity(self.isEditingTypedEntity());
	});

	var $jsonEditor = self._$element.find('[name=json-properties]').first();

	self.jsonEditor = CodeMirror.fromTextArea($jsonEditor[0], {
		mode: 'application/javascript',
		indentWithTabs: false,
		smartIndent: true,
		matchBrackets : true,
		autofocus: false,
		theme: 'neo',
		lineWrapping: true
	});

	var initialPropertiesState;

	var updateEntityForm = function() {
		var properties = {};
		try {
			properties = JSON.parse(self.jsonEditor.getValue());
		}
		catch (exception){
			console.error('JSON parse exception' ,exception);

			var revertChanges = confirm('JSON is not valid. \n\n' + exception.toString().split("\n")[0] + '\n\nRevert changes? \n');
			if (! revertChanges) {
				return false;
			}

			self.jsonEditor.setValue(initialPropertiesState);
			return true;
		}

		self.clearEntityProperties();
		self.setEntityProperties(properties);
		return true;
	};

	self.jsonEditor.on('blur', updateEntityForm);

	// Prevent losing unsaved changes
	const editTab = self._$element.find('#entity-form-edit-tab');
	editTab.on('focusout', (event) => {
		const initialPropertiesState = JSON.stringify(self.formToEntity()['properties'], null, 2);
		self.jsonEditor.getDoc().setValue(initialPropertiesState);
	});

	const jsonTab = self._$element.find('#entity-form-json-tab')[0];
	if (jsonTab) {
		const wrapper = jsonTab.querySelector('#json-edit-container > .CodeMirror-wrap');
		wrapper.classList.add('form-control');
	}

	var tabs = self._$element.find('.tabs');
	tabs.tabs({
		activate: function(event, ui) {
			// If json tab is activated
			if (ui.newPanel[0] === jsonTab) {
				initialPropertiesState = JSON.stringify(self.formToEntity()['properties'], null, 2);
				self.jsonEditor.getDoc().setValue(initialPropertiesState);
				setTimeout(function() {
					self.jsonEditor.refresh();
					self.jsonEditor.focus();
				}, 100);
				return;
			}

			// If edit tab is activated
			if (ui.newPanel[0] === self._$element.find('#entity-form-edit-tab')[0]) {
				self.resize();
			}
		}
	});

	/*
	TODO: EntityFormViewRenderer should not require any knowledge about implementing classes (node, relation)
	NodeFormViewRender should be responsible for its own differences from the parent class
	 */
	self.setEntity(renderData[self._entityName]);
	if (self._entityName === 'node' && !_.get(renderData, 'node.id')) {
		self.addStoreSelector(renderData.stores);
	}

	self.resize();

	return $div;
};

EntityFormViewRenderer.prototype.doResize = function(width, height) {
	if(isjQuery(this._$form)) {
		this._$form.find('.CodeMirror').each(function(i, editor) {
			// Force CodeMirror to re-calculate the cursor position
			editor.CodeMirror.setSize();
		});
	}
};

EntityFormViewRenderer.prototype._toString = function(value) {
	if(!_.def(value)) {
		return "";
	}
	return "" + value;
};

EntityFormViewRenderer.prototype._getEditorModeFromKey = function(key) {
	const defaultMode = Editor.Mode.DEFAULT;

	if(!_.isString(key)) return defaultMode;

	var compare = key.toLowerCase();
	for(var mode in this._modes) {
		for(var i in this._modes[mode]) {
			var find = this._modes[mode][i];
			if(compare.indexOf(find) >= 0) {
				return mode;
			}
		}
	}

	return defaultMode;
};

EntityFormViewRenderer.prototype._setupEditor = function($keyField, $valueField, key, value) {
	const self = this;
	$keyField.val(key);

	const editor = new Editor(this.dependencies, $valueField[0], [
		'markdown',
		'css',
		'html',
		'cypher',
		'sparql'
	]);

	const mode = this._getEditorModeFromKey(key);
	editor.setMode(mode);
	editor.setValue(this._toString(value));

	function setTypePropertyClass(active = true) {
		// Class added to style type property
		$keyField.closest('.entity-property').toggleClass('type-property', active);
		if(active && self.isEditingTypedEntity()) {
			self.moveTypePropertyToTop();
		}
	}

	setTypePropertyClass(key === 'type');

	$keyField.on('change', function() {
		const newKey = $keyField.val();
		const newMode = self._getEditorModeFromKey(newKey);
		editor.setMode(newMode);

		setTypePropertyClass(newKey === 'type');
	});

	$valueField.on('update-value', function() {
		editor.toTextArea();
	});
};

EntityFormViewRenderer.prototype.onEditing = function() {
	let entity = this.formToEntity();
	let notChanged  = _.isEqual(this._initialData, entity.properties);

	if(notChanged) {
		this._$form.find('.button-apply')
			.prop('disabled', true)
			.addClass("btn-outline-success")
			.removeClass("btn-success");
	} else {
		this._$form.find('.button-apply')
			.removeAttr('disabled')
			.addClass("btn-success")
			.removeClass("btn-outline-success");
		this._$form.find('.button-save').removeAttr('disabled');
	}
}

EntityFormViewRenderer.prototype.restore = function() {
	log.error("restore not implemented.");
}

module.exports = EntityFormViewRenderer;
