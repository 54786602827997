import {remove, truncate, forEach, isFunction} from 'lodash';
import Log from './log';

const log = Log.instance('core/registry');

export default class Registry {
	constructor() {
		this.lists = {};
		this.validators = {};
		this.watchers = {};
	}
	require(list, validator) {
		if(list in this.lists) {
			// List exists, check if the validator is the same
			let same = this.validators[list] === validator;
			if(!same) {
				log.warn("Required Registry exists, but with different validator.");
			}
			return same;
		}
		this.lists[list] = [];
		if(validator) {
			this.validators[list] = validator;
		}
		return true;
	}
	add(list, item) {
		if(!(list in this.lists)) {
			log.warn(`No list called ${list}.`);
			return;
		}
		// Validate (if defined)
		if(list in this.validators && !this.validators[list](item)) {
			log.warn(`Item does not pass '${this.validators[list].name}' validation for '${list}' list.`, item);
			return;
		}
		this.lists[list].push(item);
		this.notify(list, {added: item});
	}
	remove(list, item) {
		if(!(list in this.lists)) return;

		let removed = remove(this.lists[list], listItem => listItem === item);
		this.notify(list, {removed: removed});
	}
	get(list) {
		if(!(list in this.lists)) {
			log.warn(`No list called ${list}.`);
			return [];
		}
		return this.lists[list];
	}
	watch(list, watcher) {
		if(!isFunction(watcher)) {
			log.error("Watcher is not a function.", watcher);
			return;
		}
		if(!(list in this.watchers)) {
			this.watchers[list] = [];
		}
		this.watchers[list].push(watcher);
	}
	unwatch(list, watcher) {
		remove(this.watchers[list], listItem => listItem === watcher);
	}
	notifyChange(list, item) {
		this.notify(list, {updated: item});
	}
	notify(list, changes) {
		if(!(list in this.watchers)) {
			return;
		}
		forEach(this.watchers[list], watcher => {
			watcher(changes);
		});
	}
}