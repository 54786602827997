require('codemirror/addon/hint/show-hint');
require('codemirror/addon/lint/lint');
require('codemirror-graphql/hint');
require('codemirror-graphql/lint');
require('codemirror-graphql/mode');

const { GraphQLSchema, GraphQLType ,getIntrospectionQuery, buildClientSchema, } = require('graphql');
const AlternativeEditorAbstract = require('../alternative-editor-abstract').default;
const CodeMirror = require('client/libraries/code-mirror-interactor');
const IAPI = require('core/src/api-client-abstract').default;

class GraphQLEditor extends AlternativeEditorAbstract {
	constructor(dependencies, container) {
		super();

		this.editor = CodeMirror.fromTextArea(container.firstElementChild,  {
			mode: 'graphql',
			indentWithTabs: true,
			smartIndent: true,
			lineNumbers: true,
			matchBrackets: true,
			autofocus: true,
			styleActiveLine: true,
			theme: 'neo',
			lint: true,
			extraKeys: { 'Ctrl-Space': 'autocomplete' }
		});

		this.editor.on('change', this.triggerAutoCompletion.bind(this));

		this._context = {};
		this._$container = $(container);

		this.api = dependencies.get(IAPI);
	}

	triggerAutoCompletion(cm, changed) {
		if (changed.text.length !== 1) {
			return;
		}

		const text = changed.text[0];
		const shouldTriggerAutocompletion =
			text === '...' ||
			text === '()' ||
			text === '{}' ||
			text === '[' ||
			text === '(' ||
			text === '{'
		if (shouldTriggerAutocompletion) {
			cm.execCommand('autocomplete');
		}
	}

	onContextChanged() {
		if(this._context.graphqlSchemaUrl) this.setSchema(this._context.graphqlSchemaUrl);
	}

	getValue() {
		return this.editor.getValue();
	}

	setValue(value) {
		this.editor.setValue(value);
	}

	destroy() {
		const $editor = this._$container.find('.CodeMirror');
		$editor.hide();
	}

	async setSchema(url){
		let result = await $.ajax({
			type: "POST",
			url: url,
			contentType: "application/json",
			data: JSON.stringify({
				operationName: "IntrospectionQuery",
				query: getIntrospectionQuery(),
				variables: null
			})
		});
		let schema = buildClientSchema(result.data);
		this.editor.setOption("hintOptions",  {schema: schema});
		this.editor.setOption("lint",  {schema: schema});
	}
}

module.exports = {
	alternativeEditors: {
		graphql: {
			label: 'GraphQL Editor',
			Editor: GraphQLEditor
		},
	}
};
