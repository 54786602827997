import G256Edition from "./g256-edition";
import Edition from "./edition";

export default class G512Edition extends G256Edition {
	static get code() {
		return 'g512';
	}
	static get name() {
		return Edition.name + ' 512 Edition';
	}
	defineFeatures() {
		return {
			...super.defineFeatures(),
			[Edition.Feature.GRAPHILEONS_COUNT]: 512
		};
	}
}
