const Function = require('core/src/function');
const View = require('core/src/functions/view');

const TimelineView = Function.extend(View, "TimelineView");

/**
 * @override
 * @returns {undefined}
 */
TimelineView.prototype.onInit = function() {
	this.setParameters({
		'#events': undefined,
		'#groups': undefined,
		'$options': {
			'height': '100%'
		},
		'$timeMarkers': {},
		'$visibleRange': {},
		'$state': {
			selected: {
				events: []
			}
		}
	});
};

module.exports = TimelineView;