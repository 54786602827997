const AlternativeEditorAbstract = require('../alternative-editor-abstract').default;
const ComponentLoader = require('client/src/component-loader').default;
const uniqueId = require('lodash/uniqueId');
const forEach = require('lodash/forEach');
const pretty = require('pretty');

const tinymce = require('tinymce/tinymce');
require('tinymce/themes/modern');

require('./rich-text.scss');

class RichTextEditor extends AlternativeEditorAbstract {
	constructor(dependencies, container) {
		super();

		this._componentLoader = new ComponentLoader(dependencies);
		this._componentsExcluded = ['text-editor', 'markdown-editor'];
		this._validElements = [];
		let components = this._componentLoader.getAvailableComponents();
		forEach(components, (component) => {
			this._validElements.push('ia-'+component);
		});

		this._$textarea = $('<textarea>');
		$(container).append(this._$textarea);

		this._id = uniqueId('mce-');
		this._$textarea.attr('id', this._id);

		this._tinyMCE = null;
		this._setupTinyMCE();
	}

	_setupTinyMCE() {
		function defaultPluginPath(name) {
			return 'tinymce-plugins/' + name + '/plugin.js';
		}
		// Undefined plugins will be found in default plugins
		let plugins = {
			autoresize: undefined,
			print: undefined,
			preview: undefined,
			fullpage: undefined,
			searchreplace: undefined,
			autolink: undefined,
			directionality: undefined,
			visualblocks: undefined,
			visualchars: undefined,
			fullscreen: undefined,
			image: undefined,
			link: undefined,
			media: undefined,
			codesample: undefined,
			table: undefined,
			charmap: undefined,
			hr: undefined,
			pagebreak: undefined,
			nonbreaking: undefined,
			anchor: undefined,
			toc: undefined,
			insertdatetime: undefined,
			advlist: undefined,
			lists: undefined,
			textcolor: undefined,
			wordcount: undefined,
			imagetools: undefined,
			contextmenu: undefined,
			colorpicker: undefined,
			textpattern: undefined,
			help: undefined
		};
		for(let i in plugins) {
			if(plugins[i] === undefined) {
				plugins[i] = defaultPluginPath(i);
			}
		}

		setTimeout(() => { // wait for next loop, when rendered content was added to page
			tinymce.init({
				selector: '#' + this._id,
				theme: 'modern',
				skin_url: 'tinymce-skins/lightgray',
				external_plugins: plugins,
				plugins: Object.keys(plugins),
				toolbar1: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
				image_advtab: true,
				extended_valid_elements: this._validElements.join(','),
				autoresize_bottom_margin: 5,
				images_upload_handler: (blobInfo, success, failure) => {
					InterActor.api.upload(new File([blobInfo.blob()], blobInfo.name()))
						.done((file) => {
							success(file.download_link);
						})
						.fail((err) => {
							failure(err.message);
						});
				}
			}).then((editors) => {
				this._tinyMCE = editors[0];
				this._tinyMCE.on('change', () => {
					this.fireChange(this.getValue());
				});

				// If a value was set in the meantime, set TinyMCE content
				if(this._value !== undefined) {
					this.setValue(this._value);
				}

				// Load InterActor Components
				let body = this._tinyMCE.getBody();
				this._componentLoader.loadComponents(body, {
					exclude: this._componentsExcluded
				});
			});
		});
	}

	setValue(value) {
		this._value = value;
		if(this._tinyMCE) {
			this._tinyMCE.setContent(value, {format: 'raw'});
		}
	}

	getValue() {
		if(this._tinyMCE) {
			// Get contents as DOM
			let $content = $('<div>').append($(this._tinyMCE.getContent()));
			// Unload components
			this._componentLoader.unloadComponents($content);
			// Back to string
			return pretty($content[0].innerHTML.trim());
		}

		return '';
	}

	destroy() {
		this._$textarea.remove();
		if(this._tinyMCE) {
			this._tinyMCE.destroy();
		}
	}
}


module.exports = RichTextEditor;
