const _ = require('core/src/utils/legacy');
const Log = require('core/src/log');
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const ApiClientAbstract = require('core/src/api-client-abstract').default;
const Language = require('core/src/language').default;

const log = Log.instance("Function/SetPasswordView");

const SetPasswordView = Function.extend(View, 'SetPasswordView');

SetPasswordView.Event = {
	In: {
		SUBMIT: 'Submit'
	}
};

SetPasswordView.prototype.setMessage = function (title, message) {
	this.update({[title]: message});
};

SetPasswordView.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(ApiClientAbstract);
	this.language = dependencies.get(Language);
	this.setEventHandler(SetPasswordView.Event.In.SUBMIT, async (event) => {
		const check = _.validateObject(event, {
			password: ['isString', {default: '', warn: _.def}],
			newPassword: 'isString',
		});

		if(!check.isValid()) {
			this.setMessage('error', t('No valid user data provided.'));
			this.setMessage('success', undefined);
			log.error(check.createError());
			return;
		}
		const valid = check.getValue();

		try {
			let result = await this.api.userSetPassword(valid.userId, valid.token, valid.password, valid.newPassword);
			if (result){
				this.setMessage('success', this.language.translate('Password changed successfully.'));
				this.setMessage('error', undefined);
				this.executeTriggers({
					type: 'success',
					user: result.user
				});
			}
		} catch(error) {
			log.error(error);
			this.setMessage('error', error.getMessage());
			this.executeTriggers({
				type: 'error',
				error: error.export()
			});
		}
	});
	this.setParameters({
		'error': undefined,
		'success': undefined
	});
};

module.exports = SetPasswordView;
