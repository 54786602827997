
	export default {
		name: "view-toolbar-button",
		props: {
			id: [String, Number],
			icon: [String],
			style: [String],
			enable: [Boolean],
			hoverStyle: [String],
			labelHtml: [String]
		}
	}
