'use strict';

const PermissionEditView = require('core/src/functions/views/permission-edit-view');
const VueRenderer = require('../vue-renderer');
const Permission = require('./permission-view.vue').default;
const _ = require('core/src/utils/legacy');

const PermissionViewRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
};
PermissionViewRenderer.viewType = 'PermissionEditView';
PermissionViewRenderer.prototype = Object.create(VueRenderer.prototype);

PermissionViewRenderer.prototype.getVueComponent = function() {
	return Permission;
};
PermissionViewRenderer.prototype.initVueComponent = async function(component) {
	component.$on('delete', (permission) => {
		this.trigger({type: 'deleted', permission});
	});
	component.$on('submit', (permission) => {
		this.trigger({type: permission.id ? 'updated' : 'created', permission});
	});
};
module.exports = PermissionViewRenderer;
