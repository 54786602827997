const Request = require('./request');
const Edition = require('core/src/edition').default;
const Function = require('core/src/function');
const _ = require('lodash');
const axios = require('axios');
const { isRunningNodeJs } = require("core/src/utils/platform");
const Err = require('utils/src/error');

const log = require('core/src/log').instance("function/request");

Edition.setBuildFeature(Edition.Feature.FUNCTION_REQUEST, true);

let toURLSearchParams = (obj) => {
	return _.reduce(
		obj, 
		(res, val, key) => {
			res.append(key, val);
			return res;
		},
		new URLSearchParams()
	)
}

Request.prototype.execute = function (...args) {
	if(!Edition.hasFeature(Edition.Feature.FUNCTION_REQUEST)) {
		const error = Edition.functionNotAvailableError('Request');
		this.error(error);
		throw error;
	}
	return Function.prototype.execute.call(this, ...args);
};

Request.prototype.makeRequest = async function(request) {
	let req = _.cloneDeep(request);

	if (	_.get(request, ['headers','content-type']) === 'application/x-www-form-urlencoded'
		||	_.get(request, ['headers','Content-Type']) === 'application/x-www-form-urlencoded'
	) {
		req.data = toURLSearchParams(req.data);
	}
	return axios(req);
};

Request.prototype.makeServerRequest = async function(request) {
	const response = await this.api.executeRequest('/request/execute', 'post', {
		request,
		requestFunctionId: this.id
	});
	if('error' in response) {
		// Convert error response to Axios-style error
		const err = new Error(response.error.message);
		err.response = response.error.response;
		throw err;
	}
	return response.success;
};

Request.prototype.onExecute = async function() {
	const model = this.readModel();

	// Backward compatibility
	const jqueryParams = _.filter(Request.jQueryParameters, param => !_.isNil(model[param]));
	if(jqueryParams.length > 0) {
		log.warn(`The following Request parameters are deprecated and will be removed in following versions of Graphileon: ` +
			jqueryParams.join(", ") +
			". Please refer to the documentation at https://docs.graphileon.com/graphileon/For_Dashboard_Designers/Reference/Request.html.");
		return this.jqueryRequest(model);
	}

	// Normal mode, using Axios
	const request = _.pick(model, 'url', 'method', 'data', 'headers', 'params', 'timeout', 'auth', 'urlData');
	if(model.download) {
		request.responseType = 'blob';
	}

	let response;
	try {
		if(model.serverSide === true && (!isRunningNodeJs() || this.forceAllowServerSide)) {
			response = await this.makeServerRequest(request);
		} else {
			response = await this.makeRequest(request);
		}
		if(model.download === true) {
			const extension = /\.\w+$/.exec(request.url)[0] || '';
			this.download(response.data, model.downloadName + extension);
			response.data = model.downloadName;
		}
	} catch(e) {
		const finalResponse = this.standardizeResponse(e);
		for(let type of ['error', 'fail']) { // `fail` for backward compatibility
			this.executeTriggers({
				type,
				error: _.get(e, 'message'),
				completed: true, // so one can easily create Triggers for both success and failure
				response: finalResponse
			});
		}
		if(model.allowFailure !== true) {
			this.error(e);
		}
		return;
	}

	const finalResponse = this.standardizeResponse(response);
	const triggerData = {
		type: 'success',
		completed: true, // so one can easily create Triggers for both success and failure
		response: finalResponse
	};
	if('error' in finalResponse) {
		// for consistency with the 'error' trigger
		triggerData.error = finalResponse.error;
	}
	this.executeTriggers(triggerData);
};

Request.prototype.download = function(data, fileName) {
	const downloadUrl = window.URL.createObjectURL(new Blob([data]));

	const link = document.createElement('a');
	link.href = downloadUrl;
	link.setAttribute('download', fileName);

	document.body.appendChild(link);

	link.click();
	link.remove();
};

/**
 * For backward compatibility
 * @param model
 * @returns {Promise<unknown>}
 */
Request.prototype.jqueryRequest = function(model) {
	const ajaxSettings = {};
	for(let key of [...Request.jQueryParameters, 'url', 'data', 'headers', 'method']) {
		if(key in model) {
			ajaxSettings[key] = model[key];
		}
	}
	// if requestDataType = JSON transform data to JSON
	if (model.requestDataType && (model.requestDataType.toString().trim().toLowerCase() === 'json') && ! _.isString(ajaxSettings.data)) {
		ajaxSettings.data = ajaxSettings.data && JSON.stringify(ajaxSettings.data);
	}
	if(typeof $ == 'undefined' || !_.isFunction($.ajax)) {
		throw new Error("jQuery Requests cannot be executed from the server. " +
			"Use only currently documented parameters to prevent dependency on jQuery.");
	}

	const request = $.ajax(ajaxSettings);

	const self = this;
	return new Promise(async (resolve, reject) => {
		if (model.download) {
			request.success(
				function(response, status, xhr) {
					// check for a filename
					let filename = "";

					const disposition = xhr.getResponseHeader('Content-Disposition');
					if (disposition && disposition.indexOf('filename') !== -1) {
						const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
						const matches = filenameRegex.exec(disposition);
						if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
					}

					const type = xhr.getResponseHeader('Content-Type');
					const blob = new Blob([response], { type: type });

					if (typeof window.navigator.msSaveBlob !== 'undefined') {
						// IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
						window.navigator.msSaveBlob(blob, filename);
						return;
					}

					const URL = window.URL || window.webkitURL;
					const downloadUrl = URL.createObjectURL(blob);

					const a = document.createElement("a");
					// safari doesn't support this yet
					if (typeof a.download === 'undefined') {
						// window.open(downloadUrl);
					} else {
						a.href = downloadUrl;
						a.download = filename || model.downloadName;
						document.body.appendChild(a);
						a.click();
					}
					setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
					resolve();
				}
			);
		}

		request.always(function(response) {
			// Error
			let type = 'success';
			if(Request.isXHRResponse(response) && response.status !== 200) {
				type = 'fail';
				const errorMessage = self.findErrorMessage(response);
				let originalError = undefined;
				if(_.isString(errorMessage)) {
					originalError = new Err(errorMessage);
				} else {
					originalError = new Err(response.status + " " + response.statusText);
				}
				if(!_.hasBooleanValue(model.allowFailure, true)) {
					reject(new Err(self.language.translate('Ajax request failed ({{status}})', {status: response.status + " " + response.statusText}), originalError));
				} else {
					resolve();
				}
			}

			self.executeTriggers({
				type: type,
				completed: true, // so one can easily create Triggers for both success and failure
				response: self.standardizeResponse(response)
			});
		});
	});
};
