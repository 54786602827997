const esprima = require('esprima');
const _ = require('./utils/legacy');
const isRunningNode = require('core/src/utils/is-running-node');
const { t } = require('core/src/language');


const CodeEvaluator = function() {
	var self = this;
	this._globalContext = {
		undefined: undefined
	};
	this._config = {
		failOnInvalidProperty: true,
		expressionSets: {full: undefined}, // by default, 'full' expression set contains all implemented expressions
		quickEvaluationPattern: /^\$\((.*)\)$/,
		evaluateFunctionExpressionSet: 'full'
	};
	this._publicVars = {};

	/**
	 * Advanced Functions are functions that can be registered to the CodeEvaluator, but - as opposed to regular
	 * functions - take the CodeEvaluator and current scopes as first two parameters, before any parameters passed from
	 * the evaluated code.
	 */
	this._advancedFunctions = {};
	this._restricted = isRunningNode()
		? [] // TODO: add node-restricted globals
		: [window, document];

	// Some functions that are necessary in the sandbox
	const nestedEvaluate = x=>x;
	this.setPublics({
		'map': _.map,
		'flatten': _.flatten,
		'pow': Math.pow,
		'sqrt': Math.sqrt,
		'evaluate': nestedEvaluate,	// special function - if detected, its arguments are evaluated with configured expression set.
		'e': nestedEvaluate // alias
	});

	// Available operators in the sandbox
	this._operators = {
		'+' : function(a,b) { return a + b; },
		'-' : function(a,b) { return a - b; },
		'*' : function(a,b) { return a * b; },
		'/' : function(a,b) { return a / b; },
		'%' : function(a,b) { return a % b; },
		'==': function(a,b) { return a == b; },
		'!=': function(a,b) { return a != b; },
		'===': function(a,b) { return a === b; },
		'!==': function(a,b) { return a !== b; },
		'>': function(a,b) { return a > b; },
		'<': function(a,b) { return a < b;},
		'>=': function(a,b) { return a >= b; },
		'<=': function(a,b) { return a <= b; },
		'&&': function(a,b) { return a && b; },
		'||': function(a,b) { return a || b; }
	};
	this._unaryOperators = {
		'!' : function(a) { return !a; },
		'-' : function(a) { return -a; }
	};

	// Instead of directly logging failed checks, throw an error.
	this._validityHandler = function(validity) {
		if(!validity.isValid()) {
			throw validity.createError();
		}
	};

	var BinaryExpression = function(expression, expressionSet, scopes) {
		var check = _.validateObject(expression, {
			operator: [function (op) {
				return op in self._operators;
			}, "Invalid operator."],
			left: 'isObject',
			right: 'isObject'
		}, t('Could not evaluate BinaryExpression.'), self._validityHandler);
		if (!check.isValid()) return;
		var valid = check.getValue();

		try {
			// Evaluate both sides
			var leftValue = self._evaluate(valid.left, expressionSet, scopes);
			var rightValue = self._evaluate(valid.right, expressionSet, scopes);

			// Call operator
			return self._operators[valid.operator](leftValue, rightValue);
		}
		catch (err) {
			throw new _.Error(t('Could not evaluate BinaryExpression.'), err);
		}
	};

	// Processors for each expression type.
	this._processors = {
		'Literal': function(expression, expressionSet, scopes) {
			var regex = _.get(expression, 'regex');
			if(_.isObject(regex)) {
				return new RegExp(regex.pattern, regex.flags);
			}
			return _.get(expression, 'value');
		},
		'Identifier': function(expression, expressionSet, scopes) {
			var check = _.validateObject(expression, {
				name: 'isString'
			}, t('Could not evaluate Literal.'), self._validityHandler);
			if (!check.isValid()) return check.createError();
			var valid = check.getValue();

			// First try scope
			let identifierScope = self.findScopeOfIdentifier(scopes, valid.name);
			if(identifierScope) {
				return identifierScope[valid.name];
			}

			// Try function

			// Check if function is enabled in expression set.
			if(_.isObject(expressionSet) && expressionSet.Identifier !== true && !_.has(expressionSet, 'Identifier.'+valid.name)) {
				throw new _.Error(t("Function '{{name}}' is not enabled.", {name: valid.name}));
			}

			// Try EvaluatorFunction
			if(valid.name in self._advancedFunctions) {
				return function() {
					const func = self._advancedFunctions[valid.name];
					return func.apply({}, _.concat([self, scopes], arguments));
				}
			}

			// Check if function exists
			if(!(valid.name in self._publicVars)) {
				throw new _.Error(t("Undefined identifier '{{name}}'.", {name: valid.name}));
			}
			return self._publicVars[valid.name];
		},
		'ArrowFunctionExpression': function(expression, expressionSet, scopes) {
			var check = _.validateObject(expression, {
				params: [[{
					type: [_.equals('Identifier'), t("Can only be 'Identifier'.")],
					name: 'isString'
				}]],
				body: 'isObject'
			}, t('Could not evaluate ArrowFunctionExpression.'), self._validityHandler);
			if(!check.isValid()) return check.createError();
			var valid = check.getValue();

			let func = function() {
				let newScope = {};
				// Add arrow function arguments to scope
				for(let i = 0; i < arguments.length; i++) {
					let param = expression.params[i];
					if(param) {
						newScope[param.name] = arguments[i];
					}
				}
				// Provide new scope to next evaluation
				return self._evaluate(valid.body, expressionSet, _.concat(scopes, newScope));
			};
			return func;
		},
		'CallExpression' : function(expression, expressionSet, scopes) {
			var check = _.validateObject(expression, {
				callee: 'isObject',
				arguments: [['isObject']]
			}, t('Could not evaluate CallExpression.'), self._validityHandler);
			if(!check.isValid()) return check.createError();
			var valid = check.getValue();

			var func = undefined;
			// Evaluate expression to get function.
			try {
				func = self._evaluate(valid.callee, expressionSet, scopes);
			}
			catch(err) {
				throw new _.Error({
					message: t('Could not call function.'),
					data: valid.callee,
					originalError: err
				});
			}
			if(!_.isFunction(func)) {
				throw new _.Error({
					message: t("'{{calleeName}}' is not a function.", {calleeName: valid.callee.name}),
					data: valid.callee
				});
			}

			// Call found function
			if(_.isFunction(func)) {
				if(func === self._publicVars.evaluate) { // special case - `evaluate` changes expressionSet
					expressionSet = self._config.evaluateFunctionExpressionSet;
				}
				var argsEvaled = [];
				_.forEach(valid.arguments, function(arg, i) {
					var evaledArg;
					try {
						evaledArg = self._evaluate(arg, expressionSet, scopes);
					}
					catch(err) {
						throw new _.Error({
							message: t("Invalid argument ({{arg}})) for function '{{calleeName}}'.", {arg: i+1, calleeName: valid.callee.name}),
							originalError: err,
							data: arg
						});
					}
					argsEvaled.push(evaledArg);
				});
				return func.apply({}, argsEvaled);
			}

			// No available function found
			throw new _.Error(t("'{{calleeName}}' is not a function.", {calleeName: valid.callee.name}));
		},
		'BinaryExpression' : BinaryExpression,
		'LogicalExpression': BinaryExpression,
		'ExpressionStatement': function(statement, expressionSet, scopes) {
			var check = _.validateObject(statement, {
				expression: 'isObject'
			}, t('Could not evaluate ExpressionStatement.'), self._validityHandler);
			if(!check.isValid()) return;
			var valid = check.getValue();

			return self._evaluate(valid.expression, expressionSet, scopes);
		},
		'VariableDeclaration': function(statement, expressionSet, scopes) {
			var check = _.validateObject(statement, {
				declarations: [[{
					id: [{type: [self._validateType.bind(self)]}],
					init: 'isObject'
				}]]
			}, t('Could not evaluate VariableDeclaration.'), self._validityHandler);
			if(!check.isValid()) return;
			var valid = check.getValue();

			var values = {};
			let current = undefined;
			try {
				_.forEach(valid.declarations, function(declaration) {
					current = declaration;
					if(declaration.id.type !== 'Identifier') {
						throw new _.Error(t('Unexpected type in VariableDeclaration.'));
					}

					// Keep track of declared variables
					var value = self._evaluate(declaration.init, expressionSet, scopes);
					values[declaration.id.name] = value;

					// Add variable to current scope
					scopes[scopes.length-1][declaration.id.name] = value;
				});
			}
			catch(err) {
				throw new _.Error({
					message: t('Invalid VariableDeclaration.'),
					data: current,
					originalError: err
				});
			}

			return values;
		},
		'ObjectExpression': function(statement, expressionSet, scopes) {
			var check = _.validateObject(statement, {
				properties: [[{
					key: [{
						type: [function(v) { return v === 'Literal' || v === 'Identifier';}]
					}],
					value: ['isObject']
				}]]
			}, t('Could not evaluate ObjectExpression.'), self._validityHandler);
			if(!check.isValid()) return;
			var valid = check.getValue();

			var obj = {};
			var key = null;
			try {
				_.find(valid.properties, function(property) {
					if(property.key.type === 'Literal') {
						key = property.key.value;
					} else {
						key = property.key.name;
					}
					obj[key] = self._evaluate(property.value, expressionSet, scopes);
				});
			}
			catch(err) {
				throw new _.Error(t("Invalid object value for property '{{key}}'.", {key}), err);
			}

			return obj;
		},
		'ArrayExpression': function(statement, expressionSet, scopes) {
			var check = _.validateObject(statement, {
				elements: ['isArray']
			}, t('Could not evaluate ArrayExpression.'), self._validityHandler);
			if(!check.isValid()) return;
			var valid = check.getValue();

			var arr = [];
			let current = undefined;
			try {
				_.find(valid.elements, function(element, i) {
					current = element;
					var value = self._evaluate(element, expressionSet, scopes);
					arr.push(value);
				});
			}
			catch(err) {
				throw new _.Error({
					message: t('Invalid array element.'),
					data: current,
					originalError: err
				});
			}
			return arr;
		},
		'MemberExpression': function(statement, expressionSet, scopes) {
			var check = _.validateObject(statement, {
				object: ['isObject'],
				property: ['isObject']
			}, t('Could not evaluate MemberExpression.'), self._validityHandler);
			if(!check.isValid()) return;
			var valid = check.getValue();

			try {
				var object = self._evaluate(valid.object, expressionSet, scopes);
				var property;
				if(valid.property.type === 'Identifier') {
					property = valid.property.name; // if we evaluate the identifier we get 'undefined' error.
				} else {
					property = self._evaluate(valid.property, expressionSet, scopes);
				}
				if(!_.has(object, property) && !_.has(_.get(object, '__proto__'), property)) {
					if(self.getConfig('failOnInvalidProperty') !== false) {
						throw new _.Error(t('Could not evaluate MemberExpression.'), new _.Error(t("Cannot get property '{{property}}' of a non-object.", {property})));
					} else {
						return undefined;
					}
				}
			}
			catch(err) {
				throw new _.Error(t('Could not evaluate MemberExpression.'), err);
			}

			var member = object[property];
			if(_.isFunction(member)) {
				member = member.bind(object);
			}
			return member;
		},
		'ConditionalExpression': function(statement, expressionSet, scopes) {
			var check = _.validateObject(statement, {
				test: ['isObject'],
				consequent: ['isObject'],
				alternate: ['isObject']
			}, t('Could not evaluate ConditionalExpression.'), self._validityHandler);
			if(!check.isValid()) return;
			var valid = check.getValue();

			var result = undefined;
			try {
				var test = self._evaluate(valid.test, expressionSet, scopes);
				var value = test ? valid.consequent : valid.alternate;
				result = self._evaluate(value, expressionSet, scopes);
			} catch(err) {
				throw new _.Error(t('Could not evaluate ConditionalExpression.'), err);
			}
			return result;
		},
		'UnaryExpression': function(statement, expressionSet, scopes) {
			var check = _.validateObject(statement, {
				operator: ['isString'],
				argument: ['isObject'],
				prefix: ['isBoolean']
			}, t('Could not evaluate UnaryExpression.'), self._validityHandler);
			if(!check.isValid()) return;
			var valid = check.getValue();

			try {
				// Evaluate argument
				var value = self._evaluate(valid.argument, expressionSet, scopes);

				// Call operator
				return self._unaryOperators[valid.operator](value);
			}
			catch (err) {
				throw new _.Error(t('Could not evaluate UnaryExpression.'), err);
			}
		}
	};
};

CodeEvaluator.replaceCodePlaceholders = function(code, map) {
	if(!_.isObject(map)) {
		return code;
	}

	for(var placeholder in map) {
		var replacement = map[placeholder];
		code = code.split(placeholder).join(replacement); // this replaces all occurrences
	}

	return code;
};

CodeEvaluator.prototype._globalContext = null;
CodeEvaluator.prototype._operators = null;

CodeEvaluator.prototype.matchQuickEvaluationPattern = function(code) {
	if(!this._config.quickEvaluationPattern) return null;

	const match = this._config.quickEvaluationPattern.exec(code);
	if(!match) return null;

	return match[1];
};

CodeEvaluator.prototype._validateType = function(type, expressionSet) {
	var valid = new _.Validity('type', type, true);

	if(_.isPlainObject(expressionSet) && !(type in expressionSet)) {
		valid.setValid(false);
		valid.setMessage(t('Expression type not enabled.'));
	} else if(!(type in this._processors)) {
		valid.setValid(false);
		valid.setMessage(t('Cannot handle expression type.'));
	}
	return valid;
};

CodeEvaluator.prototype.setConfig = function(config) {
	var self = this;
	var check = _.validate('Config', {
		config: [config, 'isObject']
	}, t('Could not set config.'));
	if(!check.isValid()) return false;

	_.forEach(config, function(value, key) {
		self._config[key] = value;
	});
};

CodeEvaluator.prototype.getConfig = function(key) {
	return this._config[key];
};

/**
 * Set functions available in the sandbox.
 * @param {object} functions	Object with key-values pairs of names and corresponding functions.
 * @returns {boolean}
 */
CodeEvaluator.prototype.setPublics = function(functions) {
	var check = _.validate({
		functions: [functions, 'isObject']
	}, t('Could not set functions.'));
	if(!check.isValid()) return false;
	var valid = check.getValue();

	for(var i in valid.functions) {
		this.setPublic(i, valid.functions[i]);
	}
};

/**
 * Set a function available in the sandbox.
 * @param {string} name		The name of the function.
 * @param {function} func	The function.
 * @returns {boolean}
 */
CodeEvaluator.prototype.setPublic = function(name, value) {
	var check = _.validate({
		name: [name, 'isString'],
		value: [value, ! _.isNil(value), t('Invalid value.')]
	}, t("Could not set public '{{name}}.'", {name}));

	if(! check.isValid()) return false;
	var valid = check.getValue();

	this._publicVars[valid.name] = valid.value;
};

/**
 * Set an advanced function available in the sandbox. The CodeEvaluator and current scopes will be prepended to the arguments.
 * @param {string} name		The name of the function.
 * @param {function} func	The function.
 * @returns {boolean}
 */
CodeEvaluator.prototype.setAdvancedFunction = function(name, func) {
	const check = _.validate({
		name: [name, 'isString'],
		func: [func, _.isFunction(func) || !_.def(func), t('Invalid function.')]
	}, t("Could not set function '{{name}}.'", {name}));
	if(!check.isValid()) return false;
	const valid = check.getValue();

	if(_.isNil(func)) {
		delete this._advancedFunctions[valid.name];
	} else {
		this._advancedFunctions[valid.name] = valid.func;
	}
};

/**
 * Rewrites a code expression (string) containing map characters ([#]) to a code string containing calls to `map` and
 * `flatten`. Returns the unmodified expression if no map characters were found.
 * @param {string} expression	The code expression to rewrite.
 * @param {boolean} [flatten]	Set to `true` to add `flatten` to the `map` result.
 * @returns {string}
 */
CodeEvaluator.prototype.generateCodeFromArrayExpression = function(expression, flatten) {
	flatten = flatten === true;
	if (!_.isString(expression) || (! _.includes(expression, '[#].'))) {
		return expression;
	}

	var elements = expression.match(/([.\s\S]*?)([\w+_\.\[\]#]+)\[\#\]\.([\w+_\.\[\]]*)([.\s\S]*)/i);

	if (!elements || elements.length < 4) {
		return expression;
	}

	// Try to convert the third matched part
	var pluckWhat = this.generateCodeFromArrayExpression(elements[3]);

	// If nothing changed it is an object path
	// Otherwise the expression is wrong
	if (pluckWhat !== elements[3]) {
		return expression;
	}

	// Construct the expression
	var flattenStr = flatten ? 'flatten(' : '';
	var flattenStrEnd = flatten ? ')' : '';
	return elements[1] + flattenStr + 'map(' + this.generateCodeFromArrayExpression(elements[2], true) + ', \'' + pluckWhat + '\')' +
		flattenStrEnd + this.generateCodeFromArrayExpression(elements[4]);
};

/**
 * Get keys available from global context.
 * @returns {*}
 */
CodeEvaluator.prototype.getGlobalContextKeys = function() {
	return Object.keys(this._globalContext);
};

/**
 * Find the most recent scope in which the current identifier is available.
 * @param scopes		Array of scopes.
 * @param identifier	Identifier to find.
 * @return {*}
 */
CodeEvaluator.prototype.findScopeOfIdentifier = function(scopes, identifier) {
	return _.findLast(scopes, scope => identifier in scope);
};

/**
 * Evaluates an expression object from Esprima.
 * @param {object} statement
 * @param {array} expressionSet
 * @param {object} scopes			List of variable scopes valid for the evaluation.
 * 									Later scopes override earlier scopes.
 * @returns {*}
 * @private
 */
CodeEvaluator.prototype._evaluate = function(statement, expressionSet, scopes = {}) {
	var self = this;

	var check = _.validateObject(statement, {
		type: [function (type) {
			return self._validateType(type, expressionSet);
		}]
	}, t('Could not evaluate statement.'), false);
	if(!check.isValid()) {
		var err = check.createError();
		err.data = statement;
		throw err;
	}
	var valid = check.getValue();

	let evaluated = this._processors[valid.type](statement, expressionSet, scopes);
	if(this._restricted.indexOf(evaluated) >= 0) {
		return undefined;
	}

	return evaluated;
};

/**
 * Evaluates a code expression in a safe, restricted sandbox environment.
 *
 * The result of an expression can be retrieved by declaring it as a variable, e.g:
 * var result = evaluator.evaluate("var x = 1 + 1");
 * console.log(result.x); // will output 2
 *
 * Variables from outside can be added to the sandbox by including them in the context object.
 * Members of the context can be called by their name, e.g.:
 * var result = evaluator.evaluate('var x = 1 + foo;', {foo: 1});
 * console.log(result.x); // will output 2
 *
 * Functions can only be called if they were defined using setFunction(...) or setFunctions(...).
 *
 * @param expression
 * @param context
 * @param [options]
 * @returns {{}}
 */
CodeEvaluator.prototype.evaluate = function(expression, context, options) {
	var self = this;
	let scopes = [{...this._globalContext, ...context}];

	// Rewrite mapping expressions ([#]).
	if(_.get(options, 'arrayExpressions') === true) {
		expression = this.generateCodeFromArrayExpression(expression);
	}

	let expressionSet = _.get(options, 'expressionSet');
	let expressionSets = self.getConfig('expressionSets');
	if(expressionSet === undefined) expressionSet = Object.keys(expressionSets)[0];
	if(_.isString(expressionSet)) expressionSet = _.get(expressionSets, expressionSet);

	if(_.isPlainObject(expressionSet) && !Object.keys(expressionSet).length) {
		// we already know we won't be able to evaluate it without valid expressions; let's save some time parsing
		throw new Error(t('Could not evaluate expression: {{expression}}', {expression}));
	}

	// Parse the expression using Esprima
	try {
		var syntax = esprima.parse(expression);
	} catch (err) {
		throw new _.Error({
			message: t('Could not evaluate expression: {{expression}}', {expression}),
			originalError: new _.Error({
				message: err.description,
				data: {
					error: err,
					expression: expression
				}
			})
		});
	}
	var check = _.validateObject(syntax, {
		type: [function(val) { return val === 'Program';}, t("Can only evaluate type 'Program'.")],
		body: 'isArray'
	}, t('Could not evaluate expression: {{expression}}', {expression}), false);
	if(!check.isValid()) {
		var error = check.createError();
		error.data = expression;
		throw error;
	}
	var valid = check.getValue();

	// Go trough Esprima result
	_.find(valid.body, function(statement) {
		try {
			self._evaluate(statement, expressionSet, scopes);
		}
		catch (err) {
			let error = err.getDeepestError();
			error.data = {
				topError: err
			};
			throw error;
		}
	});

	// Return the root scope
	return scopes[0];
};

module.exports = CodeEvaluator;
