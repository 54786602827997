var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.chartComponent, {
    ref: "chartComponent",
    tag: "component",
    staticClass: "chartjs-component",
    attrs: { "chart-data": _vm.chartData, options: _vm.chartOptions },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }