const _ = require('core/src/utils/legacy');
const Function = require('core/src/function');
const View = require('core/src/functions/view');

const FormView = Function.extend(View, "FormView");

FormView.prototype.onInit = function() {
	this.setParameters({
		schema: null,
		uiSchema: null,
		data: {},
		actions: {}
	});

	this.setModelValidation({
		schema: ['isObject', {default: null, warn: _.def}],
		uiSchema: ['isObject', {default: null, warn: _.def}],
		data: ['isObject', {default: {}, warn: _.def}],
		actions: ['isObject', {default: {}, warn: _.def}],
		changeDelay: ['isNumber', {default: 500, warn: _.def}]
	});
};

module.exports = FormView;
