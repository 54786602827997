const _ = require('lodash');

let ensureTrailing = (trailingStr, sourceStr) => {
	if (!_.isString(trailingStr)
		|| !_.isString(sourceStr)
	) {
		throw new Error('FilePath.ensureTrailing requires two strings as parameters');
	}

	return _.endsWith(sourceStr, trailingStr) ? sourceStr : sourceStr + trailingStr;
};

let random = (length, prefix) => {
	let result = prefix ? prefix.toString() : '';

	do {
		result += Math.random().toString(36).substring(2);
	} while (result.length < length);

	return result.substring(0, length);
};


// Obfuscate/deobfuscate a string by XORing chars with an array of random bytes
// obfuscate(obfuscate(string)) == string
let defaultObfuscationArray = [182, 197, 161, 227, 144, 170, 185, 251, 186, 152, 187, 193, 232, 166, 220, 207, 212, 180, 187, 167, 159, 212, 230, 242, 217, 206, 150, 185, 245, 195, 228, 212, 177, 226, 191, 226, 129, 183, 242, 178, 155, 221, 187, 254, 213, 185, 165, 199, 239, 177];
let obfuscate = (string, obfuscationArray = defaultObfuscationArray) => {
	return String.fromCharCode(
		..._.map(
			string,
			(char, index) => char.charCodeAt(0) ^ obfuscationArray[index % obfuscationArray.length]
		)
	);
};

module.exports = {
	ensureTrailing,
	random,
	obfuscate,
	deobfuscate: obfuscate
};
