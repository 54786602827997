const Function = require('core/src/function');
const API = Function.extend(Function, 'API');
const Edition = require('core/src/edition').default;

Edition.setBuildFeature(Edition.Feature.FUNCTION_API, false);

API.Event = {
	Out: {
		RESPONSE: 'response',
		ERROR: 'apiError'
	}
};

API.prototype.onInit = function() {
	this.setParameters({
		'$stayAlive': 'session',
		'$method': 'post',
		'$input': undefined,
		'$response': undefined,
		'$error': undefined
	});
};

API.prototype.execute = function(...args) {
	// feature
	const error = Edition.functionNotAvailableError('API');
	this.error(error);
	throw error;
};

API.prototype.onUpdate = function() {
	// feature
};

API.prototype.onExecute = function() {
	// feature
};

module.exports = API;
