const prosemirrorModel = require('prosemirror-model');
const forEach = require('lodash/forEach');
const $ = require('jquery');
const camelToKebap = require('client/libraries/camel-to-kebap');

const schema = new prosemirrorModel.Schema({
	nodes: {
		doc: {
			content: "block+"
		},

		paragraph: {
			content: "inline*",
			group: "block",
			parseDOM: [{tag: "p"}],
			toDOM: function toDOM() {
				return ["p", 0];
			}
		},

		blockquote: {
			content: "block+",
			group: "block",
			parseDOM: [{tag: "blockquote"}],
			toDOM: function toDOM() {
				return ["blockquote", 0];
			}
		},

		horizontal_rule: {
			group: "block",
			parseDOM: [{tag: "hr"}],
			toDOM: function toDOM() {
				return ["div", ["hr"]];
			}
		},

		heading: {
			attrs: {level: {default: 1}},
			content: "inline*",
			group: "block",
			defining: true,
			parseDOM: [{tag: "h1", attrs: {level: 1}},
				{tag: "h2", attrs: {level: 2}},
				{tag: "h3", attrs: {level: 3}},
				{tag: "h4", attrs: {level: 4}},
				{tag: "h5", attrs: {level: 5}},
				{tag: "h6", attrs: {level: 6}}],
			toDOM: function toDOM(node) {
				return ["h" + node.attrs.level, 0];
			}
		},

		code_block: {
			content: "text*",
			group: "block",
			code: true,
			defining: true,
			attrs: {params: {default: ""}},
			parseDOM: [{
				tag: "pre", preserveWhitespace: true, getAttrs: function (node) {
					return ({params: node.getAttribute("data-params")});
				}
			}],
			toDOM: function toDOM(node) {
				return ["pre", node.attrs.params ? {"data-params": node.attrs.params} : {}, ["code", 0]];
			}
		},

		ordered_list: {
			content: "list_item+",
			group: "block",
			attrs: {order: {default: 1}, tight: {default: true}},
			parseDOM: [{
				tag: "ol", getAttrs: function getAttrs(dom) {
					return {
						order: dom.hasAttribute("start") ? +dom.getAttribute("start") : 1,
						tight: dom.hasAttribute("data-tight")
					};
				}
			}],
			toDOM: function toDOM(node) {
				return ["ol", {
					start: node.attrs.order == 1 ? null : node.attrs.order,
					"data-tight": node.attrs.tight ? "true" : null
				}, 0];
			}
		},

		bullet_list: {
			content: "list_item+",
			group: "block",
			attrs: {tight: {default: false}},
			parseDOM: [{
				tag: "ul", getAttrs: function (dom) {
					return ({tight: dom.hasAttribute("data-tight")});
				}
			}],
			toDOM: function toDOM(node) {
				return ["ul", {"data-tight": node.attrs.tight ? "true" : null}, 0];
			}
		},

		list_item: {
			content: "block*",
			defining: true,
			parseDOM: [{tag: "li"}],
			toDOM: function toDOM() {
				return ["li", 0];
			}
		},

		text: {
			group: "inline",
			toDOM: function toDOM(node) {
				return node.text;
			}
		},

		image: {
			inline: true,
			attrs: {
				src: {},
				alt: {default: null},
				title: {default: null}
			},
			group: "inline",
			draggable: true,
			parseDOM: [{
				tag: "img[src]", getAttrs: function getAttrs(dom) {
					return {
						src: dom.getAttribute("src"),
						title: dom.getAttribute("title"),
						alt: dom.getAttribute("alt")
					};
				}
			}],
			toDOM: function toDOM(node) {
				return ["img", node.attrs];
			}
		},

		hard_break: {
			inline: true,
			group: "inline",
			selectable: false,
			parseDOM: [{tag: "br"}],
			toDOM: function toDOM() {
				return ["br"];
			}
		},

		component: {
			inline: true,
			group: 'inline',
			draggable: true,
			attrs: {tag: {default: 'div'}, props: {default: {}}},
			parseDOM: [{tag: "ia-component", getAttrs: function getAttrs(dom) {
				let properties = $(dom).attr();
				return {
					tag: 'ia-component',
					props: properties
				};
			}}],
			toDOM: function toDOM(node) {
				let element = document.createElement(node.attrs.tag);
				for(let p in node.attrs.props) {
					element.setAttribute(p, node.attrs.props[p]);
				}
				return element;
			}
		},

		// Since jQueryComponents replace the component tag with a div, we need another schema that parses the loaded component
		component_loaded: {
			inline: true,
			group: 'inline',
			draggable: true,
			attrs: {tag: {default: 'div'}, props: {default: {}}, content: {default: undefined}},
			parseDOM: [{tag: "div", getAttrs: function getAttrs(dom) {
				let properties = $(dom).data();
				let type = properties['_type'];
				for(var key in properties) {
					var value = properties[key];
					delete properties[key];
					properties[camelToKebap(key)] = value;
				}
				delete properties['_type'];
				delete properties['contenteditable'];
				delete properties['draggable'];

				return {
					tag: type,
					props: properties,
					content: dom
				};
			}}],
			toDOM: function toDOM(node) {
				let $element = $(node.attrs.content).clone(true);
				$element.trigger('component-loaded'); // allow components to do their logic on the clone
				return $element[0];
			}
		}
	},

	marks: {
		em: {
			parseDOM: [{tag: "i"}, {tag: "em"},
				{
					style: "font-style", getAttrs: function (value) {
						return value === "italic" && null;
					}
				}],
			toDOM: function toDOM() {
				return ["em"];
			}
		},

		strong: {
			parseDOM: [{tag: "b"}, {tag: "strong"},
				{
					style: "font-weight", getAttrs: function (value) {
						return /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null;
					}
				}],
			toDOM: function toDOM() {
				return ["strong"];
			}
		},

		link: {
			attrs: {
				href: {},
				title: {default: null},
				target: {default: null}
			},
			inclusive: false,
			parseDOM: [{
				tag: "a[href]", getAttrs: function getAttrs(dom) {
					return {href: dom.getAttribute("href"), title: dom.getAttribute("title"), target: dom.getAttribute("target") };
				}
			}],
			toDOM: function toDOM(node) {
				return ["a", node.attrs];
			}
		},

		code: {
			parseDOM: [{tag: "code"}],
			toDOM: function toDOM() {
				return ["code"];
			}
		}
	}
});

module.exports = schema;
