const Log = require('utils/src/log');

const log = Log.instance('utils/deferred-promise');

function deferredPromise(promise) {

	promise.done = function(...callbacks) {
		callbacks.map((callback) => this.then(callback).catch(()=>{})); // catch to prevent complaining about uncaught errors

		return this;
	};

	promise.fail = function(...callbacks) {
		callbacks.map((callback) => this.catch(callback));

		return this;
	};

	promise.always = function(...callbacks) {
		callbacks.map((callback) => this.finally(callback));

		return this;
	};

	promise.then(()=>{}).catch((e)=>{
		log.log("DeferredPromise rejected.", e);
	});
	return promise;
}

module.exports = deferredPromise;