const Function = require('core/src/function');
const _ = require('core/src/utils/legacy');
const Language = require('core/src/language').default;
const Edition = require('core/src/edition/index').default;
const ApiClientAbstract = require('core/src/api-client-abstract').default;

const Websocket = Function.extend(Function, 'Websocket');

let socketIoInstance = null;

Edition.setBuildFeature(Edition.Feature.FUNCTION_WEBSOCKET, false);

Websocket.prototype.onInit = function(dependencies) {
	this.language = dependencies.get(Language);
	this.api = dependencies.get(ApiClientAbstract);

	this.setParameters({
		'$broadcast': undefined,
		'$listen': undefined,
		'$data': undefined,
		'$channel': undefined,
		'$message': undefined,
		'#messagesToListen': undefined,
		'#messagesToBroadcast': undefined
	});

	const notRequired = defaultValue => ({default: defaultValue, warn: value => !_.isNil(value)});
	this.setModelValidation({
		broadcast		: ['isBoolean', notRequired(false)],
		listen			: ['isBoolean', notRequired(false)],
		data			: ['isPlainObject', notRequired()],
		channel			: 'isString',
		message			: 'isString',
		messagesToListen: ['isArray', {default: val => _.isString(val) ? [val] : [], warn: val => _.def(val) && !_.isString(val)}],
		messagesToBroadcast: ['isArray', {default: val => _.isString(val) ? [val] : [], warn: val => _.def(val) && !_.isString(val)}],
	});
	if (!socketIoInstance)
		socketIoInstance = this.api.io('/websocket_function');

	this.io = socketIoInstance;
};

Websocket.prototype.execute = function(...args) {
	// feature
	const error = Edition.functionNotAvailableError('Websocket');
	this.error(error);
	throw error;
};

Websocket.prototype.onExecute = async function() {
	// feature
};

module.exports = Websocket;
