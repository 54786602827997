var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
      },
    },
    [
      _vm.icon ? _c("span", { class: _vm.iconClass }) : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }