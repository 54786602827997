const $ = require('jquery');
const _ = require('lodash');

function isjQuery(value) {
	/*
	We cannot check `value instanceof $` because the jquery class Utils uses may be different from the one other
	modules are using. Therefore we check the version property of the jQuery object.
	 */
	return _.isObject(value) && _.isString(value.jquery);
}

function ensureIsJQuery(value) {
	if (!isjQuery(value)) {
		return value;
	}

	return $(value);
}

function isValidJQuerySelection(selection) {
	if ( ! isjQuery(selection)) {
		return false;
	}

	if ( ! selection.length) {
		return false;
	}

	return true;
}

module.exports = {
	isjQuery,
	isValidJQuerySelection,
	ensureIsJQuery
};
