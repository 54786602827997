import _ from "lodash";
import PersonalEdition from "./personal-edition";
import Edition from "./edition";
import { deobfuscate } from 'core/src/utils/string';

export default class G64TEdition extends PersonalEdition {
	static get code() {
		return 'g64t';
	}
	static get name() {
		return Edition.name + ' 64 Team Edition';
	}

	isValidYFilesLicense(license) {
			// "projectname": "Graphileon yFilesView for
		if (_.includes(license, deobfuscate("µÓúÏÚÔùÖ¤ÊüíÆÚ×÷½¶ ¶À³ª±Â´ÖöÝé")) ||
			// Backwards compatibility
			// "projectname": "Graphileon InterActor yFilesView for
			_.includes(license, deobfuscate("µÓúÏÚÔùÖ¤ÊüíÆÚ×÷½¶ ¶ð·¦ðËóôò±ÞÐÀ°Ï×Ù·"))
		){
			return true;
		}
		return false;
	}

	defineFeatures() {
		return {
			...super.defineFeatures(),
			[Edition.Feature.USER_COUNT]: 5,
			[Edition.Feature.DASHBOARD_COUNT]: 5,
			[Edition.Feature.STORES_COUNT]: null,
			[Edition.Feature.GRAPHILEONS_COUNT]: 64,
			[Edition.Feature.EXPIRES]: true,
			[Edition.Feature.YFILES_LICENSE_AUTO]: false,
			[Edition.Feature.FUNCTION_ELASTICSEARCH]: true,
			[Edition.Feature.MULTI_LINGUAL]: true,
			[Edition.Feature.WEBSOCKET]: true,
			[Edition.Feature.FUNCTION_WEBSOCKET]: true
		};
	}
}
