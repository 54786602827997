
	export default {
		name: "vue-renderer-component",

		data: function() {
			return {
				// To be set by VueRenderer
				requestUpdate: ()=>{}
			}
		},
		methods: {
			event(type, event) {
				this.$emit('event', {
					type: type,
					data: event
				})
			},
			trigger(event) {
				this.$emit('trigger', event);
			},
			openContextMenu(menu, target, x, y) {
				this.$emit('context-menu', { menu, target, x, y });
			},
			onResize() {
				// not implemented by default
			},
			close(event) {
				this.$emit('close', event);
			},
			t(string){
				return this.$graphileon.translate(string);
			}
		}
	}
