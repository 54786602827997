require('jquery-ui/themes/base/base.css');
require('jquery-ui/themes/base/tabs.css');

const ViewRenderer = require('../view-renderer');
const StylesManager = require('../network-styles-manager');
const CodeEvaluator = require('core/src/code-evaluator');
const GraphStyles = require('core/src/graph/graph-styles').default;
const Registry = require('core/src/registry').default;
const Log = require('utils/src/log');
const {isGraph, GraphRegistry, isGraphListener, GraphListenerRegistry} = require("core/src/graph/i-graph");

const log = Log.instance("client/networkstylesview");

const _ = require('core/src/utils/legacy');

require('../css/network-styles-view.css');

/* Inheritance and constructor */

// TODO: Make NetworkStylesViewRenderer work with arbitrary Styles nodes

const NetworkStylesViewRenderer = function(dependencies) {
	ViewRenderer.call(this, dependencies);

	this.dependencies = dependencies;
	this.codeEvaluator = dependencies.get(CodeEvaluator);
	this.styles = dependencies.get(GraphStyles);
	this.registry = dependencies.get(Registry);

	this.registry.require(GraphRegistry, isGraph);

	this.registryWatcher = this.onGraphUpdated.bind(this);
	this.registry.watch(GraphRegistry, this.registryWatcher);

	this.originalStyleDefinitions = this.copyCurrentStyleDefinitions();
};
NetworkStylesViewRenderer.viewType = 'NetworkStylesView';
NetworkStylesViewRenderer.prototype = Object.create(ViewRenderer.prototype);

NetworkStylesViewRenderer.prototype.doRender = function(data) {

	const container = $('<div class="network-styles-view h-100">');

	const self = this;
	this.stylesManager = new StylesManager(this.dependencies, {
		container: container,
		styles: GraphStyles.clone(this.styles),
		hooks: {
			onNodeStyleChanged: function() {
				self.updateGraphsStyles();
				self.stylesManager.setChanged(true);
			},
			onRelationStyleChanged: function() {
				self.updateGraphsStyles();
				self.stylesManager.setChanged(true);
			},
			onNodeStyleOrderChanged: function() {
				self.updateGraphsStyles();
				self.stylesManager.setChanged(true);
			},
			onRelationStyleOrderChanged: function() {
				self.updateGraphsStyles();
				self.stylesManager.setChanged(true);
			},
			onNodeStyleRemoved: function() {
				self.updateGraphsStyles();
				self.stylesManager.setChanged(true);
			},
			onRelationStyleRemoved: function() {
				self.updateGraphsStyles();
				self.stylesManager.setChanged(true);
			},
			onSaveStyles: async function() {
				try {
					await self.styles.saveAsUserStyles();
					self.originalStyleDefinitions = self.copyCurrentStyleDefinitions();
					self.stylesManager.setChanged(false);
				} catch (e) {
					log.error("Could not save user styles.", e);
				}
			},
			onChanged: function() {
				self.stylesManager.setChanged(true);
			}
		},
		currentNodeFenotype: data['node'],
		currentRelationFenotype: data['relation']
	});

	return container;
};

NetworkStylesViewRenderer.prototype.copyCurrentStyleDefinitions = function() {
	return _.cloneDeep(this.styles.getDefinitions());
};

NetworkStylesViewRenderer.prototype.onGraphUpdated = function() {
	this.updateGraphsStyles();
};

NetworkStylesViewRenderer.prototype.updateGeneratedStyles = function() {
	const graphs = this.registry.get(GraphRegistry);

	let allNodes = [];
	let allRelations = [];

	_.forEach(graphs, function (graph) {
		allNodes = _.union(allNodes, graph.getNodes());
		allRelations = _.union(allRelations, graph.getRelations());
	});

	this.stylesManager.setNodeFenotypes(allNodes);
	this.stylesManager.setRelationFenotypes(allRelations);
};

NetworkStylesViewRenderer.prototype.updateGraphsStyles = function() {
	// Update global styles
	this.styles.loadFromGraphStyles(this.stylesManager.styles);

	this.updateGraphInstances();

	// Reload legend
	this.updateGeneratedStyles();
};

NetworkStylesViewRenderer.prototype.updateGraphInstances = function() {
	const graphs = this.registry.get(GraphRegistry);
	_.forEach(graphs, graph => {
		graph.updateStyles();
	});
};

NetworkStylesViewRenderer.prototype.onReady = function() {
	this.updateGeneratedStyles();
};

NetworkStylesViewRenderer.prototype.onClose = function() {
	// Reset styles
	if(this.stylesManager.isChanged()) {
		this.styles.setDefinitions(undefined, this.originalStyleDefinitions);
		this.updateGraphInstances();
	}

	this.registry.unwatch(GraphRegistry, this.registryWatcher);
};

module.exports = NetworkStylesViewRenderer;
