const _ = require('lodash');
const Function = require('core/src/function');
const Language = require('core/src/language').default;

const PDFMake = require('pdfmake/build/pdfmake.js');
const PDFFonts = require('pdfmake/build/vfs_fonts.js');
PDFMake.vfs = PDFFonts.pdfMake.vfs;

const PDFFile = Function.extend(Function, 'PDFFile');

PDFFile.prototype.onInit = function (dependencies) {
	this.language = dependencies.get(Language);
	this.setParameters({
		'document': {},
		'$filename': 'document.pdf',
		'$download': true
	});
};

PDFFile.prototype.onExecute = function(model) {
	return new Promise((resolve, reject) => {	
		let documentSpecs = _.cloneDeep(model.document);
		let doc;

		try {
			doc = PDFMake.createPdf(documentSpecs);
		}
		catch(ex) {
			reject(ex);
			return;
		}

		if (model.download) {
			doc.download(model.filename);
			resolve();
			return;
		}

		try{
			doc.getBase64((data) => {
				this.executeTriggers({
					type: 'success',
					data: data,
					filename: model.filename
				})
				resolve();
			})
		}
		catch(ex) {
			reject(ex);
			return;
		}
	})
};

module.exports = PDFFile;