
	import VueViewComponent from "client/src/vue-view/vue-view-component.vue";
	import ContextMenu from 'client/src/vue-view/context-menu.vue';
	import ContextMenuItem from "client/src/vue-view/context-menu-item.vue";
	import ViewToolbar from "client/src/vue-view/view-toolbar.vue";
	import ViewCommon from "client/src/vue-view/view-common.vue";
	import ViewToolbarButton from "client/src/vue-view/view-toolbar-button.vue";

	export default {
		name: "vue-view-view",
		mixins: [VueViewComponent],
		components: {ViewToolbarButton, ViewCommon, ViewToolbar, ContextMenuItem, ContextMenu},
		data(){
			return {
				model: {
					foo: {
						bar: undefined
					}
				},
			}
		},
		computed: {
			validFoo() {
				return this.check(this.model.foo, 'object', 'foo');
			}
		},
		methods: {
			getParameters() {
				return {
					foo: undefined
				}
			},
			onItemClick(value) {
				console.log(value);
			}
		},
		created() {
			this.defineContextMenu('foo', 'fooMenu');
			this.defineContextMenu('bar', 'barMenu');
		}
	}
