import { render, staticRenderFns } from "./neighbours-view.vue?vue&type=template&id=817c23ce&scoped=true&"
import script from "./neighbours-view.vue?vue&type=script&lang=js&"
export * from "./neighbours-view.vue?vue&type=script&lang=js&"
import style0 from "./neighbours-view.vue?vue&type=style&index=0&id=817c23ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "817c23ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/graphileon/graphileon/styles/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('817c23ce')) {
      api.createRecord('817c23ce', component.options)
    } else {
      api.reload('817c23ce', component.options)
    }
    module.hot.accept("./neighbours-view.vue?vue&type=template&id=817c23ce&scoped=true&", function () {
      api.rerender('817c23ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/renderers/neighbours-view-renderer/neighbours-view.vue"
export default component.exports