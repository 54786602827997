'use strict';

const ImageMapView = require('core/src/functions/views/image-map-view');
const VueRenderer = require('../vue-renderer');
const ImageMap = require('./image-map.vue').default;
const IGraphileon = require('core/src/i-graphileon').default;
require('client/libraries/jquery.maphilight.min');

const ImageMapRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
};
ImageMapRenderer.viewType = 'ImageMapView';
ImageMapRenderer.prototype = Object.create(VueRenderer.prototype);

ImageMapRenderer.prototype.getVueComponent = function() {
	return ImageMap;
};
ImageMapRenderer.prototype.initVueComponent = function(component) {
	const self = this;
	this.setComponentProps({
		contextMenus: this.getContextMenuData()
	});
	component.$on('areaClicked', (data) => {
		self.trigger({type: 'areaClicked', data});
	});
	component.$on('groupEnabled', (data) => {
		self.trigger({type: 'groupEnabled', data});
	});
	component.$on('groupDisabled', (data) => {
		self.trigger({type: 'groupDisabled', data});
	});
	component.$on('areaRightClicked', (data) => {
		self.trigger({type: 'areaRightClicked', data});
		self.openContextMenu('area', data, data.x, data.y);
	});
	component.$on('imageClicked', (data) => {
		self.trigger({type: 'imageClicked', data});
	});
	component.$on('imageRightClicked', (data) => {
		self.trigger({type: 'imageRightClicked', data});
		self.openContextMenu('image', data, data.x, data.y);
	});
};

module.exports = ImageMapRenderer;