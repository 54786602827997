const Function = require('core/src/function');
const NetworkView = require('core/src/functions/views/network-view');
const IAPI = require('core/src/api-client-abstract').default;

const _ = require('core/src/utils/legacy');

const LoadDiagram = Function.extend(Function, 'LoadDiagram');

LoadDiagram.prototype.onInit = function(dependencies) {
	this.api = dependencies.get(IAPI);

	this.setParameters({
		'$id': '<required>',
		'$store': undefined
	});

	var notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		'id'	: 'isStringOrNumber',
		'store'	: ['isString', notRequired]
	});
};

LoadDiagram.parseV2Response = function(response) {
	var diagram = response.properties;

	var result = {
		nodes: diagram.nodes,
		relations: diagram.relations,
		name: diagram.name,
		filters: _.fromJSON(diagram.filters),
		meta: {
			store: _.get(response, 'store')
		}
	};

	return result;
};

LoadDiagram.parseV1Response = function(response) {
	var version = _.get(response, 'result.table[0].version');
	var filters = LoadDiagram._convertFilterStatuses(_.fromJSON(_.get(response, 'result.table[0].filters')), version);
	var data = {
		nodes	: _.get(response, 'result.processed.nodes'),
		relations	: _.get(response, 'result.processed.relations'),
		meta	: {
			store: _.get(response, 'request.store')
		},
		filters	: filters
	};
	return data;
};

LoadDiagram.prototype.onExecute = async function(input) {
	var self = this;

	var response = await this.api.loadDiagram(_.get(input, 'id'), _.get(input, 'store'));

	var data;
	if (_.get(response, 'properties.version') >= 2) {
		data = LoadDiagram.parseV2Response(response);
	} else {
		data = LoadDiagram.parseV1Response(response);
	}

	var event = data;
	event.type = 'success';
	self.executeTriggers(event);
	return true;
};

LoadDiagram._convertFilterStatuses = function(filters, diagramVersion) {
	if(!_.isObject(filters)) {
		return undefined;
	}

	var Status = NetworkView.FilterStatus;

	var conversions = {
		'1.0': function(status, filter) {
			if(filter === 'autoFilters') { return status; }
			switch(status) {
				case 0: return Status.SHOW; break;
				case 1: return Status.HIDE; break;
				default: return Status.INACTIVE; break;
			}
		}
	};
	// No version defined, assume first version.
	if(!_.def(diagramVersion)) {
		diagramVersion = Object.keys(conversions)[0];
	}
	// No conversion found, don't apply conversion
	if(!(diagramVersion in conversions)) {
		return filters;
	}

	var convert = conversions[diagramVersion];
	for(var key in filters) {
		filters[key].status = convert(filters[key].status, filters[key].filter);
	}
	return filters;
};

module.exports = LoadDiagram;
