const log = require('core/src/log').instance("core/scr/api");
const Cache = require('core/src/cache').default;

const _ = require('lodash');
const {isPlainObject, extend, cloneDeep, get, now, find, debounce} = _;
const {def} = require('utils/src/validation');
const {waitForAll, promise} = require('utils/src/execution');
const {isValidId} = require('core/src/utils');
const Error = require('utils/src/error');
const EventInterface = require('core/src/event-interface');
const deferredPromise = require('core/src/utils/deferred-promise');

function notImplemented(method) {
	throw new Error(`APIClient method ${method} not implemented.`);
}

const NodeEndpoints = {
	IA_Dashboard: 'dashboard',
	IA_Diagram: 'diagram',
	IA_Function: 'function',
	IA_License: 'license',
	IA_Permission: 'permission',
	IA_Profile: 'profile',
	IA_Team: 'team',
	IA_Token: 'token',
	IA_UserStyles: 'style',
	IA_User: 'user',
};

const RelationEndpoints = {
	TRIGGER: 'trigger'
};

export default class APIClientAbstract {
	constructor(dependencies, config) {
		this.dependencies = dependencies;

		this.config = {
			server: '',
			caching: false,
			requestTimeoutMs: 60000,
			loadingStateDelay: 500
		};

		if (isPlainObject(config)) {
			extend(this.config, config);
		}

		this.cachedFunctions = {};
		this.token = null;
		this._requestsLoading = 0;

		this._eventInterface = new EventInterface();
		this.fire = this._eventInterface.getFireMethod();
		this.on = this._eventInterface.getOnMethod();

		// Small delay after starting loading to consider it a loading state
		this.fireLoadingStartedEvent = debounce(() => {
			if(this._requestsLoading > 0) {
				this.fire(APIClientAbstract.Event.LOADING_STARTED);
			}
		}, this.config.loadingStateDelay);
	}

	/*
	OVERRIDE
	 */
	_executeRequest(uri, method, data) {
		notImplemented('executeRequest');
	}
	upload(file) {
		notImplemented('upload');
	}

	/* -- */

	/**
	 * Sends a request (with authentication headers) to the API.
	 * @param {string} uri The endpoint to send the request to.
	 * @param {string} [method] The HTTP method to use. Defaults to GET.
	 * @param {object} [data] The payload data for the endpoint.
	 * @param {} [___] removed
	 * @param {boolean} [trackLoadingState] If false, the request will not count for the loading state of the APIClient.
	 * @returns {Promise}
	 */
	executeRequest(uri, method, data, ___, trackLoadingState = true) {
		if(trackLoadingState) {
			this.loadingRequestStarted();
		}
		const promise = new Promise(async (resolve, reject) => {
			let response;
			try {
				response = await this._executeRequest(uri, method, data);
			} catch(errorResponse) {
				log.error('API Error: ' + uri, errorResponse);
				this.loadingRequestFinished();
				const err = this.handleError(errorResponse);
				return reject(err);
			}
			if (response.licenseMessage) {
				this.fire(APIClientAbstract.Event.LICENSE_BROKEN, response.licenseMessage.message);
			}
			this.fire(APIClientAbstract.Event.REQUEST_SUCCESS, response);

			if(trackLoadingState) {
				this.loadingRequestFinished();
			}
			resolve(response.data);
		});
		return deferredPromise(promise);
	}

	loadingRequestStarted() {
		if (this._requestsLoading <= 0) {
			this._requestsLoading = 0;
			this.fireLoadingStartedEvent();
		}
		this._requestsLoading++;
	}

	loadingRequestFinished() {
		this._requestsLoading--;
		if(this._requestsLoading === 0) {
			this.fire(APIClientAbstract.Event.LOADING_FINISHED);
		}
		if(this._requestsLoading < 0) {
			log.warn("More requests finished than started.");
			this._requestsLoading = 0;
		}
	}

	setConfig(config) {
		extend(this.config, config);
	}

	getServer() {
		return this.config.server;
	}

	hashRequest(uri, method, data) {
		return "APIClient" + JSON.stringify({uri: uri, method: method, data: data});
	}

	setToken(token) {
		this.token = token;
	}
	setCaching(caching) {
		this.config.caching = caching === true;
	}

	setRequestTimeout(requestTimeoutMs) {
		this.config.requestTimeoutMs = requestTimeoutMs;
	}

	handleError(error) {
		if (error.code === 'SessionExpired' || error.code === 'InvalidToken') {
			this.token = null;
			this.fire(APIClientAbstract.Event.SESSION_EXPIRED, error);
		}
		this.fire(APIClientAbstract.Event.SERVER_ERROR, error);

		return error;
	}

	getLanguageInfo() {
		return this.executeRequest('/language/info', 'GET');
	}

	/**
	 * Get Available Locales from backend
	 */
	getCurrentLanguage() {
		return this.executeRequest('/language/current', 'GET');
	}

	getTranslationDefinitions() {
		return this.executeRequest('/language/definitions', 'GET');
	}

	saveTranslationDefinitions(mutations, originID) {
		return this.executeRequest('/language/definitions-save', 'POST', {mutations, originID});
	}

	reloadTranslations() {
		return this.executeRequest('/language/reload', 'POST');
	}

	/**
	 * Attempts to log in with username and password
	 * @param {string} username
	 * @param {string} password        md5-encoded password
	 * @returns {Promise}    A promise that will resolve with a {user, environment} object.
	 */
	async login(username, password, tfaToken) {
		let promise = new Promise((resolve, reject) => {
			this.executeRequest('/session/login', 'post', {username, password, tfaToken})
				.done(response => {
					let user = response.user;
					user.name = user.properties.name;
					let environment = response.environment;
					this.token = response.token;
					resolve({user: user, environment: environment, token: this.token});
				})
				.fail(error => {
					reject(error);
				});
		});
		return deferredPromise(promise);
	}

	getCurrentUser() {
		return this.executeRequest('/user/current');
	}

	getCurrentProfiles() {
		return this.executeRequest('/profile/forCurrentUser');
	}

	getAuthenticationProviders() {
		return this.executeRequest('/authentication/providers');
	}

	getCurrentAuthenticator() {
		return this.executeRequest('/authentication/current');
	}

	/**
	 * Attempts to log out
	 * @returns {Promise}    A promise that will resolve with a {user, environment} object.
	 */
	async logout () {
		await this.executeRequest('session/logout', 'GET', {});
		this.setToken(null);
		return true;
	}

	/**
	 * Get node by ID
	 * @param id
	 * @param store
	 */
	loadNode(id, store) {
		return this.executeRequest('node/read', 'GET', {id, store});
	}

	/**
	 * Get node by iaName [Obsolete] Most likely, not used anywhere else
	 * @param iaName
	 * @param store
	 * @returns {Promise}        A Deferred object that resolves with the saved node.
	 */
	loadNodeByName(iaName, store) {
		return this.executeRequest('node/read', 'GET', {iaName, store});
	}

	getNodeEndpoint(labels) {
		for(let label of labels) {
			if(label in NodeEndpoints) {
				return NodeEndpoints[label];
			}
		}
		return 'node';
	}

	getRelationEndpoint(type) {
		if (type in RelationEndpoints) {
			return RelationEndpoints[type];
		}
		return 'relation';
	}

	save(entity) {
		let endpoint = '';
		if(_.isArray(entity.labels)) {
			endpoint = this.getNodeEndpoint(entity.labels);
		} else if (_.isString(entity.type)) {
			endpoint = this.getRelationEndpoint(entity.type);
		} else {
			throw new Error("Unknown Entity type.");
		}
		const uri = isValidId(entity.id) ? `/${endpoint}/update` : `/${endpoint}/create`;
		return this.executeRequest(uri, 'POST', entity);
	}

	/**
	 * Saves a node to the database.
	 * @param {object} node            A node object.
	 * @param {string} [store]        The store in which to save the node.
	 * @returns {Promise}        A Deferred object that resolves with the saved node.
	 */
	saveNode(node, store) {
		let uri = isValidId(node.id) ? '/node/update' : '/node/create';

		node.store = store;

		// Send request
		return this.executeRequest(uri, 'POST', node);
	}

	delete(entity) {
		let endpoint = '';
		if(_.isArray(entity.labels)) {
			endpoint = this.getNodeEndpoint(entity.labels);
		} else if (_.isString(entity.type)) {
			endpoint = this.getRelationEndpoint(entity.type);
		} else {
			throw new Error("Unknown Entity type.");
		}
		return this.executeRequest(`/${endpoint}/delete`, 'DELETE', entity);
	}

	/**
	 * Remove a node from the given store.
	 * @param id
	 * @param store
	 * @return {Promise}
	 */
	deleteNode(id, store) {
		return this.executeRequest('node/delete', 'DELETE', {id, store});
	}


	/**
	 * Loads all data in a specific relation, as stored in the database.
	 * @param {int} id 			The ID of the relation.
	 * @param {string} [store] 	Store of the relation.
	 * @returns {Promise} A jQuery Deferred object.
	 */
	loadRelation(id, store) {
		let promise = new Promise((resolve, reject) => {
			this.executeRequest('relation/read', 'GET', {
				id: id,
				store: store
			}).done(relation => {
				if(relation === undefined) { // not found
					resolve();
					return;
				}

				// Backward compatibility
				relation.from = relation.source;
				relation.to = relation.target;

				relation.start = relation.source;
				relation.end = relation.target;

				resolve(relation);
			}).fail(err => {
				reject(err);
			});
		});
		return deferredPromise(promise);
	}


	/**
	 * Saves a relation to the database. If an ID is provided, this will be an update,
	 * otherwise a new relation will be created.
	 * @param {object} relation    The data of the relation.
	 * @param {string} store        The store to save the relation in.
	 * @returns {Promise}
	 */
	saveRelation(relation, store) {
		if (!isPlainObject(relation)) {
			log.error("Parameter should be an object.");
			return null;
		}

		let firstSetKeyValue = function (fromProps, obj) {
			let firstSetKey = find(fromProps, prop => prop in obj);
			return obj[firstSetKey];
		};

		let backendUrl = function (relation) {
			return isValidId(relation.id) ? 'relation/update' : 'relation/create';
		};

		let relData = cloneDeep(relation);

		relData.store = store;
		relData.source = firstSetKeyValue(['source', 'fromID', 'from'], relData);
		relData.target = firstSetKeyValue(['target', 'toID', 'to'], relData);

		// Send request
		return this.executeRequest(backendUrl(relData), 'POST', relData);
	}

	/**
	 * Removes a relation from the database, specified by its ID.
	 * @param {int} id The ID of the relation to remove.
	 * @param {string} store    The store to remove the relation from.
	 * @returns {Promise} A jQuery Deferred object
	 */
	deleteRelation(id, store) {
		return this.executeRequest('relation/delete', 'DELETE', {id, store});
	}

	/**
	 * Get All Users
	 */
	getAllUsers() {
		return this.executeRequest('user', 'GET');
	};

	/**
	 * Get user by ID
	 * @param id
	 */
	loadUser(id) {
		return this.executeRequest('user/read', 'GET', {id});
	}

	/**
	 * Saves a user to the database.
	 * @param {object} user            A user object.
	 * @returns {Promise}        A Deferred object that resolves with the saved node.
	 */
	saveUser(user) {
		let uri = isValidId(user.id) ? '/user/update' : '/user/create';
		return this.executeRequest(uri, 'POST', user);
	};

	/**
	 * Patches a user to the database.
	 * @param {object} user            A user object.
	 * @returns {Promise}        A Deferred object that resolves with the saved node.
	 */
	patchUser(user) {
		return this.executeRequest('/user/patch', 'POST', user);
	};

	/**
	 * Remove a user from the given store.
	 * @param id
	 * @param store
	 * @return {Promise}
	 */
	removeUser(id, store) {
		return this.executeRequest('user/delete', 'DELETE', {id, store});
	}

	registerUser(user, adminApproved){
		return this.executeRequest('/user/register', 'POST', { user, adminApproved });
	}

	acceptRequest(user){
		return this.executeRequest('/user/accept-request', 'POST', user);
	}

	declineRequest(user){
		return this.executeRequest('/user/decline-request', 'POST', user);
	}

	/**
	 * Get All Teams
	 */
	getAllTeams() {
		return this.executeRequest('team', 'GET');
	};

	/**
	 * Get permission by ID
	 * @param permissionId
	 */
	loadPermission(permissionId) {
		return this.executeRequest('permission/read', 'GET', {id: permissionId});
	};

	/**
	 * Update | Create permission
	 * @param permission
	 */
	savePermission(permission) {
		let uri = isValidId(permission.id) ? '/permission/update' : '/permission/create';

		// Send request
		return this.executeRequest(uri, 'POST', permission);
	};

	removePermission(permissionID) {
		return this.executeRequest('permission/delete', 'DELETE', {id: permissionID});
	}

	/**
	 * Get permission Users
	 * @param permissionId
	 */
	getPermissionUsers(permissionId) {
		return this.executeRequest('permission/users', 'GET', {id: permissionId});
	};

	/**
	 * Get permission Users
	 * @param permissionId
	 */
	getPermissionTeams(permissionId) {
		return this.executeRequest('permission/teams', 'GET', {id: permissionId});
	};

	/**
	 * Add User to Permission
	 * @param data {id: permissionId, userId}
	 */
	addUserToPermission(data) {
		return this.executeRequest('permission/add-user', 'POST', data);
	};

	/**
	 * Remove User from Permission
	 * @param data {id: permissionId, userId}
	 */
	removeUserFromPermission(data) {
		return this.executeRequest('permission/remove-user', 'POST', data);
	};

	/**
	 * Add Team to Permission
	 * @param data {id: permissionId, teamId}
	 */
	addTeamToPermission(data) {
		return this.executeRequest('permission/add-team', 'POST', data);
	};

	/**
	 * Remove Team from Permission
	 * @param data {id: permissionId, teamId}
	 */
	removeTeamFromPermission(data) {
		return this.executeRequest('permission/remove-team', 'POST', data);
	};

	/**
	 * Get All Users
	 */
	getAllUsers() {
		return this.executeRequest('user', 'GET');
	};

	/**
	 * Get All Teams
	 */
	getAllTeams() {
		return this.executeRequest('team', 'GET');
	};

	/**
	 * Get team by ID
	 * @param {numeric} team id
	 */
	loadTeam(id) {
		return this.executeRequest('team/read', 'GET', {id});
	};

	/**
	 * Update | Create team
	 * @param {object} team data
	 */
	saveTeam(team) {
		let uri = isValidId(team.id) ? '/team/update' : '/team/create';

		// Send request
		return this.executeRequest(uri, 'POST', team);
	};

	/**
	 * Remove Team
	 * @param {numeric} team id
	 */
	removeTeam(id) {
		return this.executeRequest('team/delete', 'DELETE', {id});
	};

	/**
	 * Get team Users
	 * @param {numeric} team id
	 */
	getTeamUsers(id) {
		return this.executeRequest('team/users', 'GET', {id});
	};

	/**
	 * Get team Users
	 * @param {numeric} team id
	 */
	getTeamTeams(id) {
		return this.executeRequest('team/teams', 'GET', {id});
	};

	/**
	 * Add User to Team
	 * @param {object} data {id: teamId, userId}
	 */
	addUserToTeam(data) {
		return this.executeRequest('team/add-user', 'POST', data);
	};

	/**
	 * Remove User from Team
	 * @param {object} data
	 */
	sendEmail(data) {
		return this.executeRequest('email/send', 'POST', data);
	};

	/**
	 * Send email
	 * @param {object} data {id: teamId, userId}
	 */
	removeUserFromTeam(data) {
		return this.executeRequest('team/remove-user', 'POST', data);
	};

	/**
	 * Add Team to Team
	 * @param {object} data {id: teamId, userId}
	 */
	addTeamToTeam(data) {
		return this.executeRequest('team/add-team', 'POST', data);
	};

	/**
	 * Remove Team from Team
	 * @param {object} data {id: teamId, teamId}
	 */
	removeTeamFromTeam(data) {
		return this.executeRequest('team/remove-team', 'POST', data);
	};

	requestDashboards() {
		return this.executeRequest('user/dashboards');
	}

	requestDashboardById(dashboardID) {
		return this.executeRequest('dashboard/by-id', 'get', {id: dashboardID});
	}

	requestUserShortcuts() {
		return this.executeRequest('user/shortcuts');
	}
	requestDashboardShortcuts(dashboardID) {
		return this.executeRequest('dashboard/shortcuts', 'GET', {id: dashboardID});
	}

	requestUserStartTriggers() {
		return this.executeRequest('user/start-triggers', 'GET');
	}
	requestDashboardStartTriggers(dashboardID) {
		return this.executeRequest('dashboard/start-triggers', 'GET', {id: dashboardID});
	}

	requestBookmarks(dashboardID, bookmarkName) {
		return this.executeRequest('dashboard/bookmarks', 'GET', {id: dashboardID, bookmark: bookmarkName});
	}

	requestUserStyleNode() {
		return this.executeRequest('/style/read', 'GET');
	}

	createUserStyleNode() {
		return this.executeRequest('/style/create', 'POST');
	}

	requestStyleNode(id) {
		return this.executeRequest('/style/read', 'GET', {id});
	}

	saveStyleNode(styleNode) {
		return this.executeRequest('/style/update', 'POST', styleNode);
	}

	/**
	 * Executes any query statement in any language.
	 * @param {object} queryProperties
	 * @param {id} queryProperties.id                	The ID of the Query Function to execute.
	 * @param {string} queryProperties.store        	The store on which to execute the query.
	 * @param {object} queryProperties.params        	The parameters to fill into the query placeholders.
	 * @param {boolean} queryProperties.process    		Whether or not the result should be processed into nodes and relations.
	 * @param {string} queryProperties.cypher        	The cypher statement to execute.
	 * @param {string} queryProperties.sparql        	The sparql statement to execute.
	 * @param {string} queryProperties.gremlin        	The gremlin statement to execute.
	 * @param {string} queryProperties.token        	A single-use token to execute a custom query.
	 * @param {object} queryProperties.options			Additional query options
	 * @param {array} [queryProperties.options.ids]		Params that are considered ids
	 * @param {number} [queryProperties.options.uuids]	How many UUIDs to generate
	 */
	async executeQuery (queryProperties) {
		return this.executeRequest('/query/execute', 'POST', {
			id: queryProperties.id,
			store: queryProperties.store,
			params: queryProperties.params,
			ids: queryProperties.ids,
			process: queryProperties.process,
			cypher: queryProperties.cypher,
			sparql: queryProperties.sparql,
			gremlin: queryProperties.gremlin,
			token: queryProperties.token,
			options: queryProperties.options,
			templateParams: queryProperties.templateParams
		});
	}

	/**
	 * Executes a cypher statement.
	 * @param {object} cypherProperties
	 * @param {id} cypherProperties.id                The ID of the CypherQuery Function to execute.
	 * @param {string} cypherProperties.cypher        The cypher statement to execute.
	 * @param {object} cypherProperties.params        The parameters to fill into the cypher placeholders.
	 * @param {boolean} cypherProperties.process    Whether or not the result should be processed into nodes and relations.
	 * @param {string} cypherProperties.store        The store on which to execute the cypher.
	 */
	async executeCypher (cypherProperties) {
		return this.executeRequest('/cypher/query', 'POST', {
			id: cypherProperties.id,
			q: cypherProperties.cypher,
			params: cypherProperties.params,
			processed: cypherProperties.process,
			store: cypherProperties.store
		});
	}

	/**
	 * Executes a sparql statement.
	 * @param {object}  sparqlProperties
	 * @param {id} 		sparqlProperties.id		The ID of the gremlin Function to execute.
	 * @param {string} 	sparqlProperties.sparql	The gremlin statement to execute.
	 * @param {object} 	sparqlProperties.params	The parameters to fill into the gremlin placeholders.
	 * @param {boolean} sparqlProperties.process	Whether or not the result should be processed into nodes and relations.
	 * @param {string} 	sparqlProperties.store		The store on which to execute the cypher.
	 * @param {string} 	sparqlProperties.graph		The graph is data is asked from.
	 */
	async executeSparql (sparqlProperties) {
		return this.executeRequest('/sparql/query', 'POST', {
			id: sparqlProperties.id,
			q: sparqlProperties.sparql,
			params: sparqlProperties.params,
			processed: sparqlProperties.process,
			store: sparqlProperties.store,
			graph: sparqlProperties.graph
		});
	};

	/**
	 * Executes a gremlin statement.
	 * @param {object}  gremlinProperties
	 * @param {id} 		gremlinProperties.id		The ID of the gremlin Function to execute.
	 * @param {string} 	gremlinProperties.gremlin	The gremlin statement to execute.
	 * @param {object} 	gremlinProperties.params	The parameters to fill into the gremlin placeholders.
	 * @param {boolean} gremlinProperties.process	Whether or not the result should be processed into nodes and relations.
	 * @param {string} 	gremlinProperties.store		The store on which to execute the cypher.
	 */
	async executeGremlin (gremlinProperties) {
		return this.executeRequest('/gremlin/query', 'POST', {
			id: gremlinProperties.id,
			q: gremlinProperties.gremlin,
			params: gremlinProperties.params,
			processed: gremlinProperties.process,
			store: gremlinProperties.store
		});
	};

	/**
	 * Execute an elasticsearch query.
	 * @param {{id,query,params,store}} options
	 * @return {Promise}
	 */
	queryElasticsearch(options) {
		let request = {
			id: get(options, 'id', null),
			query: get(options, 'query', {}),
			params: get(options, 'params', {}),
			connectionString: get(options, 'connectionString', null),
			index: get(options, 'index', null),
			queryType: get(options, 'queryType', null),
		};
		if (options.size !== undefined) {
			request.size = options.size;
		}
		return this.executeRequest('/elasticsearch/query', 'POST', request);
	}

	/**
	 * Fetch all relations between nodes.
	 * @param {array} nodeIds        Array of node IDs
	 * @param {array} [newNodeIds]    Array of node IDs. If provided, only relations connecting at least one of these nodes
	 *                                will be returned.
	 * @param {string} store        The store to find the relations from.
	 */
	autoComplete(nodeIds, newNodeIds, store) {
		return this.executeRequest('/relation/autocomplete', 'POST', {nodeIds, newNodeIds, store});
	}

	/**
	 * Load a Function node by ID or iaName.
	 * @param {string|number} functionId    The Function Node ID.
	 * @return {Promise}
	 */
	loadFunctionById(functionId) {
		return this.loadFunction({id:functionId});
	}
	/**
	 * Load a Function node by ID or iaName.
	 * @param {string|number, string} {id, iaName}  gets object with search property and find the function with given property or id.
	 * @return {Promise}
	 */
	async loadFunction({id, iaName}) {
		if (this.config.caching && ! _.isEmpty(this.cachedFunctions[id])) {
			return this.cachedFunctions[id];
		}

		if (this.config.caching && ! _.isEmpty(this.cachedFunctions[iaName])) {
			return this.cachedFunctions[iaName];
		}

		let response = await this.executeRequest('/function/read-full', 'GET', {id,iaName});

		this.cacheFunction(response);

		return response;
	}

	async loadFunctionsByIds(ids) {
		let toLoadStructs = _.map(
			_.filter(
				ids,
				(id) => ! _.has(this.cachedFunctions, id)
			),
			(id) => ({id: id, meta: {store: 'application'}})
		);

		if (_.isEmpty(toLoadStructs)) {
			return [];
		}

		let functions = await this.executeRequest('/function/read-all-full', 'POST', toLoadStructs);

		_.forEach(functions, (f) => this.cacheFunction(f));

		return functions;
	}

	async loadFunctionsByUuids(uuids) {
		let toLoadStructs = _.map(
			_.filter(
				uuids,
				(uuid) => ! _.has(this.cachedFunctions, uuid)
			),
			(uuid) => ({properties: {uuid: uuid}, meta: {store: 'application'}})
		);

		if (_.isEmpty(toLoadStructs)) {
			return [];
		}

		let functions = await this.executeRequest('/function/read-all-full', 'POST', toLoadStructs);

		_.forEach(functions, (f) => this.cacheFunction(f));

		return functions;
	}

	async loadFunctionsByIaNames(iaNames) {
		let toLoadStructs = _.map(
			_.filter(
				iaNames,
				(iaName) => ! _.has(this.cachedFunctions, iaName)
			),
			(iaName) => ({properties: {iaName: iaName}, meta: {store: 'application'}})
		);

		if (_.isEmpty(toLoadStructs)) {
			return [];
		}

		let functions = await this.executeRequest('/function/read-all-full', 'POST', toLoadStructs);

		_.forEach(functions, (f) => this.cacheFunction(f));

		return functions;
	}

	cacheFunction(response) {
		this.cachedFunctions[response.function.id] = response;

		if (_.get(response, 'function.properties.iaName')) {
			this.cachedFunctions[_.get(response, 'function.properties.iaName')] = response;
		}

		if (_.get(response, 'function.properties.uuid')) {
			this.cachedFunctions[_.get(response, 'function.properties.uuid')] = response;
		}
	}

	loadFunctionUsedResources(id, label) {
		return this.executeRequest('/function/used-resources', 'GET', {id, label});
	}

	/**
	 * Get node neighbours.
	 * @param {array} nodeIds    Array of node IDs
	 * @return {Promise}
	 */
	getNeighbours(nodeIds) {
		return this.executeRequest('/node/neighbours', 'POST', nodeIds, false, false);
	}

	/**
	 * Get node neighbours as tabular data
	 * @param {Object} params    Array of node IDs eg: {"store": store, "ids": [id], "maxNodesPerLeaf": 14}
	 * @return {DeferredPromise}
	 */
	getNeighbourDetails(params) {
		return this.executeRequest('/node/neighbours-details', 'GET', params, false, false);
	}

	/**
	 * Save graph data as diagram.
	 * @param {object} diagramProperties    Diagram object
	 * @returns {Promise}
	 */

	saveDiagram(diagramProperties, extraLabels) {
		diagramProperties.version = APIClientAbstract.DIAGRAM_VERSION;
		let uri = def(diagramProperties.id) ? '/diagram/update' : '/diagram/create';
		return this.executeRequest(uri, 'POST', {labels: extraLabels, properties: diagramProperties});
	}

	/**
	 * Loads all the nodes in the specified diagram.
	 * @param {int} id Diagram ID
	 */
	loadDiagram(id) {
		return this.executeRequest('/diagram/read', 'GET', {id});
	}

	checkLicense() {
		let promise = new Promise((resolve, reject) => {
			// cache not needed and loader should not appear on license check
			this.executeRequest('/license/check', 'GET', {}, false, false)
				.done(response => {
					resolve(response);
				})
				.fail((error, statusCode) => {
					reject(error);
				});
		});
		return deferredPromise(promise);
	}
	
	checkResetPasswordToken(token) {
		return this.executeRequest('/user/check-reset-password-token', 'POST', {token}, false, false);
	}

	getEnvironmentInfo() {
		// The f parameter ensures the data is fresh
		// When duplicating tab the request result comes from cache (not from the server) and if you just logged-in in the original tab, the result does not contain user data
		return this.executeRequest('environment/info', 'GET', {f: now()});
	}

	/**
	 * Get Graphileon config.
	 */
	getSettings() {
		return this.executeRequest('php/settings-get.php', 'GET');
	}

	/**
	 * Save Graphileon config
	 * @param {object} settings
	 */
	saveSettings(settings) {
		return this.executeRequest('store/create', 'POST', settings);
	}

	/**
	 * Test connection to a store
	 * @param {object} store    Store information
	 */
	testConnection(store) {
		return this.executeRequest('store/test-connection', 'POST', store);
	}

	/**
	 * Get EventListener Function IDs.
	 */
	getEventListeners() {
		return this.executeRequest('/event-listeners', 'GET');
	}

	createDataset(nodes, relations, store) {
		let creation = new Promise((resolve, reject) => {

			// Create nodes
			let requests = [];
			for (let i in nodes) {
				let node = nodes[i];
				if (def(node.id)) {
					requests.push(promise(node)); // we don't have to create this one
					continue;
				}
				requests.push(this.saveNode(node));
			}
			let nodeKeys = Object.keys(nodes);
			waitForAll(requests).then(responses => {
				let createdNodes = {};
				for (let i in responses) {
					let key = nodeKeys[i];
					createdNodes[key] = responses[i];
				}

				// Create relations
				let relationRequests = [];
				let relationKeys = [];
				for (let i in relations) {
					let relation = relations[i];
					if (!(relation.from in createdNodes)) {
						log.error("APIClient.createDataset: Node '" + relation.from + "' not defined.");
						continue;
					}
					if (!(relation.to in createdNodes)) {
						log.error("APIClient.createDataset: Node '" + relation.to + "' not defined.");
						continue;
					}
					relation.from = createdNodes[relation.from].id;
					relation.to = createdNodes[relation.to].id;
					relationRequests.push(this.saveRelation(relation));
					relationKeys.push(i);
				}
				waitForAll(relationRequests).then(responses => {
					let createdRelations = {};
					for (let i in responses) {
						let key = relationKeys[i];
						createdRelations[key] = responses[i];
					}

					resolve({nodes: createdNodes, relations: createdRelations});
				}).catch(reject);
			}).catch(reject);
		});

		return deferredPromise(creation);
	}

	callAPIFunction(iaName, input) {
		return this.executeRequest(`app/${iaName}`, 'post', input);
	}

	/**
	 * Attempts to set password with token and password
	 * @param id		User id
	 * @param token
	 * @param password        md5-encoded password
	 * @param {string} newPassword        md5-encoded password
	 * @returns {Promise}    A promise that will resolve with a {user, environment} object.
	 */
	async userSetPassword(id, token, password, newPassword) {
		let promise = new Promise((resolve, reject) => {
			this.executeRequest('/user/change-password', 'post', {id, token, password, newPassword})
				.done(response => {
					resolve({ user: response });
				})
				.fail(error => {
					reject(error);
				});
		});
		return deferredPromise(promise);
	}

	activateUser(token){
		let promise = new Promise((resolve, reject) => {
			this.executeRequest('/user/activate', 'post', { token })
				.done(response => {
					resolve({ user: response });
				})
				.fail(error => {
					reject(error);
				});
		});

		return deferredPromise(promise);
	}

	resetPassword(email){
		return this.executeRequest('/user/reset-password', 'post', { properties: { email } });
	}

	getStoreSchema(store){
		return this.executeRequest('store/get-schema', 'get', { store });
	}

	getNodeBackup(node){
		return this.executeRequest('/node-backup/read', 'get', _.pick(node, 'properties.uuid'));
	}

}
APIClientAbstract.Event = {
	SERVER_ERROR: 'ServerError',
	SESSION_EXPIRED: 'SessionExpired',
	LICENSE_BROKEN: 'LicenseBroken',
	REQUEST_SUCCESS: 'RequestSuccess',
	LOADING_STARTED: 'LoadingStarted',
	LOADING_FINISHED: 'LoadingFinished'
};
APIClientAbstract.DIAGRAM_VERSION = '2';
