import APIClientAbstract from "./api-client-abstract";
import EventInterface from "utils/src/event-interface";
import i18next from 'i18next';
import i18nXHR from 'i18next-http-backend';
import ISession from "./i-session";
import Log from "utils/src/log";
import { isTrue } from 'core/src/utils/validation';
import _ from 'lodash';
import Edition from "core/src/edition";

Edition.setBuildFeature(Edition.Feature.MULTI_LINGUAL, false);

const t = i18next.t.bind(i18next);
const log = Log.instance("core/language");

const Event = {
	LANGUAGE_LOADED: 'LanguageLoaded',
	LANGUAGES_LOADED: 'LanguagesLoaded',
	LANGUAGE_CHANGED: 'LanguageChanged'
};

export default class Language {
	constructor(dependencies) {
		this.api = dependencies.get(APIClientAbstract);
		this.session = dependencies.get(ISession);

		const eventInterface = new EventInterface();
		this.on = eventInterface.getOnMethod();
		this.fire = eventInterface.getFireMethod();

		this.info = {
			languages: ['en'],
			namespaces: ['core'],
			defaultLanguage: 'en'
		}
	}

	async initLocalization() {
		const info = await this.api.getLanguageInfo();
		this.info = info;
		await i18next.use(i18nXHR).init({
			lng: info.defaultLanguage,
			fallbackLng: 'en',
			ns: info.namespaces,
			fallbackNS: info.namespaces,
			load: 'currentOnly',
			backend: {
				loadPath: this.api.getServer() + '/api/language/translations?language={{lng}}&ns={{ns}}',
				parse: function(data) {
					data = JSON.parse(data);
					return data.data.translation;
				}
			}
		});
		log.log("Localization initialized.");

		// Loaded event is called for every namespace/language, so we debounce it
		const fireLanguageLoaded = _.debounce(()=>this.fire(Event.LANGUAGE_LOADED, 500));
		i18next.on('loaded', () => {
			log.log("Translations loaded.");
			fireLanguageLoaded();
		});
		i18next.on('languageChanged', (language) => {
			this.fire(Event.LANGUAGE_CHANGED, language);
		});
		this.fire(Event.LANGUAGES_LOADED, info.languages);
	}

	async changeLanguage(language) {
		// feature
	}

	getCurrentLanguage() {
		return i18next.language || 'en';
	}

	getLanguages() {
		return this.info.languages || ['en'];
	}

	async reloadTranslations() {
		// we need languages and namespace or i18next will give an error for languages undefined
		await i18next.reloadResources(this.info.languages, this.info.namespaces);
	}

	translate(key, options) {
		if(!_.isString(key)) return '';

		const translationFound = i18next.exists(key);

		let translated = key;
		if(!translationFound) {
			log.info(`No translations found for '${key}'.`);
		} else {
			translated = i18next.t(key, options);
		}

		if(this.session.isLoggedIn()) {
			const user = this.session.getUser();
			// Add debug symbol for translated items
			if(isTrue(user.properties.debugTranslations)) {
				translated += translationFound ? ' ✓' : ' ✗';
			}
		}
		return translated;
	}
}
Language.Event = Event;

export {
	t,
	i18next, // We should limit the usage if i18next directly to maintain flexibility
	i18nXHR
};
