const Function = require('core/src/function');
const Language = require('core/src/language').default;
const Edition = require('core/src/edition/index').default;
const {def, hasBooleanValue} = require('core/src/utils/validation');

const Timer = Function.extend(Function, 'Timer');

Edition.setBuildFeature(Edition.Feature.FUNCTION_TIMER, false);

Timer.prototype.onInit = function (dependencies) {
	this.language = dependencies.get(Language);
	this.setParameters({
		'$interval': undefined,
		'$repeat': false,
		'$pause': undefined,
		'$reset': undefined,
		'$kill': undefined,
		'$limit': 10
	});

	var boolean = ['isBooleanParsable', {default: false, warn: def}];
	this.setModelValidation({
		interval	: 'isNumber',
		repeat		: boolean,
		pause		: boolean,
		reset		: boolean,
		kill		: boolean,
		limit		: ['isNumber', {default: 10, warn: def}]
	});
};

Timer.prototype._timer = null;

Timer.prototype.createEmptyModel = function() {
	return {
		state: {
			start: 0,
			running: false,
			elapsed: 0,
			remaining: 0,
			iteration: 0,
			end: 0
		}
	};
};

Timer.prototype.execute = function(...args) {
	// feature
	const error = Edition.functionNotAvailableError('Timer');
	this.error(error);
	throw error;
}

Timer.prototype.onExecute = function(input) {
	// feature
};

Timer.prototype.onUpdate = function(changes) {
	// feature
};

Timer.prototype.onClose = function() {
	this.pause();
};

Timer.prototype.end = function() {
	this.pause();

	let repeat = this.readModel('repeat');
	let limit = this.readModel('limit');
	let iteration = this.readModel('state.iteration');

	let restart = hasBooleanValue(repeat, true) && (iteration < limit || limit === 0);

	this.executeTriggers({
		type: 'end'
	});

	if(restart) {
		this.start();
		return;
	}

	this.closeFunctionInstance();
};

Timer.prototype.reset = function() {
	this.pause();
	this.start();
	this.updateModel('reset', false);
};

Timer.prototype.start = function(interval) {
	// feature
};

Timer.prototype.pause = function() {
	if(this._timer === null) {
		return false;
	}
	clearTimeout(this._timer);
	this._timer = null;
	this.updateModel('state.running', false);
	return true;
};

Timer.prototype.resume = function() {
	if(this._timer !== null) {
		return;
	}
	var remaining = this.readModel('state.remaining');
	if(remaining === 0) {
		this.start();
	} else {
		this.start(remaining);
	}
};

Timer.prototype.updateState = function() {
	var running = this.readModel('state.running');
	if(!running) {
		return;
	}
	var end = this.readModel('state.end');
	var now = new Date()-0;

	var remaining = end - now;

	this.updateModel('state.remaining', remaining);
};

module.exports = Timer;
