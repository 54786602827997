'use strict';

const _ = require('core/src/utils/legacy');

var windowTemplate ='\
<div>\
<div style="text-align: right"\
	action="close"\
>\
	<span class="jsglyph jsglyph-close"></span>\
</div>\
<h2 class="title">Graph force parameters</h2>\
<div class="window-contents">\
	<label>\
		Link distance [100 to 1000] :\
		<strong holder="linkDistance"><%= linkDistance%></strong>\
	</label>\
	<div>\
		<input  name="linkDistance"\
				class="w-100"\
				type="range"\
				min="100"\
				max="1000"\
				value="<%= linkDistance%>"\
		/>\
	</div>\
\
	<label>\
		Link strength [0 to 1] :\
		<strong holder="linkStrength"><%= linkStrength %></strong>\
	</label>\
	<div>\
		<input  name="linkStrength"\
				class="w-100"\
				type="range"\
				min="0"\
				max="1"\
				step="0.05"\
				value="<%= linkStrength %>"\
		/>\
	</div>\
\
	<label>\
		Friction [0.1 to 1] : \
		<strong holder="friction"><%= friction %></strong>\
	</label>\
	<div>\
		<input  name="friction"\
				class="w-100"\
				type="range"\
				min="0.1"\
				max="1"\
				step="0.05"\
				value="<%= friction %>"\
		/>\
	</div>\
\
	<label>\
		Charge [-1000 to 1000] : \
		<strong holder="charge"><%= charge %></strong>\
	</label>\
	<div>\
		<input  name="charge"\
				class="w-100"\
				type="range"\
				min="-1000"\
				max="1000"\
				value="<%= charge %>"\
		/>\
	</div>\
\
	<label>\
		Charge distance [0 to 1000] : \
		<strong holder="chargeDistance"><%= chargeDistance %></strong>\
	</label>\
	<div>\
		<input  name="chargeDistance"\
				class="w-100"\
				type="range"\
				min="0"\
				max="1000"\
				value="<%= chargeDistance %>"\
		/>\
	</div>\
\
	<label>\
		Theta [0 to 3] : \
		<strong holder="theta"><%= theta %></strong>\
	</label>\
	<div>\
		<input  name="theta"\
				class="w-100"\
				type="range"\
				min="0"\
				max="3"\
				step="0.1"\
				value="<%= theta %>"\
		/>\
	</div>\
\
	<label>\
		Gravity [0 to 1] : \
		<strong holder="gravity"><%= gravity %></strong>\
	</label>\
	<div>\
		<input  name="gravity"\
				class="w-100"\
				type="range"\
				min="0"\
				max="1"\
				step="0.05"\
				value="<%= gravity %>"\
		/>\
	</div>\
\
	<button type="button" class="btn btn-primary" action="restore-default-parameters">Restore defaults</button>\
</div>\
</div>';

windowTemplate = _.template(windowTemplate);

var render = function(container, forceParameters) {
	var content = windowTemplate(forceParameters);
	$(container).html(content);

	return true;
};

/* Creates Force Parameters window
	settings = {
		container: $(), // container for the window
		forceParameters: {}, //begging (and default) values of the parameters, there values will be used for Restore defaults
		onParameterChange: function(parameterName, value), // called when a parameter changes
		onWindowClose: function() // called when user clicks on close window
	}
*/
var GraphForceParameters = function(settings) {
	var self = this;

	var defaultSettings = {
		container: undefined,
		forceParameters: {
			linkDistance: 20,
			linkStrength: 0.1,
			friction: 0.9,
			charge: -30,
			chargeDistance: 100,
			theta: 0.8,
			gravity: 0.1,
		},
		onParameterChange: function(parameterName, value) {},
		onWindowClose: function() {}
	};

	var state = _.extend({}, defaultSettings, settings);

	var restoreDefaultParameters = function() {
		render(state.container, state.forceParameters);
		_.forEach(state.forceParameters, function(value, parameter) {
			state.onParameterChange(parameter, value);
		});
	};

	var setParameterValueLabel = function(parameterName, value) {
		$(state.container).find('[holder=' + parameterName + ']').html(value);
	};

	var setEvents = function() {
		$(state.container).on('change', 'input[type=range]', function(event) {
			var parameterName = $(this).attr('name');
			var value = $(this).val();
			state.onParameterChange(parameterName, value);
			setParameterValueLabel(parameterName, value);
		});

		$(state.container).on('click', '[action=close]', function() {
			state.onWindowClose();
		});

		$(state.container).on('click', '[action=restore-default-parameters]', function() {
			restoreDefaultParameters();
		});
	};

	self.setContainer = function(container, forceParameters) {
		forceParameters = forceParameters || state.forceParameters;
		forceParameters = _.extend({}, state.forceParameters, forceParameters);
		var oldContainer = state['container'];
		state.container = $(container);
		render(state.container, forceParameters);
		if (oldContainer !== state.container) {
			setEvents();
		}
	};

	self.setParameters = function(forceParameters) {
		self.setContainer(state.container, forceParameters);
	};

	self.setContainer(state.container);

	self.breakDown = function() {

		state.container.remove();
	};

	return self;
};

module.exports = GraphForceParameters;