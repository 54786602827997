const Websocket = require('./websocket');
const Edition = require('core/src/edition').default;
const Function = require('core/src/function');
const _ = require('lodash');
const Err = require('utils/src/error');
import { isTrue, isFalse } from 'core/src/utils/validation';
import { isReadOnly } from 'core/src/utils/read-only';


const log = require('core/src/log').instance("function/websocket");
Edition.setBuildFeature(Edition.Feature.FUNCTION_WEBSOCKET, true);

Websocket.prototype.execute = function (...args) {
	if(!Edition.hasFeature(Edition.Feature.FUNCTION_WEBSOCKET)) {
		const error = Edition.functionNotAvailableError('Websocket');
		this.error(error);
		throw error;
	}

	this.activeChannelMessages = [];
	
	this.triggerOnReceive = (socketData) => {
		if (_.isEmpty(socketData.data)) {
			return;
		}

		this.executeTriggers({
			type: 'onReceive',
			data: {
				origin: 'websocket',
				channel: socketData.channel,
				message: socketData.message,
				...socketData.data
			}
		});
	}

	return Function.prototype.execute.call(this, ...args);
};

Websocket.prototype.onExecute = async function() {
	this.sendMessage();
	this.listen();
};

Websocket.prototype.onUpdate = async function() {
	this.sendMessage();
	this.listen();
};

Websocket.prototype.listen = function() {

	if (isFalse(this.readModel('listen'))) {
		this.closeAllChannels();
		return;
	}

	const channel = this.readModel('channel');

	let currentChannelMessages = _.map(
		this.readModel('messagesToListen'),
		(message) => `${channel}#${message}`
	)

	let messagesToClose = _.difference(this.activeChannelMessages, currentChannelMessages);
	_.forEach(
		messagesToClose,
		(channelMessage) => {
			this.io.emit(`removeFromListeners`, channelMessage);
			this.io.off(channelMessage, this.triggerOnReceive);
		}
	)

	this.activeChannelMessages = _.difference(this.activeChannelMessages, messagesToClose);

	let newChannelMessages = _.difference(currentChannelMessages, this.activeChannelMessages)
	_.forEach(
		newChannelMessages,
		(channelMessage) => {
			this.activeChannelMessages.push(channelMessage);
			this.io.emit(channelMessage);
			this.io.on(channelMessage, this.triggerOnReceive);
		}
	);
}

Websocket.prototype.sendMessage = function() {
	const channel = this.readModel('channel');
	const messagesToBroadcast = this.readModel('messagesToBroadcast');


	const broadCasting = this.readModel('broadcast');
	const message = this.readModel('message');
	const data = this.readModel('data');

	if (! _.isEmpty(message)) {
		_.defer(
			() => {
				this.update({
					message: '',
					data: null
				})				
			}
		);
	}

	if ( !broadCasting ) {
		console.warn('Websocket initliazed but not broadcasting');
		return;
	}

	if (_.isEmpty(message)) {
		return;
	}

	if (!_.includes(messagesToBroadcast, message)) {
		console.warn('The message you tried to send is not registered for broadcast');
		return;
	}

	this.io.emit(`${channel}#${message}`, isReadOnly(data) ? data.$writable() : data);
}

Websocket.prototype.closeAllChannels = function(...args) {	
	_.forEach(this.activeChannelMessages, (channelMessage) => {
		this.io.emit(`removeFromListeners`, channelMessage);
		this.io.off(channelMessage, this.triggerOnReceive);
	});

	this.activeChannelMessages = [];
}

Websocket.prototype.onClose = function(...args) {
	this.closeAllChannels();
}
