"use strict";

const parseRE = /\(ia-api\s+(.+?)\)/;

module.exports = function create_token(md, options) {
  const arrayReplaceAt = md.utils.arrayReplaceAt;

  function splitTextToken(text, level, Token) {
    let token;
    let last_pos = 0;
    const nodes = [];

    text.replace(
      RegExp(parseRE, "g"),
      function (match, p1, offset, string) {
        // Add new tokens to pending list
        if (offset > last_pos) {
          token = new Token("text", "", 0);
          token.content = text.slice(last_pos, offset);
          nodes.push(token);
        }

        token = new Token("iaApi_parse", "", 0);
        token.markup = "iaApi-parse";
        token.info = { tag: 'iaApi', arg: `{${p1.replace(/[“|”]/g, '"')}}` };
        nodes.push(token);

        last_pos = offset + match.length;
      }
    );

    if (last_pos < text.length) {
      token = new Token("text", "", 0);
      token.content = text.slice(last_pos);
      nodes.push(token);
    }

    return nodes;
  }

  return function token_replace(state, a, b, silent) {
    const blockTokens = state.tokens;

    for (let j = 0, l = blockTokens.length; j < l; j++) {
      if (blockTokens[j].type !== "inline") {
        continue;
      }

      let tokens = blockTokens[j].children;

      // We scan from the end, to keep position when new tags added.
      // Use reversed logic in links start/end match
      for (let i = tokens.length - 1; i >= 0; i--) {
        let token = tokens[i];

        if (
          token.type === "text" &&
          RegExp(parseRE).test(token.content)
        ) {
          // replace current node
          blockTokens[j].children = tokens = arrayReplaceAt(
            tokens,
            i,
            splitTextToken(token.content, token.level, state.Token)
          );
        }
      }
    }
  };
};
