
import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";
import {truncateString} from "utils/src/string";
import * as _ from 'lodash';

export default {
	name: "properties-view",
	mixins: [VueRendererComponent],
	data: function () {
		return {
			entity: {},
			entityType: "node"
		}
	},
	computed: {
		shortenedID() {
			if(!this.entity) return "";
			return truncateString(_.get(this.entity, 'id'), 1);
		},
		processedEntity() {
			if (this.entity && "properties" in this.entity) {
				let sortedProps = {};
				Object.keys(this.entity.properties).sort().forEach(k => {
					sortedProps[k] = this.entity.properties[k];
				});
				this.entity.properties = sortedProps;
				return this.entity;
			}
		}
	},
	methods: {
		getFileBasename: (val) => {
			// get last part of file to display in panel
			return val.split('/').pop();
		},
		isUploadedFile: (val) => /^([a-z0-9/]*)\/file\/([a-z0-9/]+)?\/(.+)/.test(val)
	}
};
