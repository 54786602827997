
	import _ from "lodash";
	import { abstractField } from "vue-form-generator";
	import icons from "../../icons";
	// require component
	import { codemirror } from 'vue-codemirror'

	// require styles
	import 'codemirror/lib/codemirror.css'

	export default {
		components: {
			codemirror
		},
		mixins: [ abstractField ],
		watch: {
			value: {
				immediate: true,
				handler: function(value) {
					if (!_.isString(value)) {
						this.value = JSON.stringify(value, null, this.schema.tabSize)
					}
				}
			}
		}
	}
