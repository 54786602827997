
  import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";
  import _ from "lodash";
  export default {
    name: "image-map",
    mixins: [VueRendererComponent],
    data() {
      return {
        source: "",
        enableMapHighlights: true,
        zoomToFit: false,
        enableWheelZoom: true,
        enablePan: true,
        areas: [],
        areaHighlights: {},
        zoomScale: 1.2,
        currentScale: 1.2,
        minZoomScale: 0.3,
        maxZoomScale: 3,
        enableZoomButtons: true,
        dragStarted: false,
        enabledGroups: []
      }
    },
    watch: {
      source(n,o) {
          this.$nextTick(() => {
            this.initMaphilight()
            this.updateAreas();
          });
      },
      areas(n, o) {
          this.updateAreas();      
      },
      
      areaHighlights(n, o) {
          this.updateAreas();
      },
    },
    computed: {
      mapName() {
        return `map-${_.random(0, 100000)}`;
      },
      imgId() {
        return `img-${_.random(0, 100000)}`;
      }
    },
    methods: {
      initMaphilight() {
        const self = this;
        if (self.enableMapHighlights) {
          this.mhl = $('#' + self.imgId).maphilight();
        }
        setTimeout(() => {
          if (self.enablePan)
            $('#' + self.imgId).parent().parent().draggable({
              start: function(event, ui){
                self.dragStarted = true;
              },
              stop: function(event, ui){
                setTimeout(() => {
                  self.dragStarted = false;
                }, 200)
              }
            });
          if (self.enableWheelZoom) {
            $('#' + self.imgId).parent().parent()[0].addEventListener('wheel', _.debounce(function(e){
              if (e.deltaY > 0) {
                $(this).zoomIn(this.zoomScale, this.maxZoomScale, e)
              } else {
                $(this).zoomOut(this.zoomScale, this.minZoomScale, e)
              }
            }, 15))
          }
          if (self.zoomToFit) {
            this.zoomTofit();
          }
        }, 2000)
      },

      updateAreas() {
        _.map(this.areas, x => {
          const mapHilight = _.mapValues(this.getMapHilightData(x.mapHighlighter), _.identity);
          let el = $(document.getElementById(x.id));
          el.data('maphilight', mapHilight).trigger('alwaysOn.maphilight');
        })
      },

      getArea(idx) {
        return _.find(this.areas, {id: idx}) || this.areas[idx];
      },
      
      areaClicked(e, idx) {
        const self = this;
        e.preventDefault();
        if ( this.dragStarted ) {
          return false;
        }
        const area = this.getArea(idx);
        const mapHilight = this.getMapHilightData(area.mapHighlighter);
        const groupSelector = _.get(mapHilight, 'groupBy');

        if ( groupSelector ) {
          const groupAreaElements = $(`area${groupSelector}`);
          const groupAreas = _.map(groupAreaElements, x => self.getArea($(x).attr('id')))
          let groupEvent = '';
          if (!this.enabledGroups.includes(groupSelector)) {
            this.enabledGroups.push(groupSelector);
            groupEvent = 'groupEnabled';
          } else {
            groupEvent = 'groupDisabled';
            this.enabledGroups = _.filter(this.enabledGroups, x => x !== groupSelector);
          }
          this.$emit(groupEvent, {groupSelector, area, x: e.clientX, y: e.clientY, groupAreas, enabledGroups: this.enabledGroups });  
        }
        this.$emit('areaClicked', {area, x: e.clientX, y: e.clientY, isGroupSelector: !!groupSelector});
      },
      areaRightClicked(e, idx) {
        e.preventDefault();
        this.$emit('areaRightClicked', {area: this.getArea(idx), x: e.clientX, y: e.clientY});
        return false;
      },
      imgClicked(e) {
        e.preventDefault();
        if ( this.dragStarted ) {
          return false;
        }
        this.$emit('imageClicked', {source: this.source, x: e.clientX, y: e.clientY});
      },
      imgRightClicked(e) {
        e.preventDefault();
        this.$emit('imageRightClicked', {source: this.source, x: e.clientX, y: e.clientY});
        return false;
      },
      getMapHilightData(mapHighlighter) {
          return _.mapValues(
            _.get(
              this.areaHighlights,
              mapHighlighter,
              {"stroke":false,"fillColor":"ff0000","fillOpacity":0.6, "alwaysOn": true}
            ),
            _.identity
          )
      },
      zoomIn: _.debounce(function(e) {
        $('#' + this.imgId).parent().parent().zoomIn(this.zoomScale, this.maxZoomScale, e);
      }, 100),
      zoomOut: _.debounce(function(e) {
        $('#' + this.imgId).parent().parent().zoomOut(this.zoomScale, this.minZoomScale, e)
      }, 100),
      zoomReset() {
        $('#' + this.imgId).parent().parent().zoomReset()
      },
      zoomTofit() {
        const imgElement = $('#' + this.imgId)[0];
        const wrapper = $('#wrapper-' + this.imgId)[0];
        let scale = Math.min(imgElement.width / wrapper.offsetWidth, imgElement.height / wrapper.offsetHeight) * 0.97;
        $('#' + this.imgId).parent().parent().zoomToFit(scale)
      }
    },
  }
