const View = require('core/src/functions/view');
const IAPI = require('core/src/api-client-abstract').default;

const _ = require('core/src/utils/legacy');
const { findChangeFromRoot } = require('core/src/utils/changes');
const Language = require('core/src/language').default;

class YFilesIsometricView extends View {
	constructor(dependencies, initData) {
		super(dependencies, initData);

		this.api = dependencies.get(IAPI);
		this.language = dependencies.get(Language);

		this._eventHandlers = _.cloneDeep(View.prototype._eventHandlers);
		this._modelUpdateHandlers = _.cloneDeep(View.prototype._modelUpdateHandlers);

		this.setHashCollectionItemFunction('nodes', this.hashEntity.bind(this));
		this.setHashCollectionItemFunction('state.selected.nodes', this.hashEntity.bind(this));

		this.setModelUpdateHandler('nodes', this.handleNodesUpdate.bind(this));

		this.setDeepModelUpdateHandler('state', this.handleStateUpdate.bind(this));
	}

	onInit() {
		this.setParameters({
			'store': undefined,
			'nodes': [],
			'state': this.createEmptyState(),
			'styles': {},
			'toolbarVisible': 1,
			'background': {},
			'labelProperty': null
		});

		function optional(defaultValue) { return { default: defaultValue, warn: _.def}; }
		this.setModelValidation({
			store: ['isString', optional('application')],
			nodes: this.optionalArrayOf(_.isValidNode, 'node'),
			styles: ['isObject', optional({})],
			background: ['isObject', optional({})]
		});

		// Use uniqueness when adding nodes

		this._updateCollection.add.state = {selected:{}};

		this._updateCollection.add.nodes =
			this._updateCollection.add.state.selected.nodes =
				this._updateCollection.set.default;

		// Set update priorities
		this.setUpdatePriorities({
			'state': -2,
			'nodes': -1
		});
	}

	hashEntity(entity) {
		const store = this.readModel('store') || 'application';
		return _.get(entity, 'meta.store', store) + "_" + _.get(entity, 'id');
	}

	reviseNodeProperties(nodes, oldNodes) {
		const updates = [];
		for(let node of nodes) {
			let update = {id: node.id, meta: { store: _.get(node, 'meta.store') }};
			let shouldUpdate = false;

			// 1. Backward compatibility of `px` and `py`
			if(_.isNil(node.x) && _.isFinite(node.px)) {
				update = {...update, id: node.id, x: node.px};
				shouldUpdate = true;
			}
			if(_.isNil(node.y) && _.isFinite(node.py)) {
				update = {...update, id: node.id, y: node.py};
				shouldUpdate = true;
			}

			// 2. Some properties should not be changed/deleted unless explicitly updated
			// Those properties we get from the old node (we only find the old node once using this cached function).
			let oldNode;
			function cachedOldNode() {
				if(!oldNode) oldNode = _.find(oldNodes, old => old.id === node.id);
				return oldNode;
			}
			for(let property of ['x', 'y', 'fixed', 'finalStyle']) {
				if(!(property in node)) {
					let old = cachedOldNode();
					if(!old) break;

					update[property] = old[property];
					shouldUpdate = true;
				}
			}

			if(shouldUpdate) {
				updates.push(update);
			}
		}

		if(updates.length) {
			return this.updateCollection('change', 'nodes', updates);
		}
	}

	updateState(collection, items) {
		const selected = _.filter(items, item => _.get(item, 'selected', false));

		this.updateModel('state.selected.' + collection, selected);
	}

	async handleNodesUpdate(nodes, oldNodes) {
		this.reviseNodeProperties(nodes, oldNodes);
		return this.updateState('nodes', nodes);
	}

	handleStateUpdate(changes) {
		if(findChangeFromRoot(changes, 'state.selected.nodes')) {
			this.setItemsStateProperty('nodes', 'selected', 'state.selected.nodes');
		}
	}

	createEmptyModel() {
		let model = super.createEmptyModel();
		model.state = this.createEmptyState();
		model.nodes = [];

		return model;
	}

	createEmptyState() {
		return {
			selected: {
				nodes: []
			}
		};
	};

	async onExecute() {
		super.onExecute();
	}

	optionalArrayOf(itemValidation, itemType) {
		let self = this;
		return [function(arr) {
			// Array can be undefined, or be valid.
			// Otherwise it's an error, not a warning.
			return !_.def(arr) || _.validateArray(
				_.capitaliseFirst(itemType),
				arr,
				itemValidation,
				self.language.translate('Invalid {{itemType}} structure.', {itemType}),
				{ itemType: itemType },
				false
			);
		}];
	}
}
YFilesIsometricView.functionName = 'YFilesIsometricView';

module.exports = YFilesIsometricView;
