const IAPI = require('core/src/api-client-abstract').default;
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const { isReadOnly } = require('core/src/utils/read-only');
const Language = require('core/src/language').default;

const _ = require('core/src/utils/legacy');

const RelationFormView = Function.extend(View, "RelationFormView");

/**
 * @override
 * @returns {undefined}
 */
RelationFormView.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);

	this.setParameters({
		'$id': undefined,
		'$store': undefined,
		'$preset': undefined,
		'$style': undefined
	});
};

RelationFormView.prototype._getRelationFromID = function(id, store) {
	var deferred = new $.Deferred();
	let self = this;
	// Fetch relation data
	var request = this.api.loadRelation(id, store);
	request.done(function(rel) {
		deferred.resolve(rel);
	});
	request.fail(function(err) {
		deferred.reject(new _.Error({
			message: this.language.translate("could-not-load-relation", {id, store}),
			public: true,
			originalError: err
		}));
	});

	return deferred.promise();
};

RelationFormView.prototype.alterRenderData = function (data) {
	var deferred = new $.Deferred();
	data = data || {};

	var getRelation;
	if(_.isStringOrNumber(data.id)) {
		getRelation = this._getRelationFromID(data.id, data.store);
	} else if (_.isObject(data.preset)) {
		getRelation = this._getRelationFromPreset(data.preset, data.store);
	} else {
		return new _.Error(this.language.translate("Either 'id' or 'preset' parameter must be set."));
	}
	getRelation.done(function(relation) {
		delete data.id;
		delete data.store;
		delete data.preset;

		data.relation = relation;
		deferred.resolve(data);
	});
	getRelation.fail(function(err) {
		deferred.reject(new _.Error({
			message: err,
			originalError: err
		}));
	});

	return deferred.promise();
};

/* PROTECTED */

/**
 * @param {object} preset
 * @param {string} [store]
 * @returns {$.Deferred}
 * @private
 */
RelationFormView.prototype._getRelationFromPreset = function(preset, store) {
	var deferred = new $.Deferred();
	if(isReadOnly(preset)) {
		preset = preset.$clone().$writable();
	}

	var relation = preset;

	var from = relation.from;
	var to = relation.to;

	if(_.isObject(from)) {
		relation.from = from.id;
	}
	if(_.isObject(to)) {
		relation.to = to.id;
	}

	// Check if preset stores are the same for both nodes
	if (_.get(from, 'meta.store') === _.get(to, 'meta.store')) {
		_.set(relation, 'meta.store', _.get(from, 'meta.store'));
	} else {
		deferred.reject(new _.Error({
			message: this.language.translate('Both nodes must be from the same store.'),
			public: true
		}));
		return deferred.promise();
	}

	if(!_.isString(store)) {
		store = _.get(from, 'meta.store');
	}

	_.set(relation, 'meta.store', store);

	deferred.resolve(relation);

	return deferred.promise();
};

module.exports = RelationFormView;
