'use strict';

const EventInterface = require('core/src/event-interface');
const GraphSelector = require('core/src/graph/graph-selector');
const { checkMethod } = require('utils/src/validation');

const _ = require('core/src/utils/legacy');
const Log = require('core/src/log');
const { GraphFilterStatus } = require('core/src/graph/graph-filters');

require('client/libraries/jqwidgets-imports');

const log = Log.instance("client/graph/filters");

const FILTER_STATUS_INACTIVE = GraphFilterStatus.INACTIVE,
	FILTER_STATUS_SHOW = GraphFilterStatus.SHOW,
	FILTER_STATUS_HIDE = GraphFilterStatus.HIDE,
	VALID_FILTER_STATUS = [FILTER_STATUS_INACTIVE, FILTER_STATUS_HIDE, FILTER_STATUS_SHOW],
	VALID_FILTER_TYPE = ['node', 'rel'],
	AUTOLOAD_FILTERS_PROPERTY = 'autoFilters',
	AUTOLOAD_FILTERS_PROPERTY_LEGACY = '__autoloadFilters__',
	FILTERED_OUT_PROPERTY_NAME = '__filteredOut', // property set to true on filtered out items to identity them as filtered out
	FILTER_WINDOW_LAYOUT =`
		<div>
			<div class="input-group-text bg-secondary-subtle w-100 rounded-bottom-0">
				<input class="form-check-input me-2" type="checkbox" name="auto-update-filters" />
				Add filters automatically
			</div>
			<div class="filters-table rounded-0"></div>
			<div class="filter-form-container input-group-text bg-secondary-subtle w-100 rounded-top-0">
		</div>`;
			
const GraphFilterItem = function(filter, status) {
	this.type = '';
	this.filterBody = '';
	this.filter = '';
	this.status = false;
	this.ok = true;

	if (filter !== undefined) {
		this.setFilter(filter);
	}

	if (status !== undefined) {
		this.setStatus(status);
	}
};

GraphFilterItem.prototype.isOk = function() {
	return this.ok;
};

GraphFilterItem.prototype.setStatus = function(status) {
	this.status = toValidFilterStatus(status);
	return this;
};

GraphFilterItem.prototype.getStatus = function() {
	return this.status;
};

GraphFilterItem.prototype.getNextStatus = function() {
	var nextStatusIndex = (VALID_FILTER_STATUS.indexOf(this.getStatus()) + 1) % VALID_FILTER_STATUS.length;
	return VALID_FILTER_STATUS[nextStatusIndex];
};

GraphFilterItem.prototype.setFilter = function(filter) {
	if (!_.isString(filter)) {
		console.error('GraphFilterItem.setFilter: filter should be string', filter);
		this.ok = false;
	}
	else if (filter.match(/^node/)) {
		this.type = 'node';
		this.filterBody = filter.replace('node', '');
	}
	else if (filter.match(/^rel/)){
		this.type = 'rel';
		this.filterBody = filter.replace('rel', '');
	}
	else {
		console.error('GraphFilterItem.setFilter: filter must refer to node or rel');
		this.ok = false;
	}

	if (this.ok) {
		this.filter = filter;
	}

	return this;
};

GraphFilterItem.prototype.getFullFilter = function() {
	return this.type + this.filterBody;
};

GraphFilterItem.prototype.isNodeFilter = function() {
	if (this.type === 'node') {
		return true;
	}

	return false;
};

GraphFilterItem.prototype.isRelationFilter = function() {
	if (this.type === 'rel') {
		return true;
	}

	return false;
};

GraphFilterItem.prototype.isActive = function() {
	return (this.getStatus() !== FILTER_STATUS_INACTIVE);
};

GraphFilterItem.prototype.isInactive = function() {
	return (this.getStatus() == FILTER_STATUS_INACTIVE);
};

GraphFilterItem.prototype.isShow = function() {
	return (this.getStatus() === FILTER_STATUS_SHOW);
};

GraphFilterItem.prototype.isHide = function() {
	return (this.getStatus() === FILTER_STATUS_HIDE);
};


GraphFilterItem.prototype.matchesNode = function(node) {
	if (!this.isNodeFilter()) {
		return false;
	}

	if (GraphSelector.matchNodeSelector(node, this.getExpandedSelector())) {
		return true;
	}

	return false;
};

GraphFilterItem.prototype.matchesRelation = function(relation) {
	if (!this.isRelationFilter()) {
		return false;
	}

	if (GraphSelector.matchRelSelector(relation, this.getExpandedSelector())) {
		return true;
	}

	return false;
};

GraphFilterItem.prototype.getStatusIcon = function() {
	if (this.status == FILTER_STATUS_INACTIVE) {
		return '<i class="fa fa-vector-square" title="Filter is inactive"></i>';
	}

	if (this.status == FILTER_STATUS_HIDE) {
		return '<i class="fa fa-eye-slash" title="Items are hidden"></i>';
	}

	if (this.status == FILTER_STATUS_SHOW) {
		return '<i class="fa fa-eye" title="Items are shown"></i>';
	}

	return false;
};


function isGraphFilterItem(item) {
	if (GraphFilterItem.prototype.isPrototypeOf(item)) {
		return true;
	}
	return false;
}

function toValidFilterStatus(status) {
	if (_.includes(VALID_FILTER_STATUS, status)) {
		return status;
	}

	if (status) {
		return FILTER_STATUS_SHOW;
	}

	return FILTER_STATUS_INACTIVE;
}


// FilterForm ------------------------------------------------------------------------------------------------------


var FilterForm = function(settings) {
	var defaultSettings = {
		container: undefined,
		hiddenContent: '',
		onSubmit: function(filterData) {}
	};

	settings = _.extend({}, defaultSettings, settings);
	var emptyFilter = new GraphFilterItem('node', FILTER_STATUS_SHOW);

	var template =`
		<form class="filter-edit">
			<div class="container">
				<div class="row">
					<input type="hidden" name="index" value="<%= index %>"/>
					<button type="button" class="btn btn-sm btn-primary col-1" sync-with="status" name="status" value="<%= filter.status %>">
						<%= filter.getStatusIcon() %>
					</button>
					<input type="hidden" name="status" id="status" value="<%= filter.status %>"/>
					<div class="col-4">
						<select class="form-select" name="type">
							<option value="node">node</option>
							<option value="rel" <%= filter.type == "rel" ? "selected" : "" %>>rel</option>
						</select>
					</div>
					<input type="text" class="col form-control" name="filter" value="<%= filter.filterBody %>"/>
					<button type="submit" class="col-1 btn btn-sm btn-success ms-2">
						<span class="fa fa-check action-filter-submit"></span>
					</button>
					<button type="button" class="action-close col-1 btn btn-sm btn-danger ms-2">
						<i class="fa fa-times"></i>
					</button>
				</div>
			</div>
		</form>
	`;

	var renderTemplate = _.template(template);

	var render = function(filter, index) {
		emptyFilter.setStatus(filter.getStatus());
		$(settings.container).html(renderTemplate({
			filter: filter,
			index: index
		}));
	};

	var hide = function() {
		$(settings.container).html(settings.hiddenContent);
	};

	$(settings.container).on('submit', 'form', function(ev) {
		ev.preventDefault();
		var formData = $(settings.container).find('form').first().serializeArray();
		var formObjectData = _.zipObject(_.map(formData, 'name'), _.map(formData, 'value'));

		settings.onSubmit(formObjectData, formObjectData.index);

	});

	$(settings.container).on('click', 'button[name=status]', function() {
		var $this = $(this);
		emptyFilter.setStatus(emptyFilter.getNextStatus());

		$this.html(emptyFilter.getStatusIcon());
		$(this).attr('value', emptyFilter.getStatus());

		var syncId = $this.attr('sync-with');
		if (syncId) {
			$('#'+syncId).val($this.attr('value'));
		}
	});

	$(settings.container).on('click', 'button.action-close', function() {
		hide();
	});

	hide();

	return {
		render: render,
		hide: hide
	};
};



// GraphFilters ------------------------------------------------------------------------------------------



var GraphFilters = function(settings) {
	var self = this;

	var defaultSettings = {
		container: undefined,
		graph: undefined,
		onFiltersChanged: function() {}
	};

	this.settings = _.extend({}, defaultSettings, settings);
	this.filters = [];
	this.$filtersTable = null;
	this.filterForm = null;
	this.graph = this.settings.graph;
	this.callApplyFilters = true;
	this.fixIndeterminate = true;
	this.autoFilterStatus = settings.autoFilterStatus || false;
	this.updateUI = true;
	this.globalState = new GraphFilterItem(undefined, FILTER_STATUS_SHOW);
	this.globalStateElement = null;
	this.$container = $(this.settings.container);
	this.autofilterCheckbox = null;
	this.autoFilter = false;
	this.filteredOutNodes = [];
	this.filteredOutRelations = [];

	// Check graph interface
	checkMethod(this.graph, 'getNodes', 'graph');
	checkMethod(this.graph, 'getFilteredNodes', 'graph');
	checkMethod(this.graph, 'getRelations', 'graph');
	checkMethod(this.graph, 'getFilteredRelations', 'graph');

	// Event Interface
	this.eventInterface = new EventInterface();
	this.eventInterface.extend(this);

	this.initPanel(this.settings.container);
};

GraphFilters.AUTOFILTERS_PROPERTIES = [AUTOLOAD_FILTERS_PROPERTY, AUTOLOAD_FILTERS_PROPERTY_LEGACY];

GraphFilters.Event = {
	FILTER: 'Filter',
	FILTER_RENDERER: 'FilterRenderer'
};


GraphFilters.prototype.addFilter = function(filterString, status) {
	var filter = this.findFilter(filterString);

	if (filter) {
		filter.setStatus(status);
		return filter;
	}

	filter = new GraphFilterItem(filterString,status);

	if (! filter.ok) {
		return _.withError(['GraphFilters: filter is not valid', filterString, status]);
	}

	this.filters.push(filter);

	return filter;
};

GraphFilters.prototype.setFilter = function(filter, status, index) {
	if (!isGraphFilterItem(this.filters[index])) {
		console.error('GraphFilters.setFilter: Could not find filter by index', index);
		return false;
	}

	var key = this.filters[index].key;
	var newFilter = new GraphFilterItem(filter, status);
	newFilter.key = key;

	var result = newFilter.ok;
	if (result) {
		this.filters[index] = newFilter;
	}

	return result;
};

GraphFilters.prototype.deleteFilter = function(filterIndex) {
	console.assert(_.isNumeric(filterIndex), 'GraphFilters.deleteFilter: filterIndex should be numeric', filterIndex);
	if (!this.getFilters()[filterIndex]) {
		console.error('GraphFilters.deleteFilter: could not find filter by index', filterIndex);
		return;
	}
	// Remove filter from model
	this.getFilters().splice(filterIndex, 1);
	// Reset index in FilterForm if deleted filter is the one currenlty edited

	this.filterForm.hide();
};

GraphFilters.prototype.deleteAllFilters = function() {
	this.getFilters().length = 0;
};

GraphFilters.prototype.getFilters = function() {
	return this.filters;
};

GraphFilters.prototype.getFilter = function(index) {
	if (!_.isNumeric(index)) {
		return _.withError(['GraphFilters.getFilter: index is not numeric', index]);
	}

	if (!this.getFilters()[index]) {
		return _.withError(['GraphFilters.getFilter: could not find filter with index', index]);
	}

	return this.getFilters()[index];
};

GraphFilters.prototype.export = function() {
	var result = [];
	_.forEach(this.getFilters(), function(filter) {
		result.push({
			filter: filter.getFullFilter()
			, status: filter.status
			, key: filter['key']
		});
	});
	// Save Load filters automatically status
	result.push({
		filter: AUTOLOAD_FILTERS_PROPERTY,
		status: this.autoFilterStatus
	});

	return result;
};

GraphFilters.prototype.toJSON = function() {
	return JSON.stringify(this.export());
};

GraphFilters.prototype.fromJSON = function(filtersStr, append) {
	try {
		var array = JSON.parse(filtersStr);
		if (!(array instanceof Array)) {
			return false;
		}

		this.setUpdateUI(false);

		if (append === undefined) {
			append = false;
		}
		if (!append) {
			this.deleteAllFilters();
		}

		for (var index in array) {
			if (GraphFilters.AUTOFILTERS_PROPERTIES.indexOf(array[index].filter) >= 0) {
				this.setAutoFilterStatus(array[index].status);
			}
			else {
				var filter = this.addFilter(array[index].filter, array[index].status, false);
				filter.key = array[index].key;
			}
		}
		return true;
	}
	catch (exception) {
		console.error('GraphFilters.fromJSON: could not load filters from JSON', filtersStr, append, exception);
	}

	return false;
};

GraphFilters.prototype.setFilters = function (filters) {
	if(_.isString(filters)) {
		return this.fromJSON(filters);
	}
	var self = this;
	var changed = false;
	var autoFilterStatus;

	_.forEach(filters, function (filter, key) {
		if (GraphFilters.AUTOFILTERS_PROPERTIES.indexOf(filter['filter']) >= 0) {
			autoFilterStatus = filter.status;
			return;
		}

		var find = self.findFilter(filter.filter);

		if (!find) {
			find = self.addFilter(filter.filter, filter.status, false);
			changed = true;
			if(!find) {
				console.warn("Invalid filter '" + filter.filter + "'.");
				return;
			}
		}

		if (filter['key']) {
			find['key'] = filter['key'];
		}
		else {
			find['key'] = key;
		}

		if (find.status !== filter.status) {
			find.setStatus(filter.status);
			changed = true;
		}
	});

	if (autoFilterStatus !== undefined) {
		this.setAutoFilterStatus(autoFilterStatus);
	}

	return changed;
};

GraphFilters.prototype.setAutoFilterStatus = function(status) {
	status = status == true;
	if (this.autoFilterCheckbox) {
		this.autoFilterCheckbox.prop('checked', status);
	}
	this.autoFilterStatus = status;
	if(status) {
		this.updateAutoFilters();
	}
};

GraphFilters.prototype.setUpdateUI = function(status) {
	this.updateUI = (status == true);
};

GraphFilters.prototype.updateFiltersTable = function() {
	if (!this.$filtersTable) {
		return;
	}
	this.$filtersTable.jqxDataTable('updateBoundData');
};

GraphFilters.prototype.findFilter = function(filterString) {
	return _.find(this.getFilters(), function(filter) {
		if (filter.getFullFilter() == filterString) {
			return true;
		}
	});
};

GraphFilters.prototype.filterExists = GraphFilters.prototype.findFilter;

GraphFilters.prototype.refreshFiltersTable = function() {
	if (!this.$filtersTable) {
		return;
	}
	this.$filtersTable.jqxDataTable('refresh');
};

GraphFilters.prototype.switchAllFiltersStatusTo = function(status) {
	var self = this,
		changed = false;

	_.forEach(this.getFilters(), function(filter, index) {
		if (self.filters[index].status != status) {
			changed = true;
		}
		self.filters[index].setStatus(status);
	});

	return changed;
};

GraphFilters.prototype.clearFilterEdit = function() {
	if (this.filterForm) {
		this.filterForm.hide();
	}
};

GraphFilters.prototype.loadFilterInEdit = function(filterItem, index) {
	if (!isGraphFilterItem(filterItem)) {
		console.error('filterGraph.loadFilterInEdit: filterItem must be a GraphFilterItem', filterItem);
		return;
	}

	this.filterForm.render(filterItem, index);
};

GraphFilters.prototype.onSubmitFilterForm = function(filterData, index) {
	filterData.status = parseFloat(filterData.status);

	var selector = new GraphSelector(filterData.type + filterData.filter);

	if (! selector.isOk()) {
		alert('Graph selector is not valid!');
		return;
	}

	this.filterForm.hide();

	if (_.isNumeric(index)) {
		this.setFilter(filterData.type + filterData.filter, filterData.status, index);
	} else {
		var filter = this.addFilter(filterData.type + filterData.filter, filterData.status);
		filter.key = _.uniqueId('_custom');
	}
	this.updateInterface();
	this.filterGraph();
};

// Returns items (nodes or relations) that have the '__filteredOut' property set to true
GraphFilters.prototype.getFilteredOut = function(items) {
	items = _.ensureIsArray(items);
	return _.filter(items, FILTERED_OUT_PROPERTY_NAME);
};

// Sets the property '__filteredOut' = true
GraphFilters.prototype.setFilteredOut = function(items) {
	items = _.ensureIsArray(items);

	return _.map(items, function(item) {
		item[FILTERED_OUT_PROPERTY_NAME] = true;
		return item;
	});
};

// Removes the property '__filteredOut'
GraphFilters.prototype.removeFilteredOut = function(items) {
	items = _.ensureIsArray(items);

	return _.map(items, function(item) {
		delete item[FILTERED_OUT_PROPERTY_NAME];
		return item;
	});
};

GraphFilters.prototype.filterGraph = function() {
	if(!this.graph) {
		log.error('Graph is not initialized.');
		return false;
	}

	const nodes = this.graph.getNodes();
	const visibleNodes = this.graph.getFilteredNodes();

	const relations = this.graph.getRelations();
	const visibleRelations = this.graph.getFilteredRelations();

	const showNodeFilters = _.filter(this.getFilters(), filter => filter.isNodeFilter() && filter.isShow());
	const hideNodeFilters = _.filter(this.getFilters(), filter => filter.isNodeFilter() && filter.isHide());

	const showRelationFilters = _.filter(this.getFilters(), filter => filter.isRelationFilter() && filter.isShow());
	const hideRelationFilters = _.filter(this.getFilters(), filter => filter.isRelationFilter() && filter.isHide());

	const newVisibleNodes = [];
	const newVisibleRelations = [];
	const filteredOutNodeIds = new Set();

	_.forEach(nodes, node => {
		// Node should be shown if either 1) a Show-filter matches the node
		if(_.find(showNodeFilters, filter => filter.matchesNode(node))) {
			newVisibleNodes.push(node);
			return;
		}
		// or 2) no Hide-filter matches the node
		if(!_.find(hideNodeFilters, filter => filter.matchesNode(node))) {
			newVisibleNodes.push(node);
			return;
		}
		filteredOutNodeIds.add(node.id);
	});

	// Same for relations
	_.forEach(relations, relation => {
		// If source or target node of relation was filtered out, filter relation
		if(filteredOutNodeIds.has(relation.source) || filteredOutNodeIds.has(relation.target)
			// D3Graph uses source/target objects instead of ids
			|| filteredOutNodeIds.has(_.get(relation, 'source.id')) || filteredOutNodeIds.has(_.get(relation, 'target.id'))) {
			return;
		}
		if(_.find(showRelationFilters, filter => filter.matchesRelation(relation))) {
			newVisibleRelations.push(relation);
			return;
		}
		if(!_.find(hideRelationFilters, filter => filter.matchesRelation(relation))) {
			newVisibleRelations.push(relation);
		}
	});

	const isEqualEntity = (entity1, entity2) => {
		if(_.isArray(entity1) || _.isArray(entity2)) return; // required by lodash customizer
		return entity1.id === entity2.id;
	};
	if(_.isEqualWith(visibleNodes, newVisibleNodes, isEqualEntity)
	&& _.isEqualWith(visibleRelations, newVisibleRelations, isEqualEntity)) {
		// Nothing changed
		return false;
	}

	this.fire(GraphFilters.Event.FILTER, {
		nodes: newVisibleNodes,
		relations: newVisibleRelations
	});
	return true;
};

GraphFilters.prototype.filterGraphOLD = function() {
	if (!this.graph) {
		return _.withError(['GraphFilters.filterGraph: graph is not initialized', this.graph]);
	}

	// TODO: why is `filterableNodes` not equal to `this.graph.getNodes()`?
	var visibleNodes = this.graph.getFilteredNodes();
	var filterableNodes = this.graph.getNodes();

	var filteredNodes = []; //should be visible (not filtered out)
	var filteredOutNodes = [];

	var hideNodeFilters = _.filter(this.getFilters(), function(filter) {
		return (filter.isNodeFilter() && filter.isHide());
	});
	var showNodeFilters = _.filter(this.getFilters(), function(filter) {
		return (filter.isNodeFilter() && filter.isShow());
	});

	_.forEach(filterableNodes, function(node) {
		var matchesNode = function(filter) {
			return filter.matchesNode(node);
		};

		var filterThatShows = _.find(showNodeFilters, matchesNode);

		if (filterThatShows) {
			filteredNodes.push(node);
			return;
		}

		var filterThatHides = _.find(hideNodeFilters, matchesNode);

		if (filterThatHides) {
			filteredOutNodes.push(node);
			return;
		}

		filteredNodes.push(node);
	});

	var visibleRelations = this.graph.getFilteredRelations();
	var filterableRelations = this.graph.getRelations();

	var filteredRelations = []; //should be visible (not filtered out)
	var filteredOutRelations = [];

	var hideRelationFilters = _.filter(this.getFilters(), function(filter) {
		return (filter.isRelationFilter() && filter.isHide());
	});
	var showRelationFilters = _.filter(this.getFilters(), function(filter) {
		return (filter.isRelationFilter() && filter.isShow());
	});

	_.forEach(filterableRelations, function(relation) {
		var matchesRelation = function(filter) {
			return filter.matchesRelation(relation);
		};

		var filterThatShows = _.find(showRelationFilters, matchesRelation);

		if (filterThatShows) {
			filteredRelations.push(relation);
			return;
		}

		var filterThatHides = _.find(hideRelationFilters, matchesRelation);

		if (filterThatHides) {
			filteredOutRelations.push(relation);
			return;
		}

		filteredRelations.push(relation);
	});

	// TODO: why not use _.isEqual?
	var arraysAreIdentical = function(firstArray, secondArray) {
		if (firstArray.length !== secondArray.length) {
			return false;
		}

		var difference = _.pullAll(firstArray, secondArray);

		return ! difference.length;
	};

	// TODO: why do we want to report incremental changes? Why don't we just report which nodes should be visible/invisible?
	var updates = {
		updateModel: true,
		hide: {
			nodes: [],
			relations: []
		},
		unhide: {
			nodes: [],
			relations: []
		},
		visible: {
			nodes: filteredNodes,
			relations: filteredRelations
		}
	};

	var triggerUpdate = false;

	if (! arraysAreIdentical(visibleNodes, filteredNodes)) {
		updates.hide.nodes = this.setFilteredOut(filteredOutNodes);
		updates.unhide.nodes = this.removeFilteredOut(filteredNodes);
		triggerUpdate = true;
	}

	if (! arraysAreIdentical(visibleRelations, filteredRelations)) {
		updates.hide.relations = this.setFilteredOut(filteredOutRelations);
		updates.unhide.relations = this.removeFilteredOut(filteredRelations);
		triggerUpdate = true;
	}

	if (! triggerUpdate) {
		return false;
	}

	this.fire(GraphFilters.Event.FILTER, updates);

	return true;
};

// Check if a filter for autofilter already exists
GraphFilters.prototype.autoFilterExists = function(type, label) {
	return !! _.find(
		this.getFilters(), 
		(filter) => filter.getFullFilter() == `${type}:${label}`
	)
};

GraphFilters.prototype.updateFilterStatus = function() {
	var self = this;
	_.forEach(this.filters, function(filter, i) {
		var state = filter.getStatus() === FILTER_STATUS_SHOW;
		if(filter.isNodeFilter()) {
			_.find(self.graph.getNodes(), function(node) {
				if(filter.matchesNode(node)) {
					if(state !== node.visible) {
						state = undefined;
						return true;
					}
				}
			});
		} else {
			_.find(self.graph.getRelations(), function(relation) {
				if(filter.matchesRelation(relation)) {
					if(state === null) {
						state = relation.visible;
					} else if(state !== relation.visible && !relation.hiddenBecauseOfNeighbours) {
						state = undefined;
						return true;
					}
				}
			});
		}
		if(state === undefined) {
			filter.setStatus(FILTER_STATUS_SHOW);
		}
	});
};

GraphFilters.prototype.toSimpleList = function() {
	var list = {};
	_.forEach(this.filters, function(filter) {
		var key = filter['key'] || _.uniqueId('_auto');

		list[key] = {
			filter: filter.filter,
			status: filter.status
		};
	});

	return list;
};

GraphFilters.prototype.loadFromSimpleList = function(filterList) {
	var self = this;
	this.filters.length = 0;  // keeps the same pointer to the filters array so that the array remains binded to the jqxDataTable

	_.forEach(filterList, function(filter, key) {
		filter = self.addFilter(filter.filter, filter.status);
		if(_.isObject(filter)) {
			filter['key'] = key;
		}
	});
};

GraphFilters.prototype.updateAutoFilters = function() {
	var self = this;
	if (!this.autoFilterStatus) {
		return;
	}

	if (!this.graph) {
		return;
	}

	let allUniqueNodeLabels = _.uniq(_.flatMap(this.graph.getNodes(), 'labels'));

	// Add new filters for each node:label
	_.forEach(allUniqueNodeLabels, function(label) {
		var filterString = 'node:' + label;
		if (!self.autoFilterExists('node', label)) {
			var filter = self.addFilter(filterString, FILTER_STATUS_INACTIVE);
			filter.key = _.uniqueId('_auto-');
		}
	});

	let allUniqueRelTypes = _.uniq(_.map(this.graph.getRelations(), 'type'));

	// Add new rel:type filters
	_.forEach(allUniqueRelTypes, function(type) {
		var filterString = 'rel:' + type;
		if (!self.autoFilterExists('rel', type)) {
			var filter = self.addFilter(filterString, FILTER_STATUS_INACTIVE);
			filter.key = _.uniqueId('_auto-');
		}
	});
};

GraphFilters.prototype.getFiltersTableContainer = function() {
	// Init filters table
	var $table = this.$container.find('.filters-table').first();

	if ($table.length) {
		return $table;
	}

	if (!this.$container.length) {
		console.error('GraphFilters.getFiltersTableContainer: $container not initialized');
		return false;
	}

	this.$container.append('<div class="filters-table"></div>');

	return this.getFiltersTableContainer();
};

GraphFilters.prototype.breakDown = function() {
	this.$filtersTable.jqxDataTable('destroy');
	this.$filtersTable.remove();
	this.getFiltersTableContainer().remove();
	this.$container.remove();
	this.removeListeners();
	this.graph = null;
};

GraphFilters.prototype.initPanel = function(containerElement) {
	var self = this;
	this.$container = $(containerElement);
	if (this.$container.length < 1) {
		console.error('GraphFilters.init: container not found', containerElement);
		return;
	}
	this.$container.html(FILTER_WINDOW_LAYOUT);

	this.autoFilterCheckbox = this.$container.find('input[name=auto-update-filters]');

	// Init filters form
	this.filterForm = new FilterForm({
		container: this.$container.find('.filter-form-container').first(),
		hiddenContent: '<button class="d-flex align-items-center btn btn-primary" type="button" data-action="create-filter"><i class="fa fa-plus me-1"></i>Filter</button>',
		onSubmit: _.bind(this.onSubmitFilterForm, this)
	});

	this.$filtersTable = this.getFiltersTableContainer();

	var dataSource = {
		localData: this.getFilters(),
		dataType: "array",
		dataFields:
			[
				{ name: 'type', type: 'string' },
				{ name: 'filterBody', type: 'string' },
				{ name: 'status', type: 'integer' },
			]
	};
	var dataAdapter = new $.jqx.dataAdapter(dataSource);

	//  Enable/activate jaxDataTable
	this.$filtersTable.jqxDataTable({
		pageable: true,
		source: dataAdapter,
		columnsResize: true,
		enableHover: true,
		sortable: true,
		pageSize: 10,
		height: 390,
		width: 'auto',
		pageSizeOptions: ['5', '10', '20', '30'],
		pagerMode: 'advanced',
		rendered: function() {
			self.$filtersTable.find('div.filter-state').each(function() {
				var $this = $(this);
				var status = false;

				if ($this.attr('status') === 'checked') {
					status = true;
				}
				if ($this.attr('status') === 'indeterminate') {
					status = null;
				}
				var checkboxSettings = {
					hasThreeStates: true,
					checked: status
				};
				$this.jqxCheckBox(checkboxSettings);
			});
		},
		columns: [
			{
				text: 'Explore',
				align: 'center',
				width: 35 ,
				sortable: false,
				rendered: function (element, align, height) {
					self.globalStateElement = element;

					var renderGlobalState = function() {
						$(element).html(self.globalState.getStatusIcon());
					};

					renderGlobalState();

					element.on('click', function (event) {
						self.globalState.setStatus(self.globalState.getNextStatus());
						renderGlobalState();
						self.switchAllFiltersStatusTo(self.globalState.getStatus());
						self.updateInterface();
						self.filterGraph();
					});

					return true;
				},
				cellsRenderer: function (row, column, value, rowData) {
					return '<button class="filter-state d-flex justify-content-center btn btn-sm btn-primary w-100" index="' + rowData.uid + '">' + self.getFilter(rowData.uid).getStatusIcon() + '</button>';
				}
			},
			{
				text: 'Type',
				align: 'center',
				dataField: 'type',
				width: 50
			},
			{
				text: 'Filter',
				dataField: 'filterBody',
				width: 300,
				cellsRenderer: function(row, column, value, rowData) {
					return $("<div>").text(value).html(); // escape html
				}
			},
			{
				text: 'Actions',
				align: 'center',
				width: 58,
				sortable: false,
				cellsRenderer: function (row, column, value, rowData) {
					var actions = `
						<div class="container">
							<div class="row d-flex align-items-center text-center">
								<button class=\"fa fa-pencil\ col p-0 btn btn-sm btn-primary me-1" index=${rowData['uid']} data-action=\"action-edit-filter\"></button>
								<button class=\"fa fa-times\ col p-0 btn btn-sm btn-danger ms-1" index=${rowData['uid']} data-action=\"action-delete-filter\"></button>
							</div>
						</div>
					`;

					return actions;
				}
			}
		]
	});

	// Set event on tables checkboxes to update the items statuses
	this.$filtersTable.on('click', '.filter-state', function(){

		var $this = $(this);
		var filterItemIndex = $this.attr('index');
		var filterItem = self.getFilter(filterItemIndex);
		filterItem.setStatus(filterItem.getNextStatus());

		$this.html(filterItem.getStatusIcon());

		self.updateInterface();
		self.filterGraph();
	});

	// Set event for edit filter
	this.$filtersTable.on('click', '[data-action=action-edit-filter]', function(event){
		event.preventDefault();

		var itemIndex = $(this).attr('index');
		self.loadFilterInEdit(self.getFilter(itemIndex), itemIndex);
	});

	// Set event for delete filter
	this.$filtersTable.on('click', '[data-action=action-delete-filter]', function(event){
		event.preventDefault();

		var itemIndex = $(this).attr('index');

		self.deleteFilter(itemIndex);
		self.updateInterface();
		self.filterGraph();
	});

	// Event auto update filters change
	this.$container.on('change', 'input[name=auto-update-filters]', function() {
		self.autoFilterStatus = $(this).prop('checked');
		self.updateAutoFilters();
		self.updateInterface();
		self.filterGraph();
	});

	this.$container.on('click', '[data-action=create-filter]', function() {
		var newFilter = new GraphFilterItem('node', FILTER_STATUS_SHOW);

		self.loadFilterInEdit(newFilter);
	});

	if (this.autoFilterStatus) {
		this.setAutoFilterStatus(this.autoFilterStatus);
	}
};

GraphFilters.prototype.updateInterface = function() {
	var self = this;
	if (self.updateUI) {
		self.updateFiltersTable();
	}
};

GraphFilterItem.prototype.getExpandedSelector = function() {
	if (!this.expandedSelector) {
		this.expandedSelector = GraphSelector.expandSelector(this.getFullFilter());
	}

	return this.expandedSelector;
};

module.exports = {
	GraphFilterItem,
	GraphFilters
};
