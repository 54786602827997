import { isNil, isPlainObject, forEach, isString, cloneDeep, get, isArray } from 'lodash';
import { Validity, validateObject } from "utils/src/validation";
const { t } = require('core/src/language');

const GraphFilterStatus = {
	INACTIVE: 0,
	SHOW: 1,
	HIDE: -1
};

function validateGraphFilters(filters) {
	const filtersList = cloneDeep(filters);
	const validityMap = {};
	let isValid = true;

	if(isNil(filtersList)) {
		return true;
	}

	if(!isPlainObject(filtersList) && !isArray(filtersList)) {
		return new Validity('filters', filters, false, t('Invalid filters list.'));
	}

	// Selectors must be unique
	const selectors = {};

	// Works for array and for object
	forEach(filtersList, (filter, i) => {
		if(get(filter, 'filter') === 'autoFilters') {
			validityMap[i] = validateObject(i, filter, {
				filter: 'isString',
				status: 'isBoolean'
			}, t('Invalid filter definition.'), false);
		}
		else {
			validityMap[i] = validateObject(i, filter, {
				filter: [
					function(x) {
						return isString(x) && !(x.trim() in selectors);
					}, // check for selector uniqueness
					function(x) { return !isString(x)
						? t('Filter selector must be string.')
						: t(`Filter selector '{{x}}' is already used by filter '{{selectorName}}'.`, {x, selectorName: selectors[x.trim()]})
					}
				],
				status: 'isStringOrNumber'
			}, t('Invalid filter definition.'), false);
		}

		if(! validityMap[i].isValid()) {
			isValid = false;
		} else {
			selectors[filter['filter'].trim()] = i;
		}
	});

	const valid = new Validity('filters', filters, isValid, t('Invalid filters list.'));
	valid.setValidityMap(validityMap);
	return valid;
}

export {
	GraphFilterStatus,
	validateGraphFilters
}
