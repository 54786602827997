const VueRenderer = require('../vue-renderer');
const NeighboursView = require('./neighbours-view.vue').default;

const NeighboursViewRenderer = function (dependancies) {
	VueRenderer.call(this, dependancies);
};

NeighboursViewRenderer.viewType = 'NeighboursView';
NeighboursViewRenderer.prototype = Object.create(VueRenderer.prototype);

NeighboursViewRenderer.prototype.getVueComponent = function() {
	return NeighboursView;
};

module.exports = NeighboursViewRenderer;