const Function = require('core/src/function');
const IAPI = require('core/src/api-client-abstract').default;
const _ = require('core/src/utils/legacy');
const deferredPromise = require('core/src/utils/deferred-promise');
const Language = require('core/src/language').default;

let SparqlQuery = Function.extend(Function, 'SparqlQuery');

SparqlQuery.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);

	let params = {
		'$sparql': '<required>',
		'$params': undefined,
		'$autoComplete': false,
		'$process': false,
		'$store': undefined
	};
	this.setParameters(params);

	let notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		sparql: 'isString',
		params: ['isObject', notRequired],
		autoComplete: ['isBooleanParsable', {default: false, warn: _.def}],
		process: ['isBooleanParsable', {default: false, warn: _.def}],
		store: ['isString', notRequired]
	});
};

SparqlQuery.prototype.onExecute = function () {
	const promise = new Promise(async (resolve, reject) => {
		const sparqlQuery = {
			id: this.getId(),
			sparql: this.getInput('sparql'),
			params: this.getInput('params'),
			autoComplete: this.getInput('autoComplete'),
			process: this.getInput('process'),
			store: this.getInput('store')
		};

		try{
			const response = await this.api.executeSparql(sparqlQuery);
			const event = {
				type: 'success',
				data: _.get(response, 'result.table'),
				processed: _.get(response, 'result.processed'),
				meta: {
					columns: _.get(response, 'resultInfo.columns'),
					nodecount: _.get(response, 'resultInfo.nodecount'),
					relcount: _.get(response, 'resultInfo.relcount'),
					sparql: _.get(response, 'request.sparql'),
					params: _.get(response, 'request.params'),
					store: _.get(response, 'request.store'),
					processData: _.get(response, 'request.process'),
					autoComplete: _.get(response, 'request.autoComplete'),
					status: _.get(response, 'status'),
					stats: _.get(response, 'meta.stats')
				}
			};

			this.executeTriggers(event);
			resolve(true);
		}
		catch (error) {
			reject(new _.Error({
				message: this.language.translate('Sparql error: {{errorMsg}}', {errorMsg: _.get(error, 'message')}),
				data: error
			}));
		}
	});

	return deferredPromise(promise);
};

module.exports = SparqlQuery;
