const RichTextEditor = require('./rich-text-editor');

module.exports = {
	options: {
		mode: 'text/html',
		lineNumbers: true
	},
	alternativeEditors: {
		'html': 'HTML',
		'wysiwyg': {
			label: 'WYSIWYG',
			Editor: RichTextEditor
		}
	}
};
