const $ = require('jquery');
$(document).ready(function() {
	// Activates collapsible functionality
	$(document).on('click', '.collapse-toggle' , function() {
		var $parent = $(this).parents('.collapsible').first();
		if ($parent.hasClass('collapsed')) {
			$parent.removeClass('collapsed');
			setTimeout(() => {$(window).trigger('resize')}, 20 );
			return;
		}
		$parent.addClass('collapsed');
	});
	// Activate toggle-class
	$(document).on('click', '[toggle-class]' , function() {
		var $this = $(this);
		var classToToggle = $this.attr('toggle-class');
		if (! classToToggle) {
			console.error(['Toggle-class attribute present but empty for', $this]);
			return;
		}
		var $elem = $this;
		var href=$this.attr('href');
		if (href) {
			$elem = $(href);
		}
		if ($elem.hasClass(classToToggle)) {
			$elem.removeClass(classToToggle);
			$this.removeClass('active');
			return;
		}
		$elem.addClass(classToToggle);
		$this.addClass('active');
	});
});