import PersonalEdition from "core/src/edition/personal-edition";
import _ from "lodash";
import Edition from "./edition";

const editions = [
	PersonalEdition,
	require("./g64-edition").default,
	require("./g64t-edition").default,
	require("./g128-edition").default,
	require("./g256-edition").default,
	require("./g512-edition").default,
	require("./g1024-edition").default,
	require("./enterprise-edition").default,
];

_.forEach(editions, EditionClass => {
	Edition.register(EditionClass.code, EditionClass);
});
Edition.setDefaultEdition(PersonalEdition);
Edition.setCurrent(new PersonalEdition());

export default Edition;
