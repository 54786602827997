const Function = require('core/src/function');
const View = require('core/src/functions/view');

const { def } = require('utils/src/validation');
const { map, isNil } = require('lodash');
const deferredPromise = require('core/src/utils/deferred-promise');
const { findChangeFromRoot } = require('core/src/utils/changes');

const AgGridView = Function.extend(View, "AgGridView");

/**
 * Increments the given firstTry id until it does not exist in the existing ID set.
 * @param {number} firstTry		First id to try.
 * @param {Set} existingIds		A Set of existing ids.
 */
function generateUniqueId(firstTry, existingIds) {
	if(!existingIds.has(firstTry)) {
		return firstTry;
	}
	return generateUniqueId(firstTry+1, existingIds);
}

AgGridView.Event = {
	In: {
		SELECTION: 'selection'
	}
};
AgGridView.FEATURE = 'ag-grid';

AgGridView.prototype.onInit = function() {
	this.setParameters({
		'data': undefined,
		'options': undefined,
		'sizeColumnsToFit': false,
		'autoSizeColumns': false,
		'hiddenColumns' : undefined,
		'state': {} // if it's not a parameter, we cannot call update on it
	});

	this.setModelValidation({
		data: [['isObject'], {default: undefined, warn: def}],
		options: ['isObject', {default: {}, warn: def}],
		hiddenColumns: ['isArray', {default: [], warn: def}]
	});

	this.setUpdatePriorities({
		'data': -1 // before options
	});

	this.setModelUpdateHandler('data', this.handleDataUpdate);
	this.setDeepModelUpdateHandler('options', this.handleOptionsUpdate);
	this.setDeepModelUpdateHandler('state', this.handleStateUpdate);
};

AgGridView.prototype.handleDataUpdate = function(newData) {
	let data = newData;

	let ids = new Set(map(data, 'id'));

	// Provide default row ids
	let changed = false;
	const setVisible = [];
	for(let i = 0, id=i; i < data.length; i++) {
		if(!('id' in data[i])) {
			id = generateUniqueId(id, ids);
			data[i].id = id++;
			changed = true;
		}
		// New data starts visible
		if(!('visible' in data[i])) {
			data[i].visible = true;
			setVisible.push(data[i]);
			changed = true;
		}
	}
	if(changed) {
		this.updateModel('data', data);
	}
	if(setVisible.length) {
		this.updateModel('state.visible', setVisible);
	}
	this.reviseState();

	/*
	18 sep 2019: Losing the '_selected' property means a data update.
		If you don't put the property means you don't care about the state so this code is commented to not be added again.
		This will allow setting the selected state by setting _selected on the row data and not only on state._selected

		If you want to update the data on the table and keep the _selected property use #_update.merge
	*/

	// In case the new data lost the 'selected' properties
	// this.applySelection();
};

AgGridView.prototype.handleOptionsUpdate = function(changes) {
	const rowDataChange = findChangeFromRoot(changes, 'options.rowData');
	if(!rowDataChange) return;

	// Backward compatibility
	if(rowDataChange.new) {
		this.updateModel('data', rowDataChange.new);
	}
};

AgGridView.prototype.handleStateUpdate = function(changes) {
	if(findChangeFromRoot(changes, 'state.selected')) {
		this.reviseState();
		this.applySelection();
	}
};

AgGridView.prototype.applySelection = function() {
	this.setItemsStateProperty('data', '_selected', 'state.selected');
};

AgGridView.prototype.reviseState = function() {
	this.reviseStateListConsistency('state.selected', 'data');
};

AgGridView.prototype.createEmptyModel = function() {
	return {
		state: {
			selected: [],
			visible: []
		}
	};
};

// For overriding View.prototype.createRenderData function in order to avoid losing cellRenderer function in some browsers
// (e.g. Safari) after renderData object cloned using cloneDeep
AgGridView.prototype.createRenderData = function(model){
	const promise = new Promise(async (resolve, reject) => {
		let renderData = await View.prototype.createRenderData.call(this, model);
		if (renderData.input.hasOwnProperty('options') && renderData.input.options.hasOwnProperty('columnDefs')){
			for (let i = 0; i < renderData.input.options.columnDefs.length; i++){
				if (!renderData.input.options.columnDefs[i].hasOwnProperty('cellRenderer')) continue;
				renderData.input.options.columnDefs[i].cellRenderer = model.options.columnDefs[i].cellRenderer;
			}
		}

		resolve(renderData);
	});
	return deferredPromise(promise);
};

AgGridView.prototype.onExecute = function() {
	// Google Maps does not have default/minimum height, so if container height was not set, we set it here.
	const containerHeight = this.readModel('container.height');
	if(isNil(containerHeight)) {
		this.updateModel('container.height', 400);
	}

	this.applyModelFixes();

	return View.prototype.onExecute.call(this);
};

AgGridView.prototype.onUpdate = function() {
	this.applyModelFixes();

	return View.prototype.onUpdate.call(this);
}

// Fixes breaking changes on AgGrid updates
AgGridView.prototype.applyModelFixes = function() {
	// Add floating filter as column property (previously it was a global option)
	if (_.get(this._model, ['options', 'floatingFilter']) === true) {
		_.defaults(this._model.options.defaultColDef, {floatingFilter: true});
	}
}

module.exports = AgGridView;
