const Function = require('core/src/function');
const View = require('core/src/functions/view');

const { def } = require('utils/src/validation');
const _ = require('lodash');

const ChartJsView = Function.extend(View, "ChartJsView");

ChartJsView.Event = {
	In: {
		CLICK: 'click'
	}
};

ChartJsView.FEATURE = 'chartjs-view';

/**
 * @override
 * @returns {undefined}
 */
ChartJsView.prototype.onInit = function() {
	this.setParameters({
		'$type': undefined,
		'$chartType': undefined,
		'$data': undefined,
		'$options': undefined
	});

	let types = ['bar', 'bubble', 'doughnut', 'gauge', 'horizontalbar', 'line', 'pie', 'polararea', 'radar', 'scatter'];
	this.setModelValidation({
		chartType: [(x) => types.indexOf(_.toLower(x)) >= 0, "Must be one of " + types.join(', '), {default: 'line', warn: def}],
		data: [{
			datasets: ['isObject']
		}],
		options: ['isObject', {default: {}, warn: def}]
	});

	this.setModelUpdateHandler('type', this.handleTypeChanged.bind(this));

	this.setUpdatePriorities({
		'chartType': -1 // before type
	});
};

ChartJsView.prototype.handleTypeChanged = function(newValue, oldValue) {
	const chartType = this.readModel('chartType');
	if(chartType) return; // chartType is set; ignore type

	// Handle backward compatibility
	this.updateModel('chartType', newValue);
};

module.exports = ChartJsView;

