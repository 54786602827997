const EventInterface = require('core/src/event-interface');

class AlternativeEditorAbstract {
	constructor() {
		EventInterface.extend(this);
	}

	fireChange(value) {
		this.fire('change', value);
	}

	setValue(value) {
		return null;
	}

	getValue() {

	}

	destroy () {

	}

	setContext(context) {
		this._context = context;
		this.onContextChanged();
	}

	onContextChanged() {

	}
}

export default AlternativeEditorAbstract;
