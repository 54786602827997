const CodeEvaluator = require('core/src/code-evaluator');

const _ = require('core/src/utils/legacy');
const md5 = require('md5');
const stringFormat = require('string-format');
const Language = require('core/src/language').default;
const t = require('core/src/language').t;
const Pivot = require('quick-pivot');
const Turf = require('@turf/turf');
const WKT = require('wkt');

/**
 * Wrapper for string-format that creates a function if only the template was provided.
 * @return {*}
 */
const codeEvaluatorStringFormat = function() {
	let args = Array.from(arguments);
	if(args.length === 1) {
		return function() {
			let finalArgs = Array.from(arguments);
			finalArgs.unshift(args[0]);
			return codeEvaluatorStringFormat.apply({}, finalArgs);
		};
	}
	return stringFormat.apply({}, args);
};

const createDefaultCodeEvaluator = function(dependencies) {
	let translate = t;
	if(dependencies) {
		const language = dependencies.get(Language);
		translate = function(key, options) { return language.translate(key, options); }
	}

	const evaluator = new CodeEvaluator();
	evaluator.setConfig({
		failOnInvalidProperty: false,
		expressionSets: {
			'path': {
				VariableDeclaration: true,
				MemberExpression: true,
				Literal: true,
				Identifier: {
					map: true,
					flatten: true,
					translate: true,
					t: true, // alias
					evaluate: true
				},
				CallExpression: true
			},
			'full': undefined,
			'none': {}
		}
	});

	// Splits a string into an array of strings of max length. Word separator is space, paragraph separator is \n
	let splitStringIntoLines = (string, rowMaxLength = 50) => {
		return _.flow([
			(string) => _.split(string, '\n'),
			(paragraphs) => _.map(paragraphs, (paragraph) => _.split(paragraph, ' ')),
			(paragraphs) => _.flatMap(
				paragraphs,
				(paragraph) => _.reduce(
					paragraph,
					(res, word) => {
						let line = _.size(res) - 1;
						if ((_.size(res[line]) + _.size(word) + 1) > rowMaxLength) {
							res.push('');
							line += 1;
						}
						res[line] = res[line] + (_.size(res[line]) ? ' ' : '') + word;
						return res;
					},
					['']
				)
			)
		])(string)
	};

	// Add lodash to evaluator functions
	_.forEach(_, function(func, i) {
		if(_.isFunction(func)) {
			evaluator.setPublic(i, func);
		}
	});

	// Deep process a nested structure, flip arrays with 2 finite number values ([x,y] => [y,x])
	let flipXYCoords = (val) => {
		if (_.isArray(val) && _.size(val) === 2 && _.isFinite(val[0]) && _.isFinite(val[0])) {
			return [val[1], val[0]];
		}

		if (_.isArray(val)) {
			return _.map(val, flipXYCoords)
		}

		if (_.isPlainObject(val)) {
			return _.mapValues(val, flipXYCoords)
		}		

		return val;
	}

	evaluator.setPublic('_', _);
	evaluator.setPublic('addColumn', _.addColumn);
	evaluator.setPublic('arrayToTable', _.arrayToTable);
	evaluator.setPublic('btoa', function(value) { return btoa(value); }); // Closure because btoa cannot be called with btoa.apply(this, [value])
	evaluator.setPublic('cellsToRows', _.cellsToRows);
	evaluator.setPublic('Console', () => console);
	evaluator.setPublic('console', console);	
	evaluator.setPublic('formatString', codeEvaluatorStringFormat);
	evaluator.setPublic('isoDate', _.isoDate);
	evaluator.setPublic('Turf', Turf);
	evaluator.setPublic('isoDatetime', _.isoDatetime);
	evaluator.setPublic('isoTime', _.isoTime);
	evaluator.setPublic('Math', () => Math);
	evaluator.setPublic('md5', md5);
	evaluator.setPublic('nodeToCreateStatement', _.nodeToCreateStatement);
	evaluator.setPublic('pivot', (...params) => (new Pivot(...params)));
	evaluator.setPublic('pivotDataToObjects', _.pivotDataToObjects);
	evaluator.setPublic('relationToCreateStatement', _.relationToCreateStatement);
	evaluator.setPublic('splitIntoLines', splitStringIntoLines);
	evaluator.setPublic('t', translate); // alias
	evaluator.setPublic('toDate', (value) => (new Date(value)));
	evaluator.setPublic('translate', translate);
	evaluator.setPublic('WKT', WKT);
	evaluator.setPublic('flipXYCoords', flipXYCoords);

	evaluator.setAdvancedFunction('evaluateString', (evaluator, scopes, value) => {
		// Evaluate a string inside an evaluated string. Allows nesting an evaluation and evaluation of string variables.
		const result = evaluator.evaluate('let evaluated = '+value, scopes[0], {expressionSet: 'full'});
		return result['evaluated'];
	});

	evaluator.setAdvancedFunction('findMatches', (evaluator, scopes, regex, context) => {
		if (!_.isEmpty(context) && !_.isEmpty(regex)) {
			const result = context.match(new RegExp(regex, 'g'))
			return result;
		}
	});

	evaluator.setAdvancedFunction('evaluateWithInput', (evaluator, scopes, key, value, inputAsContext) => {
		const getParameterMetaData = function(path, parameterMeta) {
			var value = undefined;
			if(path === undefined) {
				value = parameterMeta;
			} else {
				if(_.isArray(path)) path = path.join('.');
				value = _.get(parameterMeta, path);
			}

			return value;
		}

		const getParameterDefinition = function (parameterName) {
			let match = /^([$#]?)([\w\d.]+?)(?::([^:]*))?$/.exec(parameterName);
			if (!match) {
				return null;
			}
			return {
				prefix: match[1],
				root: match[2].split('.')[0],
				name: match[2],
				meta: match[3]
			};
		};

		const parameterToKey = function (parameter) {
			let def = getParameterDefinition(parameter);
			if (def !== null && !_.isEmpty(def.prefix)) {
				return parameter.substring(1);
			}

			return parameter;
		};

		const getEvaluationLevel = function(value) {
			if(value === undefined) return 'path';
			return value;
		};

		const matchValueToParameterType = function(parameter, value) {
			if(value === undefined) {
				return value;
			}

			if (_.isArray(value) && (value.length > 0) && (getParameterType(parameter) == 1)) {
				value = value[0];
			}
			else if (!_.isArray(value) && (getParameterType(parameter) == 2)) {
				value = [value];
			}

			return value;
		};

		const isValidParameter = function (parameterName, parameterValue) {
			if (!_.isString(parameterName) || parameterName.length < 2) {
				return false;
			}

			if (!_.isObjectPath(parameterName.substring(1))) {
				return false;
			}

			if (!(_.includes(['$', '#'], parameterName[0]))) {
				return false;
			}

			if ((_.includes(['$'], parameterName[0])) && (_.isArray(parameterValue)) && (parameterValue !== undefined)) {
				return false;
			}

			return true;
		};

		const getParameterType = function (parameter) {
			if (!isValidParameter(parameter)) {
				return false;
			}

			if (parameter.substring(0, 1) === '$') {
				return 1;
			}

			if (parameter.substring(0, 1) === '#') {
				return 2;
			}

			return false;
		}

		// Evaluate a string inside an evaluated string. Allows nesting an evaluation and evaluation of string variables.
		if (inputAsContext == '')
			throwError('Context can not be null');

		const paramDef = getParameterDefinition(key);
		if(paramDef === null) {
			console.error('Should not be here: parameter is not valid', key);
			return;
		}

		const outputKey = parameterToKey(key);

		const evaluate = _.get(getParameterMetaData(outputKey), 'evaluate');
		const expressionSet = getEvaluationLevel(evaluate);

		const reg = /  |\r\n\t|\r\n|\n|\r/gm;
		let code = value.replace(reg,"").trim();
		try {
			code = CodeEvaluator.replaceCodePlaceholders(code, {
				'(%)': '_event',
				'(@)': '_event._global'
			});
		} catch (e) {
			console.error(e);
		}
		
		const context = _.extend(scopes[0],{_event: inputAsContext});
		let result;
		try {
			result = evaluator.evaluate('let evaluated = ' + code, context, {arrayExpressions: true, expressionSet});
			value = result['evaluated'];
		} catch (err) {
			if (expressionSet === 'full' || /^e(?:valuate)?\(.*\)$/.exec(code)) {
				throw new _.Error({
					message: `Could not evaluate parameter value '${value}'.`,
					originalError: err
				});
			}
			return value;
		}
		value = matchValueToParameterType(key, value);
		if(_.isPlainObject(value)) {
			// this is so that the receiving Function knows to replace everything at this path (outputKey) with the given value
			value['_valueObject'] = true;
		}
		return value;
	});

	return evaluator;
};

module.exports = createDefaultCodeEvaluator;
