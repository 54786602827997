const isString = require('lodash/isString');

const Icons = {
	isIconClass: function(value) {
		if(!isString(value)) return false;

		return /^fa fa-[\w-]+$|^mdi mdi-[\w-]+$/.exec(value.trim()) !== null;
	},
	toIconClass: function(name, defaultClass) {
		if(!isString(name) || name.length === 0) {
			return defaultClass;
		}
		if(Icons.isIconClass(name)) return name;

		return 'fa fa-' + name;
	}
};

module.exports = Icons;
