const Function = require('core/src/function');
const FileSaver = require('file-saver');

const _ = require('core/src/utils/legacy');

const FileDownload = Function.extend(Function, "FileDownload");

/**
 * @override
 * @returns {undefined}
 */
 FileDownload.prototype.onInit = function() {
	this.setParameters({
		'$content': '',
		'$mimeType': 'text/plain',
		'$fileName': '',
	});

	const notRequired = {default: [], warn: _.def};
	this.setModelValidation({
		content		: ['isString', notRequired],
		mimeType		: ['isString', notRequired],
		fileName		: ['isString', notRequired],
	});
};

/**
 * @override
 * @param {object} data The data passed with the event.
 */
 FileDownload.prototype.onExecute = async function(input) {
	const blob = new Blob([input.content], {type: input.mimeType});
	let res = FileSaver.saveAs(blob, input.fileName);
	this.executeTriggers({type: 'success'});
};

module.exports = FileDownload;