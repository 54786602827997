import createDefaultCodeEvaluator from './default-code-evaluator';
import Registry from './registry';
import GraphStyles from "core/src/graph/graph-styles";
import Environment from "core/src/environment";
import registerDefaultFunctions from 'core/src/functions/all';
import Dependencies from "core/src/dependencies";
import ApplicationInfo from "core/src/application-info";
import IFactory from "core/src/i-factory";
import Factory from "core/src/factory";
import Language from "core/src/language";
import ActivityLog from 'core/src/activity-log';


// ES5 modules (must be imported with require, otherwise we get a Proxy)
const FTI = require('./fti');
const CodeEvaluator = require('./code-evaluator');

export default function setupDefaultCoreDependencies(dependencies, nodeSafe = false) {
	dependencies = dependencies || new Dependencies();

	dependencies.setDefault(ApplicationInfo, ()=>new ApplicationInfo());
	dependencies.setDefault(Registry, ()=>new Registry());
	dependencies.setDefault(Language, ()=>new Language(dependencies));
	dependencies.setDefault(FTI, ()=>new FTI(dependencies));
	dependencies.setDefault(CodeEvaluator, ()=>createDefaultCodeEvaluator(dependencies));
	dependencies.setDefault(Environment, ()=>new Environment());
	dependencies.setDefault(ActivityLog, ()=>new ActivityLog(dependencies));

	// Using an interface dependency to avoid circular import problems
	dependencies.setDefault(IFactory, ()=>{
		let factory = new Factory(dependencies);
		registerDefaultFunctions(factory, nodeSafe);
		return factory;
	});

	dependencies.setDefault(GraphStyles, ()=>{
		let graphStyles = new GraphStyles(dependencies);
		let codeEvaluator = dependencies.get(CodeEvaluator);
		codeEvaluator.setPublic('computeStyles', (entity, entityType, styleDefinitions) => {
			if(_.isPlainObject(styleDefinitions)) {
				let styles = graphStyles.extend(GraphStyles.unflatten(styleDefinitions));
				return styles.computeStyles(entity, entityType, true, true);
			}
			return graphStyles.computeStyles(entity, entityType, true, true);
		});
		return graphStyles;
	});

	return dependencies;
}
