const IAPI = require('core/src/api-client-abstract').default;
const ApplicationInfo = require('core/src/application-info').default;
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const {isReadOnly} = require('core/src/utils/read-only');
const Language = require('core/src/language').default;

const _ = require('core/src/utils/legacy');

const NodeFormView = Function.extend(View, "NodeFormView");

/**
	 * @override
	 * @returns {undefined}
	 */
NodeFormView.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.appInfo = dependencies.get(ApplicationInfo);
	this.language = dependencies.get(Language);

	this.setParameters({
		'$id': undefined,
		'$store': undefined,
		'storeOptions': undefined,
		'$storeTypes': undefined,
		'$preset': undefined,
		'$style': undefined
	});
};

NodeFormView.prototype._getNodeFromID = function(id, store) {
	var deferred = new $.Deferred();
	let self = this;
	// Fetch relation data
	var request = this.api.loadNode(id, store);
		request.done(function(node) {
			deferred.resolve(node);
		});
		request.fail(function(err) {
			deferred.reject(new _.Error({
				message: this.language.translate("could-not-load-node", {id, store}),
				public: true,
				originalError: err
			}));
		});

		return deferred.promise();
};

NodeFormView.prototype.alterRenderData = function (data) {
	let self = this;
	var deferred = new $.Deferred();
	data = data || {};

	var getNode;
	data.stores = this.getOutputStores(data);
	if(_.isStringOrNumber(data.id)) {
		getNode = this._getNodeFromID(data.id, data.store);
	}
	else if (_.isObject(data.preset)) {
		getNode = this._getNodeFromPreset(data.preset, data.store);
	}
	else {
		var node = {};
		if(data.store !== undefined) {
			_.set(node, 'meta.store', data.store);
		}
		getNode = new $.Deferred();
		getNode.resolve(node);
		getNode = getNode.promise();
	}

	getNode.done(function(node) {
		delete data.id;
		delete data.store;
		//delete data.preset;

		data.node = node;

		deferred.resolve(data);
	});
	getNode.fail(function(err) {
		deferred.reject(new _.Error({
			message: self.language.translate('Could not prepare render data.'),
			originalError: err
		}));
	});

	return deferred.promise();
};

NodeFormView.prototype.getOutputStores = function(input) {
		var definedOptions = _.isString(input.storeOptions) ? _.map(input.storeOptions.split(','), _.trim) : input.storeOptions;

		if (_.isArray(definedOptions)) {
			return _.filter(definedOptions, _.isString);
		}

			return this.getStores(input.storeTypes);
};

NodeFormView.prototype.getStores = function(storeTypes) {
	const types = _.isString(storeTypes) ? _.map(storeTypes.split(','), _.trim) : storeTypes;

	if (! _.isArray(types)) {
		return [];
	}

	const stores = this.appInfo.stores;

	const storeTypeIn = _.curry(function(typesList, store) {
		return _.includes(typesList, store.driver);
	});

	return _.keys(
		_.pickBy(
			stores,
			storeTypeIn(types)
		)
	);
};

/**
	 * @param {object} preset
	 * @param {string} [store]
	 * @returns {$.Deferred}
	 * @private
	 */
NodeFormView.prototype._getNodeFromPreset = function(preset, store) {
		let deferred = new $.Deferred();

		if(isReadOnly(preset)) {
			preset = preset.$clone().$writable();
		}

		let labels = preset.labels;
		if (_.isString(labels)) {
			preset.labels = _.parseArray(labels);
		}

		if(_.isString(store)) {
			_.set(preset, 'meta.store', store);
		}

		_.unset(preset, 'meta.core');

		deferred.resolve(preset);
		return deferred.promise();
};

module.exports = NodeFormView;
