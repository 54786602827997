import G64TEdition from "./g64t-edition";
import Edition from "./edition";

export default class G64Edition extends G64TEdition {
	static get code() {
		return 'g64';
	}
	static get name() {
		return Edition.name + ' 64 Edition';
	}
	defineFeatures() {
		return {
			...super.defineFeatures(),
			[Edition.Feature.USER_COUNT]: null,
			[Edition.Feature.DASHBOARD_COUNT]: null,
			[Edition.Feature.STORES_COUNT]: null
		};
	}
}
