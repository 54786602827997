const Log = require('./log');
const _ = require('./utils/legacy');
const deferredPromise = require('_common/core/src/utils/deferred-promise');

const log = Log.instance("core/eventinterface");

const EventInterface = function (config) {
	var self = this;
	this._listeners = {};

	if(_.isObject(config)) {
		if(_.isArray(config.events)) {
			this._strictEvents = true;
			_.forEach(config.events, function(event) {
				self._listeners[event] = [];
			});
		}
	}
};

EventInterface.extend = function(obj) {
	const eventInterface = new EventInterface();
	eventInterface.extend(obj);
};

EventInterface.ANY_EVENT 	= "ANY";

EventInterface.prototype._strictEvents = false; // if true, only preset events can be fired and listened to
EventInterface.prototype._listeners = null;
EventInterface.prototype._previouslyFired = {};

EventInterface.prototype._addListener = function(event, moment, listener, once) {
	var check = _.validate({
		event	: [event, "isString"],
		moment	: [moment, "isString"],
		listener: [listener, "isFunction"],
		once	: [once, "isBoolean", {default: false, warn: _.def}]
	}, "Could not register event listener.");
	if(!check.isValid()) return false;
	var valid = check.getValue();

	if(!_.isObject(this._listeners[valid.event])) {
		if(this._strictEvents) {
			Log.warn("Cannot listen to event called " + valid.event + ".");
			return false;
		}

		this._listeners[valid.event] = {};
	}
	if(!_.isArray(this._listeners[valid.event][valid.moment])) {
		this._listeners[valid.event][valid.moment] = [];
	}

	var listenerObj = {
		callback: valid.listener,
		once	: valid.once
	};
	// Check if listener doesn't exist yet
	var found = _.find(this._listeners[valid.event][valid.moment], function(listener) {
		return listener.callback === valid.listener;
	});
	// Add if not present
	if(!found) {
		this._listeners[valid.event][valid.moment].push(listenerObj);
		return true;
	}
	return false;
};

/**
 *
 * @param event			The name of the event.
 * @param moment		The moment in the event handling.
 * @param data			Will be passed as the first argument (data) to the listener.
 * @param eventArg 		Will be passed as the second argument (event) to the listener.
 * @returns DeferredPromise
 * @private
 */
EventInterface.prototype._fire = function(event, moment, data, eventArg) {
	const promise = new Promise((resolve, reject) => {
		var self = this;
		var check = _.validate({
			event	: [event, "isString"],
			moment	: [moment, "isString"],
			eventArg: [eventArg, 'isString']
		}, "Could not fire event.");
		if(!check.isValid()){
			return reject(check.createError());
		}
		const valid = check.getValue();

		var requests = [];
		var listeners = _.get(this._listeners, valid.event + '.' + valid.moment);
		if(_.isArray(listeners)) {
			for(var i = listeners.length-1; i >= 0; i--) {
				var listener = listeners[i];
				if(_.isFunction(listener.callback)) { // somehow it's sometimes undefined
					// Start callback
					requests.push(_.promise(listener.callback.call(self, data, eventArg)));

					// Remove if one-time use
					if(listener.once === true) {
						listeners.splice(i, 1);
					}
				}
			}
		}
		_.waitForAll(requests, 5000)
			.then(resolve)
			.catch(err => {
				log.error(err);
				reject(err);
			});
	});

	return deferredPromise(promise);
};

EventInterface.prototype.extend = function(target) {
	var self = this;
	var __extend = function(prop) {
		if(prop in target) {
			Log.warn("Class property/method '" + prop + "' is being overwritten by EventInterface in object:", target);
		}
		target[prop] = self[prop].bind(self);
	};
	__extend('before');
	__extend('on');
	__extend('after');
	__extend('dependOn');
	__extend('onEvent');
	__extend('fire');
	__extend('removeListener');
	__extend('removeListeners');
};

EventInterface.prototype.on = function(event, callback, once) {
	this._addListener(event, "0", callback, once);
};

EventInterface.prototype.before = function(event, callback, once) {
	this._addListener(event, "-1", callback, once);
};

EventInterface.prototype.after = function(event, callback, once) {
	this._addListener(event, "1", callback, once);
};

/**
 * Waits for an event to be fired to execute the given callback (once).
 * If the event was already fired before, callback will be executed immediately.
 *
 * @param event
 * @param callback
 */
EventInterface.prototype.dependOn = function(event, callback) {
	// Event was already fired before
	if(this._previouslyFired[event]) {
		callback();
		return;
	}

	// Otherwise wait for it (and fire once)
	this.on(event, callback, true);
};

/**
 * Register listener for any event.
 * @param {function} callback
 */
EventInterface.prototype.onEvent = function(callback) {
	var check = _.validate({
		callback : [callback, 'isFunction']
	}, "Could not register event listener.");
	if(!check.isValid()) return false;

	var ANY_EVENT = EventInterface.ANY_EVENT;

	this._addListener(ANY_EVENT, 'on', callback);
};

/**
 * Fire event.
 * @param {string} event
 * @param {object} data
 * @returns {$.Deferred}
 */
EventInterface.prototype.fire = function(event, data) {
	const promise = new Promise((resolve, reject) => {
		var self = this;
		var check = _.validate({
			event	: [event, "isString"]
		}, "Could not fire event.");
		if(!check.isValid()) {
			return reject(check.createError());
		}
		var valid = check.getValue();

		var momentsUnsorted;
		if(_.isObject(this._listeners[valid.event])) {
			momentsUnsorted = Object.keys(this._listeners[valid.event]);
			if(momentsUnsorted.indexOf("0") < 0) {
				momentsUnsorted.push("0");
			}
		} else {
			momentsUnsorted = ["0"];
		}

		var requests = [];
		var moments = momentsUnsorted.sort(_.compareIndex);
		_.forEach(moments, function(moment) {
			requests.push(self._fire(valid.event, moment, data, valid.event));
			if(moment === "0") {
				// Notify listeners for *any* event ('on').
				requests.push(self._fire(EventInterface.ANY_EVENT, 'on', data, valid.event));
			}
		});

		this._previouslyFired[event] = true;
		_.waitForAll(requests, 5000)
			.then(resolve)
			.catch(err => {
				log.error("Event listener error: ", err);
				reject(err);
			});
	});
	return deferredPromise(promise);
};

/**
 * Remove all listeners (for a specific event).
 * @param {string} [event]	  The name of the event.
 */
EventInterface.prototype.removeListeners = function(event) {
	var check = _.validate({
		event	: [event, "isString", {default: undefined, warn: _.def(event)}]
	}, "Could not remove listeners.");
	if(!check.isValid()) return false;
	const valid = check.getValue();

	if(_.isObject(this._listeners[event])) {
		for(var moment in this._listeners[event]) {
			this._listeners[valid.event][moment] = [];
		}
	} else {
		for(var event in this._listeners) {
			this.removeListeners(event);
		}
	}
};

/**
 * Remove listener for events.
 * @param {string} event
 * @param {function} callback
 * @returns {boolean}
 */
EventInterface.prototype.removeListener = function(event, callback) {
	var check = _.validate({
		event	: [event, "isString"],
		callback : [callback, 'isFunction']
	}, "Could not remove listener.");
	if(!check) return false;
	var valid = check.getValue();

	var listeners = this._listeners[valid.event];
	if(!_.isObject(listeners)) {
		return false;
	}
	for(var moment in listeners) {
		for(var i = listeners[moment].length-1; i >= 0; i--) {
			if(listeners[moment][i].callback === callback) {
				listeners[moment].splice(i, 1);
			}
		}
	}
};

EventInterface.prototype.getOnMethod = function() {
	return this.on.bind(this);
};
EventInterface.prototype.getFireMethod = function() {
	return this.fire.bind(this);
};

module.exports = EventInterface;
