var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { class: { "tree-node": true } }, [
    _c(
      "span",
      {
        key: _vm.open,
        staticClass: "toggle-icon",
        class: { "empty-toggle": !_vm.isFolder },
        on: { click: _vm.toggle },
      },
      [
        _vm.isFolder
          ? _c("i", {
              class: {
                "fa fa-caret-down": this.open,
                "fa fa-caret-right": !this.open,
              },
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        key: _vm.icon,
        staticClass: "tree-icon",
        class: { "empty-toggle": !_vm.icon },
      },
      [_vm.icon ? _c("i", { class: _vm.icon }) : _vm._e()]
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        key: "item.id",
        staticClass: "tree-text",
        attrs: { for: _vm.item.id, title: _vm.item.tooltip },
        on: { click: _vm.toggle },
      },
      [
        _c(
          "span",
          {
            staticClass: "name",
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.itemClicked(_vm.item)
              },
            },
          },
          [_vm._v(_vm._s(_vm.item.name))]
        ),
        _vm._v(" "),
        _vm.isFolder
          ? _c("span", [
              _vm._v("\n              (" + _vm._s(_vm.item.childCount)),
              _vm.item.totalChildCount > _vm.item.childCount
                ? _c("span", [
                    _vm._v(" of " + _vm._s(_vm.item.totalChildCount)),
                  ])
                : _vm._e(),
              _vm._v(")\n          "),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "add-neighbours",
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) return null
            return _vm.itemAddClicked(_vm.item)
          },
        },
      },
      [_vm._v("[+]")]
    ),
    _vm._v(" "),
    _vm.isFolder
      ? _c("div", { staticClass: "tree-children" }, [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.open,
                  expression: "open",
                },
              ],
            },
            _vm._l(_vm.sortedValues, function (child) {
              return _c("neighbour-item", {
                key: child.id,
                attrs: {
                  item: child,
                  treeTypes: _vm.treeTypes,
                  openAll: _vm.openAll,
                },
                on: {
                  itemClicked: _vm.itemClicked,
                  itemAddClicked: _vm.itemAddClicked,
                  openTree: _vm.openTree,
                },
              })
            }),
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }