const MarkdownSerializer = require('prosemirror-markdown').MarkdownSerializer;
const fmt = require('string-format');

// :: MarkdownSerializer
// A serializer for the [basic schema](#schema).
var markdownSerializer = new MarkdownSerializer({
	blockquote: function blockquote(state, node) {
		state.wrapBlock("> ", null, node, function () {
			return state.renderContent(node);
		});
	},
	code_block: function code_block(state, node) {
		state.write("```" + node.attrs.params + "\n");
		state.text(node.textContent, false);
		state.ensureNewLine();
		state.write("```");
		state.closeBlock(node);
	},
	heading: function heading(state, node) {
		state.write(state.repeat("#", node.attrs.level) + " ");
		state.renderInline(node);
		state.closeBlock(node);
	},
	horizontal_rule: function horizontal_rule(state, node) {
		state.write(node.attrs.markup || "---");
		state.closeBlock(node);
	},
	bullet_list: function bullet_list(state, node) {
		state.renderList(node, "  ", function () {
			return (node.attrs.bullet || "*") + " ";
		});
	},
	ordered_list: function ordered_list(state, node) {
		var start = node.attrs.order || 1;
		var maxW = String(start + node.childCount - 1).length;
		var space = state.repeat(" ", maxW + 2);
		state.renderList(node, space, function (i) {
			var nStr = String(start + i);
			return state.repeat(" ", maxW - nStr.length) + nStr + ". ";
		});
	},
	list_item: function list_item(state, node) {
		state.renderInline(node);
	},
	paragraph: function paragraph(state, node) {
		state.renderInline(node);
		state.closeBlock(node);
	},

	image: function image(state, node) {
		let src = state.esc(node.attrs.src);
		src = src.replace(' ', '%20'); // markdownit does not expect spaces in src

		state.write("![" + state.esc(node.attrs.alt || "") + "](" + src +
			(node.attrs.title ? " " + state.quote(node.attrs.title) : "") + ")");
	},
	hard_break: function hard_break(state, node, parent, index) {
		for (var i = index + 1; i < parent.childCount; i++) {
			if (parent.child(i).type !== node.type) {
				state.write("\\\n");
				return;
			}
		}
	},
	text: function text(state, node) {
		state.write(node.text); // note: if we get problems because we don't escape things, we can use state.text
	},
	component: function component(state, node) {
		state.write('(');
		state.text(node.attrs.tag);
		const props = node.attrs.props;
		if(Object.keys(props).length > 0) {
			const list = [];
			for(let i in props) {
				list.push(fmt('"{}": "{}"', i, props[i]));
			}
			state.text(' ' + list.join(', '));
		}
		state.write(')');
	},
	component_loaded: function component(state, node) {
		state.write('(');
		state.text(node.attrs.tag);
		const props = node.attrs.props;
		if(Object.keys(props).length > 0) {
			const list = [];
			for(let i in props) {
				list.push(fmt('"{}": "{}"', i, props[i]));
			}
			state.text(' ' + list.join(', '));
		}
		state.write(')');
	},
}, {
	em: {open: "*", close: "*", mixable: true, expelEnclosingWhitespace: true},
	strong: {open: "**", close: "**", mixable: true, expelEnclosingWhitespace: true},
	link: {
		open: function close(state, mark) {
			return "[";
		},
		close: function close(state, mark) {
			return `](${state.esc(mark.attrs.href)} ${mark.attrs.title ? " " + state.quote(mark.attrs.title) : ""})${mark.attrs.target ? `{target="${mark.attrs.target}"}` : ''}`;
		}
	},
	code: {open: "`", close: "`"}
});

module.exports = markdownSerializer;
