var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-context-menu",
    {
      ref: "menu",
      on: {
        "ctx-cancel": function ($event) {
          return _vm.onClose()
        },
      },
    },
    [
      _vm._l(_vm.visibleItems, function (item) {
        return _c(
          "context-menu-item",
          {
            key: item.id,
            attrs: { id: item.id, icon: item.icon },
            on: { click: _vm.onItemClick },
          },
          [_vm._v("\n\t\t" + _vm._s(item.action) + "\n\t")]
        )
      }),
      _vm._v(" "),
      _vm.$slots.default && _vm.$slots.default.length ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }