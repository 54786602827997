const Timer = require('./timer');
const Edition = require('core/src/edition').default;
const Function = require('core/src/function');
const _ = require('lodash');
const Err = require('utils/src/error');
const { hasBooleanValue } = require('core/src/utils/validation');

Edition.setBuildFeature(Edition.Feature.FUNCTION_TIMER, true);

Timer.prototype.execute = function(...args) {
	if(!Edition.hasFeature(Edition.Feature.FUNCTION_TIMER)) {
		const error = Edition.functionNotAvailableError('Timer');
		this.error(error);
		throw error;
	}
	return Function.prototype.execute.call(this, ...args);
}

Timer.prototype.onExecute = function(input) {
	if(isNaN(input.interval)) {
		return new Err(this.language.translate('Interval must be numeric (in milliseconds).'));
	}
	if(isNaN(input.limit)) {
		return new Err(this.language.translate('Limit must be numeric (in maximum number of iterations).'));
	}
	input.interval = parseInt(input.interval);
	input.limit = parseInt(input.limit);

	this.registerFunctionInstance();

	this.start();
	this.updateState();

}

Timer.prototype.onUpdate = function(changes) {
	if('pause' in changes) {
		if(hasBooleanValue(changes.pause.new, true)) {
			this.pause();
		} else {
			this.resume();
		}
	}
	if('reset' in changes && hasBooleanValue(changes.reset.new, true)) {
		this.reset();
	}

	this.updateState();
}

Timer.prototype.start = function(interval) {
	var self = this;
	if(this._timer !== null) {
		return false;
	}

	// Use default interval if not given
	if(_.isNil(interval)) {
		interval = this.readModel('interval');
	}

	this._timer = setTimeout(function(){
		self.end();
	}, interval);

	// Set start and end
	var iteration = this.readModel('state.iteration');
	var start = new Date()-0;
	var end = new Date()-0;
	end += interval;
	this.updateModel('state', {
		iteration: iteration + 1,
		running: true,
		start: start,
		end: end
	}, true); // merge

	return true;
}
