import md5 from "core/src/utils/md5";
import Log from "utils/src/log";

const log = Log.instance("client/utils/loadscript");

const scriptPromises = {};

export default function requireScript(url) {
	// Url loading was already started, return current promise.
	if(url in scriptPromises) {
		return scriptPromises[url];
	}
	const promise = new Promise((resolve, reject) => {
		const id = 'resource-' + md5(url);
		const existing = document.getElementById(id);
		if(existing) {
			// already loaded
			resolve();
			return;
		}

		const script = document.createElement('script');
		script.onload = resolve;
		script.onerror = ()=>{
			log.error("Failed to load resource: " + url);
			reject(new Error("Failed to load resource."));
		};
		script.id = id;
		script.src = url;
		document.body.appendChild(script);
	});
	scriptPromises[url] = promise;
	return promise;
}
