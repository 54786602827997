
	import VueContextMenu from 'vue-context-menu';
	import ContextMenuItem from "client/src/vue-view/context-menu-item.vue";
	import _ from 'lodash';

	export default {
		name: "context-menu",
		components: {ContextMenuItem, VueContextMenu},
		props: {
			items: Array,
			filter: Function
		},
		computed: {
			visibleItems() {
				return _.filter(this.items, item => {
					return this.filter(item);
				});
			}
		},
		methods: {
			open(event, target) {
				let itemCount = this.items.length;
				this.target = target;
				if(this.$slots.default) itemCount += this.$slots.default.length;

				// Only show context menu if it's got items
				if(this.$refs.menu && itemCount > 0) {
					event.preventDefault(); // only prevent default context menu if custom context menu will be opened
					this.$refs.menu.open(event);
					return true;
				}
				return false;
			},
			close() {
				this.$refs.menu.ctxVisible = false;
			},
			onItemClick(id) {
				this.$emit('itemClick', this.target, id);
			},
			onClose() {
				this.$emit('close');
			}
		}
	}
