/**
 *  Set timeouts on all browser tabs that are triggered at roughly the same time. The expiry time is set in localStorage so all tabs have access to it.
 *  Used for example to redirect to login page if session expired.
 *  
 *  The current logic is kinda flawed: if you set a long timeout and in another tab a shorter timeout the current tab will execute after the long timeout (will not synchronize)
 *  It can be fixed with a localStorage flag that says when the last update was and all tabs will check that flag at a certain interval (ex 1 sec) and syncronize if needed
 */

const _ = require('lodash');
const { t } = require('core/src/language');
const TIMEOUT_ERROR = 100; //in milliseconds, if the timeout is due to expire in less than TIMEOUT_ERROR it will be considered expired.


let Timestamp = {
	isValid: (ts) => _.isFinite(ts) && ts > 0,
	isBefore: (baseTs, ts) => (ts - baseTs) < TIMEOUT_ERROR, // true if ts is before baseTs
	isAfter: (baseTs, ts) => (ts - baseTs) >= TIMEOUT_ERROR
}

let Text = {
	isNonEmpty: (text) => _.isString(text) && !! text,
}

// Wrapper over localStorage with a bit of logic
let GlobalTimestamp = {
	isValid: _.conforms({
		id: Text.isNonEmpty,
		value: Timestamp.isValid
	}),

	set: (id, value) => {
		if (! GlobalTimestamp.isValid({id, value})) {
			return console.warn('GlobalTimestamp: id or value invalid', {id, value});
		}

		localStorage[id] = value;
	},

	get: (id) => {
		return parseFloat(localStorage[id]);
	},

	delete: (id) => GlobalTimestamp.isValid({id, value: GlobalTimestamp.get(id)}) && localStorage.removeItem(id)
}

// Logic for a timeout structure. Tries to be pure

let Timeout = {
	isValid: _.conforms({
		identifier: Text.isNonEmpty,
		onTimeout: 	_.isFunction,
		timeoutMs: 	(value) => _.isFinite(value) && (value >= 0),
		expiredAt: 	(value) => _.isFinite(value) && (value >= 0),
		executed: _.isBoolean
	}),

	isExpiredAt: (unixTime, timeout) => {
		return Timestamp.isBefore(unixTime, timeout.expiredAt);
	},

	// Returns the structure of a timeout (but invalid)
	null: () => { 
		return {
			identifier: '',
			timeoutMs: null,
			onTimeout: null,
			expiredAt: 0,
			executed: false
		};
	},

	from: (params) => {
		let timeout = _.extend({}, Timeout.null(), _.pick( params, _.keys(Timeout.null())));

		if (! Timeout.isValid(timeout)) {
			error(t('Timeout: invalid timeout specs'), params, timeout);
		}

		return timeout;
	},

	update: (params, timeout) => _.extend({}, timeout, params),

	reset: (startedAt, timeout) => Timeout.update({expiredAt: startedAt + timeout.timeoutMs, executed: false}, timeout),

	shouldBeExecutedAt: (unixTime, timeout) => ! timeout.executed && Timeout.isExpiredAt(unixTime, timeout),
	
	isPendingAt: (unixTime, timeout) => ! timeout.executed && ! Timeout.isExpiredAt(unixTime, timeout),

	asExecuted: (timeout) => Timeout.update({executed: true}, timeout),

	execute: (timeout) => (timeout.onTimeout(), Timeout.asExecuted(timeout)),

}

// Expects first paramter as error message
let error = (...params) => {
	console.error(...params);
	throw new Error(params[0]);
}

let timeouts = {}; //timeouts set in the current tab/window
let executionTimeout; // handler for the setTimeout that will execute the expired timeouts

// Logic for the (singleton) timeouts collection. Synchronizes the in memory Timeouts with the GlobalTimestamp(s)

let GlobalTimeouts = {
	set: (params) => {
		let timeout = Timeout.from(params);
		timeouts[timeout.identifier] = timeout;
	},

	byIdentifier: (identifier) => {
		return timeouts[identifier];
	},

	remove: (timeout) => {
		delete timeouts[timeout.identifier];
	},

	all: () => {
		return timeouts;
	},

	reset: (timeout) => {
		if (! timeout) return;
		timeout = Timeout.reset(_.now(), timeout);
		GlobalTimeouts.set(timeout);
		GlobalTimestamp.set(timeout.identifier, timeout.expiredAt);

		GlobalTimeouts.execute();
	},

	add: (timeout) => GlobalTimeouts.reset(timeout),

	stop: (timeout) => {
		GlobalTimeouts.set(Timeout.asExecuted(timeout));
	},

	delete: (timeout) => {
		GlobalTimeouts.remove(timeout);
		GlobalTimestamp.delete(timeout.identifier);
	},

	syncronize: () => {
		timeouts = _.mapValues(
			_.pickBy(timeouts, (timeout) => GlobalTimestamp.get(timeout.identifier)), 
			(timeout) => Timeout.update(
				{expiredAt: GlobalTimestamp.get(timeout.identifier)}, 
				timeout
			)
		)
	},

	pending: () => _.filter(timeouts, _.curry(Timeout.isPendingAt)(_.now())),
	toBeExecuted: () =>  _.filter(timeouts, _.curry(Timeout.shouldBeExecutedAt)(_.now())),
	firstToExpire: () => _.minBy(GlobalTimeouts.pending(), 'expiredAt'),

	execute: () => {
		clearTimeout(executionTimeout);
		GlobalTimeouts.syncronize();

		_.forEach(
			GlobalTimeouts.toBeExecuted(), 
			(timeout) => GlobalTimeouts.set(Timeout.execute(timeout))
		);

		if (_.isEmpty(GlobalTimeouts.pending())) {
			return
		}

		executionTimeout = setTimeout(GlobalTimeouts.execute, 1000);
	}
}

module.exports = _.pick(GlobalTimeouts, ['add', 'byIdentifier', 'delete', 'reset', 'set', 'stop']);
