const Function = require('core/src/function');
const _ = require('lodash');
const Edition = require('core/src/edition').default;

const Iterate = Function.extend(Function, 'Iterate');

Edition.setBuildFeature(Edition.Feature.FUNCTION_TIMER, false);

Iterate.prototype.onInit = function() {
	this.setParameters({
		'#data': undefined,
		'$stayAlive': 'dashboard',
		'$nextIteration': null, // should be updated to run next iteration in serial mode
		'$nextIndex': null, // specifies wich item is next in serial mode
		'$iterationType': 'serial' // parallel | serial
	});
};

Iterate.prototype.execute = function(...args) {
	// feature
	const error = Edition.functionNotAvailableError('Iterate');
	this.error(error);
	throw error;
};

Iterate.prototype.onExecute = function(input) {
	// feature
};

module.exports = Iterate;
