var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "self", staticClass: "read-field-container" }, [
    _vm.reading
      ? _c(
          "div",
          {
            staticClass: "btn btn-danger",
            staticStyle: { "margin-right": "5px" },
            attrs: { title: "Stop the read" },
            on: { click: _vm.stopRead },
          },
          [_c("i", { staticClass: "fa fa-spinner" })]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.reading
      ? _c("label", [
          _c(
            "div",
            { staticClass: "btn btn-primary", attrs: { title: "Read a file" } },
            [_c("i", { class: _vm.schema.icon })]
          ),
          _vm._v(" "),
          _c("input", {
            ref: "read",
            staticClass: "invisible",
            attrs: { type: "file" },
            on: { change: _vm.onFilesChange },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.fileName,
          expression: "fileName",
        },
      ],
      ref: "visible",
      staticClass: "form-control",
      attrs: { type: "text", disabled: !_vm.schema.editable },
      domProps: { value: _vm.fileName },
      on: {
        change: _vm.onVisibleChange,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.fileName = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }