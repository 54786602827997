const APIClientAbstract = require("core/src/api-client-abstract").default;
const ApplicationInfo = require('core/src/application-info').default;
const Function = require('core/src/function');
const View = require('core/src/functions/view');
const log = require('core/src/log').instance("function/teameditview");
const Language = require('core/src/language').default;

const _ = require('core/src/utils/legacy');

const MarkdownEditView = Function.extend(View, "MarkdownEditView");

/**
* @override
* @returns {undefined}
*/
MarkdownEditView.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(APIClientAbstract);
	this.appInfo = dependencies.get(ApplicationInfo);
	this.language = dependencies.get(Language);

	this.setParameters({
		'$id': undefined,
		'$markdown': undefined,
		'$markdown:templating': 'none'
	});
};

MarkdownEditView.prototype.alterRenderData = async function (data) {
	return data;
};

module.exports = MarkdownEditView;