import { isArray, isObject, cloneDeep, clone, isPlainObject, mapValues } from 'lodash';
import Log from "utils/src/log";

const log = Log.instance("core/utils/log");

// `this` refers to the target in these methods
const proxyMethods = {
	/**
	 * Returns the original, writable value of the ReadOnly Proxy.
	 * @returns {*}
	 */
	$writable() {
		return this;
	},
	/**
	 * Returns a new ReadOnly Proxy that allows writing to the top level.
	 * @returns {*}
	 */
	$writableTop() {
		return readOnly(this, true);
	},
	/**
	 * Returns a deep clone of the ReadOnly Proxy.
	 * @returns {*}
	 */
	$clone() {
		return readOnly(cloneDeep(this));
	},
	/**
	 * Returns a shallow clone of the ReadOnly Proxy.
	 * @returns {*}
	 */
	$cloneShallow() {
		return readOnly(clone(this));
	}
};

function isReadOnly(value) {
	return isObject(value) && value['$readOnlyProxyIdentifier'] === readOnly;
}

function writable(value, levelsDeep = 0) {
	if(isReadOnly(value)) {
		return value.$writable();
	}
	if(levelsDeep > 0 && isPlainObject(value)) {
		return mapValues(value, subValue => {
			return writable(subValue, levelsDeep-1);
		});
	}
	return value;
}

const arrayWriteMethods = new Set([
	'copyWithin', 'fill', 'pop', 'push', 'reverse', 'shift', 'splice', 'unshift'
]);

export default function readOnly(value, allowTopLevelModifications = false) {
	if(!isObject(value) || isReadOnly(value)) return value;

	return new Proxy(value, {
		get(target, property) {
			if(property === 'prototype') {
				// prototype is read-only and non-configurable and returning anything else will cause errors
				return target.prototype;
			}
			if(property === '$readOnlyProxyIdentifier') {
				return readOnly;
			}
			if(proxyMethods.hasOwnProperty(property)) {
				return function() {
					return proxyMethods[property].apply(target, arguments);
				};
			}
			if(!(property in target)) {
				return;
			}
			if(isArray(value) && arrayWriteMethods.has(property)) {
				return function() {
					log.error(`Cannot call method '${property}' on a read-only array.`);
				};
			}
			return readOnly(target[property]);
		},
		set(target, property, value) {
			if(allowTopLevelModifications) {
				target[property] = value;
				return;
			}
			log.error(`Cannot set property '${property}' on read-only object.`, target);
		}
	});
}

export {
	isReadOnly, writable
};
