const $ = require('jquery');
require('client/libraries/jquery-custom-plugins')($);

module.exports = function(form) {
	var data = $(form).serializeObject();
	var nameRegex = /^(.*?)((?:\[[a-zA-Z0-9_]+\])*)(\[\])?$/;
	var pathRegex = /\[([a-zA-Z0-9_]+)\]/g;
	var stepRegex = /\[([a-zA-Z0-9_]+)\]/;
	for(var i in data) {
		var matches = nameRegex.exec(i);
		var main = matches[1];
		var pathStr = matches[2];
		var isArray = matches[3] !== undefined;

		var path = pathStr.match(pathRegex);
		// If input name has nested structure (running example foo[bar][baz][])
		if(path !== null) {
			// Create path array (['bar', 'baz'])
			path = _.map(path, function(item) {
				var stepMatch = stepRegex.exec(item);
				return stepMatch[1];
			});
			// Add main variable name ('foo').
			path.unshift(main);

			// IF path ended in '[]', ensure array
			var value = data[i];
			if(isArray && !_.isArray(value)) {
				value = [value];
			}

			// Set data in original object
			_.set(data, path, value);
			delete data[i];
		}
	}

	return data;
};