const _ = require('lodash');
const ViewRenderer = require('client/src/view-renderer');
const log = require('core/src/log').instance("function/mapviewrenderer");
const {checkType} = require('utils/src/validation');
const Leaflet = require('leaflet');
const EsriLeaflet = require('esri-leaflet');
const circleToPolygon = require('circle-to-polygon');
const proj4 = require('proj4').default;
const leafletPip = require('@mapbox/leaflet-pip');
const {isTrue} = require('core/src/utils/validation');

require('proj4leaflet');
require('leaflet-draw');
require('leaflet-measure');
require('leaflet.markercluster');

require('leaflet/dist/leaflet.css');
require('leaflet-measure/dist/leaflet-measure.css');
require('leaflet-draw/dist/leaflet.draw.css');
require('leaflet.markercluster/dist/MarkerCluster.css');
require('leaflet.markercluster/dist/MarkerCluster.Default.css');

const LeafletMapViewRenderer = function() {
	ViewRenderer.call(this);

	this.markers = {};
	this.leafletMarkers = {};
	this.markersSelected = {};
	this.markerClusterLayer = null;

	this.geoJSONStyle = {};
	this.geoJSONSelectedStyle = {};
	this.featuresSelected = {};

	this.autoPanZoom = false; // set to true when panning or zooming is done by the app (not manually by user)

	this.markerBounds = null;
	this.geoJSONBounds = null;
};
LeafletMapViewRenderer.viewType = 'LeafletMapView';
LeafletMapViewRenderer.prototype = Object.create(ViewRenderer.prototype);


LeafletMapViewRenderer.prototype.doRender = function(data) {
	this.markers = _.keyBy(data.markers, 'id');

	// Fix default icons URLs
	Leaflet.Icon.Default.prototype.options.iconUrl = 'images/map-marker-blue.png';
	Leaflet.Icon.Default.prototype.options.iconRetinaUrl = 'images/map-marker-blue-2x.png';
	Leaflet.Icon.Default.prototype.options.shadowUrl = 'images/marker-shadow.png';

	this.iconMarker = L.icon({
		iconUrl: 'images/map-marker-blue.png',
		iconRetinaUrl: 'images/map-marker-blue-2x.png',
		iconSize: [25, 40],
		iconAnchor: [12, 40],
		popupAnchor: [1, -34],
		shadowUrl: 'images/marker-shadow.png',
		shadowSize: [41, 41]
	});

	this.iconMarkerSelected = L.icon({
		iconUrl: 'images/map-marker-red.png',
		iconRetinaUrl: 'images/map-marker-red-2x.png',
		iconSize: [25, 40],
		iconAnchor: [12, 40],
		popupAnchor: [1, -34],
		shadowUrl: 'images/marker-shadow.png',
		shadowSize: [41, 41]
	});

	let div = $('<div class="leaflet-map-view-renderer" style="width: 100%; height: 100%;">');

	this.autoPanZoom = true;


	this.projections = _.mapValues(
		data.projections, 
		(p, name) => {
			return new L.Proj.CRS(
				name,
				p.projection,
				p.options
			)
		}
	)

	this.mapCRS = Leaflet.CRS[data.options.crs] || this.projections[data.options.crs] || Leaflet.CRS.EPSG3857;

	// Prevent zoom on boxzoom when map.noFit = true
	Leaflet.Map.BoxZoom.prototype._onMouseUp = function (e) {
		if ((e.which !== 1) && (e.button !== 1)) { return; }

		this._finish();

		if (!this._moved) { return; }
		// Postpone to next JS tick so internal click event handling
		// still see it as "moved".
		this._clearDeferredResetState();
		this._resetStateTimeout = setTimeout(L.Util.bind(this._resetState, this), 0);

		var bounds = new L.LatLngBounds(
			this._map.containerPointToLatLng(this._startPoint),
			this._map.containerPointToLatLng(this._point)
		);
		this._map.fire('boxzoomend', {boxZoomBounds: bounds})

		if (!this._map.options.noFit) {
			this._map.fitBounds(bounds);
		}
	};

	this.map = Leaflet.map(
		div[0], 
		_.extend(
			{
				center: data.options.center || [data.center.lat, data.center.lng || data.center.long],
				zoom: data.options.zoom || data.zoom,
				noFit: true,
			}, 
			data.options
		)
	)

	if (isTrue(data.markerCluster.enabled)) {
		this.markerClusterLayer = L.markerClusterGroup(data.markerCluster.options);
	}

	L.Control.Measure.include({
		_setCaptureMarkerIcon: function () {
			this._captureMarker.options.autoPanOnFocus = false;
			this._captureMarker.setIcon(
				L.divIcon({
					iconSize: this._map.getSize().multiplyBy(2)
				})
			);
		},
	});

	this.measureControl = L.control.measure(data.controls.measure);
	this.measureControl.options.units['sqkilometers'] = {
		factor: 0.000001,
		display: 'sqkilometers',
		decimals: 3
	};

	this.map.addControl(this.measureControl);

	this.drawControl = new L.Control.Draw(data.controls.drawing);
	this.map.addControl(this.drawControl);

	// Create layers
	this.layers = _.mapValues(
		data.layers, 
		(layer) => {
			if (layer.type === 'tileLayer') {
				return Leaflet.tileLayer(
					layer.url,
					layer
				);
			}
			return layer;
		}
	)

	this.activeLayers = _.keys(_.pickBy(
		data.layers,
		'active'
	));

	_.forEach(this.activeLayers, (layerName) => {
		this.layers[layerName].addTo(this.map);
	})


	// Create layer control
	if (_.size(data.layerControl)) {
		let baselayers = _.mapValues(data.layerControl.baselayers, (layerName) => this.layers[layerName]);
		let overlays = _.mapValues(data.layerControl.overlays, (layerName) => this.layers[layerName]);
		this.layerControl = L.control.layers(baselayers, overlays).addTo(this.map);
	}


	// Create markers
	_.forEach(data.markers, (marker) => this.markerAdd(marker));


	// Create geoJSON
	this.geoJSONStyle = data.geoJSONStyle;
	this.geoJSONSelectedStyle = data.geoJSONSelectedStyle;
	if (! _.isNil(data.geoJSON)) {
		this.resetGeoJSONLayer();
		this.geoJSONLayer.addData(data.geoJSON);
		this.geoJSONBounds = this.geoJSONLayer.getBounds();
		(this.markerClusterLayer || this.map).addLayer(this.geoJSONLayer);
	}


	// Set up events
	this.map.on(
		'click',
		(ev) => {
			this.userEvent({
				type: 'mapClick',
				location: ev.latlng,
				x: ev.originalEvent.pageX,
				y: ev.originalEvent.pageY,
				features: _.isNil(this.geoJSONLayer) 
					? [] 
					: leafletPip.pointInLayer(ev.latlng, this.geoJSONLayer).map(layer => layer.feature)
			})
		}

	);

	// Add markerClusterLayer as the top layer
	if (this.markerClusterLayer) {
		this.map.addLayer(this.markerClusterLayer);
	}

	this.map.on(
		'contextmenu',
		(ev) => {
			this.userEvent({
				type: 'mapRightClick',
				location: ev.latlng,
				x: ev.originalEvent.pageX,
				y: ev.originalEvent.pageY
			})

			this.openContextMenu('map', {location: ev.latlng}, ev.originalEvent.pageX, ev.originalEvent.pageY);

		}

	);

	this.map.on(
		'moveend',
		(ev) => {
			let bounds = this.map.getBounds();
			this.userEvent({
				type: 'boundsChanged',
				bounds: {
					east: bounds._northEast.lat,
					north: bounds._northEast.lng,
					south: bounds._southWest.lng,
					west: bounds._southWest.lat
				},
				center: this.map.getCenter(),
				zoom: this.map.getZoom(),
			})

			let modelUpdate = {
				center: this.map.getCenter(),
				bounds: {
					east: bounds._northEast.lat,
					north: bounds._northEast.lng,
					south: bounds._southWest.lng,
					west: bounds._southWest.lat
				},
				zoom: this.map.getZoom()
			};

			if (! this.autoPanZoom) {
				modelUpdate.autoFit = false;
			}

			this.requestUpdate(modelUpdate);
			this.autoPanZoom = false;
		}
	);

	this.map.on(
		'boxzoomend',
		(ev) => {
			this.userEvent({
				type: 'envelope',
				east: ev.boxZoomBounds._northEast.lat,
				north: ev.boxZoomBounds._northEast.lng,
				south: ev.boxZoomBounds._southWest.lng,
				west: ev.boxZoomBounds._southWest.lat
			})
		}
	)

	this.map.on(
		Leaflet.Draw.Event.CREATED, 
		(e) => {
			let drawingGeoJSON = (e.layerType == 'circle') 
				? {
					type: 'Feature',
					geometry: circleToPolygon(
						[e.layer.getLatLng().lng, e.layer.getLatLng().lat], 
						e.layer.getRadius(),
						{numberOfEdges: 64}
					),
					properties: {}
				}
				: e.layer.toGeoJSON();
		
			this.userEvent({
				type: 'drawing',
				tool: e.layerType,
				geoJSON: drawingGeoJSON

			})				
		}
	);

	this.map.on('measurefinish',
		(results) => {
			this.userEvent({
				type: 'measure',
				area: results.area,
				areaDisplay: results.areaDisplay,
				lastCoord: results.lastCoord,
				length: results.length,
				lengthDisplay: results.lengthDisplay,
				pointCount: results.pointCount,
				points: results.points

			})
		}
	);

	this.map.on('baselayerchange',
		(params) => {
			let center = this.map.getCenter();
			this.map.options.crs = this.projections[params.layer.options.crs] || this.mapCRS;
			this.map.setView(center);
			this.map._resetView(this.map.getCenter(), this.map.getZoom());
		}
	);

	this.autoPanZoom = false;

	return div;
};


LeafletMapViewRenderer.prototype.doUpdate = function(data, changes, updateId) {
	if (this.hasChangedIn(changes, 'layers')) {
		_.forEach(
			data.layers,
			(layer, name) => {
				let mapLayer = this.layers[name];

				if (mapLayer.options.url !== layer.url) {
					mapLayer.setUrl(layer.url);
				}
			}
		)
	}


	if (! _.isNil(changes.center) || ! _.isNil(changes.zoom)) {
		this.autoPanZoom = true;
		this.map.setView(
			{
				lat: data.center.lat,
				lng: data.center.lng || data.center.long
			}, 
			data.zoom
		);
		this.autoPanZoom = false;
	}

	// Update markers add, remove, update
	if (! _.isNil(changes.markers)) {
		let oldMarkers = _.keyBy(changes.markers.old, 'id');
		let newMarkers = _.keyBy(changes.markers.new, 'id');

		let markersTo = _.groupBy(
			changes.markers.new,
			(marker) => {
				if (_.isNil(oldMarkers[marker.id])) {
					return 'add';
				}

				if (_.isEqual(marker, oldMarkers[marker.id])) {
					return 'same';
				}

				return 'update';
			}
		);

		markersTo.remove = _.reduce(
			oldMarkers,
			(res, marker) => (_.isNil(newMarkers[marker.id]) ? (res.push(marker), res) : res),
			[]
		);

		_.forEach(markersTo.remove, (m) => this.markerRemove(m));
		_.forEach(markersTo.update, (m) => this.markerUpdate(m));
		_.forEach(markersTo.add, (m) => this.markerAdd(m));

		this.markers = _.keyBy(data.markers, 'id');
	}

	if (this.hasChangedIn(changes, 'state.selected.markers')) {
		let selectedMarkersById = _.keyBy(data.state.selected.markers, 'id');

		// unselect markers
		_.forEach(
			this.markersSelected,
			(marker) => {
				if (! selectedMarkersById[marker.id]) {
					this.markerUnselect(marker);
				}
			}
		);

		// select markers
		_.forEach(
			data.state.selected.markers,
			(marker) => {
				this.markerSelect(marker);
			}
		);		
	}

	if (_.isEmpty(data.markers)) {
		this.markerBounds = null;
	}
	else {
		this.markerBounds = Leaflet.latLngBounds(_.map(data.markers, (m) => Leaflet.latLng(m.lat, m.lng || m.long)));
	}

	this.geoJSONStyle = data.geoJSONStyle;
	this.geoJSONSelectedStyle = data.geoJSONSelectedStyle;
	if (! _.isNil(changes.geoJSON) || ! _.isNil(changes['geoJSON.features'])) {
		this.resetGeoJSONLayer();
		this.geoJSONLayer.addData(data.geoJSON);
		this.geoJSONBounds = this.geoJSONLayer.getBounds();
		(this.markerClusterLayer || this.map).addLayer(this.geoJSONLayer);
	}

	if (data.autoFit  
		&& (
				! _.isNil(changes.autoFit) 
			|| 	! _.isNil(changes.markers) 
			|| 	! _.isNil(changes.geoJSON) 
			|| 	! _.isNil(changes['geoJSON.features'])
		)
	) {
		this.zoomToFit();
	}

};

LeafletMapViewRenderer.prototype.doResize = function() {
	this.autoPanZoom = true;
	this.map.invalidateSize();
	this.autoPanZoom = false;
}


/* --------------------   Map  ---------------------*/


LeafletMapViewRenderer.prototype.fitBounds = function(bounds) {
	this.autoPanZoom = true;
	this.map.fitBounds(bounds);
}


LeafletMapViewRenderer.prototype.zoomToFit = function() {
	let mapBounds = Leaflet.latLngBounds();
	this.fitBounds(mapBounds.extend(this.markerBounds).extend(this.geoJSONBounds));
}

module.exports = LeafletMapViewRenderer;


/* --------------------   Markers  ---------------------*/


LeafletMapViewRenderer.prototype.markerAdd = function(marker) {
	this.leafletMarkers[marker.id] = Leaflet.marker(
		[marker.lat, marker.lng || marker.long], 
		marker		
	).addTo(this.markerClusterLayer || this.map);

	this.leafletMarkers[marker.id].on(
		'click', 
		(ev) => {
			let markerId = ev.target.options.id;

			if (ev.originalEvent.ctrlKey) {
				Leaflet.DomEvent.stopPropagation(ev);
				this.markerSelectToggle(this.markers[markerId]);
			}
			else {
				this.userEvent({
					type: 'markerClick',
					marker: this.markers[markerId]
				});

				if (_.isString(this.markers[markerId].info) && ! _.isEmpty(this.markers[markerId].info)) {
					Leaflet.popup()
						.setLatLng(this.leafletMarkers[markerId].getLatLng())
						.setContent(this.markers[markerId].info)
						.openOn(this.map)
				}
			}
		}
	);

	this.leafletMarkers[marker.id].on(
		'contextmenu', 
		(ev) => {
			this.userEvent({
				type: 'markerRightClick',
				marker: this.markers[ev.target.options.id]
			});
		}
	);
}

LeafletMapViewRenderer.prototype.markerUpdate = function(marker) {
	this.markerRemove(marker);
	this.markerAdd(marker);
}

LeafletMapViewRenderer.prototype.markerRemove = function(marker) {
	this.leafletMarkers[marker.id].remove();
	delete this.leafletMarkers[marker.id];
}

LeafletMapViewRenderer.prototype.markerSelect = function(marker) {
	if (this.markersSelected[marker.id]) {
		return false;
	}

	this.markersSelected[marker.id] = marker;
	this.leafletMarkers[marker.id].setIcon(this.iconMarkerSelected);

	return true;
}

LeafletMapViewRenderer.prototype.markerUnselect = function(marker) {
	if (! this.markersSelected[marker.id]) {
		return false;
	}

	delete this.markersSelected[marker.id];
	this.leafletMarkers[marker.id].setIcon(this.iconMarker);

	return true;
}

LeafletMapViewRenderer.prototype.markerSelectToggle = function(marker) {
	if (! this.markerSelect(marker)) {
		this.markerUnselect(marker);
	}

	let selectedMarkers = _.values(this.markersSelected);

	this.userEvent({
		type: 'selectionChanged',
		markers: selectedMarkers
	})

	this.requestUpdate({
		'state.selected.markers': selectedMarkers
	})
}


/* --------------------   GeoJSON  ---------------------*/


LeafletMapViewRenderer.prototype.resetGeoJSONLayer = function() {
	if (! _.isNil(this.geoJSONLayer)) {
		this.geoJSONLayer.removeFrom(this.markerClusterLayer || this.map);
	}

	this.geoJSONLayer = Leaflet.geoJSON(
		null,
		{
			style: (feature) => {
					if (this.featuresSelected[feature.id]) {
						return feature.selectedStyle || this.geoJSONSelectedStyle;
					}
					return feature.style || this.geoJSONStyle;
				},

			onEachFeature: (feature, layer) => {
				if (! _.isNil(feature.tooltip)) {
					layer.bindTooltip(
						(layer) => {
							return (_.isFunction(layer.feature.tooltip) 
								? layer.feature.tooltip(layer.feature) 
								: layer.feature.tooltip);
						}, 
						layer.feature.tooltipOptions
					);
				}


				layer.on(
					'click',
					(ev) => {
						if (ev.originalEvent.ctrlKey) {
							this.geoJSONFeatureSelectToggle(ev.target.feature);
						}
						else {							
							this.userEvent({
								type: 'featureClick',
								feature: feature,
								location: ev.latlng
							});
						}
					}
				)
			}
		}
	);
}

LeafletMapViewRenderer.prototype.geoJSONFeatureSelectToggle = function(feature) {
	if (this.featuresSelected[feature.id]) {
		delete this.featuresSelected[feature.id];
	}
	else {
		this.featuresSelected[feature.id] = feature;
	}

	this.geoJSONLayer.resetStyle();

	let selectedFeatures = _.values(this.featuresSelected);

	this.userEvent({
		type: 'selectionChanged',
		geoJSONFeatures: selectedFeatures
	})

	this.requestUpdate({
		'state.selected.features': selectedFeatures
	})
}
