import Function from 'core/src/function';
import Err from 'utils/src/error';
import Vue from 'vue';
import Log from 'utils/src/log';
import _, { get, isPlainObject } from 'lodash';
import {checkType} from "utils/src/validation";
import { compareIndex } from 'core/src/utils';

const log = Log.instance("client/vue-view");
const Edition = require("core/src/edition").default;

const Event = {
	Out: {
		RENDER_COMPONENT: 'RenderComponent',
		CONTAINER_CHANGED: 'ContainerChanged',
		COMPONENT_CLOSED: 'ComponentClosed'
	}
};

export default class VueView extends Function {
	static extendWith(VueComponent, name) {
		class VueViewExtension extends VueView {
			getVueComponent() {
				return VueComponent;
			}
		}
		Function.initFunctionClass(VueViewExtension, VueView, name);
		return VueViewExtension;
	}

	/**
	 * @override
	 * @param model
	 */
	setModel(model) {
		super.setModel(model);
		if(this.vue) {
			this.vue.setModel(this._model);
		}
	}

	/**
	 * @override
	 *
	 * Sets the model value reactively.
	 * @param pathString
	 * @param value
	 */
	setModelValue(pathString, value) {
		return this.vue.setModelValue(pathString, value);
	}

	onInit() {
		this.vue = new Vue(this.getVueComponent());

		// Initialze the Function model with the Vue model
		this._model = this.vue.model;

		this.vue.setFunction(this);
		this.vue.setDependencies(this.dependencies);
		this.vue.setModel(this._model);

		// We'll have to use our own validation, because reactivity will give use invalid models before they are corrected
		this._modelValidationEnabled = false;

		this.setParameters({
			view: undefined,
			container: undefined,
			...this.vue.getParameters()
		});
	}

	getVueComponent() {
		throw new Err("No Vue component defined.");
	}

	close(...args) {
		super.close(...args);
		this.vue.closeComponent();
	}

	onExecute() {
		this.vue.setContextMenuItems(this.findContextTriggers());
		this.vue.setBatchTriggers(this.findBatchTriggers());

		this.registerFunctionInstance();

		this.eventOut(Event.Out.RENDER_COMPONENT, {
			renderer: this.vue,
			area: this.vue.getAreaSpecs(),
			container: this.vue.getContainerSpecs(),
			history: this.getHistory()
		});
	}

	findContextTriggers() {
		// Get context menu items from Triggers
		const contextTriggers = this.findTriggersWith({
			type: 'context'
		}); // we check conditions later

		const evaluate = str => this.evaluate(str, {type: 'context'});

		let contextMenuData = {};
		for(let trigger of contextTriggers) {
			let properties = trigger.getProperties();

			let menu, action;
			try {
				menu = checkType(properties.menu, 'string', 'ContextTrigger.menu');
				action = checkType(properties.action, 'string', 'ContextTrigger.action');
			} catch(e) {
				log.error(e.message);
				continue;
			}

			if(!isPlainObject(contextMenuData[menu])) {
				contextMenuData[menu] = {};
			}
			if(!isPlainObject(contextMenuData[menu][action])) {
				contextMenuData[menu][action] = {
					id: trigger.id,
					menu: evaluate(menu),
					action: evaluate(action),
					index: evaluate(properties.index),
					icon: evaluate(properties.index),
					condition: properties.condition
				};
			}
		}

		return contextMenuData;
	}

	findBatchTriggers() {
		const batchTriggers = this.findTriggersWith({
			type: 'batch'
		}); // we check conditions later

		const evaluate = str => this.evaluate(str, {type: 'batch'});

		const batchTriggerSpecs = _.map(batchTriggers, (trigger) => {
			let properties = trigger.getProperties();
			checkType(properties, 'object', 'BatchTrigger');
			checkType(properties.action, 'string', 'BatchTrigger.action');

			return {
				id: trigger.id,
				index: evaluate(properties.index) || 0,
				action: evaluate(properties.action),
				name: evaluate(properties.name) || '',
				icon: evaluate(properties.icon),
				style: evaluate(properties.style) || '',
				hoverStyle: evaluate(properties.hoverStyle) || '',
				condition: properties.condition,
				tooltip: evaluate(properties.tooltip),
				enable: properties.enable,
				show: properties.show
			};
		});

		let compareStrings = (a, b) => ((a === b) ? 0 : (a > b) ? 1 : -1);

		batchTriggerSpecs.sort((triggerA, triggerB) => 
			// Sort by index first
			compareIndex(
				_.get(triggerA, 'index'),
				_.get(triggerB, 'index')
			)

			// If equal, sort by action
			|| compareStrings(
				_.toLower(_.get(triggerA, 'action', '')),
				_.toLower(_.get(triggerB, 'action', ''))
			)
		);

		return batchTriggerSpecs;
	}
}
Function.initFunctionClass(VueView, Function, 'VueView'); // TODO: make this obsolete
VueView.Event = Event;
