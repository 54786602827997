var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toolbar" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.$slots.default &&
      _vm.$slots.default.length &&
      _vm.batchTriggers.length
        ? _c("div", { staticClass: "separator" })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.batchTriggers, function (trigger) {
        return _c("view-toolbar-button", {
          key: trigger.id,
          style: trigger.style,
          attrs: {
            id: trigger.id,
            icon: trigger.icon,
            hoverStyle: trigger.hoverStyle,
            title: trigger.tooltip,
            enable: trigger.enable,
            labelHtml: trigger.action,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("batchTriggerClick", $event)
            },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }