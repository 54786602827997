const Function = require('core/src/function');
const IFactory = require('core/src/i-factory').default;
const Language = require('core/src/language').default;
const Trigger = require('core/src/trigger');

const _ = require('core/src/utils/legacy');

const ExecuteFunction = Function.extend(Function, "ExecuteFunction");

/**
 * @override
 * @returns {undefined}
 */
ExecuteFunction.prototype.onInit = function(dependencies) {
	this.factory = dependencies.get(IFactory);
	this.language = dependencies.get(Language);
	this.dependencies = dependencies;

	this.setParameters({
		'$id': undefined,
		'$function': undefined,
		'$input': undefined
	});

	const notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		id		: ['isStringOrNumber', notRequired],
		function: ['isObject', notRequired],
		input	: ['isObject', notRequired]
	});
};

/**
 * @override
 * @param {object} data The data passed with the event.
 */
ExecuteFunction.prototype.onExecute = function(input) {
	var deferred = new $.Deferred();

	// Compute trigger mapping from Function input.
	let triggerParameters = _.reduce(
		input.input, 
		(result, value, key) => (
				_.isArray(value) 
			? _.set(result, `#${key}`, `(%).${key}`) 
			: _.set(result, `$${key}`, `(%).${key}`) 
		),
		{}
	)

	// We want to have all the fatures of a normal function execution so we do it though a trigger.
	let trigger = new Trigger(this.dependencies, {
		targetFunction: input.function || input.id,
		parameters: triggerParameters
	})

	// Add _global data to function execution.
	let execution = trigger.execute(this.alterTriggerData(input.input));
	execution.done((executedFunction) => {
		this.executeTriggers({
			'type': 'success',
			'function': {
				name: executedFunction.name,
				parameters: executedFunction.parameters,
				properties: executedFunction.properties
			}
		});
	});

	execution.fail(() => {
		deferred.reject(new _.Error(this.language.translate('Function not found ({{id}})', {id})));
	});

	return deferred.promise();
};

module.exports = ExecuteFunction;