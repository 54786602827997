const Function = require('core/src/function');
const View = require('core/src/functions/view');
const { def } = require('utils/src/validation');

const MarkdownView = Function.extend(View, "MarkdownView");

/**
 * @override
 * @returns {undefined}
 */
MarkdownView.prototype.onInit = function() {
	this.setParameters({
		'$markdown': undefined,
		'$props': undefined
	});

	this.setModelValidation({
		markdown: ['isString']
	});
};

module.exports = MarkdownView;
