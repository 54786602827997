const Utils = {};
const _ = require('lodash');

const {delay} = require('./async');


/**
 * Compare two index values to determine precedence.
 * @param a
 * @param b
 * @return {number}
 */
Utils.compareIndex = function(a,b) {
	// Undefined is handled as 0.
	a = a || 0;
	b = b || 0;

	let numA = parseFloat(a);
	let numB = parseFloat(b);
	// Numbers go first
	if(isNaN(numA) && !isNaN(numB)) {
		return 1;
	}
	if(!isNaN(numA) && isNaN(numB)) {
		return -1;
	}
	// Both numbers, compare numbers
	if(!isNaN(numA) && !isNaN(numB)) {
		if(numA < numB)	return -1;
		if(numA > numB)	return 1;
		else			return 0;
	}

	if(a < b)	return -1;
	if(a > b)	return 1;
	else		return 0;
};

/**
 * Compare two arrays of indexes to determine precedence.
 * @param {Array} a
 * @param {Array} b
 * @return {number}	-1 if a < b, 1 if a > b, 0 if a==b
 */
Utils.compareIndexPath = function(a, b) {
	for(let i = 0; i < a.length; i++) {
		let comparison = Utils.compareIndex(a[i], b[i]);
		if(comparison < 0) {
			return -1;
		} else if (comparison > 0) {
			return 1;
		}
	}
	// Still equal
	if(a.length > b.length) {
		return 1;
	}
	if (a.length < b.length) {
		return -1;
	}

	// Equal
	return 0;
};

/**
 * Check if a given path starts with a given segment.
 * @param {string|Array} path
 * @param {string|Array} segment
 */
Utils.pathStartsWith = function(path, segment) {
	if(_.isString(path)) {
		path = path.split('.');
	}
	if(_.isString(segment)) {
		segment = segment.split('.');
	}
	return !_.find(segment, (step, i) => {
		return path[i] !== step;
	});
};



/**
 *
 * @param {String} srcTxt String to linkify
 * @returns {String}
 */
Utils.urlToHtmlLink = (srcTxt) => {
	let result = srcTxt,
		patterns = [
			//URLs starting with http://, https://, or ftp://
			[/(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim, '<a href="$1" target="_blank">$1</a>'],
			//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
			[/(^|[^\/])(www\.[\S]+(\b|$))/gim, '$1<a href="http://$2" target="_blank">$2</a>'],
			//Change email addresses to mailto:: links.
			[/(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim, '<a href="mailto:$1">$1</a>']
		];

	patterns.forEach(([pat, link]) => {
		result = result.replace(pat, link);
	});

	return result;
};

// checks if subset is a deep "sub-object" of superset (all keys of subset are in superset and have same value)
Utils.isSupersetOf = (superset, subset) => {
	return _.isPlainObject(superset)
		&& _.isPlainObject(subset)
		&& _.isEqual(
			_.pick(superset, _.keys(subset)),
			subset
		)
};

/**
 * Find paths to any arrays in the object.
 * @param {object} object
 * @param {string} basePath 	The path to the current object. Will be prepended to all returned paths.
 * @return {Array}
 */
Utils.findPathsToArrays = function(object, basePath = '') {
	if( _.isArray(object) ) return [basePath];
	return _.reduce(
		object,
		(paths, value, key) => {
			// For speed: just continue if value is not interesting
			if (! ( _.isArray(value) || _.isPlainObject(value)) ) {
				return paths;
			}

			let path = basePath ? `${basePath}.${key}` : key;

			if ( _.isPlainObject(value) ) {
				return _.concat(paths, Utils.findPathsToArrays(value, path));
			}

			paths.push(path);

			return paths;
		},
		[]
	)
};

/**
 * @deprecated Import from async.js instead
 */
Utils.delay = delay;

/**
 * Checks if value is a valid generic id meaning is a non-empty string or finite number
 */
Utils.isValidId = function(value) {
    return _.isString(value) ? !! _.trim(value).length : _.isFinite(value);
};

/**
 * Used to check if a value is a nonempty string. Returns trimmed string or false
 */
Utils.isNonEmptyString = function(value) {
	return _.isString(value) && !! value;
};

module.exports = Utils;
