import Language from "core/src/language";
import Edition from "core/src/edition";
import _ from 'lodash';
import i18next from "i18next";
import {isTrue} from "core/src/utils/validation";
import Log from "utils/src/log";

Edition.setBuildFeature(Edition.Feature.MULTI_LINGUAL, true);

const log = Log.instance("core/language");

Language.prototype.changeLanguage = async function (language) {
	if(!Edition.hasFeature(Edition.Feature.MULTI_LINGUAL)) {
		return false;
	}

	if(this.getCurrentLanguage() === language) {
		return false;
	}

	try {
		await this.session.setLanguage(language);
		await i18next.loadLanguages(language);
		await i18next.changeLanguage(language);
		await i18next.reloadResources();
		log.log(`Language changed to: ${language}`);
		return true;
	} catch(err) {
		log.error(err.message);
		return err;
	}
};

export default Language;
