const ViewRenderer = require('client/src/view-renderer');

const get = require('lodash/get');
const def = require('utils/src/validation').def;
const isArray = require('lodash/isArray');
const values = require('lodash/values');
const uniqueId = require('lodash/uniqueId');
const map = require('lodash/map');
const sortBy = require('lodash/sortBy');
const debounce = require('lodash/debounce');
const cloneDeep = require('lodash/cloneDeep');
const deepDiff = require('deep-diff').default;
require('../libraries/jsonforms/jsonforms'); // we don't need to load Angular explicitly, we get it from jsonforms. Requiring it again throws a warning.
const addFileWidgetToJSONForms = require('./filewidget.js');
const form_view_template = require('./form-view.nghtml');
const arraywidget_template = require('./arraywidget.nghtml');
require('../libraries/jsonforms/jsonforms.css');
require('./form-view-renderer.css');

// Patch JSONForms to add a file widget to it
var ngapp = angular.module('formview', ['jsonforms']);

function patchJSONForms(ngapp, dependencies) {
	addFileWidgetToJSONForms(ngapp, dependencies);
	overrideArrayWidgetTemplate(ngapp);
}

function overrideArrayWidgetTemplate(ngapp) {
	return angular.module('jsonforms.renderers.controls.array')
		.run(['$templateCache', function($templateCache) {
			$templateCache.put('array.html', arraywidget_template);
		}]);
}

const FormViewRenderer = function(dependencies) {
	patchJSONForms(ngapp, dependencies);
	return ViewRenderer.call(this);
};
FormViewRenderer.viewType = 'FormView';
FormViewRenderer.prototype = Object.create(ViewRenderer.prototype);

FormViewRenderer.prototype.doRender = function(renderdata) {
	this.renderdata = renderdata;
	if(!('uischema' in this.renderdata)) {
		this.renderdata.uischema = this.renderdata.uiSchema;
	}
	if(!def(this.renderdata.data)) {
		this.renderdata.data = {};
	}
	this.viewid = uniqueId(this.getInstanceID());

	return form_view_template.replace('@id@', this.viewid);
};

FormViewRenderer.prototype.onReady = function() {
	var vrenderer = this;
	var ngapp = angular.module('formview');
	var element = document.getElementById(vrenderer.viewid);

	this.setupJSONForms(vrenderer, ngapp, element);
};

FormViewRenderer.prototype.setupJSONForms = function(vrenderer, ngapp, element) {
	var self = this;
	if (
		get(vrenderer.renderdata, 'uischema.elements') &&
		!isArray(vrenderer.renderdata.uischema.elements)
	)
		vrenderer.renderdata.uischema.elements =
			values(vrenderer.renderdata.uischema.elements);
	// The InterActor function interface allows users to define the
	// "uischema.elements" argument as an object, but the related JSONForms
	// function expects this to be an array. For that reason we are
	// doing the conversion here if the argument is an object.

	ngapp
		.controller('FormViewCtrl', ['$scope', function($scope) {
			var ctrl = this;

			this.schema = vrenderer.renderdata.schema;
			this.uischema = vrenderer.renderdata.uischema;
			this.data = vrenderer.renderdata.data;
			this.lastdata = cloneDeep(vrenderer.renderdata.data);
			this.actions = sortBy(map(vrenderer.renderdata.actions,
				function(v, k) { return {name: k, props: v};}),
				'props.index');

			$scope.$on(
				'jsonforms:change',
				debounce(
					self.handleFormChange.bind(ctrl, vrenderer, ctrl),
					vrenderer.renderdata.changeDelay
				)
			);

			this.submit = self.handleFormSubmit.bind(ctrl, vrenderer, ctrl);
		}]);

	angular.bootstrap(element, ['formview']);
};

FormViewRenderer.prototype.handleFormChange = function(vrenderer, ctrl, change_event) {
	var diff = deepDiff(ctrl.lastdata, ctrl.data);
	ctrl.lastdata = cloneDeep(ctrl.data);

	if (!diff)
		return;

	diff.forEach(function(diff_part) {
		if (diff_part.path[diff_part.path.length - 1] == '$$hashKey')
			return;
		// $$hashKey is an internal variable injected by JSONForms to
		// array elements, which we don't care and don't want shown
		// in our change events.

		return vrenderer.trigger({
			type: 'change',
			field: diff_part.path.join('.') + (diff_part.index ? '.' + diff_part.index : ''),
			old: diff_part.kind == 'A' ? diff_part.item.lhs : diff_part.lhs,
			new: diff_part.kind == 'A' ? diff_part.item.rhs : diff_part.rhs,
			data: cloneDeep(ctrl.data)
		});
	});
};

FormViewRenderer.prototype.handleFormSubmit = function(vrenderer, ctrl, action_name) {
	return vrenderer.trigger({
		type: 'submit',
		action: action_name,
		data: vrenderer.renderdata.data
	});
};

module.exports = FormViewRenderer;

