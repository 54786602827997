import uploadPopup from "./upload";
import schema from './markdown-schema';
import APIClientAbstract from "core/src/api-client-abstract";

export default function buildFileTooltip(dependencies) {
	const api = dependencies.get(APIClientAbstract);

	return {
		nodeType: schema.nodes.image,
		content: (node, state, dispatch, view) => {
			const content = $('<a href="javascript:void(0)">');
			content.click(() => {
				uploadPopup(api, node.attrs.src, state, dispatch, view);
			});
			content.html('Change image');
			return content;
		}
	}
};
