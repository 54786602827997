
	import {values, orderBy} from "lodash";

	export default {
		name: 'neighbour-item',
		props: {
			"treeData": [],
			"item": {},
			"treeTypes": [],
			"openAll": true
		},
		data() {
			return {
				open: false
			};
		},
		computed: {
			isFolder() {
				return this.item["children"] && values(this.item["children"]).length;
			},
			icon() {
				return this.getTypeRule(this.item["type"]).icon || "";
			},
			sortedValues() {
				return orderBy(values(this.item["children"]),'name');
            }
		},
		methods: {
			getTypeRule(type) {
				return this.treeTypes.filter(t => t.type === type)[0] || {};
			},
			selected(node) {
				this.checked = null;
				this.checked = this.item.id;
				this.$emit('selected', node);
			},
			openTree(node) {
				this.open = true;
				this.$emit('openTree', node);
			},
			itemAddClicked(item) {
				this.$emit("itemAddClicked", item);
			},
			itemClicked(item) {
				this.$emit("itemClicked", item);
			},
			toggle() {
				if (this.isFolder) {
					this.open = !this.open;
				}
				this.selected(this);
			}
		},
		created() {
			this.open = this.openAll;
		},
		watch: {
			openAll(openAll) {
				this.open = openAll;
			}
		}
	};
