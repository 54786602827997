const ElasticsearchQuery = require('./elasticsearch-query');
const Err = require('utils/src/error');
const log = require('core/src/log').instance("function/elasticsearch-query");
const Edition = require('core/src/edition').default;
const Function = require('core/src/function');

Edition.setBuildFeature(Edition.Feature.FUNCTION_ELASTICSEARCH, true);

ElasticsearchQuery.prototype.execute = function(...args) {
	if (!Edition.hasFeature(Edition.Feature.FUNCTION_ELASTICSEARCH)) {
		const error = Edition.functionNotAvailableError('ELASTICSEARCH');
		this.error(error);
		throw error;
	}
	return Function.prototype.execute.call(this, ...args);
};

ElasticsearchQuery.prototype.onExecute = async function() {
	const request = this.createRequest();

	try {
		const result = await this.api.queryElasticsearch(request);
		const event = {
			type: 'success',
			elasticsearch: result.elasticsearch,
			processed: result.processed,
			data: result.data
		};
		this.executeTriggers(event);
	} catch (e) {
		throw new Err(this.language.translate('Elasticsearch error.'), e);
	}
};