/**
 * Created by Remi on 1-9-15.
 * This file contains functions from Prologram 1.x, to be re-used in legacy components. These legacy components
 * should slowly be replaced in Prologram 2.x.
 */

(function() {
	const $ = require('jquery');

	const Legacy = {};

	$(document).ready(function () {
		var __clone = function () {
			var $this = $(this);
			var parent = $this.attr('search-from');
			var searchFrom = $this;
			if (parent !== undefined) {
				searchFrom = $this.closest(parent);
			}
			var target = $this.attr('target');
			var newField = Legacy.cloneField(searchFrom.find(target));
			$this.trigger('add', newField);
		};

		// Copy input field
		$(document).on('click', '.dynamic-set-add', __clone);

		// Remove input field
		$(document).on('click', '.dynamic-set-remove', function () {
			$(this).closest('.dynamic-set-element').remove();
		});
	});

	/**
	 * Adds an element to the dynamic set, based on a dummy element in the set.
	 * @param {Object|string} dynamicset A jQuery object/selector of an element with
	 * class 'dynamic-set', containing only a dummy element with class 'dynamic-set-element'.
	 * @returns {object}
	 */
	Legacy.addDynamicSetElement = function (dynamicset) {
		return Legacy.cloneField($(dynamicset).find('.dummy'));
	};

	/**
	 * Clears all elements from the dynamic set.
	 * @param {Object|string} dynamicset A jQuery object/selector of an element with
	 * class 'dynamic-set', containing only a dummy element with class 'dynamic-set-element'.
	 * @returns {undefined}
	 */
	Legacy.clearDynamicSet = function (dynamicset) {
		$(dynamicset).children(':not(.dummy):not(.dynamic-set-exclude)').remove();
	};

	/**
	 * Clones the dummy element into its parent and activates it.
	 * @param {Object|string} element The selector for the dummy element.
	 * @returns {Object}
	 */
	Legacy.cloneField = function (element) {
		var newField = $(element).clone();
		newField.removeAttr('id');
		newField.removeClass('dummy').removeClass('nospace');
		newField.find(':input, textarea').removeAttr('disabled');
		newField.appendTo($(element).parent());

		return newField;
	};

	// Returns a string with a html link to the given url
	Legacy.nodeFileLink = function (url) {
		return '<a class="node-file-link" href="' + url + '" target="_window">' + url + '</a>';
	};

	Legacy.checkNodeIsReal = function (node) {
		var deferrer = $.Deferred();

		if (!_.isPlainObject(node) || (!isNumeric(node.id))) {
			console.error('checkNodeIsReal(node) : node should be plain object');
			deferrer.reject();
		}

		// Get node from neo4j and compare properties with the given data
		getNodeById(node.id, function (nodeData) {
			if (_.findIndex([node], nodeData) > -1) {
				deferrer.resolve();
			}
			deferrer.reject();
		});

		return deferrer.promise();
	};


	Legacy.checkRelationIsReal = function (relation) {
		var deferrer = $.Deferred();

		if (!_.isPlainObject(relation) || (!isNumeric(relation.id))) {
			console.error('checkRelationIsReal(relation) : relation should be plain object', relation, relation.prototype);
			deferrer.reject();
		}

		// Get node from neo4j and compare properties with the given data
		getRelationById(relation.id, function (relationData) {
			var sourceId = relationData.source,
				targetId = relationData.target;
			delete relationData.source;
			delete relationData.target;
			if ((_.findIndex([relation], relationData) > -1) && (relation.source.id == sourceId) && (relation.target.id == targetId)) {
				deferrer.resolve();
			}
			deferrer.reject();
		});

		return deferrer.promise();
	};

	Legacy.getNodeById = function (id, callback) {
		var request = $.ajax({
			url: 'php/nodebyid.php',
			data: {id: id},
			method: 'POST'
		});

		request.done(function (result) {
			if (_.isFunction(callback)) {
				callback(result);
			}
		});

		request.fail(function (result) {
			console.log('Node not found: ', id, result);
			if (_.isFunction(callback)) {
				callback(null, result);
			}
		});

		return request;
	};

	Legacy.getRelationById = function (id, callback) {
		var request = $.ajax({
			url: 'php/relbyid.php',
			data: {id: id},
			method: 'POST'
		});

		request.done(function (result) {
			if (_.isFunction(callback)) {
				callback(result);
			}
		});

		request.fail(function (result) {
			console.log('Relationship not found: ', id, result);
			if (_.isFunction(callback)) {
				callback(null, result);
			}
		});

		return request;
	};

	module.exports = Legacy;
})();
