"use strict";

const $ = require('jquery');
const _ = require('core/src/utils/legacy');
const log = require('core/src/log').instance("function/yfilesview/renderer");

const ViewRenderer = require('client/src/view-renderer');
const Registry = require('core/src/registry').default;
const { GraphRegistry, isGraph } = require('core/src/graph/i-graph');
const Language = require("core/src/language").default;
const IGraphileon = require("core/src/i-graphileon").default;
const APIClientAbstract = require('core/src/api-client-abstract').default;

class YFilesIsometric extends ViewRenderer {

	constructor(dependencies) {
		super(dependencies);
		this.dependencies = dependencies;
		this.language = dependencies.get(Language);
		this.graphileon = dependencies.get(IGraphileon);
		this.api = dependencies.get(APIClientAbstract);
		this.registry = dependencies.get(Registry);
		this.registry.require(GraphRegistry, isGraph);

		/**
		 * ID of this instance
		 * @type {Number}
		 */
		this.id = _.uniqueId();
		/**
		 * Wrapper element containing graph and toolbar
		 * @type {Object}
		 */
		this.wrapperEl = null;
		/**
		 * Graph container
		 * @type {Object}
		 */
		this.graphEl = null;
		/**
		 * Toolbar container
		 * @type {Object}
		 */
		this.toolbarEl = null;
		/**
		 * The YFilesGraph instance
		 * @type {YFilesGraph}
		 */
		this.graph = null;
		/**
		 * The state of the model the renderer is currently showing
		 * @type {{}}
		 */
		this.model = {};
		/**
		 * doUpdate may called from outside before doRender finish.
		 * If that is the case, wait for doRender.
		 */
		this.loading = new Promise((resolve, reject) => {
			this.resolveLoading = resolve;
			this.rejectLoading = reject;
		});
	}

	/**
	 * Get unique index of the given entity.
	 * @param {Node|Relation} entity
	 * @returns {string}
	 */
	getIndex(entity) {
		const store = _.get(entity, 'meta.store');
		let prefix = store ? `${store}_` : '';

		// If it seems like a relationship include source and target in the index
		// If one changes this means that it is a "new" relationship even if it has the same id and store
		if (_.has(entity, 'source') && _.has(entity, 'target')) {
			return `${prefix}${entity.id}-${entity.source}-${entity.target}`;
		}

		return `${prefix}${entity.id}`;
	}

	initDOM() {
		// Main graph element
		this.graphEl = $("<div/>")
			.css({
				position: "relative",
				overflow: "hidden",
				outline: 0,
				width: "100%",
				height: "100%",
				padding: "0",
				margin: "0",
			});

		this.toolbarEl = this.createToolbar();
		this.createContextMenus();

		// Wrapper element for all of the components
		this.wrapperEl = $("<div/>")
			.css({
				width: "100%",
				height: "100%",
				"padding-top": "50px",
				"box-sizing": "border-box",
				position: "relative"
			})
			.append(
				$("<div/>")
					.css({
						width: "100%",
						height: "100%",
						position: "relative"
					})
					.append(
						this.graphEl,
						this.toolbarEl
					)
			);
	}

	async initGraph(model) {
		try {
			const YFilesIsometricGraph = (await import(/* webpackChunkName: 'yfiles' */ './yfiles-isometric-graph')).default;
			// Initialize Licence
			await this.graphileon.requireScript(`${this.api.getServer()}/api/license/yfiles.js?2.2.0`).catch(()=>{}); // logging is already taken care of

			this.graph = new YFilesIsometricGraph(
				this.graphEl,
				{
					getIndex: this.getIndex.bind(this)
				},
				model
			);

			this.setupEvents(this.graph);
			this.resolveLoading(this.graph);
		} catch (e) {
			this.rejectLoading(e);
		}
	}

	/* @private */
	setupEvents(graph) {
		const addEventListener = event => {
			let method = `on${_.upperFirst(event)}`;
			if (!_.isFunction(this[method])) {
				log.error(`Cannot listen to '${event}' event. YFilesIsometricViewRenderer.${method} is not a function.`);
				return;
			}
			graph.on(event, this[method].bind(this));
		};

		addEventListener('nodeClick');
		addEventListener('nodeRightClick');
	}

	doRender(model) {
		this.model = model;

		this.initDOM();
		this.initGraph(model);

		// Register
		this.registry.add(GraphRegistry, this);

		return this.wrapperEl;
	}

	async doUpdate(model) {
		await this.loading;

		this.model = model;

		this.graph.setData({
			nodes: model.nodes,
			labelProperty: model.labelProperty
		});

		this.registry.notifyChange(GraphRegistry, this);
		this.graph.graphEl.focus();
	}

	createToolbar() {
		const toolbarEl = $("<div/>")
			.css({
				padding: "4px 8px",
				"z-index": "99",
				position: "absolute",
				left: "0",
				right: "0",
				top: "-45px",
				width: "100%"
			});

		const rotationEl = $("<input/>")
			.attr("id", "rotation")
			.attr("type", "range")
			.attr("min", "-3.14")
			.attr("max", "3.14")
			.attr("step", "0.01")
			.attr("value", "0")
			.css({
				width: "200px",
				padding: "0"
			})
			.on('input', (e) => {
				this.graph.rotateProjection(e.target.value);
			});

		const zoomToFitEl = $("<button/>")
			.attr("title", "Zoom to fit")
			.css({
				"margin-left": "10px"
			})
			.addClass("yfiles-view-zoom-to-fit radio")
			.addClass("active")
			.append("<span class=\"fa fa-expand-arrows-alt\"></span>")
			.on('click', () => {
				this.graph.zoomToFit();
			});

		toolbarEl.append(
			rotationEl,
			zoomToFitEl
		);

		toolbarEl.addClass("yfiles-view-toolbar");

		return toolbarEl;
	}

	/** @override */
	createContextMenus(contextMenus) {
		ViewRenderer.prototype.createContextMenus.call(this, contextMenus);
	}

	onNodeClick(event) {
		this.trigger({
			type: 'nodeClick',
			mouse: ViewRenderer.mouse,
			...event
		});
	}

	onNodeRightClick(event) {
		if (!this.openContextMenu('node', event.data)) {
			this.trigger({
				type: 'nodeRightClick',
				mouse: ViewRenderer.mouse,
				...event
			});
		}
	}
}

YFilesIsometric.viewType = 'YFilesIsometricView';

export default YFilesIsometric;