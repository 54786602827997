const Function = require('core/src/function');
const View = require('core/src/functions/view');
const {def} = require("utils/src/validation");
const Utils = require('core/src/utils');
const IAPI = require('core/src/api-client-abstract').default;
const Err = require('utils/src/error');
const Language = require('core/src/language').default;

const _ = require('lodash');

const PropertiesView = Function.extend(View, "PropertiesView");

/**
 * @override
 * @returns {undefined}
 */
PropertiesView.prototype.onInit = function (dependencies) {

	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);

	this.setTemplating("entity", false);

	this.setParameters({
		"entity": undefined,
		"id": undefined,
		"store": undefined,
		"entityType": undefined
	});

	this.setModelValidation({
		"entity": ["isObject", {"default": undefined, warn: def}],
		"id": ["isStringOrNumber", {"default": undefined, warn: def}],
		"store": ["isString", {"default": undefined, warn: def}],
		"entityType": ["isString", {"default": undefined, warn: def}]
	});
};


/**
 *
 * @param input
 */
PropertiesView.prototype.onExecute = async function (input) {
	let entity = this.readModel("entity"),
		id = this.readModel("id"),
		store = this.readModel("store"),
		entityType = this.readModel("entityType");

	if (! entity) {
		let entity = await this.getEntityData(id, store, entityType);
		this.updateModel('entity', entity);
	}
	return View.prototype.onExecute.call(this, input);
};

/**
 * API call details of entity node or relation
 *
 * @param id
 * @param store
 * @param entityType
 */
PropertiesView.prototype.getEntityData = async function(id, store, entityType) {
	let validParams = _.conforms({
		id: 		(id) 	=> Utils.isValidId(id),
		store: 		(store) => store && _.isString(store),
		entityType:	(type) 	=> _.includes(['node', 'relation'], _.toLower(type))
	});
	if (! validParams({id, store, entityType})) {
		return;
	}

	try {
		return await this.api.executeRequest('/data/read-entity', 'get', {
			entity: {id, meta: {store}},
			type: entityType
		});
	} catch(e) {
		const message = entityType === 'relation' ? 'could-not-load-relation' : 'could-not-load-node';
		throw new Err({
			message: this.language.translate(message, {id, store}),
			public: true,
			originalError: e
		});
	}
};

module.exports = PropertiesView;
