const _ = require('core/src/utils/legacy');
const Mustache = require('mustache');
const uuid = require('uuid').v4;
const Function = require('core/src/function');
const View = require('core/src/functions/view');

const ImageMapView = Function.extend(View, "ImageMapView");

/**
* @override
* @returns {undefined}
*/
ImageMapView.prototype.onInit = function (dependencies) {
	this.setParameters({
		'$source': undefined,
		'$id': undefined,
		'$areas': undefined,
		'$areaHighlights': undefined,
		'$enableMapHighlight': undefined,
		'$enableWheelZoom': undefined,
		'$wheelZoomScale': undefined,
		'$enableZoomButtons': undefined,
		'$minZoomScale': undefined,
		'$maxZoomScale': undefined,
		'$zoomScale': undefined,
		'$zoomToFit': undefined,
	});
	this.setModelValidation({
		// Validation calls the function with this as a Validation instance, so we need to prevent that
		'source': ['isString'],
		'id': ['isString', {default: uuid(), warn: _.def}],
		'areas': ['isObject', {default: undefined, warn: _.def}],
		'areaHighlights': ['isObject', {default: undefined, warn: _.def}],
		'enableMapHighlight': ['isBoolean', {default: true, warn: _.def}],
		'enableWheelZoom': ['isBoolean', {default: true, warn: _.def}],
		'wheelZoomScale': ['isNumber', {default: 0.1, warn: _.def}],
		'minZoomScale': ['isNumber', {default: 0.3, warn: _.def}],
		'maxZoomScale': ['isNumber', {default: 3, warn: _.def}],
		'zoomScale': ['isNumber', {default: 1.2, warn: _.def}],
		'enableZoomButtons': ['isBoolean', {default: true, warn: _.def}],
		'zoomToFit': ['isBoolean', {default: false, warn: _.def}]
	});
};

module.exports = ImageMapView;