const IAPI = require('core/src/api-client-abstract').default;
const jQueryComponent = require('../component.jquery');
const log = require('core/src/log').instance("client/component/api");
const get = require('lodash/get');
require('./api.scss');

const APIComponent = function(dependencies) {
	jQueryComponent.call(this, dependencies, 'api');
	this.api = dependencies.get(IAPI);
};
APIComponent.prototype = Object.create(jQueryComponent.prototype);

APIComponent.prototype.onRender = function(properties) {
	// Start with a loader
	var $element = $('<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>');

	// Get API input
	const input = {};
	for(let p in properties) {
		if(p.substr(0, 6) === 'input-') {
			input[p.substr(6)] = properties[p];
		}
	}

	// Perform request
	const iaName = properties['ia-name'];
	const request = this.api.callAPIFunction(iaName, input);

	// Replace element with content on error (on component-loaded event so that potential clones can be replaced too).
	$element.on('component-loaded', function() {
		request.done((response) => {
			$(this).replaceWith(response);
		});
		request.fail((response) => {
			let error = $('<i class="fa fa-remove"></i>');
			error.attr('title', get(response, 'message'));
			$(this).replaceWith(error);
		});
	});
	$element.trigger('component-loaded');

	request.fail((err) => {
		log.error(err);
	});

	return $element;
};

module.exports = APIComponent;
