const Log = require('core/src/log');

const _ = require('core/src/utils/legacy');

const log = Log.instance("function/misc/datatable");

const DataTable = function(data, columns, autoColumns = false) {

	this._columns = this._defineColumns(data, columns, autoColumns);
	this._tableData = data;

	this.assignDataToColumns();
};
DataTable.prototype._columns = null;
DataTable.prototype._tableData = null;

DataTable.prototype.getColumns = function() {
	return this._columns;
};

DataTable.prototype.generateTableDataForJqxTable = function() {
	var resultTable = [];

	_.forEach(this._columns, function(column, colIdentifier) {
		if (!_.has(column, 'data')) {
			Log.error('DataTable: column definition does not have data', column);
			return;
		}
		_.forEach(column.data, function(value, rowIndex) {
			if (resultTable[rowIndex] === undefined ) {
				resultTable[rowIndex] = {};
			}
			resultTable[rowIndex][colIdentifier] = value;
		});
	});

	return resultTable;
};

DataTable.prototype.generateTableArray = function() {
	var colOrder = this.getOrderedColumns();
	var data = [];

	// Headers
	var headers = [];
	for(var c in colOrder) {
		var id = colOrder[c].identifier;
		var header = this._columns[id];
		if(header.label === undefined) {
			header.label = id;
		}
		headers.push(this._columns[id]);
	}
	data.push(headers);

	// Data
	for(var r in this._tableData) {
		var row = [];
		for(var c in colOrder) {
			var identifier = colOrder[c].identifier;
			var column = this._columns[identifier].data;
			row.push(column[r]);
		}
		data.push(row);
	}
	return data;
};

DataTable.prototype.isValidColumnDefinition = function(columnDefinition) {
	return _.isObject(columnDefinition);
};

DataTable.prototype.isValidColumnDefinitions = function(columnDefinitions) {
	var self = this;
	if (!_.isPlainObject(columnDefinitions)) {
		return false;
	}

	var result = true;
	_.forOwn(columnDefinitions, function(columnDefinition) {
		if (!self.isValidColumnDefinition(columnDefinition)) {
			result = false;
			return false;
		}
	}, this);

	return result;

};

DataTable.prototype.hasValidColumnOrder = function(columnDefinition) {
	if (_.has(columnDefinition, 'index') && ($.isNumeric(columnDefinition.index))) {
		return true;
	}

	return false;
};

// Returns array with array[columnIndex] = true for user set column indexes
DataTable.prototype.getUsedColumnOrders = function() {
	var self = this;
	var usedIndexes = [];
	// Fill usedIndexes with column definition indexes
	_.forEach(this._columns, function(columnDefinition, columnIdentifier) {
		if (self.hasValidColumnOrder(columnDefinition)) {
			usedIndexes[columnDefinition.index] = true;
		}
	}, this);

	return usedIndexes;
};

DataTable.prototype.getUnusedColumnOrder = function(usedColumnOrders, startFrom) {
	var currentIndex = startFrom;

	while (usedColumnOrders[currentIndex]) {
		currentIndex++;
	}

	return currentIndex;
};


DataTable.prototype.setColumnOrderToAllColumnDefinitions = function() {
	var self = this;
	var usedColumnIndexes = this.getUsedColumnOrders();
	var currentIndex = -1; // 0 will be the first column index

	_.forEach(this._columns, function(columnDefinition, columnIdentifier) {
		if (!self.hasValidColumnOrder(columnDefinition)) {
			currentIndex = self.getUnusedColumnOrder(usedColumnIndexes, currentIndex + 1);
			self._columns[columnIdentifier].index = currentIndex;
		}
	}, this);
};

DataTable.prototype.getOrderedColumns = function() {
	this.setColumnOrderToAllColumnDefinitions();
	var columnsList = [];

	_.forEach(this._columns, function(columnDefinition, columnIdentifier) {
		columnsList.push({
			identifier: columnIdentifier,
			index: columnDefinition.index
		});
	});

	return _.orderBy(columnsList, ['index', 'asc']);
};

DataTable.prototype.columnLabelFromIdentifier = function(identifier) {

	var result = identifier.replace(/_/g, ' ');

	return _.capitalize(result);
};

DataTable.prototype.assignDataToColumns = function() {
	var self = this;
	_.forEach(this._columns, function(column, columnIdentifier) {
		if(_.isArray(column.data)) return; // if data is set directly, use that

		var values = _.has(column, 'values') ? column.values : columnIdentifier;
		self._columns[columnIdentifier].data = _.map(self._tableData, values);
	}, this);
};

DataTable.prototype.columnsHaveValuesParameter = function(columnDefinitions) {
	var result = false;
	_.forEach(columnDefinitions, function(columnDefinition) {
		if (_.has(columnDefinition, 'values')) {
			result = true;
			return false;
		}
	}, this);

	return result;
};

DataTable.prototype.isValidTableData = function(data) {
	if (!_.isArray(data)) {
		return false;
	}

	var result = true;

	_.forEach(data, function(rowData, rowIndex) {
		if (!_.isPlainObject(rowData)) {
			result = false;
			return false;
		}
	});

	return result;
};

DataTable.prototype.generateColumnDefinitionsFromData = function(data) {
	if (!this.isValidTableData(data)) {
		return;
	}

	var columnDefinitions = {};

	_.forEach(data, function(rowData) {
		_.forOwn(rowData, function(value, key) {
			columnDefinitions[key] = {values: key};
		});
	});

	return columnDefinitions;
};

/**
 * Feature: column definitions
 * @param data
 * @param columns
 * @private
 */
DataTable.prototype._defineColumns = function(data, columns, autoColumns = false) {
	let generatedColumnDefinitions = null;
	if (autoColumns === true || !_.isObject(columns) || Object.keys(columns).length === 0) {
		log.log('DataTable auto columns: using data to generate column definitions.');
		if (_.isEmpty(data)) {
			log.warn('DataTable: data is empty');
			data = {};
		}
		generatedColumnDefinitions = this.generateColumnDefinitionsFromData(data);
		if (!this.isValidColumnDefinitions(generatedColumnDefinitions)) {
			log.warn('DataTable: could not generate column definitions from data parameter', data.data);
			generatedColumnDefinitions = null;
		}
	}

	if (_.def(columns) &&
		!this.isValidColumnDefinitions(columns) ||
		(this.columnsHaveValuesParameter(columns) && !this.isValidTableData(data))) {
		log.warn("DataTable: invalid column definitions.");
		columns = null;
	}

	return _.extend(generatedColumnDefinitions, columns);
};

module.exports = DataTable;