const forEach = require('lodash/forEach');

require('./component.jquery.scss');

const jQueryComponent = function(dependencies, type) {
	this.dependencies = dependencies;
	this.type = type;
	this.properties = {};
	this.innerHtml = '';
	this._firstRender = true;
};
jQueryComponent.prefix = 'ia-';

jQueryComponent.createContainer = function(type, properties) {
	let $container = $('<div class="ia-component">');
	$container.addClass('ia-component-' + type);
	forEach(properties, function(value, key) {
		$container.attr('data-' + key, value);
	});
	$container.attr('data-_type', jQueryComponent.prefix + type);

	return $container;
};

/**
 * Creates a component placeholder from a fully rendered component.
 * @param container
 */
jQueryComponent.createPlaceholder = function(container) {
	// Get attributes from component container
	let properties = {};
	// Go through 'data-' attributes because data() will give us camelCase keys
	forEach(container[0].attributes, function(attribute) {
		let match = /^data-(.*)$/.exec(attribute.name);
		if(!match) return;
		properties[match[1]] = attribute.value;
	});

	let type = properties._type;
	delete properties._type;

	// Create placeholder element
	let placeholder = $('<' + type +'>');
	placeholder.attr(properties);

	return placeholder;
};

jQueryComponent.prototype.type = 'component';

jQueryComponent.prototype.onRender = function(element) {
	// for override
};

jQueryComponent.prototype.render = function(element) {
	let $element = $(element);
	let properties = this.getProperties($element);
	let innerHtml = this.getInnerHtml($element);

	// Check for changes
	let changed = false;
	for(let p in properties) {
		if(properties[p] !== this.properties[p]) {
			changed = true;
			break;
		}
	}
	changed = this._firstRender || changed || innerHtml !== this.innerHtml;

	if(!changed) return false;

	this._firstRender = false;

	let $container = jQueryComponent.createContainer(this.type, properties);
	$element.replaceWith($container);

	let $rendered = this.onRender(properties, innerHtml);
	$container.html($rendered);

	$container.on('component-loaded', function() {
		$(this).children().triggerHandler('component-loaded'); // no bubbling
	});
};

jQueryComponent.prototype.getProperty = function(element, property) {
	return element.attr(property);
};

jQueryComponent.prototype.getProperties = function(element) {
	const attributes = {};
	forEach($(element)[0].attributes, function(attribute) {
		let key = attribute.name;
		let value = attribute.value;

		attributes[key] = value;
	});
	return attributes;
};

jQueryComponent.prototype.getInnerHtml = function(element) {
	return $(element)[0].innerHTML;
};

module.exports = jQueryComponent;
