const Edition = require('core/src/edition').default;

Edition.setBuildFeature(Edition.Feature.WEBSOCKET, false);

export default class Socket {
  io(...args) {
    const error = Edition.functionNotAvailableError('Websocket');
    // TODO: error should be shown on function
    throw error;
  }
}
