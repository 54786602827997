var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-map-view", attrs: { id: "wrapper-" + _vm.imgId } },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { key: _vm.source, staticClass: "panable zoomable dragable3" },
          [
            _c("img", {
              attrs: {
                src: _vm.source,
                usemap: "#" + _vm.mapName,
                id: _vm.imgId,
              },
              on: {
                click: function ($event) {
                  return _vm.imgClicked($event)
                },
                contextmenu: function ($event) {
                  return _vm.imgRightClicked($event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "map",
              { attrs: { name: _vm.mapName, id: _vm.mapName } },
              _vm._l(_vm.areas, function (item, idx) {
                return _c(
                  "area",
                  _vm._b(
                    {
                      on: {
                        click: function ($event) {
                          return _vm.areaClicked($event, item.id || idx)
                        },
                        contextmenu: function ($event) {
                          return _vm.areaRightClicked($event, item.id || idx)
                        },
                      },
                    },
                    "area",
                    _vm.getArea(item.id || idx),
                    false
                  )
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _vm.enableZoomButtons
          ? _c(
              "div",
              {
                staticClass: "btn-group-vertical btn-group-sm zoomButtons",
                attrs: { role: "group", "aria-label": "Zoom Buttons" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.zoomIn($event)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-plus" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.zoomOut($event)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-minus" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: { click: _vm.zoomReset },
                  },
                  [_c("i", { staticClass: "fa fa-refresh" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: { click: _vm.zoomTofit },
                  },
                  [_c("i", { staticClass: "fa fa-expand-arrows-alt" })]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }