require('cypher-codemirror/dist/cypher-codemirror-all.css');
require('../../../libraries/cypher-editor/codemirror-cypher-mode');
require('codemirror/addon/lint/lint');
require('codemirror/addon/lint/lint.css');
require('codemirror/addon/hint/show-hint');
require('codemirror/addon/edit/closebrackets');

const AlternativeEditorAbstract = require('../alternative-editor-abstract').default;
const { CypherEditorSupport, TreeUtils } = require('cypher-editor-support/dist/cypher-editor-support.min.js');
const { neo4jSchema } = require('../../../libraries/cypher-editor/common');
const CodeMirror = require('client/libraries/code-mirror-interactor');
const IAPI = require('core/src/api-client-abstract').default;
const _ = require('lodash');


export class CypherEditor extends AlternativeEditorAbstract {
	constructor(dependencies, container) {
		super();

		this.editor = CodeMirror.fromTextArea(container.firstElementChild, options);
		this.editor.on('change', this.triggerAutoCompletion.bind(this));

		this.editor.cypherMarkers = [];
		this.editor.version = 1;
		this.editor.newContentVersion = function newContentVersion() {
			this.version += 1;
			return this.version;
		};

		this._context = {};
		this._$container = $(container);

		this.api = dependencies.get(IAPI);
		this.editorSupport = new CypherEditorSupport();
		this.editor.editorSupport = this.editorSupport;
		this.getStoreSchema();
	}

	async getStoreSchema(store) {
		const dbSchema = await this.api.getStoreSchema(store);
		const schema = _.assign({}, neo4jSchema, dbSchema);
		this.editorSupport.setSchema(schema);
	}

	triggerAutoCompletion(cm, changed) {
		if (changed.text.length !== 1) {
			return;
		}

		const text = changed.text[0];
		const shouldTriggerAutocompletion =
			text === '.' ||
			text === ':' ||
			text === '[]' ||
			text === '()' ||
			text === '{}' ||
			text === '[' ||
			text === '(' ||
			text === '{' ||
			text === '$';
		if (shouldTriggerAutocompletion) {
			cm.execCommand('autocomplete');
		}
	}

	onContextChanged() {
	    if(this._context.store) this.getStoreSchema(this._context.store);
    }

	getValue() {
		return this.editor.getValue();
	}

	setValue(value) {
		this.editor.setValue(value);
	}

	destroy() {
		const $editor = this._$container.find('.CodeMirror');
		$editor[0].remove();
	}
}

const options = {
	mode: 'application/x-cypher-editor',
	indentWithTabs: true,
	smartIndent: false,
	lineNumbers: true,
	matchBrackets: true,
	autofocus: true,
	theme: 'neo',
	lint: true,
	styleActiveLine: true,
	extraKeys: { 'Ctrl-Space': 'autocomplete' },
	hintOptions: {
		completeSingle: false,
		closeOnUnfocus: false,
		alignWithWord: true,
		async: true,
	},
	gutters: ['cypher-hints'],
	lineWrapping: true,
	autoCloseBrackets: {
		explode: '',
	}
};

function translatePosition(from, to) {
	return {
		from: { line: from.line - 1, ch: from.column },
		to: { line: to.line - 1, ch: to.column },
	};
}

function getPosition(element, editorSupport) {
	const { start, stop } = TreeUtils.getPosition(element) || { start: 0, stop: 0 };
	const from = editorSupport.positionConverter.toRelative(start);
	const to = editorSupport.positionConverter.toRelative(stop + 1);
	return translatePosition(from, to);
}

function fixColors(editor, editorSupport) {
	var markers = editor.cypherMarkers;

	markers.forEach(function (m) {
		return m.clear();
	});
	if (editorSupport.parseTree == null) {
		return;
	}

	editorSupport.applyHighlighthing(function (element, type) {
		var _getPosition = getPosition(element, editorSupport),
			from = _getPosition.from,
			to = _getPosition.to;

		markers.push(editor.markText(from, to, {
			className: 'cm-p-' + type
		}));
	});
}

CodeMirror.registerHelper('hint', 'cypher-editor', editor => {
	const editorSupport = editor.editorSupport;
	if (!editorSupport) return {};
	editorSupport.update(editor.getValue());

	const { line, ch } = editor.getCursor();
	const { items, from, to } = editorSupport.getCompletion(line + 1, ch);

	const position = translatePosition(from, to);
	const render = (element, self, data) => {
		// eslint-disable-next-line no-param-reassign
		element.innerHTML += `<b>${data.displayText}</b>${data.postfix ? data.postfix : ''}`;
	};

	return {
		list: items
			.map(({ type, view, content, postfix }) => ({
				text: content,
				displayText: view,
				className: `cm-hint-${type}`,
				type,
				postfix,
				render,
			})),
		...position,
	};
});

CodeMirror.registerHelper('lint', 'cypher-editor', (text, options, editor) => {
	var editorSupport = editor.editorSupport;
	if (!editorSupport) return [];
	var version = editor.newContentVersion();
	editorSupport.update(text, version);

	fixColors(editor, editorSupport);

	return (editorSupport.parseErrors || []).map(function (_ref2) {
		var line = _ref2.line,
			col = _ref2.col,
			msg = _ref2.msg;
		return {
			severity: 'error',
			from: { line: line - 1, ch: Math.min(editor.getLine(line - 1).length - 1, col) },
			to: { line: line, ch: 0 },
			message: msg
		};
	});
});
