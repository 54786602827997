import Log from 'core/src/log';
import ApiClientAbstract from 'core/src/api-client-abstract';
import Trigger from 'core/src/trigger';
import Function from 'core/src/function';
import IFactory from 'core/src/i-factory';

import {has, isString, isArray, includes, forEach, isPlainObject} from 'lodash';
import {isStringOrNumber, def} from 'utils/src/validation';
import Err from 'utils/src/error';

import _ from 'lodash';
import deferredPromise from 'core/src/utils/deferred-promise';
import Language from 'core/src/language';

const IA = 'IA_'; // TODO: from configuration

const log = Log.instance("core/factory");

export default class Factory extends IFactory {
	constructor(dependencies) {
		super();
		this.functions = {};
		this.api = dependencies.get(ApiClientAbstract);
		this.language = dependencies.get(Language);
		this.dependencies = dependencies;
	}

	static functionToNode(func) {
		if(!(func instanceof Function)) {
			Log.error('Given argument is not a Function.');
			return null;
		}

		// Construct node
		const node = new Prologram.Node();
		node.labels = ['Prologram', 'Function']; // TODO: add specific Function type as label
		const parameters = func.getParameters();
		for(let i in parameters) {
			node.properties[i] = parameters[i];
		}
		// Properties and parameters should not conflict, since the latter has
		// prefixes ('#' or '$')
		const properties = func.getProperties();
		for(let i in properties) {
			node.properties[i] = properties[i];
		}

		return node;
	}

	static isFunctionNode(functionNode) {
		return (has(functionNode, 'labels')
			&& 	isArray(functionNode.labels)
			&&  includes(functionNode.labels, 'IA_Function'));
	}

	functionExists(functionName) {
		return has(this.functions, functionName);
	}

	getFunctionNameFromLabelsList(labels) {
		let result = undefined;

		if (!isArray(labels)) {
			return result;
		}

		forEach(labels, label => {
			let name = label;
			if (isString(label)) {
				name = name.replace(new RegExp('^' + IA), '');
			}

			if (name !== 'Function' && this.functionExists(name)) {
				result = name;
			}
		});

		return result;
	}

	getFunctionNameFromTypeProperty(properties) {
		let result = undefined;

		if (!isPlainObject(properties) || (!properties.hasOwnProperty("type") && !properties.type)) {
			return result;
		}

		let name = properties.type.trim();
		if (name !== 'Function' && this.functionExists(name)) {
			result = name;
		}

		return result;
	}

	createFunctionInstance(functionName, initData) {
		if (this.functionExists(functionName)) {
			return new this.functions[functionName](this.dependencies, initData);
		}
		log.error('Factory.createFunctionInstance: function does not exist', functionName);
		return false;
	}

	loadFunction(initData) {
		let functionClass = 'Function';

		if (has(initData, '_functionName')) {
			functionClass = initData._functionName;
			let loadedFunction = this.createFunctionInstance(functionClass, initData);

			if (loadedFunction) {
				return deferredPromise(Promise.resolve(loadedFunction));
			}
			// continue to failure
		} else if (initData instanceof Function) {
			return deferredPromise(Promise.resolve(initData)); // Function was already loaded.
		} else if (isStringOrNumber(initData)) {
			return this.loadFunctionFromNeo4j({id:initData});
		} else if (_.has(initData, 'id') || _.has(initData, 'iaName') ) {
			return this.loadFunctionFromNeo4j(initData);
		}

		let error = new Err({
			message: this.language.translate("Could not load Function: {{initData}}", {initData}),
			data: initData
		});
		log.error('Factory.loadFunction: could not load function: ', initData);
		return deferredPromise(Promise.reject(error));
	}

	loadFunctionFromNode(nodeData) {
		let functionName;

		functionName = this.getFunctionNameFromTypeProperty(nodeData.properties);

		// For backward compatibility
		if (!functionName){
			functionName = this.getFunctionNameFromLabelsList(nodeData.labels);
			log.warn(`Deprecation Warning: Using 'IA_${functionName}' as label is being deprecated. 
				Instead add a property called 'type' (e.g. type: ${functionName}) 
				https://docs.graphileon.com/graphileon/For_Dashboard_Designers/Functions/index.html`);
		}

		if(!functionName) {
			throw new Err(this.language.translate("cannot-determine-function", {nodeLabels: nodeData.labels.join(','), type: nodeData.properties.type}));
		}
		const initData = Function.splitParametersAndProperties(nodeData.properties) || {};
		initData.id = nodeData.id;
		initData.labels = nodeData.labels;
		return this.createFunctionInstance(functionName, initData);
	}

	loadFunctionFromNeo4j(functionNodeFindObject) {
		const promise = new Promise(async (resolve, reject) => {
			try {
				const response = await this.api.loadFunction(functionNodeFindObject);
				if (! Factory.isFunctionNode(response.function)) {
					return reject(new Err(this.language.translate('Function nodes require IA_Function label'), response.function));
				}

				const functionInstance = this.loadFunctionFromNode(response.function);

				if (isArray(response.triggers)) {
					forEach(response.triggers, triggerNodeData => {
						let trigger = this.createTriggerFromRelation(triggerNodeData);
						functionInstance.registerTrigger(trigger);
					});
				}

				resolve(functionInstance);
			} catch(e) {
				reject(e);
			}
		});
		return deferredPromise(promise);
	}

	register(FunctionClass, functionName) {
		if(!functionName) functionName = FunctionClass.functionName;
		if(!functionName) {
			log.warn("Cannot register Function without functionName property.", FunctionClass);
			return;
		}
		this.functions[functionName] = FunctionClass;
		if(FunctionClass.hasOwnProperty('aliases')) {
			_.forEach(FunctionClass.aliases, alias =>
				this.functions[alias] = FunctionClass
			);
		}
	}

	deleteFunction(existingFunctionName) {
		if (this.functionExists(existingFunctionName)) {
			delete this.functions[existingFunctionName];
			return true;
		}

		return false;
	}

	createTrigger(initData) {
		return new Trigger(this.dependencies, initData);
	}

	createTriggerFromRelation(nodeData) {
		if (!nodeData.properties) {
			Log.error('Factory.createTriggerFromRelation: nodeData does not have properties attribute', nodeData);
			return false;
		}
		if(isArray(nodeData.properties) && nodeData.properties.length === 0) {
			nodeData.properties = {};
		}
		const initData = Function.splitParametersAndProperties(nodeData.properties);

		if (!initData || (!def(nodeData.target))) {
			Log.error('Factory.createTriggerFromRelation: nodeData is invalid', nodeData);
			return false;
		}
		initData.targetFunction = nodeData.target;
		initData.id = nodeData.id;

		return this.createTrigger(initData);
	}

	executeFunction(functionInitData, data) {
		return this.createTrigger({
			targetFunction: functionInitData
		}).execute(data);
	}
}
