
import Vue from 'vue';
import VueJsonPretty from 'vue-json-pretty';
import { abstractField } from "vue-form-generator";

import 'vue-json-pretty/lib/styles.css';

export default {
  mixins: [ abstractField ],
  components: {
    VueJsonPretty,
  }
}
