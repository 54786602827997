"use strict";

import _ from 'lodash';
import Log from 'core/src/log';
import ViewRenderer from 'client/src/view-renderer';

import { 
	Color,
	MeshBasicMaterial,
	LineBasicMaterial,
	Mesh,
	BoxGeometry 
} from 'three';

import { IfcViewerAPI } from 'web-ifc-viewer';

import {
	IFCWALLSTANDARDCASE,
	IFCSLAB,
	IFCFURNISHINGELEMENT,
	IFCDOOR,
	IFCWINDOW,
	IFCPLATE,
	IFCMEMBER 
} from 'web-ifc';

import { } from 'three';

let log = Log.instance('function/ifc3dview/renderer');

const lineMaterial = new LineBasicMaterial({ color: 0x555555 });
const baseMaterial = new MeshBasicMaterial({ color: 0xffffff, side: 2 });

class IFC3DViewRenderer extends ViewRenderer {
	constructor(dependencies) {
		super(dependencies);
		this.dependencies = dependencies;

		this.dom = null;
		this.viewer = null;
		this.model = null;
		this.IFCItems = [];
	}


	async loadIFCFromURL(url) {
		this.model = await this.viewer.IFC.loadIfcUrl(url);
		this.loadFileButton.remove();
		this.resize()
		this.calculateSpacialStructure()
	}


	async loadIFCFromFileInput(fileInstance) {
		this.model = await this.viewer.IFC.loadIfc(fileInstance, true);
		this.loadFileButton.remove();
		this.resize();
		this.calculateSpacialStructure()
	}

	getFaceId(face) {
		if (! this.viewer) {
			return null;
		}

		return this.viewer.IFC.loader.ifcManager.getExpressId(face.object.geometry, face.faceIndex);
	}

	getHoveredVisibleFace() {
		let faces = this.viewer.IFC.context.ifcCaster.filterClippingPlanes(
			this.viewer.IFC.context.ifcCaster.castRay(this.viewer.IFC.context.items.pickableIfcModels)
		)

		let found = _.find(
			faces,
			(f) => _.has(this.visibleItems, this.getFaceId(f))
		)

		return found;
	}

	async calculateSpacialStructure() {
		let items = [];
		let parseStructure = (structure, parentId = null) => {
			items.push(
				_.extend(
					{
						id: structure.expressID,
						parentId: parentId,
						visible: true
					},
					_.omit(structure, 'children')
				)
			);
			_.forEach(structure.children, (x) => parseStructure(x, structure.expressID));
		};

		let spatialStructure = await this.viewer.IFC.getSpatialStructure(this.model.modelID, true);
		parseStructure(spatialStructure);

		this.IFCItems = items;
		this.IFCItemsById = _.keyBy(items, 'id');
		this.visibleSubset = this.model;

		this.requestUpdate({
			IFCItems: this.IFCItems,
			IFCItemsById: this.IFCItemsById
		});

		this.trigger({
			type: 'modelLoaded',
			IFCItems: this.IFCItems,
			IFCItemsById: this.IFCItemsById
		});
	}

	doRender(model) {
		let dom = `
			<div style="display: contents">
				<label class="btn">
					<input type="file" style="width: 0px; visibility: hidden;"/>
					Load file
				</label>
				<div class="ifc-3d-view flexbox flex" style="background-color: #fafafa; overflow: hidden;"></div>
			<div>
		`;

		this.dom = $(dom);
		this.canvasContainer = this.dom.find('.ifc-3d-view');
		this.loadFileButton = this.dom.find('label.btn');
		this.fileInput = this.dom.find('input[type=file]');
		this.fileInput.on('change', () => {
			this.loadIFCFromFileInput(this.fileInput[0].files[0]);
		})

		this.canvasContainer.on(
			'mousemove', 
			_.debounce(
				async () => {
					if (! this.viewer) 
						return;

					// const found = this.viewer.IFC.context.castRayIfc();
					
					let found = this.getHoveredVisibleFace();

					this.hoveredItemId = null;
					
					// This is more efficient than destroying and recreating the subset when the user hovers away
					if (! found) {
						this.viewer.IFC.selector.preselection.toggleVisibility(false);
						return;
					}

					let res  = await this.viewer.IFC.selector.preselection.pick(found);

					if (! res) {
						return
					}

					if (! this.visibleItems[res.id]) {
						this.viewer.IFC.selector.preselection.toggleVisibility(false);
						return;						
					}

					this.hoveredItemId = res.id;

					this.trigger({
						type: 'mouseOver',
						data: this.IFCItemsById[this.hoveredItemId]
					});

				},
				100
			)
		);

		this.canvasContainer.on(
			'click',
			async (ev) => {
				if (! this.viewer) 
					return;

				await this.viewer.IFC.selector.unpickIfcItems();

				let found = this.getHoveredVisibleFace();
				let res;

				if (found) {
					res = await this.viewer.IFC.selector.selection.pick(found, false, true);
				}

				this.selectedItems = [];
	
				if (_.isNil(_.get(res, 'id'))) {
					this.requestUpdate({
						'state.selected': []
					})
					return;
				}

				this.selectedItems = [this.IFCItemsById[res.id]];

				this.requestUpdate({
					'state.selected': this.selectedItems
				})

				this.trigger({
					type: 'IFCItemClick',
					data: this.IFCItemsById[res.id],
					keyPressed: {
						alt: ev.altKey,
						ctrl: ev.ctrlKey,
						meta: ev.metaKey,
						shift: ev.shiftKey
					},
					mouse: ViewRenderer.mouse
				})
			}
		);

		this.canvasContainer.on(
			'contextmenu',
			(ev) => {
				ev.preventDefault();
				let face = this.getHoveredVisibleFace();

				if (! face) {
					this.openContextMenu('canvas');
					return;
				}

				let item = this.IFCItemsById[this.getFaceId(face)];
				if (! this.openContextMenu('IFCItem', item)) {
					this.trigger({
						type: 'IFCItemRightClick',
						data: item,
						keyPressed: {
							alt: ev.altKey,
							ctrl: ev.ctrlKey,
							meta: ev.metaKey,
							shift: ev.shiftKey
						},
						mouse: ViewRenderer.mouse
					});					
				}
			}
		);

		_.defer(() => {
			this.viewer = new IfcViewerAPI({ 
				container: this.canvasContainer[0],
				backgroundColor: new Color(255, 255, 255) 
			});

			this.viewer.axes.setAxes();
			this.viewer.grid.setGrid();
			this.viewer.shadowDropper.darkness = 1.5;
			this.scene = this.viewer.context.getScene()
			this.viewer.IFC.loader.ifcManager.applyWebIfcConfig({
				USE_FAST_BOOLS: true,
				COORDINATE_TO_ORIGIN: true
			});

			if (model.url) {
				this.loadIFCFromURL(model.url);

			}

		});

		return this.dom;

	}

	doUpdate(model, updates) {
		let current = this.visibleSubset;

		let visibleItems = _.filter(model.IFCItems, {visible: true});

		this.visibleItems = _.keyBy(visibleItems, 'id');

		this.visibleSubset = this.model.createSubset({
			modelID: this.model.modelID,
			ids: _.map(visibleItems, 'id'),
			removePrevious: true
		});
		this.scene.remove(current);
		this.scene.add(this.visibleSubset);

		if (! _.has(this.visibleItems, this.hoveredItemId)) {
			this.viewer.IFC.selector.preselection.toggleVisibility(false);
		}

		_.forEach(
			this.selectedItems,
			(item) => {
				if (! _.has(this.visibleItems, item.id)) {
					this.viewer.IFC.selector.unpickIfcItems();
				}
			}
		)

		this.requestUpdate({
			'state.visible': visibleItems
		})

	}

	doResize(width, height) {
		if (! this.viewer) {
			return;
		}

		this.viewer.IFC.context.resize();
		// First resize is smaller because of the scrollbars of the view-container
		setTimeout(this.viewer.IFC.context.resize, 100);
	}

	onClose(...params) {
		this.viewer.dispose();
	}
}

IFC3DViewRenderer.viewType = 'IFC3DView';

export default IFC3DViewRenderer;
