import _ from 'lodash';

const Icon = {
	getClass(icon) {
		if(!_.isString(icon)) return '';
		icon = _.trim(icon);

		// Full FontAwesome classes (including legacy .fa classes)
		let match = /^fa[srldb]? fa-[\w-]+$/.exec(icon);
		if(match) {
			return icon;
		}

		match =
			/^fa-([\w-]+)$/.exec(icon) // Partial FontAwesome classes
			|| /^([\w-]+)$/.exec(icon) // Icon name only

		if(!match) {
			// Unknown pattern; just return input
			return icon;
		}

		// Convert to full, up-to-date FontAwesome class (including `fa` for backward compatibility)
		return `fa fas fa-${match[1]}`;
	}
};

export default Icon;
