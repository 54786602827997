const Function = require('core/src/function');
const IAPI = require('core/src/api-client-abstract').default;
const _ = require('core/src/utils/legacy');
const Validation = require('core/src/utils/validation');
const Language = require('core/src/language').default;

const Email = Function.extend(Function, 'Email');

Email.prototype.onInit = function (dependencies) {
	this.api = dependencies.get(IAPI);
	this.language = dependencies.get(Language);

	let params = {
		'$subject': '',
		'$message': '',
		'$html': '',
		'$to': '',
		'$cc': '',
		'$bcc': '',
		'#attachments': []
	};
	this.setParameters(params);

	var notRequired = {default: undefined, warn: _.def};
	this.setModelValidation({
		subject		: ['isString', {default: '', warn: _.def}],
		message		: ['isString', {default: '', warn: _.def}],
		html		: ['isString', notRequired],
		to			: [val => { return _.isString(val) || _.isArray(val); }, {default: '', warn: _.def}],
		cc			: [val => { return _.isString(val) || _.isArray(val); }, {default: '', warn: _.def}],
		bcc			: [val => { return _.isString(val) || _.isArray(val); }, {default: '', warn: _.def}],
		attachments : ['isArray', notRequired]
	});
};

Email.prototype.onExecute = function(){
	const promise = new Promise(async (resolve, reject) => {
		const data = {
			id: this.getId(),
			subject: this.readModel('subject'),
			message: this.readModel('message'),
			html: this.readModel('html'),
			to: this.readModel('to'),
			cc: this.readModel('cc'),
			bcc: this.readModel('bcc'),
			attachments: this.readModel('attachments')
		};

		try {
			const response = await this.api.sendEmail(data);
			const event = {
				type: 'success',
				user: _.get(response, 'result.user'),
			};

			this.executeTriggers(event);
		    resolve(true);

		} catch (error) {
			reject(new _.Error({
				message: this.language.translate('Mail sent error: {{errorMsg}}', {errorMsg: _.get(error, 'message')}),
				originalError: error
			}));
		}

	});

	return promise;
};

module.exports = Email;
