const API = require('./api');
const Edition = require('core/src/edition').default;
const Function = require('core/src/function');
const _ = require('lodash');
const Err = require('utils/src/error');
const { writable } = require('core/src/utils/read-only');

Edition.setBuildFeature(Edition.Feature.FUNCTION_API, true);

API.prototype.execute = function(...args) {
	if (!Edition.hasFeature(Edition.Feature.FUNCTION_API)) {
		const error = Edition.functionNotAvailableError('API');
		this.error(error);
		throw error;
	}
	return Function.prototype.execute.call(this, ...args);
};

API.prototype.onExecute = function() {
	const input = this.readModel('input');

	this.updateModel('_path.apiInstance', this._instanceID);

	this.executeTriggers({
		type: 'request',
		input: input
	});
};

API.prototype.onUpdate = function() {
	let response = this.readModel('response');
	if(!_.isNil(response)) {
		// We don't want to bother listeners with ReadOnly Proxies
		this.fire('response', _.cloneDeep(writable(response)));
		this.closeFunctionInstance();
	}

	const error = this.readModel('error');
	if(!_.isNil(error)) {
		const err = new Err(error);
		this.fire(API.Event.Out.ERROR, err);
		this.error(err);
	}
};
