
    import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";

    export default {
        name: "set-password",
        mixins: [VueRendererComponent],

        data: function () {
            return {
                userId: undefined,
                token: undefined,
                tokenError: undefined,
                password: undefined,
                passwordConfirm: undefined,
                passwordCurrent: undefined,
                error: undefined,
                success: undefined
            }
        },

        methods: {
            submit() {
                if (this.passwordConfirm !== this.password){
                    this.error = "Passwords do not match.";
                    return;
                }

                this.$emit('submit', {
                    userId: this.userId,
                    token: this.token,
                    newPassword: this.password,
                    password: this.passwordCurrent

                });
            },
            textChanged(event){
                if (this.error) this.error = null;
            }
        },
        computed: {
            isDisabled: function () {
                return !!this.success;
            },
            classObject: function () {
                return {
                    error: !!this.error || !!this.tokenError,
                    success: !!this.success
                }
            },
            showMessage: function () {
                return !!this.success || !!this.error || !!this.tokenError;
            }
        }

    }
