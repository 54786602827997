const ensure = require('utils/src/validation').ensure;
require('fullcalendar');
require('fullcalendar/dist/fullcalendar.min.css');

const ViewRenderer  = require('client/src/view-renderer');
const log = require('core/src/log').instance("function/calendarviewrenderer");

const CalendarViewRenderer = function () {
	ViewRenderer.call(this);
};
CalendarViewRenderer.viewType = 'CalendarView';
CalendarViewRenderer.prototype = Object.create(ViewRenderer.prototype);

/* Properties */

CalendarViewRenderer.prototype.calendar = null;

/* Methods */

CalendarViewRenderer.prototype.getEvents = function () {
	if (this.calendar === null) {
		log.warn("Cannot get calendar events before render.");
		return [];
	}
	return this.calendar.fullCalendar('clientEvents');
};

/**
 * Creates a 'clean' copy of an event, to pass between Functions.
 * @param {object} event
 * @returns {object}
 */
CalendarViewRenderer.prototype._cleanEvent = function (event) {
	var cleanEvent = _.cloneDeep(event);
	delete cleanEvent._allDay;
	delete cleanEvent._end;
	delete cleanEvent._id;
	delete cleanEvent._start;
	delete cleanEvent.source;

	cleanEvent.start = _.get(cleanEvent, 'start._i');
	cleanEvent.end = _.get(cleanEvent, 'end._i');
	return cleanEvent;
};

/**
 * Create a 'clean' string of a date object, to pass between Functions.
 * @param {object} date
 * @returns {undefined}
 */
CalendarViewRenderer.prototype._cleanDate = function (date) {
	if (_.isFunction(date.format)) {
		return date.format();
	} else {
		log.warn("Date does not have a format function.", date);
	}
	return undefined;
};

CalendarViewRenderer.prototype._dayClick = function (date) {
	var cleanDate = this._cleanDate(date);
	this.userEvent({
		type: 'dayClick',
		date: cleanDate
	});
};
/**
 * Create a function for a FullCalendar callback targeting a calendar event.
 * @param {string} userEventType The type to set for the userEvent.
 * @returns {function}
 */
CalendarViewRenderer.prototype._eventClick = function (event) {
	var cleanEvent = this._cleanEvent(event);
	this.userEvent({
		type: 'eventClick',
		event: cleanEvent
	});
};
/**
 * Opens the 'event' context menu.
 * @param {object} event
 * @returns {undefined}
 */
CalendarViewRenderer.prototype._eventClickRight = function (event) {
	this.openContextMenu(event, 'event');
};

CalendarViewRenderer.prototype._eventRender = function (event, element) {
	var self = this;
	// Right click for context menu
	element.bind('mousedown', function (e) {
		if (e.button === 2) {
			self.openContextMenu("event", self._cleanEvent(event), e.clientX, e.clientY);
		}
	});
	// Left click for user event
	element.on('click', function (e) {
		self._eventClick(event, e);
		e.preventDefault();
	});
};
CalendarViewRenderer.prototype._dayRender = function (date, cell) {
	var self = this;
	// Right click for context menu
	cell.bind('mousedown', function (e) {
		if (e.button === 2) {
			self.openContextMenu("day", self._cleanDate(date), e.pageX, e.pageY);
		}
	});
	// Left click for userEvent
	cell.on('click', function (e) {
		self._dayClick(date);
	});
};

/**
 * @override
 * @param {object} data
 * @returns {jQuery}
 */
CalendarViewRenderer.prototype.doRender = function (data) {
	var self = this;

	var events = ensure(data.events, _.isArray, {});

	// Initialize Calendar
	this.calendar = $('<div>');
	this.calendar.addClass('calendarview');
	this.calendar.fullCalendar({
		defaultDate: data.defaultDate,
		header: {
			left: 'prev,next today',
			center: 'title',
			right: 'month,basicWeek,basicDay'
		},
		editable: false,
		eventLimit: true, // allow "more" link when too many events
		events: events,
		timeFormat: 'h:mm',
		eventRender: function (event, element) {
			self._eventRender(event, element);
		},
		dayRender: function (date, cell) {
			self._dayRender(date, cell);
		}
	});
	// Disable browser context menu
	this.calendar.on('contextmenu', function (e) {
		return false;
	});

	// Make events available in local memory (for getEvents).
	this.calendar.fullCalendar('refetchEvents');

	return this.calendar;
};

CalendarViewRenderer.prototype.doResize = function (width, height) {
	// Some internal part of the calendar is not initialized at the moment the container is ready, so allow it to initialize first
	setTimeout(function () {
		$(this.calendar).fullCalendar('option', 'height', height);
	});
};

CalendarViewRenderer.prototype.onReady = function () {
	$(this.calendar).fullCalendar('render');
};

module.exports = CalendarViewRenderer;
