	"use strict";

	const Function = require('core/src/function');
	const ViewRenderer = require('client/src/view-renderer');
	const $ = require('jquery');
	const _ = require('core/src/utils/legacy');

	/* Inheritance and constructor */

	const TreeViewRenderer = function () {
		ViewRenderer.call(this);
	};
	TreeViewRenderer.prototype = Object.create(ViewRenderer.prototype);
	TreeViewRenderer.viewType = "TreeView";

	TreeViewRenderer.prototype.$container = null;
	TreeViewRenderer.prototype.tree = null;
	TreeViewRenderer.prototype.model = null;

	/* Methods */

	// Needed because setting states on items (checked, selected, expanded) in jqxTree triggers the respective events and it will create an endless update loop
	TreeViewRenderer.prototype.enableEvents = function () {
		this.eventsActive = true;
	}

	TreeViewRenderer.prototype.disableEvents = function () {
		this.eventsActive = false;
	}

	TreeViewRenderer.prototype.trigger = function (eventData) {
		if (! this.eventsActive) {
			return;
		}

		if ((eventData.type === "itemRightClick") && this.openContextMenu('item', eventData.item)) {
			return;
		}

		ViewRenderer.prototype.trigger.call(this, eventData);
	};

	TreeViewRenderer.prototype.updateState = function() {

		if (! this.eventsActive) {
			return;
		}

		var state = this.getState();
		state[Function.VALUE_OBJECT] = true; // make sure entire state gets replaced instead of merged
		return this.requestUpdate({state: state});
	}

	TreeViewRenderer.prototype.createTree = function (config, items) {
		this.$container = $('<div class="tree-view flexbox flex flex-column"></div>');

		this.tree = $('<div></div>')
			.appendTo(this.$container)
			.jqxTree(JqxTree.validConfig(config, items));

		JqxTree.setEvents(this.tree, _.bind(this.updateState, this), _.bind(this.userEvent, this));
	};

	TreeViewRenderer.prototype.updateTree = function (items) {
		this.tree.jqxTree({source: items});
	};

	TreeViewRenderer.prototype.doRender = function (renderData) {
	this.enableEvents();
		this.createTree(renderData.config, []);
		this.doUpdate(renderData);

		return this.$container;
	};

	TreeViewRenderer.prototype.treeStructureFromItems = function (items, keyFor) {
		var Record = RecordAccessor(keyFor);

		var rootTreeItems = _.map(
			Record.getDirectChildrenFor({id: 0}, items),
			_.curry(TreeItem.fromRecord)(__, undefined, keyFor)
		);

		var listOfTreeItems =
				_.reduce(
					rootTreeItems,
					function (treeItemsList, treeItem){
						return _.concat(
							treeItemsList,
							TreeItem.getChildren(treeItem, items, keyFor)
						);
					},
					rootTreeItems
				);

		var treeStructure = TreeStructure.fromListOfTreeItems(
			listOfTreeItems
		);

		return treeStructure;
	};

	TreeViewRenderer.prototype.treeStructureFromModel = function (model) {
		if (! _.isEmpty(model.tree)) {
			return TreeStructure.fromTreeStructure(model.tree, model.keyFor);
		}
		else if (! _.isEmpty(model.items)) {
			return this.treeStructureFromItems(model.items, model.keyFor);
		}

		console.error('TreeViewRenderer.treeStructureFromModel: model has no items or tree properties', {model: model});

		return [];
	};

	TreeViewRenderer.prototype.doUpdate = function (model, mutations, updateId) {
		if (this._updateRequests[updateId]) {
			return;
		}

		this.disableEvents();

		var treeStructure = this.treeStructureFromModel(model);

		this.updateTree(treeStructure);

		JqxTree.setItemsClass(this.tree, model.keyFor);

		this.model = _.cloneDeep(model);

		this.setState(model.state, model.keyFor);
		JqxTree.searchFor(this.tree, model.search.trim().toLowerCase());

		JqxTree.refresh(this.tree);

		_.defer(() => {
			$(':animated').promise().done(
				() => this.enableEvents()
			);
		});

	};


	TreeViewRenderer.prototype.setState = function(state, keyFor) {
		if (! state){
			return;
		}

		JqxTree.selectItem(this.tree, _.head(state.selected));

		let currentlyExpanded = JqxTree.getExpandedItems(this.tree);
		let items = {
			toCollapse: _.differenceBy(currentlyExpanded, state.expanded, _.property(keyFor['id'] || 'id')),
			toExpand: _.differenceBy(state.expanded, currentlyExpanded, _.property(keyFor['id'] || 'id'))
		}
		_.forEach(items.toExpand, item => JqxTree.expandItem(this.tree, _.pick(item, keyFor['id'])));
		_.forEach(items.toCollapse, item => JqxTree.collapseItem(this.tree, _.pick(item, keyFor['id'])));

		if (state.checked && _.get(this.model, 'config.checkboxes')) {
			JqxTree.uncheckAllItems(this.tree);
			_.map(
				state.checked,
				(item) => JqxTree.checkItem(this.tree, _.pick(item, keyFor['id']))
			);
		}
	}

	TreeViewRenderer.prototype.getState = function() {

		let selected = JqxTree.getSelectedItem(this.tree);

		var state = {
		selected: selected ? [selected] : [],
		checked: JqxTree.getCheckedItems(this.tree),
		expanded: JqxTree.getExpandedItems(this.tree)
		};

		return state;
	}

	var __ = _.curry.placeholder;


	// Functions that work with a jqxTree
	var JqxTree = {
		// This filters out any unexpected data from renderData, jqxTree will throw an error in case unexpected data is present in the init object
		validConfig: function (config, source) {
			var defaultSettings = {
				animationShowDuration: 100,
				animationHideDuration: 100,
				allowDrag: false,
				allowDrop: false,
				checkboxes: false,
				dragStart: null,
				dragEnd: null,
				disabled: false,
				easing: 'easeInOutCirc',
				enableHover: true,
				height: null,
				hasThreeStates: false,
				incrementalSearch: true,
				keyboardNavigation: true,
				rtl: false,
				source: [],
				toggleIndicatorSize: 16,
				toggleMode: 'dblclick',
				theme: '',
				width: null
			};

			var treeInit = _.mapValues(
				defaultSettings,
				function (value, key) {
					return config[key] || value;
				}
			);

			treeInit.source = source || treeInit.source;

			return treeInit;
		},

		setEvents: function (jqxTree, updateState, sendUserEvent) {

			// checked/unchecked event handler
			// for trees with checkboxes with three states the event triggers on the whole tree branch and is handled bellow (itemsCheckChange)
			// this is the handler for the item that the user clicks on (checks/unchecks)
			jqxTree.on('click', 'div.chkbox.jqx-checkbox', function(){
				let treeItemElement = $(this).parents('li[role=treeitem]')[0];

				// wait for the tree to update 
				_.defer(async () => {
					var item = jqxTree.jqxTree('getItem', treeItemElement);

					if (! item) {
						return;
					}

					await updateState();

					let checked = $(this).attr('checked') == 'checked';

					sendUserEvent({
						type: 'itemCheckChange',
						item:  TreeItem.toRecord(item),
						checked
					});

					if (checked) {
						// TODO: remove in next versions
						sendUserEvent({
							type: 'checked',
							item:  TreeItem.toRecord(item)
						});

						sendUserEvent({
							type: 'itemCheck',
							item:  TreeItem.toRecord(item)
						});					
					}
					else {
						// TODO: remove in next versions
						sendUserEvent({
							type: 'unchecked',
							item:  TreeItem.toRecord(item)
						});

						sendUserEvent({
							type: 'itemUncheck',
							item:  TreeItem.toRecord(item)
						});					
					}
				});
			})

			jqxTree.on('select', async function (event) {
				var args = event.args;
				var item = jqxTree.jqxTree('getItem', args.element);

				if (! item) {
					return;
				}

				await updateState();

				// TODO: remove in next versions
				sendUserEvent({
					type: 'select',
					item: TreeItem.toRecord(item)
				});

				sendUserEvent({
					type: 'itemSelect',
					item: TreeItem.toRecord(item)
				});

			});

			jqxTree.on('itemClick', async function (event) {
				var args = event.args;
				var item = jqxTree.jqxTree('getItem', args.element);

				if (! item) {
					return;
				}

				await updateState();

				sendUserEvent({
					type: 'itemClick',
					item: TreeItem.toRecord(item)
				});

			});

			// If jqxTree has checkboxes with 3 states, there will be a cascade of check changes to synchronize the branch (ex: when you check an item all the children will be checked)
			// We need to collect all the events and trigger just one triggerEvent.
			let itemsWithCheckedState = []; // collection of checked/unchecked items with state

			// will execute after all the checked items have been collected
			let debouncedTriggerItemsCheckChangeEvent = _.debounce( 
				async() => {
					// _.uniq because jqxTree triggers the event multiple times per tree-item
					let _checkedItems = _.uniq(_.map(_.filter(itemsWithCheckedState, {checked: true}), 'item'));
					let _uncheckedItems = _.uniq(_.map(_.filter(itemsWithCheckedState, (item ) => (! item.checked)), 'item'));

					// free the pointers for the next events
					itemsWithCheckedState = [];

					await updateState();

					sendUserEvent({
						type: 'itemsCheckChange',
						checked:  _checkedItems,
						unchecked: _uncheckedItems
					});

					if (! _.isEmpty(_checkedItems)) {					
						sendUserEvent({
							type: 'itemsChecked',
							items:  _checkedItems,
						});
					}

					if (! _.isEmpty(_uncheckedItems)) {
						sendUserEvent({
							type: 'itemsUnchecked',
							items:  _uncheckedItems,
						});						
					}

				}, 100);

			jqxTree.on('checkChange', async function (event) {
				var args = event.args;
				var item = jqxTree.jqxTree('getItem', args.element);

				if (! item) {
					return;
				}

				itemsWithCheckedState.push({
					item:  TreeItem.toRecord(item),
					checked: event.args.checked
				});

				debouncedTriggerItemsCheckChangeEvent();
			});
 			

			jqxTree.on('dragEnd', async function (event) {
				var targetItem = jqxTree.jqxTree('hitTest', event.args.originalEvent.pageX, event.args.originalEvent.pageY);

				if (! targetItem) {
					return;
				}

				await updateState();

				sendUserEvent({
					type: 'itemDrag',
					item: TreeItem.toRecord(event.args),
					overItem: TreeItem.toRecord(targetItem)
				});

			});

			jqxTree.on('expand', async function (event) {
				var args = event.args;
				var item = jqxTree.jqxTree('getItem', args.element);

				if (! item) {
					return;
				}

				await updateState();

				sendUserEvent({
					type: 'itemExpand',
					item: TreeItem.toRecord(item)
				});
					});

			jqxTree.on('collapse', async function (event) {
				var args = event.args;
				var item = jqxTree.jqxTree('getItem', args.element);

				if (! item) {
					return;
				}

				await updateState();

				sendUserEvent({
					type: 'itemCollapse',
					item: TreeItem.toRecord(item)
				});
			});

			jqxTree.on('contextmenu', 'li .jqx-tree-item', async function (event) {
				event.preventDefault();

				await updateState();

				sendUserEvent({
					type: 'itemRightClick',
					item: TreeItem.toRecord(
						JqxTree.getItemFromLi(
							jqxTree, 
							$(this).parents('li').first()[0]
						)
					)
				});
			});

		},
		
		setItemsClass: function (jqxTree, keyFor) {
			var treeItems = jqxTree.jqxTree('getItems');

			_.forEach(
				treeItems,
				function (treeItem) {
					$(treeItem.element)
						.children('div.jqx-tree-item')
						.addClass(
							_.get(TreeItem.toRecord(treeItem), keyFor.class)
						);
				}
			);
		},

		searchFor: function (jqxTree, searchedString) {
			var unHighlightItem = function (treeItem) {
				$(treeItem.element).children('.jqx-tree-item').removeClass('jqx-tree-item-highlighted');
			};

			var highlightItem = function (treeItem) {
				jqxTree.jqxTree('expandItem', treeItem.parentElement);
				$(treeItem.element).children('.jqx-tree-item').addClass('jqx-tree-item-highlighted');
			};

			var itemLabelIncludes = function (treeItem, string) {
				return treeItem.label.toLowerCase().indexOf(string) !== -1;
			};

			var items = jqxTree.jqxTree("getItems");

			_.map(items, unHighlightItem);

			if (! searchedString) {
				return;
			}

			JqxTree.collapseAllItems(jqxTree);

			var hasSeachedString = _.curry(itemLabelIncludes)(__, searchedString);

			_.map(
				_.filter(
					items,
					hasSeachedString
				),
				highlightItem
			);

		},

		collapseAllItems: function(jqxTree) {
		jqxTree.jqxTree('collapseAll');
	},

		refresh: function(jqxTree) {
			setTimeout(
				function() {
					jqxTree.jqxTree('refresh');
				},
				100
			);
		},

		getItemLi: function(jqxTree, item) {
			return _.get(
				_.find(
					jqxTree.jqxTree('getItems'),
					{value: item}
				),
				'element',
				null
			);
		},

		getItemFromLi: function(jqxTree, li) {
			return _.find(
				jqxTree.jqxTree('getItems'),
				{element: li}
			);
		},

		selectItem: function(jqxTree, item) {
			jqxTree.jqxTree('selectItem', JqxTree.getItemLi(jqxTree, item));
		},

		getSelectedItem: function(jqxTree) {
			return TreeItem.toRecord(jqxTree.jqxTree('getSelectedItem'));
		},

		uncheckAllItems: function(jqxTree) {
			jqxTree.jqxTree('uncheckAll');
		},

		checkItem: function(jqxTree, item) {
			jqxTree.jqxTree('checkItem', JqxTree.getItemLi(jqxTree, item), true);
		},

		uncheckItem: function(jqxTree, item) {
			jqxTree.jqxTree('checkItem', JqxTree.getItemLi(jqxTree, item), false);
		},

		getCheckedItems: function(jqxTree) {
			return _.map(
				jqxTree.jqxTree('getCheckedItems'),
				TreeItem.toRecord
			);
	},

		getExpandedItems: function(jqxTree) {
			return _.map(
				_.filter(
					jqxTree.jqxTree('getItems'), 
					{isExpanded: true}
				),
				TreeItem.toRecord
			);
		},

		expandItem: function(jqxTree, item) {
			jqxTree.jqxTree('expandItem', JqxTree.getItemLi(jqxTree, item));
		},

		collapseItem: function(jqxTree, item) {
			jqxTree.jqxTree('collapseItem', JqxTree.getItemLi(jqxTree, item));
		}
	};

	// Returns a Record "namespace" that is used to access data from a Tree Record using keyFor mapping
	var RecordAccessor = function (keyFor) {
		var Record = {
			id: _.curry(_.get, 2)(__, keyFor.id),

			parentId: _.curry(_.get, 2)(__, keyFor.parentId),

			label: _.curry(_.get, 2)(__, keyFor.label),

			isRoot: function (record) {
				return ! Record.parentId(record);
			},

			toTreeItem: _.curry(TreeItem.fromRecord)(__, __, keyFor),

			isParentOf: function (record, child) {
				return (Record.parentId(child) == Record.id(record)
					// To be able to get root records as children of record {id: 0}
					|| (! Record.id(record) && ! Record.parentId(child)));
			},

			getDirectChildrenFor: function (record, recordList) {
				return _.filter(recordList, _.curry(Record.isParentOf)(record));
			}
		};

		return Record;
	};

	// A TreeItem is a data structure that will become a jqxTree item
	var TreeItem = {
		parentId: _.curry(_.get, 2)(__, 'parentId'),

		isRoot: function (item) {
			return ! TreeItem.parentId(item);
		},

		isParentOf: function (parent, child) {
			return parent.id == TreeItem.parentId(child);
		},

		childrenFrom: function (treeItems, parent) {
			return _.filter(
				treeItems,
				_.curry(TreeItem.isParentOf)(parent)
			);
		},

		fromRecord: function (record, parentId, keyFor) {
			const NOT_SET = {dummy: true}; // (fallback) value returned by _.get when property does not exist, checked by refference
			var treeItem = _.reduce(
				keyFor,
				function (itemTree, recordKey, treeItemKey) {
					var value = _.get(record, recordKey, NOT_SET);
					if (! (value === NOT_SET)) {
						itemTree[treeItemKey] = value;
					}

					return itemTree;
				},
				{}
			);

			treeItem.id = _.uniqueId();
			treeItem.value = record;

			if (parentId) {
				treeItem.parentId = parentId;
			}

			return treeItem;
		},

		toRecord: function(treeItem) {
			return _.get(treeItem,'value');
		},

		getChildren: function (treeItem, recordList, keyFor) {

			var Record = RecordAccessor(keyFor);

			var directChildrenRecords = Record.getDirectChildrenFor(
				TreeItem.toRecord(treeItem),
				recordList
			);

			var directChildrenTreeItems = _.map(
				directChildrenRecords,
				_.curry(TreeItem.fromRecord)(__, treeItem.id, keyFor)
			);

			return  _.reduce(
				directChildrenTreeItems,
				function (resultList, treeItem) {
					return _.concat(
						resultList,
						TreeItem.getChildren(treeItem, recordList, keyFor)
					);
				},
				directChildrenTreeItems
			);
		}
	};

	// A TreeStructure is used to initialize a jqxTree
	var TreeStructure = {
		fromListOfTreeItems: function (treeItems) {
			var treeItemsWithItems = _.map(
				treeItems,
				function (treeItem) {
					treeItem.items = TreeItem.childrenFrom(treeItems, treeItem);

					return treeItem;
				}
			);

			var treeStructure = _.filter(treeItemsWithItems, TreeItem.isRoot);

			return treeStructure;
		},
		fromTreeStructure: function (tree, keyFor) {
			return _.map(
				tree,
				function (record) {
					var treeItem = TreeItem.fromRecord(record, record[keyFor.parentId], keyFor);

					if (_.isArray(record[keyFor.items])) {
						treeItem.items = TreeStructure.fromTreeStructure(record[keyFor.items], keyFor);
					}

					return treeItem;
				}
			);
		}
	};

	module.exports = TreeViewRenderer;