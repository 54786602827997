var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-json-pretty", {
    attrs: {
      deep: _vm.schema.deep,
      "show-double-quotes": _vm.schema.showDoubleQuotes,
      "show-line": _vm.schema.showLine,
      "show-line-number": _vm.schema.showLineNumber,
      editable: _vm.schema.editable,
      "editable-trigger": _vm.schema.editableTrigger,
      "selected-value": _vm.schema.selectedValue,
      "root-path": _vm.schema.rootPath,
      "highlight-mouseover-node": _vm.schema.highlightMouseoverNode,
      "highlight-selected-node": _vm.schema.highlightSelectedNode,
      "show-length": _vm.schema.showLength,
      "select-on-click-node": _vm.schema.selectOnClickNode,
      "collapsed-on-click-brackets": _vm.schema.collapsedOnClickBrackets,
      "node-selectable": _vm.schema.nodeSelectable,
      "selectable-type": _vm.schema.selectableType,
      "show-select-controller": _vm.schema.showSelectController,
      "show-icon": _vm.schema.showIcon,
      "show-key-value-space": _vm.schema.showKeyValueSpace,
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.schema, "selectedValue", $event)
      },
      "update:selected-value": function ($event) {
        return _vm.$set(_vm.schema, "selectedValue", $event)
      },
      "node-click": _vm.nodeClick,
      "brackets-click": _vm.bracketsClick,
      "icon-click": _vm.iconClick,
      "selected-change": _vm.selectedChange,
      input: _vm.valueChanged,
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }